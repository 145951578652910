import React, { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Avatar,
	Box,
	Button,
	Flex,
	Heading,
	HStack,
	Image,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Spinner,
	Stack,
	Text,
	useDisclosure,
	useMediaQuery,
	useTheme,
	VStack,
} from '@chakra-ui/react';
import loopIcon from 'Assets/svg/loopIcon2.svg';
import connectLeft from 'Assets/svg/ConnectLeft.svg';
import connectRight from 'Assets/svg/ConnectRight.svg';

import { useAppSelector } from 'Hooks';
import { IBrand } from 'Types/Interface/IBrand';
import { BrandPermissions, PrivacySelection } from 'Components/BrandPermissions';
import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';
import { TfiUnlock } from 'react-icons/tfi';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { RiInformationLine } from 'react-icons/ri';
import PriceToggle from 'Components/PriceToggle';
import { useGetPrivacyLevelsQuery } from 'services';
import { useLocale } from 'context/LocalizationContext';
import ErrorComponent from 'Components/ErrorComponent';
import { Content } from 'Pages/Dashboard/sections/AccountSettings/sections/Preferences/Content';


interface IBrandLoopProps {
	brand: IBrand;
	onSubmit: (permissions?: IUserBrandPermission) => void;
	isLoading: boolean;
}

export const SurveyBrandLoop = ({ brand, onSubmit, isLoading }: IBrandLoopProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { activeSurvey } = useAppSelector((state) => state.survey);
	const { userInfo } = useAppSelector((state) => state.authUser);	
	const selectedPermissions = useAppSelector((state) => state.userBrandPermissions.permissions);
	const { locale } = useLocale();
	const theme = useTheme();

	const [adjustedPoints, setAdjustedPoints] = useState<number | undefined>(undefined);
	const [extraPoints, setExtraPoints] = useState<number | undefined>(undefined);

	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const avatarSize = isMobile ? 'md' : 'lg';
	
	const { data: privacyLevelRewards, refetch, isLoading:isPrivacyLevelLoading, isError: isPrivacyLevelsError } = useGetPrivacyLevelsQuery({
		locale,
		surveyId: activeSurvey?.survey?.id,
	});

	const onPrivacyLevelChange = (extraPoints: number | undefined, adjustedPoints: number | undefined) => {
		setAdjustedPoints(adjustedPoints);
		setExtraPoints(extraPoints);
	};
	
	const isFirstInteraction = !activeSurvey.brandPermission?.privacyLevelId;
	const isSavedMaxSharing = activeSurvey.brandPermission?.isMaxSharingPrivacyLevel === true;

	useEffect(() => {
		if (!isOpen && adjustedPoints !== extraPoints) {
			refetch();
		}
	}, [isOpen, adjustedPoints, extraPoints, refetch]);

	useEffect(() => {
		if (isSavedMaxSharing && privacyLevelRewards) {
			// When isSavedMaxSharing is true, the PrivacySelector is hidden,
			// and extra points and adjusted extra points are assigned as fixed values from the survey,
			// rather than based on user selection.
			const initialPrivacyLevel = privacyLevelRewards.privacyLevels.find(
				({ extraPoints }) => extraPoints > 0
			);
			onPrivacyLevelChange(initialPrivacyLevel?.extraPoints, initialPrivacyLevel?.adjustedExtraPoints);
		}
	}, [privacyLevelRewards, isSavedMaxSharing]);

	return (
		<>
			<Stack spacing={[4, 5]}>
				<HStack pb={['2', '5']} justify={'center'}>
					<Avatar
						src={userInfo?.avatarUrl}
						name={`${userInfo?.firstName} ${userInfo?.lastName}`}
						objectFit='cover'
						size={avatarSize}
					/>
					<Image src={connectLeft} />
					<Image src={loopIcon} h={[6, 8]} />
					<Image src={connectRight} />
					<Image src={brand.logoUrl} objectFit='contain' maxH={['40px', '60px']} />
				</HStack>

				<HStack justify={'center'}>
					<Heading fontSize={['xl', '3xl']} color={'blackTextColor.100'}>
						Your{' '}
						<Text as='span' color={'lightCoral'}>
							Loop
						</Text>{' '}
						with {brand.name}
					</Heading>
				</HStack>

				{isSavedMaxSharing && adjustedPoints !== undefined && (<>
					<VStack fontSize={['md', 'lg']} justify={'center'} spacing={0}>
						<Text>You are sharing your preferences with  KitKat and so will earn an extra</Text>
						<PriceToggle mt={0} value={adjustedPoints}/>
						
					</VStack>
				</>)}

				{!isSavedMaxSharing && (<>
					{isFirstInteraction && <>
						<Text fontSize={['md', 'lg']}>This is the first time you are interacting with {brand.name}.</Text>
						<Text fontSize={['md', 'lg']}>Select from the options below the information you wish to share with {brand.name}.</Text>
					</>}

					{!isFirstInteraction && <Text fontSize={['md', 'lg']}>Would you like to share your preferences with {brand.name} and earn more?</Text>}

					<Text fontSize={['xs', 'sm']} color={'loopGrey.700'}>
						You may adjust the information you share, contact preferences and participation preferences at any time.
					</Text>

				</>)}

				{isPrivacyLevelLoading ? <Flex justifyContent={'center'} align={'center'}><Spinner display={'flex'} color='darkCoral' /></Flex> : isPrivacyLevelsError ? <ErrorComponent/> : (<>				
					{!isSavedMaxSharing && <PrivacySelection privacyLevelRewards={privacyLevelRewards} onPrivacyLevelChange={onPrivacyLevelChange} />}

					{!extraPoints && <Box h={[0, '43px']}> {/* placeholder box to avoid layout shift for 'Continue' button */}</Box>}

					{extraPoints && (
						<Box>
							{!!extraPoints && adjustedPoints === extraPoints && (
								<HStack fontSize={['xs', 'sm']} pt={[0, 3]}>
									<Box bg={'extraLightCoral'} p={2} borderRadius={'5px'}>
										<FaRegCircleCheck size={15} color={theme.colors.darkCoral} />
									</Box>
									<Box>
										You have answered all preferences question and earned max amount of reward
									</Box>
								</HStack>
							)}
							
							{!!extraPoints && adjustedPoints !== extraPoints && (
								<HStack fontSize={['xs', 'sm']} pt={[0, 3]}>
									<Box bg={'extraLightCoral'} p={2} borderRadius={'5px'}>
										<TfiUnlock size={15} color={theme.colors.darkCoral} />
									</Box>
									<Box>
										Unlock to earn more rewards by completing your{' '}
										<Link onClick={onOpen} variant={'primary'}>
										account preferences
										</Link>
										<Popover arrowSize={6} placement={'bottom-end'} offset={[8, 6]}>
											<PopoverTrigger>
												<Box as="span" display="inline-flex" pl={1} pb={1} verticalAlign={'middle'} cursor="pointer">
													<RiInformationLine />
												</Box>
											</PopoverTrigger>
											<PopoverContent fontSize="xs" width={'175px'}>
												<PopoverArrow />
												<PopoverBody>
													You have answered{' '}
													<Text as="span" color="darkCoral" fontWeight={'bold'}>
														{privacyLevelRewards?.completedPreferenceCount}/{privacyLevelRewards?.totalPreferenceCount}
													</Text>{' '}
												preferences. Increase the amount you can earn by completing the remaining unanswered preferences.
												</PopoverBody>
											</PopoverContent>
										</Popover>
									</Box>
								</HStack>
							)}
						</Box>
					)}
				</>)}

				{ isFirstInteraction && <Accordion variant={'primary'} allowMultiple>
					<AccordionItem>
						<AccordionButton>Information and Contact preferences</AccordionButton>
						<AccordionPanel>
							<BrandPermissions brandName={brand.name} />
						</AccordionPanel>
					</AccordionItem>
				</Accordion>}
			</Stack>

			<Box display={'flex'} justifyContent={'center'}>
				<Button
					variant='primary'
					w={['100%', '250px']}
					h={['48px', '60px']}
					fontSize={['sm', 'md']}
					onClick={() => {onSubmit();}}
					isLoading={isLoading}
					isDisabled={isLoading || (isFirstInteraction && !selectedPermissions.privacyLevelId)}
				>
					Continue
				</Button>
			</Box>
			
			<Modal isCentered isOpen={isOpen} onClose={onClose} scrollBehavior='inside' >
				<ModalOverlay  />
				<ModalContent maxH={'auto'} h={'98%'} borderRadius={0} borderTopRadius={isMobile ? 10 : 0}>
					<ModalHeader>Preferences</ModalHeader>
					<ModalCloseButton />
					<ModalBody  p={[5,7]}>
						<Content isSurvey={true} /> 
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
