import { CodeType } from './../Types/Enums/SurveyEnums';
import { createApi } from '@reduxjs/toolkit/query/react';
import { TagType } from 'Types/Enums';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { INotification } from 'Types/Interface/INotification';
import {
	IActiveSurvey,
	IAllScanHistory,
	IAddFeedbackDto,
	IOngoingFeedBack,
	ISurvey,
	ICodeScanResponse,
} from 'Types/Interface/ISurvey';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';
import { IPaginatedResponse } from './types';
import { IGeolocation } from 'Types/Interface/IGeoLocation';

type DynamicLinkQrCodeDto = {
	link: string;
};

export const surveyApi = createApi({
	reducerPath: 'surveyApi',
	baseQuery,
	tagTypes: [TagType.Notifications, TagType.Ongoing],
	endpoints: (builder) => ({
		getNotifications: builder.query<
			IPaginatedResponse<INotification[]>,
			{ pageNumber?: number; pageSize?: number; locale: string }
		>({
			query: ({ pageNumber, pageSize, locale }) => ({
				url: `/Notification?pageNumber=${pageNumber}&pageSize=${pageSize}`,
				headers: getLocalizationHeader(locale),
			}),
			keepUnusedDataFor: 0,
			providesTags: [TagType.Notifications],
		}),
		getUnreadNotifications: builder.query<
			IPaginatedResponse<INotification[]>,
			{ pageNumber?: number; pageSize?: number; locale: string }
		>({
			query: ({ pageNumber, pageSize, locale }) => ({
				url: `/Notification?isRead=${false}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
				headers: getLocalizationHeader(locale),
			}),
			keepUnusedDataFor: 0,
			providesTags: [TagType.Notifications],
		}),
		getOngoingSurveys: builder.query<IJsonResponse<IOngoingFeedBack[]>, { locale: string }>({
			query: ({ locale }) => ({
				url: '/feedback/ongoing',
				headers: getLocalizationHeader(locale),
			}),
			providesTags: [TagType.Ongoing],
		}),
		readAllNotifications: builder.mutation<unknown, { locale: string }>({
			query: ({ locale }) => ({
				url: '/Notification/read-all',
				method: 'POST',
				headers: getLocalizationHeader(locale),
			}),
			invalidatesTags: [TagType.Notifications],
		}),
		scanCode: builder.mutation<IJsonResponse<ICodeScanResponse>, { code: string; codeType: CodeType; geolocation: IGeolocation | null, locale: string }>({
			query: ({ code, codeType, locale, geolocation }) => ({
				url: `survey/scan/${code}?codeType=${codeType}`,
				method: 'POST',
				body: geolocation,
				headers: getLocalizationHeader(locale),
			}),
			invalidatesTags: [TagType.Notifications, TagType.Ongoing],
		}),
		getSurveyBySurveyId: builder.query<IJsonResponse<ISurvey>, { surveyId: string; locale: string }>({
			query: ({ surveyId, locale }) => ({
				url: `survey/${surveyId}`,
				headers: getLocalizationHeader(locale),
			}),
			keepUnusedDataFor: 0,
		}),
		getSurveyByCode: builder.query<IJsonResponse<ISurvey>, { code: string; locale: string }>({
			query: ({ code, locale }) => ({
				url: `survey/code/${code}`,
				headers: getLocalizationHeader(locale),
			}),
			keepUnusedDataFor: 0,
		}),
		updateFeedback: builder.mutation<number, { feedback: IAddFeedbackDto; locale: string }>({
			query: ({ feedback: body, locale }) => ({
				url: 'feedback/addupdatefeedback',
				method: 'POST',
				body,
				headers: getLocalizationHeader(locale),
			}),
			invalidatesTags: [TagType.Notifications, TagType.Ongoing],
		}),
		getQrCodeFromDynamicLink: builder.mutation<unknown, { body: DynamicLinkQrCodeDto; locale: string }>({
			query: ({ body, locale }) => ({
				url: 'survey/dynmaiclinkqrCode',
				method: 'POST',
				body,
				headers: getLocalizationHeader(locale),
			}),
		}),
		getAllScanHistory: builder.query<IJsonResponse<IAllScanHistory>, { locale: string }>({
			query: ({ locale }) => ({
				url: 'survey/allscanhistory',
				headers: getLocalizationHeader(locale),
			}),
		}),
		continueSurvey: builder.mutation<IJsonResponse<IActiveSurvey>, { qrCode: string; locale: string }>({
			query: ({ qrCode, locale }) => ({
				url: `survey/continue/qrCode/${qrCode}`,
				method: 'GET',
				headers: getLocalizationHeader(locale),
			}),
		}),
	}),
});

export const {
	useGetNotificationsQuery,
	useGetUnreadNotificationsQuery,
	useGetOngoingSurveysQuery,
	useReadAllNotificationsMutation,
	useScanCodeMutation,
	useGetSurveyBySurveyIdQuery,
	useGetSurveyByCodeQuery,
	useUpdateFeedbackMutation,
	useGetQrCodeFromDynamicLinkMutation,
	useGetAllScanHistoryQuery,
	useContinueSurveyMutation,
} = surveyApi;
