import React from 'react';
import { Dashboard } from 'Pages/Dashboard';
import { LoginAndSecurity } from 'Pages/Dashboard/sections/AccountSettings/sections';
import { Notifications } from 'Pages/Dashboard/sections/AccountSettings/sections/Notifications';
import { PaymentAndPayout } from 'Pages/Dashboard/sections/AccountSettings/sections/PaymentAndPayout';
import { Preferences } from 'Pages/Dashboard/sections/AccountSettings/sections/Preferences';
import { PrivacyAndSharing } from 'Pages/Dashboard/sections/AccountSettings/sections/PrivacyAndSharing';
import { Referral } from 'Pages/Dashboard/sections/AccountSettings/sections/Referral';
import { AppRoutes } from './AppRoutes';
import { ProductPage } from 'Pages/ProductPage';
import { Explore } from 'Pages/ExplorePage/Explore';
import { BrandPage } from 'Pages/BrandPage/BrandPage';
import { NewSurvey } from 'Pages/Survey';
import { Home as AuthHomePage } from 'Pages/HomePage/Home';
import { AllBrands } from 'Pages/AllBrands/AllBrands';
import { AllProducts } from 'Pages/AllProducts/AllProducts';
import { NotificationPage } from 'Pages/NotificationPage/NotificationPage';
import { AllWithdrawalHistory } from 'Pages/Dashboard/sections/Rewards/AllWithdrawalHistory';
import { BrandLoopDetails } from 'Pages/Dashboard/sections/BrandLoops/BrandLoopDetails';
import { BrandLoops } from 'Pages/Dashboard/sections';
import { HomePage } from 'Pages/LandingPage/HomeSection/HomePage';
import { LoginPage } from 'Pages/LoginPage/LoginPage';
import { ResetPassword } from 'Pages/ResetPassword/ResetPassword';
import { RegisterPage } from 'Pages/RegisterPage/RegisterPage';
import { Verification } from 'Pages/RegisterPage/Verification';
import { SuccessPage } from 'Pages/RegisterPage/SuccessPage';
import { RegisterPartialUser } from 'Pages/PartialUserPage/RegisterPartialUser';
import { IntroductionPage } from 'Pages/RegisterPage/IntroductionPage';
import { ComingSoonPage } from 'Pages/ComingSoonPage/ComingSoonPage';
import { BusinessSection } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import { LoopAppPage } from 'Pages/LandingPage/LoopAppSection/LoopAppPage';
import { HowItWorksPage } from 'Pages/LandingPage/HowItWorksSection/HowItWorksPage';
import { BrandsPage } from 'Pages/LandingPage/BrandsSection/BrandsPage';
import { DynamicProductPage } from 'Pages/DynamicLinkProduct';
import { FeaturedBrandsPage } from 'Pages/LandingPage/FeaturedBrandsSection/FeaturedBrandsPage';
import { EnterCodePage } from 'Pages/DynamicLinkProduct/EnterCodePage';
import { ActivityDetails } from 'Pages/Dashboard/sections/UserActivity/ActivityDetails';
import { LoopForBusiness } from 'Pages/LoopForBusiness/LoopForBusiness';
import { ProcessOverview } from 'Pages/ProcessOverview/ProcessOverview';
import { Contact } from 'Pages/BusinessContact/Contact';

export const nonAuthRoutes = [
	{
		path: AppRoutes.landingpage,
		element: <HomePage />,
	},
	{
		path: AppRoutes.login,
		element: <LoginPage />,
	},
	{
		path: AppRoutes.resetPassword,
		element: <ResetPassword />,
	},
	{
		path: AppRoutes.register,
		element: <RegisterPage />,
	},
	{
		path: AppRoutes.verify,
		element: <Verification />,
	},
	{
		path: AppRoutes.success,
		element: <SuccessPage />,
	},
	{
		path: AppRoutes.registerPartial,
		element: <RegisterPartialUser />,
	},
	{
		path: AppRoutes.introduction,
		element: <IntroductionPage />,
	},
	{
		path: AppRoutes.comingSoon,
		element: <ComingSoonPage />,
	},
	{
		path: AppRoutes.business,
		element: <BusinessSection />,
	},
	{
		path: AppRoutes.LoopForBusiness,
		element: <LoopForBusiness />,
	},
	{
		path: AppRoutes.processOverview,
		element: <ProcessOverview/>,
	},
	{
		path: AppRoutes.contact,
		element: <Contact/>,
	},
	{
		path: AppRoutes.loopApp,
		element: <LoopAppPage />,
	},
	{
		path: AppRoutes.howItWorks,
		element: <HowItWorksPage />,
	},
	{
		path: AppRoutes.brands,
		element: <BrandsPage />,
	},
	{
		path: AppRoutes.dynamicLinkProduct,
		element: <DynamicProductPage />,
	},
	{
		path: AppRoutes.featuredBrands,
		element: <FeaturedBrandsPage />,
	},
	{
		path: AppRoutes.EnterCode,
		element: <EnterCodePage />,
	},
];

export const authRoutes = [
	{
		path: AppRoutes.productPage,
		element: <ProductPage />,
	},
	{
		path: AppRoutes.home,
		element: <AuthHomePage />,
	},
	{
		path: AppRoutes.survey,
		element: <NewSurvey />,
	},
	{
		path: AppRoutes.explore,
		element: <Explore />,
	},
	{
		path: AppRoutes.brand,
		element: <BrandPage />,
	},
	{
		path: AppRoutes.allBrands,
		element: <AllBrands />,
	},
	{
		path: AppRoutes.allProducts,
		element: <AllProducts />,
	},
	{
		path: AppRoutes.notifications,
		element: <NotificationPage />,
	},
];

export const fullyRegisteredRoutes = [
	{
		path: AppRoutes.accountDashboard,
		element: <Dashboard />,
	},
	{
		path: AppRoutes.security,
		element: <LoginAndSecurity />,
	},
	{
		path: AppRoutes.privacy,
		element: <PrivacyAndSharing />,
	},
	{
		path: AppRoutes.paymentAndPayout,
		element: <PaymentAndPayout />,
	},
	{
		path: AppRoutes.referral,
		element: <Referral />,
	},
	{
		path: AppRoutes.notification,
		element: <Notifications />,
	},
	{
		path: AppRoutes.preference,
		element: <Preferences />,
	},
	{
		path: AppRoutes.withdrawalHistory,
		element: <AllWithdrawalHistory />,
	},
	{
		path: AppRoutes.brandLoops,
		element: <BrandLoops />,
	},
	{
		path: AppRoutes.brandLoop,
		element: <BrandLoopDetails />,
	},
	{
		path: AppRoutes.activityDetails,
		element: <ActivityDetails />,
	},
];
