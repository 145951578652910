import React from 'react';
import { Image, Text, Flex } from '@chakra-ui/react';
import { IBrand } from 'Types/Interface/IBrand';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { AppRoutes } from 'Routes/AppRoutes';
import { useToast } from 'Hooks';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';

interface BrandCaoruselItemsProps {
  brands?: IBrand;
  visibility: boolean; 
}

export const BrandCaoruselItems = ({ brands, visibility}: BrandCaoruselItemsProps) => {
	const navigate = useLocalizedNavigate();
	const { alert } = useToast();

	const handleNavigate = (brandId?: string) => {
		if(brandId){
			navigate(AppRoutes.brand.replace(':brandId', brandId));
		}else{
			alert('error','Something went wrong');
		}
	};

	return (
		<Flex
			direction={'column'}
			alignItems={{ base: 'flex-start', md: 'flex-start', xxl: 'flex-start' }}
			justifyContent={'center'}
			h={'100%'}
			// mb={[0,16]}
		>
			<Flex
				key={brands?.id}
				direction={'column'}
				justifyContent={'center'}
			>
				<Flex
					direction={'column'}
					justifyContent={'flex-end'}
					alignItems={'flex-start'}
					onClick={() => handleNavigate(brands?.id)}
					cursor={'pointer'}
					h="250px"
					w={['250px', '200px', 'full']}
					position="relative"
				>
					<Image
						src={brands?.imageUrl}
						boxSize="100%"
						objectFit="cover"
						rounded={4}
						filter={'auto'}
						brightness={'80%'}
					/>
					<Image
						src={brands?.logoUrl}
						position="absolute"
						m={[2, 5]}
						w={['3rem', '4rem']}
						h={['3rem', '4rem']}
						objectFit={'cover'}
						bg={'rgba(255, 255, 255, 0.8)'}
						borderRadius={'50%'}
						p={2}
					/>
				</Flex>
				<Flex alignItems={'center'} mt={4}>
					<Text
						fontSize={{ base: 'sm', tablet: 'sm', xxl: '.9rem' }}
						fontWeight={'semibold'}
						cursor={'pointer'}
						textAlign="left"
					>
						{brands?.name}
					</Text>
					{visibility === true && (
						<ChevronRightIcon w={4} h={3} color={'gray.700'} />
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};