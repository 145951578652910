import React from 'react';
import { Flex,IconButton, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetActiveSurvey } from 'store/slices';
import { AppRoutes } from 'Routes/AppRoutes';

export const HeaderSection = () => {
	const location = useLocation();
	const canClose = location.pathname !==  AppRoutes.dynamicLinkProduct;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		activeSurvey: { survey, responses },
		isStartSurvey
	} = useAppSelector((state) => state.survey);

	const headings = {
		surveyTitle: survey?.title,
		brandName: survey?.product.brand.name,
	};

	const isNewSurvey = (!responses || responses.length === 0) && !isStartSurvey;

	const handleCloseSurvey = () => {
		navigate(-1);
		setTimeout(() => {
			dispatch(resetActiveSurvey());
		}, 1000);
	};

	return (
		<Flex
			direction={['row-reverse', 'row']}
			px="10"
			color="white"
			bg={isNewSurvey ? '#ECEFF2' : '#264653'}
			h={['60px','118px']}
			alignItems="center"
			justifyContent="space-between"
		>
			<Flex 
				direction={['column', 'column']}
				alignItems={['flex-end','flex-start']}
			>
				{!isNewSurvey &&(
					<>
						<Text 
							fontSize={['10px','25px']}
							fontWeight={['400', '600']}
							textAlign={'right'}
						>
							{headings.surveyTitle}
						</Text>
						<Text fontSize={['10px','14px']}>by {headings.brandName}</Text>
					</> 
				)}
			</Flex>
			{canClose && <IconButton
				color={isNewSurvey ? '#899AA2' : 'white'}
				aria-label="close survey"
				bg={['#5860681A','none']}
				w={'20px'} h={'20px'}
				borderRadius={['50%', 0]}
				icon={<CloseIcon boxSize={['8px', '18px']} color={[ isNewSurvey ? 'loopGrey.500' : 'white', isNewSurvey ? '#26465380' : 'white']}  />}
				onClick={handleCloseSurvey}
			/>}
		</Flex>
	);
};
