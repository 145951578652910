import { fetchCountryByIP as fetchCountryByIP } from 'Utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import StorageService from './storageService';

export const useLocalizationRedirect = () => {
	const [isRedirectingToLocalizedRoute, setIsRedirectingToLocalizedRoute] = useState(true);
	const [currentKey, setCurrentKey] = useState('');
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const checkForRedirect = async () => {
			const isLocalized = /^\/[a-z]{2,4}\/[a-z]{2,4}\//.test(location.pathname);
			if (isLocalized) {
				setIsRedirectingToLocalizedRoute(false);
			} else {
				let country = StorageService.getCountry();
				let locale = StorageService.getLanguage();

				// Fetch country and locale by IP if not in localStorage
				if (!country || !locale) {
					const fetchedCountry = await fetchCountryByIP();
					country = fetchedCountry || StorageService.getCountryOrDefault();
					locale = fetchedCountry?.defaultLanguage.code || StorageService.getLanguageOrDefault();

					// Store in localStorage for future visits
					StorageService.setCountry(country);
					StorageService.setLanguage(locale);
				}

				// Redirect to localized route
				const localizedPath = `/${country.url}/${locale}${location.pathname}`;
				setIsRedirectingToLocalizedRoute(true);
				navigate(localizedPath, { replace: true });

				// Force re-render after URL update by updating the key
				setCurrentKey(localizedPath);
				setIsRedirectingToLocalizedRoute(false);
			}
		};

		checkForRedirect();
	}, [location, navigate]);

	return { isRedirectingToLocalizedRoute, currentKey };
};
