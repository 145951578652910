import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'Hooks';
import { setQrCodeFromDynamicLink } from 'store/slices';
import { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
import { AppRoutes } from 'Routes/AppRoutes';
import { localizePath } from 'Utils';

export const ScanPage = () => {
	const { qrCode } = useParams();
	const dispatch = useAppDispatch();
	const authContext = useContext(AuthContext);

	useEffect(() => {
		if (qrCode?.includes(':')) {
			dispatch(setQrCodeFromDynamicLink(qrCode));
		}
	}, [qrCode, dispatch]);

	const redirectRoute = authContext.isAuthenticated()
		? AppRoutes.home
		: AppRoutes.dynamicLinkProduct.replace(':qrCode', qrCode ?? '');

	return <Navigate to={localizePath(redirectRoute)} replace />;
};
