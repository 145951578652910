import React, { useEffect, useState } from 'react';
import { HomePageLayout } from 'Layouts/HomePageLayout';
import { DiscoverProducts } from './sections/DiscoverProducts';
import { DiscoverBrands } from './sections/DiscoverBrands';
// import { HomeFeaturedBrands } from './sections/HomeFeaturedBrands';
// import { ExploreSection } from 'Pages/LandingPage/HomeSection/sections/ExploreSection';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { setUserCoords, setQrCodeFromDynamicLink, setWebCodeFromEnterCode } from 'store/slices';
import { OngoingSurveyContainer } from './sections/OngoingSurveyContainer';
import { Flex, Spinner, useMediaQuery } from '@chakra-ui/react';
import { CodeType } from 'Types/Enums';
import { Header } from './sections/Header';
import { CustomModal } from 'Components';
import { DynamicProductScanErrorModal } from '../DynamicLinkProduct/sections/DynamicProductScanErrorModal';
import { useCodeScan } from 'Hooks/useCodeScan';
import { EmailVerification } from './Components/EmailVerification';
import { useRefreshUserProfile } from 'Utils/useRefreshUserProfile';
import { useHomePageLocalization } from 'Utils/localizationHooks';
import { IHeaderLocalization } from 'Types/ComponentLocalizations/IHeaderLocalization';
import { useLocale } from 'context/LocalizationContext';
import { useGeolocation } from 'Hooks/useGeolocation';
import { useUpdateUserCountryWithGeolocationMutation } from 'services';
import { isGeolocationExpired } from 'Utils';

export const Home = () => {
	const { locale } = useLocale();
	const dispatch = useAppDispatch();
	const { userGeolocation } = useAppSelector((state) => state.authUser);
	const coords = useGeolocation(isGeolocationExpired(userGeolocation));
	

	const [isOpen, setIsOpen] = useState(false);
	const [errMsg, setErrMsg] = useState('');

	const { refreshUserProfile } = useRefreshUserProfile();
	const [updateUserCountryWithGeolocation] = useUpdateUserCountryWithGeolocationMutation();


	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

	useEffect(() => {
		refreshUserProfile({ locale });
	}, [locale]);

	const { localization: homePageLocalization } = useHomePageLocalization();

	const { qrCodeFromDynamicLink, webCodeFromEnterCode } = useAppSelector((state) => state.survey);

	const { handleScan, isLoading: isScanning } = useCodeScan({
		onScanComplete: () => {
			dispatch(setQrCodeFromDynamicLink(''));
			dispatch(setWebCodeFromEnterCode(''));
			onClose();
		},
		onError: (message) => {
			dispatch(setQrCodeFromDynamicLink(''));
			dispatch(setWebCodeFromEnterCode(''));
			setErrMsg(message);
			setIsOpen(true);
		},
	});

	useEffect(() => {
		if (qrCodeFromDynamicLink) {
			handleScan(qrCodeFromDynamicLink, CodeType.qrCode);
		}
		if (webCodeFromEnterCode) {
			handleScan(webCodeFromEnterCode, CodeType.Webcode);
		}
	}, [qrCodeFromDynamicLink, webCodeFromEnterCode]);

	useEffect(() => {
		if (isGeolocationExpired(userGeolocation) && coords) {
			dispatch(setUserCoords(coords));
			updateUserCountryWithGeolocation({geoLocation: coords, locale});
		}
	}, [coords, userGeolocation, dispatch]);

	const onClose = () => {
		setIsOpen(false);
	};

	if (isScanning) {
		return (
			<Flex
				justifyContent={'center'}
				alignItems={'center'}
				padding='6'
				boxShadow='lg'
				bg='white'
				h={'100vh'}
				w={'full'}
			>
				<Spinner size='xl' color='darkCoral' />
			</Flex>
		);
	}

	return (
		<>
			<HomePageLayout
				JumbotronSection={<Header localization={homePageLocalization as IHeaderLocalization} />}
				StickyNotificationSection={<EmailVerification />}
			>
				<OngoingSurveyContainer />
				{/* <HomeFeaturedBrands /> */}
				<DiscoverProducts
					title={homePageLocalization?.discoverProducts}
					seeAllLinkText={homePageLocalization?.seeAllProducts}
				/>
				<DiscoverBrands
					title={homePageLocalization?.discoverBrands}
					seeAllLinkText={homePageLocalization?.seeAllBrands}
				/>
				{/* <ExploreSection /> */}
				<CustomModal
					content={<DynamicProductScanErrorModal errorText={errMsg} />}
					isOpen={isOpen}
					onClose={onClose}
					modalContentStyles={{ borderRadius: 10, boxShadow: '0px 0px 30px rgba(88, 96, 104, 0.1)' }}
					size={isLargerThan768 ? 'md' : 'full'}
					isCentered
				/>
			</HomePageLayout>
		</>
	);
};
