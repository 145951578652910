import Scan from 'Assets/Images/Scan.png';
import EarnPoints from 'Assets/Images/EarnPoints.png';
import AnswerSurvey from 'Assets/Images/AnswerSurvey.png';
import { IImageSlider } from './../Types/Interface/ILandingPage';
import MobileAnswerSurvery from 'Assets/Images/MobileAnswerSurvery.png';
import MobileEarnPoints from 'Assets/Images/MobileEarnPoints.png';
import MobileScan from 'Assets/Images/MobileScan.png';

export const Carousels: IImageSlider[] = [
	{
		id: 0,
		label: 'Scan to Join the Conversation',
		src: Scan,
		mobileSrc:MobileScan,
		text: 'Find a Loop connector inside select products from participating brands. Scan it to join the brand’s community and instantly earn cash rewards just for connecting!'
	},
	{
		id: 1,
		label: 'Share Your Voice',
		src: AnswerSurvey,
		mobileSrc: MobileAnswerSurvery,
		text: 'When you’re ready, opt to share your thoughts through a quick survey or other engagement activities. Have a real conversation with the brand, help them improve the products you love, and earn more rewards for your input.'
	},
	{
		id: 2,
		label: 'Redeem and Keep Engaging',
		src: EarnPoints,
		mobileSrc:MobileEarnPoints,
		text: 'Easily transfer your earnings to your bank or PayPal account to spend anywhere. Every time you repurchase a product with a Loop Connector, scan again to stay connected, earn more, and deepen your relationship with the brand—whether you discover Loop organically or via the app.',
	}
];