import React from 'react';
import { Box, VStack, ButtonProps, Button, Divider, useMediaQuery, theme, Stack, BoxProps } from '@chakra-ui/react';
import { AppRoutes } from 'Routes/AppRoutes';
import { ErrorRobot } from './Icons/ErrorRobot';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';

const StyledLink: React.FC<ButtonProps> = ({ children, ...props }) => {
	return (
		<Button textDecoration={'underline'} fontWeight={'bold'} _hover={{ textDecoration: 'none' }} {...props}>
			{children}
		</Button>
	);
};

const ErrorComponent: React.FC<BoxProps> = ({ ...props }) => {
	const navigate = useLocalizedNavigate();

	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

	const reloadPage = () => {
		window.location.reload();
	};

	const contactSupport = () => {
		navigate(AppRoutes.help);
	};

	return (
		<Box
			width='100%'
			minHeight={['200px', null, '250px']}
			display='flex'
			alignItems='center'
			justifyContent='center'
			bg='extraLightCoral'
			borderRadius='5px'
			{...props}
		>
			<VStack spacing={7}>
				<ErrorRobot w={['60px', null, '100px']} />
				<Stack
					direction={['column', null, 'row']}
					spacing={[4, null, 7]}
					fontSize={['sm', null, 'md']}
					color='darkCoral'
				>
					<StyledLink onClick={reloadPage}>Reload page</StyledLink>
					{!isMobile && <Divider orientation='vertical' height={'5'} borderColor='darkCoral' />}
					<StyledLink onClick={contactSupport}>Contact support</StyledLink>
				</Stack>
			</VStack>
		</Box>
	);
};

export default ErrorComponent;
