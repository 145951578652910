/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Link as RLink } from 'react-router-dom';
import { useFormik } from 'formik';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { AuthLayout } from 'Layouts/AuthLayout';
import { PhoneNumberInput } from 'Components/FormInput/PhoneNumberInput';
import 'Extensions/Methods';
import { AppRoutes } from 'Routes/AppRoutes';
import { IJwtToken, INewUserRequestbody } from 'Types/Interface/IUser';
import { initialValues, validationSchema } from './utils';
import {
	Box,
	Flex,
	Heading,
	VStack,
	Text,
	Button,
	Link,
	Divider,
	Stack,
} from '@chakra-ui/react';
import { useToast, useSocialLogin, useValidateAndSendVerification } from 'Hooks';
// import { GoogleLogin, GoogleLoginResponseOffline } from 'react-google-login';
import { LoopVectorIcon } from 'Components/Icons';
// import { ReactFacebookLoginInfo } from 'react-facebook-login/dist/facebook-login-render-props';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { GoogleLogin, GoogleCredentialResponse } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { localizePath } from 'Utils';
import { TermsAgreement } from 'Components/TermsAgreement';

export const RegisterPage = () => {
	const formData = sessionStorage.getItem('registration_details') ?? JSON.stringify({});
	const parsedFormData = JSON.parse(formData) as INewUserRequestbody;
	const navigate = useLocalizedNavigate();
	const { handleGoogleLogin } = useSocialLogin(); // return this (handleFacebookLogin),
	const { mutate: validateAndSendVerificationCode, data: validationData, isLoading, isSuccess, isError, error } = useValidateAndSendVerification();
	const { alert } = useToast();
	
	const [termsAccepted, setTermsAccepted] = useState(false);

	const { handleChange, values, setFieldValue, handleSubmit, errors } = useFormik({
		initialValues: Object.keys(parsedFormData).length ? parsedFormData : initialValues,
		validationSchema,
		onSubmit: (values) => {
			sessionStorage.setItem('registration_details', JSON.stringify(values));

			if (values.countryCode) {
				const requestBody: INewUserRequestbody = {
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					phoneNumber: values.phoneNumber.toInternationalPhoneNumber(),
					countryCode: values.countryCode?.toInternationalPhoneNumber(),

				};
				validateAndSendVerificationCode(requestBody);
			}
		},
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onGoogleSuccess = (response: GoogleCredentialResponse) => {
		const decodedToken = jwt_decode(response.credential ?? '') as IJwtToken;
		handleGoogleLogin(response.credential ?? '', decodedToken?.email);
	};
	const onLoginFailure = () => {
		alert('error', 'Login failed');
	};

	// const responseFacebook = (userInfo: ReactFacebookLoginInfo) => {
	// 	handleFacebookLogin(userInfo?.accessToken);
	// };

	useEffect(() => {
		if (isSuccess) {
			if (validationData?.status) {
				alert('success', `Token was sent to ${values.phoneNumber}`);
				navigate(AppRoutes.verify);
			}
			else {
				alert('error', validationData?.errors?.[0].message ?? 'Validation failed');
			}
		}
	}, [isSuccess]);

	useDefaultErrorHandler(isError, error);

	return (
		<AuthLayout>
			<Stack
				alignItems={['flex-start', 'center']}
				h={['auto', 'auto']}
				justify={['center', 'center']}
				w={['auto', '100%']}
				bgColor={['#FAFAFA', 'white']}
				border={['2px solid rgba(88, 96, 104, 0.07)', 'none']}
				borderRadius={['20px', 'none']}
				flex={1}
				overflow={'auto'}
				position={['absolute', 'relative']}
				left={0}
				right={0}
				m={['15px', '0']}
				py={['30px', 0]}
			>
				<LoopVectorIcon display={['flex', 'none']} pl={['5', '0']} />
				<Box
					p={['5', '10']}
					borderRadius='10px'
					boxShadow={['none', '0px 0px 90px rgba(0, 0, 0, 0.1)']}
					w={['100%', 'auto']}
				>
					<Heading alignSelf='flex-start' fontSize='xl' mb='4'>
						Sign up
					</Heading>
					<Text fontSize='sm' mb='10'>
						Fill this sign up form to create a new account
					</Text>
					<VStack spacing={3} mb={'10px'} alignItems='flex-start'>
						<CustomInput
							onChange={handleChange}
							name='firstName'
							type='text'
							placeholder='First name'
							error={errors.firstName}
							value={values.firstName}
						/>
						<CustomInput
							onChange={handleChange}
							name='lastName'
							type='text'
							placeholder='Last name'
							error={errors.lastName}
							value={values.lastName}
						/>
						<CustomInput
							onChange={handleChange}
							name='email'
							type='text'
							placeholder='Email'
							error={errors.email}
							value={values.email}
						/>
						<CustomInput
							onChange={handleChange}
							name='password'
							type='password'
							placeholder='Password'
							error={errors.password}
							value={values.password}
						/>
						<PhoneNumberInput
							value={values.phoneNumber}
							onChange={(phoneNumber, countryPhoneCode) => {
								setFieldValue('phoneNumber', phoneNumber);
								setFieldValue('countryCode', countryPhoneCode);
							}}
							error={errors.phoneNumber}
						/>
						<TermsAgreement isAccepted={termsAccepted} onAccepted={setTermsAccepted} />
						<Button
							isDisabled={!termsAccepted}
							type='button'
							variant='primary'
							w='full'
							isLoading={isLoading}
							onClick={() => handleSubmit()}
						>
							Sign up
						</Button>
					</VStack>
					<Flex mb='2' alignItems='center'>
						<Text fontSize='xs'>Already a user? </Text>
						<Link as={RLink} to={localizePath(AppRoutes.login)} ml='1' fontSize='xs' fontWeight={600} color='darkCoral'>
							Login
						</Link>
					</Flex>
					<Flex my={'20px'} justifyContent={'space-between'} alignItems={'center'}>
						<Divider w={['150px', '195px']} />
						<Text mx={['9px', '20px']}> OR </Text>
						<Divider w={['150px', '195px']} />
					</Flex>

					<Flex h={['48px', '50px']} w={'100%'} justifyContent={'center'} alignItems={'center'} cursor={'pointer'}>
						<GoogleLogin
							width={'auto'}
							shape='pill'
							onSuccess={onGoogleSuccess}
							onError={onLoginFailure}
							useOneTap={true}
							text={'continue_with'}
						/>
					</Flex>

					{/* <FacebookLogin
						appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
						callback={responseFacebook}
						fields='name,email,picture'
						scope="public_profile,email"
						render={(renderProps: RenderProps) => (
							<IconButton
								onClick={renderProps.onClick}
								borderRadius='50%'
								h='30px'
								mr='4'
								aria-label={'facebook'}
								icon={<FacebookIcon h='100%' />}
							/>
						)}
					/> */}
				</Box>
			</Stack>
		</AuthLayout>
	);
};
