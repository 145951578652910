import React, { useEffect } from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	Text,
	VStack,
	useTheme,
	useMediaQuery,
	ButtonProps,
	useDisclosure,
} from '@chakra-ui/react';
import { convertLNTToUsd } from 'Utils';
import { useAppSelector, useTilia } from 'Hooks';
import { CustomModal } from './CustomModal';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import BalanceDetailsAccordion from './BalanceDetailsAccordion';
import { ITiliaWithdrawalPayload } from 'Types/Interface/ITilia';
import { useLogPayoutRequestMutation } from 'services';
import { useLocale } from 'context/LocalizationContext';

// interface IBalanceProps {
// 	isLoading?: boolean;
// }

export const Balance = () => {
	const { locale } = useLocale();
	const theme = useTheme();
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

	const { userSummary } = useAppSelector((state) => state.authUser);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [logPayoutRequestMutation, { isError: isLogPayoutError, error: logPayoutError }] =
		useLogPayoutRequestMutation();
	useDefaultErrorHandler(isLogPayoutError, logPayoutError);

	const {
		loadWidgetScript,
		isLoadingRedirect,
		initTiliaWidget,
		onCompleteCallbackPayload,
		widgetScriptLoadingRef,
		widgetScriptLoadedRef,
		isError: isWidgetError,
		error: widgetError,
	} = useTilia({
		flow: 'payout-full',
		flowConfig: {
			currency: process.env.REACT_APP_TILIA_CURRENCY,
		},
	});

	useEffect(() => {
		loadWidgetScript();
	}, [widgetScriptLoadingRef, widgetScriptLoadedRef, isLoadingRedirect]);

	useDefaultErrorHandler(isWidgetError, widgetError, onClose);

	const showRedeemWidget = () => {
		onOpen();
		initTiliaWidget();
	};

	const WithdrawButton: React.FC<ButtonProps> = ({ ...props }) => (
		<Button variant='primary' width='160px' h={'55px'} onClick={showRedeemWidget} {...props}>
			Withdraw
		</Button>
	);

	useEffect(() => {
		if (onCompleteCallbackPayload) {
			const payoutId = (onCompleteCallbackPayload as ITiliaWithdrawalPayload).payout_status_id;
			logPayoutRequestMutation({ payoutId, locale });
			onClose();
		}
	}, [onCompleteCallbackPayload]);

	return (
		<VStack
			p={8}
			h={'auto'}
			spacing={4}
			align={'flex-start'}
			w={'100%'}
			borderWidth={1}
			borderRadius={'10px'}
			overflow={'hidden'}
		>
			<Text fontSize={'14px'} color='#586068' textTransform={'uppercase'}>
				Available balance
			</Text>

			<Flex alignItems={['left', null, 'center']} w='100%' direction={['column', null, 'row']}>
				<Heading fontSize={['40px']} fontWeight='bold' flex={'1 1 0'}>
					{(userSummary?.availableRewardPoints ?? 0).toLocaleString()} LNT
					{/* {isLoading && <Spinner color='loop.500' ml='20px' />} */}
				</Heading>

				{!isMobile && (
					<Box flex={'1 1 0'}>
						<WithdrawButton />
					</Box>
				)}
			</Flex>

			<Text color='#586068' fontSize={'14px'} pb={[8, null, 0]}>
				{convertLNTToUsd(userSummary?.availableRewardPoints ?? 0).toFixed(2)} USD
			</Text>

			{isMobile && <WithdrawButton />}

			<BalanceDetailsAccordion />

			<CustomModal
				content={<Box id='tilia-widget-wrapper'></Box>}
				size={isMobile ? 'full' : 'xxl'}
				showCloseButton={false}
				onClose={onClose}
				isOpen={isOpen}
				modalContentStyles={{
					borderRadius: isMobile ? 0 : 10,
					h: isMobile ? '100%' : '1060px',
					w: isMobile ? '100%' : '680px',
				}}
			/>
		</VStack>
	);
};
