import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, HStack, IconButton, Stack, Text, VStack,Divider, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import { motion } from 'framer-motion';

const steps = [
	{
		title: 'Discover the Loop',
		description:'Customers scan a QR code or tap an NFC chip on your product packaging.',
		video: 'https://sumosoft.blob.core.windows.net/archibald/pages/about/about-banner-video.mp4?v=3470507',
	},
	{
		title: 'Share Feedback',
		description:'Customers complete a short survey or provide product feedback.',
		video: 'https://sumosoft.blob.core.windows.net/archibald/pages/about/about-banner-video.mp4?v=3470507',
	},
	{
		title: 'Earn Rewards',
		description:'Customers receive points redeemable for cash or other benefits.',
		video: 'https://sumosoft.blob.core.windows.net/archibald/pages/about/about-banner-video.mp4?v=3470507',
	},
	{
		title: 'Stay Connected',
		description:'Customers join your Loop for ongoing campaigns, exclusive offers, and deeper engagement.',
		video: 'https://www.w3schools.com/html/mov_bbb.mp4',
	},
];

export const Connections = () => {
	const [currentStep, setCurrentStep] = useState(0);
	const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isLargerThan768] = useMediaQuery('(min-width: 984px)');

	useEffect(() => {
		if (stepRefs.current[currentStep]) {
			stepRefs.current[currentStep]?.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}

		if (videoRef.current) {
			videoRef.current.src = steps[currentStep].video;
			setIsPlaying(false);
			videoRef.current.pause();
			videoRef.current.load();
		}
	}, [currentStep]);

	const handleNext = () => {
		if (currentStep < steps.length - 1) {
			setCurrentStep(currentStep + 1);
		}
	};
  
	const handlePrev = () => {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1);
		}
	};

	const togglePlay = () => {
		if (videoRef.current) {
			if (isPlaying) {
				videoRef.current.pause();
			} else {
				videoRef.current.play();
			}
			setIsPlaying(!isPlaying);
		}
	};
  
	return(
		<Stack spacing={['30px','20px']}  pt={['40px','70px']}>
			<Text display={['none', 'block']} fontSize={'18px'}>How the product works</Text>
			<Heading fontWeight={'extrabold'} fontSize={['20px','40px']} pb={[0,'50px']}>Build connections using Bridging Campaigns</Heading>
			{/* <Button variant={'secondary'} w={'215px'}>
                Learn How It Works
				<Icon as={GoArrowUpRight} boxSize={'16px'} />
			</Button> */}
			<Stack direction={['column-reverse', null, null, 'row']} spacing={[null, 8]} align="start"  w="full" maxW="900px" p={[0,2]}>
				<VStack spacing={['30px',6]} align="start" w={['','50%']} mt={['20px', 0]}>
					<HStack>
						<IconButton
							icon={<ArrowBackIcon />}
							onClick={handlePrev}
							isDisabled={currentStep === 0}
							aria-label="Previous"
						/>
						<IconButton
							icon={<ArrowForwardIcon />}
							onClick={handleNext}
							isDisabled={currentStep === steps.length - 1}
							aria-label="Next"
						/>
					</HStack>

					
					<VStack spacing={4} align="start" w="full">
						{ isLargerThan768 ? steps.map((step, index) => (
							<Box
								key={index}
								ref={(el) => (stepRefs.current[index] = el)}
								style={{ width: '100%' }}
							>
								<Box
									py={2}
									bg={index === currentStep ? 'white' : 'white'}
									opacity={index === currentStep ? 1 : 0.2}
									w={'300px'}
								>
									<HStack>
										{ index === currentStep && <Divider orientation='vertical' h={'20px'} borderColor={'darkCoral'} border={'1px solid'} />}
										<Text
											fontSize={'16px'}
											fontWeight={index === currentStep ? 'bold' : 'normal'}
										>
											{step.title}
										</Text>
									</HStack>
									<Text mt={[0,2]} fontSize={'14px'} color="blackTextColor.200">
										{step.description}
									</Text>
								</Box>
							</Box>
						)) : 
							<motion.div
								key={currentStep} 
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								exit={{ opacity: 0, y: -20 }}
								transition={{ duration: 0.3, ease: 'easeOut' }}
								style={{ width: '100%'}}
							>
								<Box
									pl={1}
									w={'355px'}
								>

									<HStack>
										<Divider orientation='vertical' h={'20px'} borderColor={'darkCoral'} border={'1px solid'} />
										<Text fontSize={'16px'} fontWeight="bold">{steps[currentStep].title}</Text>
									</HStack>
									<Text mt={2} fontSize={'14px'} color="blackTextColor.200">{steps[currentStep].description}</Text>
								</Box>
							</motion.div>
						}
					</VStack>
				</VStack>

				<Box  
					position="relative" 
				>
					<Box
						key={currentStep}
						w={['auto', null, '550px', null, '880px']}
						h={['260px','auto']}
					>
						<video
							ref={videoRef}
							width="880"
							height="610"
							controls={false}
							style={{
								borderRadius: '8px',
								boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
								objectFit: 'cover',
								width: '100%',
								height: '100%',
							}}
						>
							<source src={steps[currentStep].video} type="video/mp4" />
						Your browser does not support the video tag.
						</video>

						<Button
							onClick={togglePlay}
							position="absolute"
							top="50%"
							left="50%"
							transform="translate(-50%, -50%)"
							bg="rgba(0,0,0,0.6)"
							color="white"
							borderRadius="50%"
							p={4}
							_hover={{ bg: 'rgba(0,0,0,0.8)' }}
						>
							{isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
						</Button>
					</Box>
				</Box>
			</Stack>
		</Stack>
	);
};