import React from 'react';
import { Icon } from '@chakra-ui/icons';
import { IoMdNotificationsOutline } from 'react-icons/io';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverArrow,
	ButtonProps,
	IconButton,
	useMediaQuery,
	HStack,
	Text,
	VStack,
	Divider,
	Image,
	PopoverFooter,
	Flex,
	Box,
} from '@chakra-ui/react';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';
import dayjs from 'dayjs';
import { useGetUnreadNotificationsQuery, useGetNotificationsQuery, useReadAllNotificationsMutation, useReadNotificationMutation } from 'services';
import { notificationIcons } from 'Constants/NotificationIcons';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useLocale } from 'context/LocalizationContext';

interface INotificationWidget {
    style?: ButtonProps,
	linkColorMode?: 'light' | 'black',
}

export const NotificationWidget = ({style, linkColorMode}:INotificationWidget) => {
	const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');
	const { locale } = useLocale();
	const params = { pageNumber:1, pageSize:3, locale };
	const {data} = useGetNotificationsQuery(params);
	const {data:unreadNotifications, isSuccess} = useGetUnreadNotificationsQuery(params);
	const [readNotification] = useReadNotificationMutation();
	const [readAllNotifications, {isError: isReadAllError, error: readAllError}] = useReadAllNotificationsMutation();
	const navigate = useLocalizedNavigate();
	dayjs.extend(relativeTime);

	useDefaultErrorHandler(isReadAllError, readAllError);
	
	const unreadNotificationsCount = unreadNotifications?.data.length;
	const isHasNewNotification =  !!data?.data?.length && !!unreadNotificationsCount && isSuccess;
	
	const handleNavigation = () => {
		navigate(AppRoutes.notifications);
	};

	const handleMarkAllRead = () => {
		readAllNotifications({locale});
	};

	const handleMarkRead = (id: string) => {
		readNotification({ ids: [id], locale });
		navigate(AppRoutes.notifications);
	};

	
	return(
		<Popover arrowSize={12} placement={'bottom-start'} offset={[20, 16]} >
			<PopoverTrigger>
				<Box pos="relative"> 
					<IconButton 
						aria-label='Notification'
						w="max-content" 
						icon={<Icon
							h={5}
							w={5} 
							as={IoMdNotificationsOutline}
							color={linkColorMode  === 'light' ? 'white' : '#586068'}
							{...!isLargerThan1000 && ({color: '#586068'})}
						/>} 
						borderWidth={1}
						borderColor={linkColorMode  === 'light' ? 'white' : '#E5E5E5'}
						{...!isLargerThan1000 && ({borderColor: '#E5E5E5'})}
						borderRadius="50%"
						p={1}
						display="flex"
						alignItems="center"
						justifyContent="center"
						{...style}
					/>
					{isHasNewNotification && <Box 
						pos={'absolute'} 
						top={'8px'} 
						right={'8px'}  
						bg={'loop.500'} 
						h='6px' 
						w={'6px'} 
						border={'1px solid black'} 
						borderRadius={'50%'}
					/>}
				</Box>
			</PopoverTrigger>
			<PopoverContent borderRadius={'10px'}  px={2}  height={'auto'}>
				<PopoverArrow  />
				<PopoverHeader>
					<HStack py={2} justify={'space-between'}>
						<Text fontSize={'14px'} color={'#2B2B2B'}>Notifications</Text>
						<Text 
							color={ '#586068'}
							cursor={'pointer'}
							_hover={{
								textDecoration: 'underline'
							}}
							onClick={handleMarkAllRead}
						>
							Mark all as read
						</Text>
					</HStack>
				</PopoverHeader>
				<PopoverBody >
					{
						data?.data.slice(0,3).map((item) => {
							return(
								<Box key={item.id}>
									<Flex py={4} justify={'space-between'} alignItems={'flex-start'} key={item.id} onClick={() => handleMarkRead(item.id)} >
										<Image w={'40px'} h={'40px'} src={notificationIcons[item.notificationType]} />
										<VStack cursor={'pointer'} justify={'center'}  align={'start'}>
											<Text 
												color={item.isRead ? 'rgba(88, 96, 104, 0.7)':'#2B2B2B'} 
												fontWeight={'600'} 
												fontSize={'12px'}
											>
												{item.title}
											</Text>
											<Text  
												w={'180px'} 
												fontSize={'10px'}
												color={item.isRead ? 'rgba(88, 96, 104, 0.7)':'#2B2B2B'} 
											>
												{item.message}
											</Text>
										</VStack>
										<Text fontSize={'8px'}>
											{dayjs(item.dateCreated).fromNow()}
										</Text>
									</Flex>
									<Divider />
								</Box>
							);
						})
					}
				</PopoverBody>
				<PopoverFooter py={4} border={'none'}>
					{!data?.data.length ? <Text textAlign={'center'} color={'#96999B'}>You have 0 notifications</Text> :
						<Text 
							cursor={'pointer'} 
							color={'#FF6F61'} 
							textAlign={'center'}
							_hover={{
								textDecoration: 'underline'
							}}
							onClick={handleNavigation}
						>
							view all notifications
						</Text>
					}
				</PopoverFooter>
			</PopoverContent>
		</Popover>
	);
};
