import React, { useEffect } from 'react';
import {
	Button,
	Divider,
	Flex,
	Heading,
	Stack,
	Text,
	useDisclosure,
	FormControl,
	FormLabel,
	Spacer,
} from '@chakra-ui/react';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUpdatePasswordMutation } from 'services';
import { IChangePasswordDto } from 'Types/Interface/IUser';
import { useToast } from 'Hooks';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useLocale } from 'context/LocalizationContext';


export const PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{6,}$/;

const validationSchema = yup.object().shape({
	newPassword: yup
		.string()
		.min(6, 'Password too short should be atleast 6 characters long')
		.matches(PASSWORD_REGEX,'Minimum six characters, at least one upper case English letter, one lower case English letter, one number and one special character')
		.required('Password required'),
	confirmPassword: yup
		.string()
		.required('Password required')
		.when('newPassword', {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			is: (val: string | any[]) => (val && val.length > 0 ? true : false),
			then: yup
				.string()
				.oneOf([yup.ref('newPassword')], 'password does not match'),
		}),
	currentPassword:  yup.string().required('Current password is required'),
});

export const ChangePassword = () => {
	const { locale } = useLocale();
	const { alert } = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [updatePassword, { isLoading, isSuccess, isError, error }] = useUpdatePasswordMutation();
	useDefaultErrorHandler(isError, error);
	
	const { handleChange, handleSubmit, errors } = useFormik({
		initialValues: {
			newPassword: '',
			confirmPassword: '',
			currentPassword: ''
		},
		validationSchema,
		onSubmit: async (values) => {
			const data: IChangePasswordDto = {
				password: values.currentPassword,
				newPassword: values.newPassword
			};
			updatePassword({ body: data, locale });
		},
	});
	useEffect(() => {
		if(isSuccess){
			alert('success', 'password updated successfully');
		}
	},[isSuccess]);
	return (
		<>
			{ !isOpen && <Stack spacing={10} w="100%" flex={1}>
				<Heading size="sm">Login</Heading>
				<Flex flex={1} justifyContent="space-between">
					<Text color="#586068" fontSize="md">Password</Text>
					<Button
						color="loop.500"
						_hover={{
							textDecoration: 'underline',
						}}
						onClick={ isOpen ? onClose : onOpen}
					>
						{isOpen ? 'Cancel' : 'Change Password'}
					</Button>
				</Flex>
				<Divider />
			</Stack>}
			{ isOpen && <Stack spacing={6}>		
				<Heading size="sm">Reset password</Heading>
				<Flex flex={1} justifyContent="space-between">
					<Text color="#586068" fontSize="md">Password</Text>
					<Button
						color="loop.500"
						_hover={{
							textDecoration: 'underline',
						}}
						onClick={ isOpen ? onClose : onOpen}
					>
						{isOpen ? 'Cancel' : 'Change Password'}
					</Button>
				</Flex>					
				<FormControl w={[null, '450px', 'auto', '350px', '550px']}>
					<FormLabel fontSize='sm'>Current password</FormLabel>
					<CustomInput
						onChange={handleChange}
						name='currentPassword'
						type='password'
						placeholder=''
						error={errors.currentPassword}
					/>
				</FormControl>
				<FormControl w={[null, '450px', 'auto', '350px', '550px']}>
					<FormLabel fontSize='sm'>New password</FormLabel>
					<CustomInput
						onChange={handleChange}
						name='newPassword'
						type='password'
						placeholder=''
						error={errors.newPassword}
					/>
				</FormControl>
				<FormControl w={[null, '450px', 'auto', '350px', '550px']} >
					<FormLabel fontSize='sm'>Confirm password</FormLabel>
					<CustomInput
						onChange={handleChange}
						name='confirmPassword'
						type='password'
						placeholder=''
						error={errors.confirmPassword}
					/>
				</FormControl>
				<Spacer />
				<Button
					w={[null, '200px']}
					h={'50px'}
					variant='primary'
					onClick={() => handleSubmit()}
					isLoading={isLoading}
					mb={['20px', null]}
				>
					Update password
				</Button>
			</Stack>}
		</>
	);
};
