import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import customerFeedback from '../../Assets/svg/customer-feedback.svg';


export const CustomerFeedbackIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={customerFeedback} ref={ref} {...props} />;
});