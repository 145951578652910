/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Button, Flex, Input, Stack, Text, theme, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { InputName } from '../AccountDetailList';
import { renderEditForm } from './utils';
import { Gender, InputNameEnums } from 'Types/Enums';
import { CustomModal } from '../../../Rewards/Components/CustomModal';
import { useSendVerification, useToast } from 'Hooks';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useSendEmailOtpMutation, useUpdatePhoneNumberMutation } from 'services';
import { EmailVerificationModalContentOtp } from 'Pages/HomePage/Components/EmailVerificationModalContentOtp';
import { useRefreshUserProfile } from 'Utils/useRefreshUserProfile';
import { useLocale } from 'context/LocalizationContext';
import { IPhoneNumberVerificationDto } from 'Types/Interface/IUser';
import { PhoneNumberVerification } from 'Components';

interface IAccountDetail {
	label: string;
	placeholder: string;
	inputName: InputName;
	defaultValue: string | number;
	onChange: (inputName: InputName, value: string | number) => void;
	onSubmit: () => void;
	isLoading: boolean;
	isSuccess: boolean;
	handleActiveForm: (value: string) => void;
	activeForm: string;
}

const genderLabel: Record<string, string> = {
	[Gender.Male]: 'Male',
	[Gender.Female]: 'Female',
	[Gender.NoInput]: 'NoInput',
	[Gender.Others]: 'Others',
	[Gender.NonBinary]: 'Non-binary',
};

export const AccountDetail = ({
	label,
	placeholder,
	inputName,
	defaultValue,
	onChange,
	onSubmit,
	isLoading,
	isSuccess,
	handleActiveForm,
	activeForm,
}: IAccountDetail) => {
	const { locale } = useLocale();
	const [isEditing, setIsEditing] = useState(false);
	const [countryCode, setCountryCode] = useState('');
	const [otpValue, setOtpValue] = useState('');

	const { isOpen: isPhoneModalOpen, onOpen: onPhoneModalOpen, onClose: onPhoneModalClose } = useDisclosure();
	const { isOpen: isEmailModalOpen, onOpen: onEmailModalOpen, onClose: onEmailModalClose } = useDisclosure();
	const { alert } = useToast();

	const { refreshUserProfile } = useRefreshUserProfile();
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

	const {
		mutate: sendPhoneVerificationCode,
		isSuccess: isSendPhoneCodeSuccess,
		isError: isSendPhoneCodeError,
		error: sendPhoneCodeError,
	} = useSendVerification();

	const [
		sendEmailVerificationCode,
		{ isSuccess: isSendEmailCodeSuccess, isError: isSendEmailCodeError, error: sendEmailCodeError },
	] = useSendEmailOtpMutation();

	const [changePhoneNumber,
		{ isLoading: isUpdatePhoneLoading, isSuccess: isUpdatePhoneSuccess, isError: isUpdatePhoneError, error: updatePhoneError }
	] = useUpdatePhoneNumberMutation();

	const handleCancel = () => {
		setIsEditing(false);
	};

	const handleSubmit = (inputName: string) => {
		handleActiveForm(inputName);
		if (inputName == InputNameEnums.phoneNumber) {
			handleSendPhoneCode();
		} else if (inputName == InputNameEnums.emailAddress) {
			handleSendEmailCode();
		} else {
			onSubmit();
		}
	};

	const handleSendPhoneCode = () => {
		if (defaultValue) {
			const data = {
				phoneNumber: defaultValue.toString().toInternationalPhoneNumber(),
				countryCode: countryCode,
			};
			sendPhoneVerificationCode(data);
		}
	};

	const handleSendEmailCode = () => {
		if (defaultValue) {
			sendEmailVerificationCode({ email: defaultValue.toString(), isForActiveUser: true, locale });
		}
	};

	const handleEdit = () => {
		setIsEditing(!isEditing);
	};


	useEffect(() => {
		if (isSuccess) {
			setIsEditing(false);
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isSendPhoneCodeSuccess) {
			onPhoneModalOpen();
		}
	}, [isSendPhoneCodeSuccess]);

	useEffect(() => {
		if (isSendEmailCodeSuccess) {
			onEmailModalOpen();
		}
	}, [isSendEmailCodeSuccess]);

	useDefaultErrorHandler(isSendPhoneCodeError, sendPhoneCodeError);
	useDefaultErrorHandler(isSendEmailCodeError, sendEmailCodeError);
	useDefaultErrorHandler(isUpdatePhoneError, updatePhoneError);


	const handleOtpSubmitted = (data: IPhoneNumberVerificationDto) => {
		changePhoneNumber({ body: data, locale });
	};


	useEffect(() => {
		if (isUpdatePhoneSuccess) {
			alert('success', 'Phone number updated successfully');
			refreshUserProfile({ locale });
			onPhoneModalClose();
			setIsEditing(false);
		}
	}, [isUpdatePhoneSuccess]);

	const handleEmailVerified = () => {
		alert('success', 'Email updated successfully');
		onEmailModalClose();
		setIsEditing(false);
	};

	const handleChange = (inputName: InputName, value: string | number, ...args: string[]) => {
		if (inputName == 'phoneNumber') {
			setCountryCode(args[0]);
		}
		onChange(inputName, value);
	};

	return (
		<>
			<Stack spacing={3}>
				<Flex alignItems='center' justifyContent='space-between'>
					<Text fontWeight='semibold'>{label}</Text>
					<Button
						color='loop.500'
						_hover={{
							textDecoration: 'underline',
						}}
						//display={InputNameEnums.emailAddress === inputName ? 'none': 'block'}
						onClick={() => (isEditing ? handleCancel() : handleEdit())}
					>
						{isEditing ? 'Cancel' : 'Edit'}
					</Button>
				</Flex>
				{isEditing ? (
					<Stack spacing={2}>
						{renderEditForm(inputName, handleChange)}
						<Button
							isLoading={isLoading}
							onClick={() => handleSubmit(inputName)}
							alignSelf='left'
							variant='primary'
							w='max-content'
							p={2}
							borderRadius={['20px', '5']}
						>
							Save
						</Button>
					</Stack>
				) : (
					<Input
						w={'100%'}
						variant='flushed'
						placeholder={placeholder}
						defaultValue={genderLabel[defaultValue] || defaultValue}
						disabled
						_placeholder={{
							color: '#586068',
							fontSize: 'sm',
						}}
					/>
				)}
			</Stack>
			{activeForm === InputNameEnums.phoneNumber && (
				<CustomModal
					content={
						<PhoneNumberVerification
							phoneNumber={defaultValue}
							countryCode={countryCode}
							otpValue={otpValue}
							setOtpValue={setOtpValue}
							onClose={onPhoneModalClose}
							onSubmit={handleOtpSubmitted}
							handleSendCode={handleSendPhoneCode}
							isSendCodeError={isSendPhoneCodeError}
							sendCodeError={sendPhoneCodeError}
							isLoading={isUpdatePhoneLoading}
						/>
					}
					size={isMobile ? 'full' : 'lg'}
					isOpen={isPhoneModalOpen}
					onClose={onPhoneModalClose}
					modalContentStyles={{ borderRadius: isMobile ? '0px' : '10px' }}
				/>
			)}
			{activeForm === InputNameEnums.emailAddress && (
				<CustomModal
					content={
						<EmailVerificationModalContentOtp email={defaultValue.toString()} onVerified={handleEmailVerified} />
					}
					size={isMobile ? 'full' : 'lg'}
					isOpen={isEmailModalOpen}
					onClose={onEmailModalClose}
					modalContentStyles={{ borderRadius: isMobile ? '0px' : '10px' }}
				/>
			)}
		</>
	);
};
