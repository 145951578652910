import { createApi } from '@reduxjs/toolkit/query/react';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';
import { ILocalizationCountry } from 'Types/Interface/ILocalizationCountry';

export const localizationApi = createApi({
	reducerPath: 'localizationApi',
	baseQuery,
	endpoints: (builder) => ({
		getCountries: builder.query<IJsonResponse<ILocalizationCountry[]>, { locale: string }>({
			query: ({ locale }) => ({
				url: '/localization/countries',
				headers: getLocalizationHeader(locale),
			}),
		}),
	}),
});

export const { useGetCountriesQuery } = localizationApi;
