import { createApi } from '@reduxjs/toolkit/query/react';
import { TagType } from 'Types/Enums';
import { INotification } from 'Types/Interface/INotification';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';
import { IPaginatedResponse } from './types';

export const notificationsApi = createApi({
	reducerPath: 'notificationsApi',
	baseQuery,
	tagTypes: [TagType.Notifications],
	endpoints: (builder) => ({
		getAllNotifications: builder.query<
			IPaginatedResponse<INotification[]>,
			{ pageNumber?: number; pageSize?: number; locale: string }
		>({
			query: ({ pageNumber, pageSize, locale }) => ({
				url: `/Notification?pageNumber=${pageNumber}&pageSize=${pageSize}`,
				headers: getLocalizationHeader(locale),
			}),
			keepUnusedDataFor: 0,
			providesTags: [TagType.Notifications],
		}),
		readNotification: builder.mutation<unknown, { ids: string[] | undefined; locale: string }>({
			query: ({ ids: body, locale }) => ({
				url: '/Notification/read',
				method: 'POST',
				body,
				headers: getLocalizationHeader(locale),
			}),
			invalidatesTags: [TagType.Notifications],
		}),
		deleteNotification: builder.mutation<unknown, { notificationId: string; locale: string }>({
			query: ({ notificationId, locale }) => ({
				url: `/Notification/${notificationId}`,
				method: 'DELETE',
				headers: getLocalizationHeader(locale),
			}),
			invalidatesTags: [TagType.Notifications],
		}),
	}),
});

export const { useGetAllNotificationsQuery, useReadNotificationMutation, useDeleteNotificationMutation } =
	notificationsApi;
