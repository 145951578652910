import React, { useContext } from 'react';

import { Button, Flex, Image, Text } from '@chakra-ui/react';
import completeIcon from 'Assets/svg/completeIcon.svg';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { resetActiveSurvey } from 'store/slices';
import { AuthContext } from 'context/AuthContext';
import { CampaignType } from 'Types/Enums';
import PriceToggle from 'Components/PriceToggle';

export const SurveyCompleted = () => {
	const navigate = useLocalizedNavigate();
	const dispatch = useAppDispatch();
	const auth = useContext(AuthContext);
	const { codeScanData, activeSurvey } = useAppSelector((state) => state.survey);
	const isBrandLoopOnly = activeSurvey.survey.campaignType == CampaignType.brandLoopOnly;

	const handleBtnClick = () => {
		dispatch(resetActiveSurvey());
		navigate(AppRoutes.home);
	};

	const getMessage = ()=>{
		if (isBrandLoopOnly)
			return `Your information has successfully been shared with ${activeSurvey.survey.product.brand.name} and you have earned {points}`;

		if (auth.isFullyRegisteredUser())
			return 'Thank you for taking time to complete the survey, you have earned {points}.';
		else
			return 'Thank you for taking time to complete survey, {points} has been attached to the email you provided.';
	};

	const renderMessage = () => {
		const message = getMessage();
		const parts = message.split(/{points}/);
		return (
			<>
				{parts.length > 0 && parts[0]}
				<PriceToggle value={codeScanData?.latestEarnedPoints} />
				{parts.length > 1 && parts[1]}
			</>
		);
	};

	return (
		<Flex direction={'column'} alignItems={'center'} mt={12}>
			<Image src={completeIcon} mb={10} />
			<Text fontSize={'2xl'} fontWeight={600} color={'#264653'} mb={3}>
				{isBrandLoopOnly ? <>Thanks for sharing</> : <>Thanks for your feedback</>}
			</Text>
			<Text
				fontSize={['12px', '20px']}
				fontWeight={400}
				color={'#586068'}
				mb={[10, null, 12]}
				textAlign={'center'}
				w={'90%'}
			>
				{renderMessage()}
			</Text>
			<Button
				variant='primary'
				fontSize={['sm', null, 'lg']}
				onClick={handleBtnClick}
				w={['315px', '250px']}
				h={['50px', '60px']}
				borderRadius={'5px'}
			>
				Got It
			</Button>
		</Flex>
	);
};
