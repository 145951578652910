import React, { useEffect } from 'react';
import { SplitLayout } from 'Pages/Dashboard/Components/SplitLayout';
import { Balance } from './Components';
// import { useGetUserSummaryQuery } from 'services';
// import { useAppDispatch } from 'Hooks';
// import { setUserSummary } from 'store/slices';
import { WithdrawalHistory } from './Components/WithdrawalHistory';
import { Box } from '@chakra-ui/react';
// import { useLocale } from 'context/LocalizationContext';

export const Rewards = () => {
	// const { locale } = useLocale();
	// const dispatch = useAppDispatch();
	// const [getUserSummary, { data: userSummary, isSuccess: isSummarySuccess, isLoading }] = useGetUserSummaryMutation();

	// useEffect(() => {
	// 	getUserSummary({ locale });
	// }, [locale]);

	// useEffect(() => {
	// 	if (isSummarySuccess && userSummary?.data) {
	// 		dispatch(setUserSummary(userSummary.data));
	// 	}
	// }, [isSummarySuccess]);

	return (
		<SplitLayout display='block' width='72%'>
			<Balance  />
			<Box ml={[0, null, 10]}>
				<WithdrawalHistory />
			</Box>
		</SplitLayout>
	);
};
