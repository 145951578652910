import React from 'react';
import { useInView } from 'react-intersection-observer';
import {
	Box,
	Button,
	Heading,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Stack,
	Text,
} from '@chakra-ui/react';
import { usePrivacyLocalization, 
	useTermsOfServiceBrandsLocalization, 
	useTermsOfServiceUserLocalization 
} from 'Utils/localizationHooks';
import { IChildren } from 'Types/Interface/Sanity';

interface ITermsOfServiceProps {
	isOpen: boolean;
	onClose: () => void;
	onAccept: (isAccepted: boolean) => void;
	showButtons?: boolean;
	isBusiness?: boolean;
	type: 'privacy' | 'terms';
}

export const TermsModal = ({
	isOpen,
	onClose,
	onAccept,
	isBusiness,
	showButtons = true,
	type
}: ITermsOfServiceProps) => {
	const { ref, inView } = useInView({
		threshold: 0.5,
	});

	const accept = (isAccepted: boolean) => {
		onClose();
		onAccept(isAccepted);
	};
	
	const { localization: termsLocalization } = useTermsOfServiceUserLocalization();
	const { localization: BrandstermsLocalization } = useTermsOfServiceBrandsLocalization();
	const {localization: privacyLocalization} = usePrivacyLocalization();

	return (
		<Modal closeOnOverlayClick={true} size="4xl" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent height={['', '620px']} overflow="auto">
				<ModalBody>
					<Stack spacing={6} p={[3, 10]} mt={8}>
						<Heading
							alignSelf={['flex-start', 'center']}
							fontSize={['20px', '28px']}
							fontWeight={600}
							color="#264653"
						>
							{type === 'terms' ? 'Terms of Service' : 'Privacy'}
						</Heading>
						
						{ type === 'terms' ? (!isBusiness ? termsLocalization?.termsOfServiceUser?.map((term) => (
							<Box key={term._key}>
								<Text ref={ref} fontSize="14px" fontWeight={500} color="#121B26">
									{term.children.map((child:IChildren) =>
										child.marks.includes('strong') ? (
										<strong key={child._key}>{child.text}</strong>
										) : (
										<span key={child._key}>{child.text}</span>
										)
									)}
								</Text>
							</Box>
						)) :

						BrandstermsLocalization?.termsOfServiceBrands?.map((term) => (
							<Box key={term._key}>
								<Text ref={ref} fontSize="14px" fontWeight={500} color="#121B26">
									{term.children.map((child:IChildren) =>
										child.marks.includes('strong') ? (
										<strong key={child._key}>{child.text}</strong>
										) : (
										<span key={child._key}>{child.text}</span>
										)
									)}
								</Text>
							</Box>
						))) : 
						privacyLocalization?.privacy?.map((term) => (
							<Box key={term._key}>
								<Text ref={ref} fontSize="14px" fontWeight={500} color="#121B26">
									{term.children.map((child:IChildren) =>
										child.marks.includes('strong') ? (
										<strong key={child._key}>{child.text}</strong>
										) : (
										<span key={child._key}>{child.text}</span>
										)
									)}
								</Text>
							</Box>
						))
						}
					</Stack>
				</ModalBody>

				{showButtons && (<ModalFooter
					justifyContent="center"
					alignItems="center"
					position={['fixed', 'sticky']}
					left={['7%', '35%']}
					bottom={['20', 0]}
					bgGradient={
						inView ? '' : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 61.98%)'
					}
				>
					<Button
						border="1px solid #91979D"
						p="3"
						borderRadius="10px"
						w="150px"
						h="50px"
						fontSize="16px"
						color="#586068"
						mr={4}
						onClick={() => { accept(false); }}
					>
						Decline
					</Button>

					<Button
						variant="primary"
						borderRadius="10px"
						w="150px"
						h="50px"
						fontSize="16px"
						onClick={() => { accept(true); }}
					>
						Accept
					</Button>
				</ModalFooter>)}
				
			</ModalContent>
		</Modal>
	);
};
