import React from 'react';
import { VStack, HStack, Divider, Heading, Text, Button, Link, Image, Flex } from '@chakra-ui/react';
import sadFaceSvg from '../../Assets/svg/sad-face.svg';
import { AppRoutes } from 'Routes/AppRoutes';
import { FallbackProps } from 'react-error-boundary';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';

const ErrorPage: React.FC<FallbackProps> = ({ error }) => {
	const navigate = useLocalizedNavigate();

	const handleGoHome = () => {
		navigate(AppRoutes.home);
	};

	return (
		<Flex
			direction={{ base: 'column', md: 'row' }}
			alignItems={'flex-start'}
			width='100%'
			maxWidth={'1400px'}
			px={['24px', null, '150px']}
			mt={['40px', null, '100px']}
			gap={{ base: 7, md: 32 }}
			color={'brand.black'}
		>
			<Image src={sadFaceSvg} alt='Oops' boxSize={{ base: '100px', md: '400px' }} />
			<VStack align={'flex-start'} w='100%' spacing={0}>
				<Heading fontSize={['5xl', null, '8xl']} pb={[3, null, 10]}>
					Oops...
				</Heading>
				<Heading fontSize={['md', null, '3xl']} pb={5}>
					Looks like something went wrong
				</Heading>
				<Text fontSize={['sm', null, 'xl']} pb={5}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
					mag na aliqua.
				</Text>
				{error && error.message && (
					<Text fontSize={['sm', null, 'xl']} pb={[8, null, 12]} color={'darkCoral'}>
						Error code: {error.message}
					</Text>
				)}
				<Button variant='secondary' px={'7'} my={12} fontSize={['sm', null, 'md']} onClick={handleGoHome}>
					Go home
				</Button>
				<HStack spacing={6} pt={[10, null, 12]} align='center' color='darkCoral' fontSize={['sm', null, 'xl']}>
					<Link variant={'primary'} href={AppRoutes.help}>
						Contact support
					</Link>
					<Divider orientation='vertical' height='30px' borderColor={'darkCoral'} />
					<Link variant={'primary'} href={AppRoutes.home}>
						Questions and Answers
					</Link>
				</HStack>
			</VStack>
		</Flex>
	);
};

export default ErrorPage;
