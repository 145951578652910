import { createApi } from '@reduxjs/toolkit/query/react';
import { IImageUploadResponse, IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IGeolocation } from 'Types/Interface/IGeoLocation';
import { IUser, IUserSummary } from 'Types/Interface/IUser';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';

export const userAPI = createApi({
	reducerPath: 'userAPI',
	baseQuery,
	endpoints: (builder) => ({
		getUserProfile: builder.mutation<IJsonResponse<IUser>, { locale: string }>({
			query: ({ locale }) => ({
				url: 'user/profile',
				method: 'GET',
				headers: getLocalizationHeader(locale),
			}),
		}),
		getAllUsers: builder.query<IJsonResponse<IUser[]>, { pageNumber: number; locale: string }>({
			query: ({ pageNumber, locale }) => ({
				url: `user/all?pageNumber=${pageNumber}`,
				headers: getLocalizationHeader(locale),
			}),
		}),
		getUserSummary: builder.query<IJsonResponse<IUserSummary>, { locale: string }>({
			query: ({ locale }) => ({
				url: 'user/summary',
				method: 'GET',
				headers: getLocalizationHeader(locale),
			}),
		}),
		redeemPoints: builder.mutation<IJsonResponse<IImageUploadResponse>, { totalPoints: number; locale: string }>({
			query: ({ totalPoints, locale }) => ({
				url: `user/redeem?totalPoints=${totalPoints}`,
				method: 'POST',
				headers: getLocalizationHeader(locale),
			}),
		}),
		sendEmailOtp: builder.mutation<IJsonResponse<void>, { email: string; isForActiveUser: boolean; locale: string }>({
			query: ({ email, isForActiveUser, locale }) => ({
				url: `user/send_verification_code?accountIdentifier=${email}&isForActiveUser=${isForActiveUser}`,
				method: 'PUT',
				headers: getLocalizationHeader(locale),
			}),
		}),
		resendEmailOtp: builder.mutation<IJsonResponse<void>, { email: string; locale: string }>({
			query: ({ email, locale }) => ({
				url: `user/resend_verification_code?email=${email}`,
				method: 'PUT',
				headers: getLocalizationHeader(locale),
			}),
		}),
		verifyEmailWithOtpCode: builder.mutation<IJsonResponse<void>, { code: string; locale: string }>({
			query: ({ code, locale }) => ({
				url: `user/verify_email?verificationCode=${code}`,
				method: 'PUT',
				headers: getLocalizationHeader(locale),
			}),
		}),
		updateUserCountryWithGeolocation: builder.mutation<IJsonResponse<void>, {geoLocation: IGeolocation; locale: string }>({
			query: ({ geoLocation, locale }) => ({
				url: 'user/update-country',
				method: 'PUT',
				body: geoLocation,
				headers: getLocalizationHeader(locale),
			}),
		}),
	}),
});

export const {
	useGetUserProfileMutation,
	useGetUserSummaryQuery,
	useRedeemPointsMutation,
	useGetAllUsersQuery,
	useSendEmailOtpMutation,
	useResendEmailOtpMutation,
	useVerifyEmailWithOtpCodeMutation,
	useUpdateUserCountryWithGeolocationMutation
} = userAPI;
