import React from 'react';
import { Button, Heading, Stack, Text, chakra, Alert, AlertIcon } from '@chakra-ui/react';
import { OTPInput } from 'Components/FormInput/OTPInput';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { IPhoneNumberVerificationDto } from 'Types/Interface/IUser';

interface IPhoneNumberVerificationProps {
	phoneNumber: string | number;
	countryCode: string;
	otpValue: string;
	setOtpValue: (value: string) => void;
	onClose: () => void;
	onSubmit: (data: IPhoneNumberVerificationDto) => void;
	handleSendCode: () => void;
	isSendCodeError: boolean;
	sendCodeError: unknown;
	isLoading: boolean;
}

export const PhoneNumberVerification = ({
	phoneNumber,
	countryCode,
	otpValue,
	setOtpValue,
	handleSendCode,
	isSendCodeError,
	sendCodeError,
	onClose,
	onSubmit,
	isLoading
}: IPhoneNumberVerificationProps) => {

	const handleSubmit = () => {
		const data: IPhoneNumberVerificationDto = {
			verificationCode: otpValue,
			phoneNumber: phoneNumber.toString().toInternationalPhoneNumber(),
			countryCode: countryCode,
		};
		onSubmit(data);
	};


	useDefaultErrorHandler(isSendCodeError, sendCodeError, onClose);

	return (
		<Stack h={['100vh','full']} p={['20px', '40px']} spacing={['30px', '35px']} justify={'center'} align={'center'}>
			<Heading fontSize={['20px', '30px']} fontWeight={600} color={''}>
				Verify Code
			</Heading>
			<Text textAlign={'center'} w={['auto', '400px']} fontSize={['12px', '16px']}>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
			</Text>
			{otpValue === '' && (
				<Alert status='error'>
					<AlertIcon />
					OTP is required
				</Alert>
			)}
			<OTPInput onChange={setOtpValue} value={otpValue} />
			<Button
				w={['280px', '397px']}
				h={['40px', '60px']}
				variant={'primary'}
				isLoading={isLoading}
				onClick={() => handleSubmit()}
			>
				Verify
			</Button>
			<Text fontSize={'16px'}>
				Didn’t get a code?
				<chakra.span
					color={'darkCoral'}
					cursor={'pointer'}
					_hover={{ textDecoration: 'underline' }}
					onClick={handleSendCode}
				>
					Resend
				</chakra.span>
			</Text>
		</Stack>
	);
};
