import React from 'react';

import { Flex, useMediaQuery } from '@chakra-ui/react';
import { Products } from 'Components/Products/products';
import { ProductNavigation } from '../Components/ProductNavigation';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';
import { MobileProductsSlider } from '../Components/MobileDiscoverProductsSlider';
import { useGetAllProductsQuery } from 'services';
import ErrorComponent from 'Components/ErrorComponent';
import { useLocale } from 'context/LocalizationContext';

interface DiscoverProductsProps {
	title: string | undefined;
	seeAllLinkText: string | undefined;
}

export const DiscoverProducts = (props: DiscoverProductsProps) => {
	const { locale } = useLocale();
	const params = { pageNumber: 1, pageSize: 4, categoryId: '', locale };
	const { data, isLoading, isError } = useGetAllProductsQuery(params);
	const navigate = useLocalizedNavigate();
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

	return (
		<Flex
			direction={'column'}
			// py={{ xxl: 6 }}
		>
			<ProductNavigation
				onClickLink={() => {
					navigate(AppRoutes.allProducts);
				}}
				title={props.title}
				linkText={props.seeAllLinkText}
			/>
			{isError && <ErrorComponent />}
			{!isError && isLargerThan768 && <Products products={data?.data} isLoading={isLoading} />}
			{!isError && !isLargerThan768 && <MobileProductsSlider products={data?.data} isLoading={isLoading} />}
		</Flex>
	);
};
