/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Stack, useMediaQuery } from '@chakra-ui/react';
import { BsFilter } from 'react-icons/bs';
import { useGetAllBrandsQuery } from 'services';
import { BrandGallery } from 'Components/Gallery/BrandGallery';
import { useGetCategoriesQuery } from 'services';
import { IBrand } from 'Types/Interface/IBrand';
import { SearchBrandsAndProducts } from 'Components/SearchBrandsAndProducts';
import ErrorComponent from 'Components/ErrorComponent';
import { useLocale } from 'context/LocalizationContext';


const CONTROLLED_MAX_PAGE_SIZE = 200;
const CONTROLLED_MIN_PAGE_SIZE = 10;

export const AllBrandsCategory = () => {
	const { locale } = useLocale();
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(CONTROLLED_MIN_PAGE_SIZE);
	const [activeCategory, setActiveCategory] = useState('');
	const params = { pageNumber, pageSize, categoryId: activeCategory, locale };
	const { data, isLoading, isFetching, isError: isErrorFetchingBrands, refetch } = useGetAllBrandsQuery(params);
	const { data: categories } = useGetCategoriesQuery({locale});
	const [brands, setBrands] = useState<IBrand[]>([]);
	const [isLargerThan768] = useMediaQuery('(min-width: 984px)');
	const isHasMoreData = data ? data?.pageNumber < data?.totalNoOfPages : false;

	useEffect(() => {
		if (data) {
			setBrands(data.data);
		}
	}, [data, activeCategory, refetch]);

	const _handleShowMoreItems = () => {
		setPageNumber((prevPage) => prevPage + 1);
		if (activeCategory === '') {
			setPageSize(CONTROLLED_MAX_PAGE_SIZE);
			setPageNumber(1);
		}
	};

	const _handleClick = (id: string) => {
		setActiveCategory(id);
	};

	const onClickAll = () => {
		setActiveCategory('');
		setPageSize(CONTROLLED_MIN_PAGE_SIZE);
		setPageNumber(1);
		refetch();
	};

	useEffect(() => {
		if (activeCategory === '' && data) {
			setBrands(data.data);
		}
	}, [activeCategory]);

	return (
		<Stack spacing={8}>
			<Flex gap={2} mt={[8, 8, null]} alignItems={'center'}>
				<Menu>
					{!isLargerThan768 ? (
						<MenuButton
							as={Button}
							_focus={{ outline: 'none' }}
							border={'1px solid #C1C8CF'}
							borderRadius={'5px'}
							px={2}
							py={2}
							bgColor={'#FF6F61'}
							color={'white'}
						>
							<BsFilter fontSize='1.7em' />
						</MenuButton>
					) : (
						<MenuButton
							as={Button}
							rightIcon={<BsFilter />}
							_focus={{ outline: 'none' }}
							border={'1px solid #C1C8CF'}
							borderRadius={'10px'}
							px={6}
							py={3}
						>
							Filter
						</MenuButton>
					)}
					<MenuList>
						<MenuItem onClick={onClickAll}>All</MenuItem>
						{categories?.data.map((category, index) => {
							return (
								<MenuItem key={category.id + index} onClick={() => _handleClick(category.id)}>
									{category.name}
								</MenuItem>
							);
						})}
					</MenuList>
				</Menu>

				{!isLargerThan768 ? (
					<SearchBrandsAndProducts />
				) : (
					<>
						<Button
							my={[8, 8, 12]}
							ml={10}
							fontSize='sm'
							fontWeight='500'
							color={activeCategory === 'All' ? '#121B26' : '#A0A8AF'}
							_focus={{
								color: '#121B26',
								borderBottom: '2px solid #FF6F61',
								borderRadius: '0',
								// margin: '0',
							}}
							onClick={onClickAll}
						>
							All
						</Button>
						{categories?.data.map((category) => {
							return (
								<Button
									key={category.id}
									my={[8, 8, 12]}
									ml={6}
									fontSize='sm'
									fontWeight='500'
									color={activeCategory === category.id ? '#121B26' : '#A0A8AF'}
									_focus={{
										color: '#121B26',
										borderBottom: '2px solid #FF6F61',
										borderRadius: '0',
										// margin: '0',
									}}
									onClick={() => _handleClick(category.id)}
								>
									{category.name}
								</Button>
							);
						})}
					</>
				)}
			</Flex>
			
			{isErrorFetchingBrands ? (
				<ErrorComponent />
			) : (
				<>
					<BrandGallery visibility={true} brands={brands} isLoading={isLoading || isFetching} />
					{isHasMoreData && (
						<Button
							variant={'secondary'}
							onClick={_handleShowMoreItems}
							w={{ xxl: '52' }}
							h={{ xxl: '10' }}
							alignSelf={'center'}
							my={16}
							_focus={{ outline: 'none' }}
							isLoading={isLoading || isFetching}
						>
							Load More
						</Button>
					)}
				</>
			)}
		</Stack>
	);
};
