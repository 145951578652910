import React, { useEffect } from 'react';
import {
	Stack,
	Flex,
	Text,
	Button,
	Divider,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	ModalFooter,
	HStack,
	useMediaQuery
} from '@chakra-ui/react';
import { UserPreferenceFormName } from 'Types/Enums';
import { userPreferenceQuestions } from 'Data/UserPreferenceQuestions';
import { StepItem } from 'Pages/RegisterPage/Steps';
import { IUserPreferenceQuestion } from 'Types/Interface/IUserPreferenceQuestion';
import { getValueName } from 'Utils';
import { ChevronRightIcon } from '@chakra-ui/icons';

interface IPreferenceItemProps {
  title: UserPreferenceFormName;
  value?: string | number;
  onSave?: () => void;
  actionLabel?: string;
  onChange: (name:string, val:string|number) => void;
  isLoading?: boolean;
  canClose?: boolean;
  isSurvey?: boolean;
}

export const PreferenceItem = ({
	title,
	value,
	onSave,
	actionLabel,
	onChange,
	isLoading,
	canClose,
	isSurvey
}: IPreferenceItemProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

	const selectedQuestion = userPreferenceQuestions.find(
		(item) => item.name === title,
	);

	const handleChange = (val:string| number) => {
		onChange(title,val);
	};

	const handleSave = () => {
		onSave && onSave();
		
	};

	useEffect(() => {
		if(canClose){
			onClose();
		}
	},[canClose]);

	return (
		<Stack>
			<Flex flex={1} justifyContent="space-between">
				<Text
					textTransform="capitalize"
					color="#586068"
					fontSize="md"
					fontWeight="bold"
				>
					{title.split('_').join(' ')}
				</Text>
				<Button
					color={isLargerThan768 ? 'loop.500' : 'blackTextColor.200'}
					_hover={{ textDecoration: 'underline' }}
					onClick={onOpen}
				>
					{actionLabel || (isLargerThan768 ? 'Edit' : <ChevronRightIcon boxSize={'18px'} />)}
				</Button>
			</Flex>
			<Text color={ isSurvey && getValueName(title, value) === 'Prefer not to say' ? '#FF9E00' : '#A2A7AD'}>
				{getValueName(title, value)}
			</Text>
			<Divider />
			<Modal size={isLargerThan768 ? 'lg' : isSurvey ? 'lg' : 'full'} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent marginBottom={[0, null]} pos={isLargerThan768 ? 'relative' : 'fixed'} borderRadius={[0, '5px']} borderTopRadius={!isLargerThan768 ? '10px' : 0} bottom={0}>
					<ModalCloseButton mt={['30px', '0']} />
					<ModalBody mt={['20px', '0']}>
						<StepItem
							data={selectedQuestion as IUserPreferenceQuestion}
							stepsLeft={0}
							value={value || ''}
							onChange={handleChange}
							hideIcon
							activeColor='loop.500'
							isSurvey={isSurvey}
						/>
					</ModalBody>
					<ModalFooter justifyContent="flex-start" mt={5} mb={['60px', 0]}>
						<HStack justify={isLargerThan768 ? 'start': isSurvey ? 'center' : 'start'} w={isSurvey ? '100%' : 'auto'} spacing={4}>
							<Button 
								variant="primary"
								width={ isLargerThan768 ? '100px': isSurvey ? '335px' : '100px'}
								height={['50px', '40px']}
								onClick={handleSave}
								fontSize={['14px', '18px']}
								fontWeight={500}
								borderRadius={isLargerThan768 ? 'none' : isSurvey ? '400px' : 'none'}
								isLoading={isLoading}>Save</Button>
							{!isSurvey && (
								<Button
									variant="unstyled"
									color="#ADB3B9"
									w={['120px', '100px']}
									h={['50px', '40px']}
									border={['1px solid', 'none']}
									borderColor="loopGrey.300"
									fontSize={['14px', '18px']}
									fontWeight={500}
									_hover={{ color: 'black' }}
									onClick={onClose}
								>
								Cancel
								</Button>
							)}
						</HStack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Stack>
	);
};
