import React from 'react';
import { Flex, Text, Spacer, Link } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

interface IProductNavigationProps {
  onClickLink: () => void;
  title: string | undefined;
  linkText: string | undefined;
}

export const ProductNavigation = ({
	title,
	onClickLink,
	linkText
}: IProductNavigationProps) => {
	return (
		<Flex
			alignItems={'center'}
			// px={{ base: '4', tablet: '10', xxl:'10' }}
			py={{ base: '4', tablet: '8' }}
		>
			<Text
				fontSize={{ base: 'md', tablet: 'md', xxl: '16px' }}
				fontWeight={'600'}
				lineHeight={'32px'}
			>
				{title}
			</Text>
			<Spacer />
			<Link
				fontSize={{ base: 'xs', tablet: 'sm', xxl: '16px' }}
				fontWeight={'400'}
				color='darkCoral'
				// borderBottom={'2px solid #FF6F61'}
				// _hover={{
				// 	borderBottom:'2px solid #FF6F61'
				// }}
				cursor={'pointer'}
				onClick={onClickLink}
				display={['none', 'block']}
				position={'relative'}
				_hover={{
					outline: 'none',
					textDecoration: 'none',
					_after: {
						width: '100%'
					}
				}}
				_after={{
					content: '""',
					position: 'absolute',
					bottom: 1,
					left: 0,
					right: 0,
					height: '0.13rem',
					width: '0%',
					background: 'loop.500',
					transition: '0.3s ease',
				}}
			>
				{linkText}
				<ArrowForwardIcon ml={2} h={10} />
			</Link>
		</Flex>
	);
};
