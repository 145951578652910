import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { AuthenticatedRoute } from './AuthenticatedRoutes';
// import { ComingSoonPage } from 'Pages/ComingSoonPage/ComingSoonPage';
import { authRoutes, fullyRegisteredRoutes, nonAuthRoutes } from './config';
import { RoleRestrictedRoute } from './RoleRestrictedRoute';
import { UserRoleEnum } from 'Types/Enums';
import { LocalizationProvider } from 'context/LocalizationContext';
import { useLocalizationRedirect } from 'Utils';
import { ScanPage } from 'Pages/Scan/ScanPage';
import ErrorPage from 'Components/ErrorBoundary/ErrorPage';

export const MainRoute = () => {
	const { isRedirectingToLocalizedRoute, currentKey } = useLocalizationRedirect();

	const isDev = window.location.href.includes('netlify') ||
		window.location.href.includes('ngrok') ||
		window.location.href.includes('localhost') ||
		window.location.href.includes('deploy') ||
		window.location.href.includes('ngrok'); // Temporary condition, to redirect visitor to coming soon page

	const localizePath = (path: string): string => `/:country/:locale${path}`;

	if (isRedirectingToLocalizedRoute) {
		return null; // Prevent rendering during redirecting to localized route
	}

	return (
		<Routes key={currentKey}>
			{/* {isDev ? ( */}
			<>
				<Route path={localizePath(AppRoutes.scan)} element={<ScanPage />} />

				{nonAuthRoutes.map(({ path, element }) => (
					<Route
						key={path}
						path={localizePath(path)}
						element={<LocalizationProvider>{element}</LocalizationProvider>}
					/>
				))}

				{authRoutes.map(({ path, element }) => (
					<Route
						key={path}
						path={localizePath(path)}
						element={
							<LocalizationProvider>
								<AuthenticatedRoute component={element} />
							</LocalizationProvider>
						}
					/>
				))}

				{fullyRegisteredRoutes.map(({ path, element }) => (
					<Route
						key={path}
						path={localizePath(path)}
						element={
							<LocalizationProvider>
								<RoleRestrictedRoute component={element} restrictedRole={UserRoleEnum.PartialUser} />
							</LocalizationProvider>
						}
					/>
				))}

				<Route path='*' element={<ErrorPage error={new Error('Page not found')} resetErrorBoundary={() => window.location.reload()} />} />
			</>
			{/* ) : (
				<Route path='*' element={<ComingSoonPage />} />
			)} */}
		</Routes>
	);
};
