import { ILocalizationCountry } from 'Types/Interface/ILocalizationCountry';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';

export const fetchCountryByIP = async (): Promise<ILocalizationCountry | null> => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}localization/get-country-by-ip`);
		if (!response.ok) {
			throw new Error('Failed to fetch country and locale');
		}
		const countryData = (await response.json()) as IJsonResponse<ILocalizationCountry>;
		return countryData.data;
	} catch (error) {
		console.error('Failed to fetch country by IP:', error);
		return null;
	}
};

export const fetchCountryByUrl = async (url: string): Promise<ILocalizationCountry | null> => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}localization/get-country-by-url?countryUrl=${url}`);
		if (!response.ok) {
			throw new Error('Failed to fetch country and locale');
		}
		const countryData = (await response.json()) as IJsonResponse<ILocalizationCountry>;
		return countryData.data;
	} catch (error) {
		console.error('Failed to fetch country by IP:', error);
		return null;
	}
};
