import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';

interface UserBrandPermissionsState {
  permissions: IUserBrandPermission;
}

const defaultPermissions: IUserBrandPermission = {
	userId: '',
	brandId: '',
	allowContactViaEmail: true,
	allowContactViaSms: true,
	allowContactViaWhatsapp: true,
	allowAdTargeting: true,
	allowContactViaLoop: true,
	shareName: true,
	sharePhone: true,
	shareEmail: true,
	privacyLevelId: '',
	isMaxSharingPrivacyLevel: false,
};

const initialState: UserBrandPermissionsState = {
	permissions: defaultPermissions, 
};

const userBrandPermissionsSlice = createSlice({
	name: 'userBrandPermissions',
	initialState,
	reducers: {
		setUserBrandPermissions(state, action: PayloadAction<IUserBrandPermission>) {
			state.permissions = action.payload;
		},

		clearUserBrandPermissions(state) {
			state.permissions = defaultPermissions;
		},
	},
});

export const { setUserBrandPermissions, clearUserBrandPermissions } = userBrandPermissionsSlice.actions;

export default userBrandPermissionsSlice.reducer;

export const selectUserBrandPermissions = (state: { userBrandPermissions: UserBrandPermissionsState }) =>
	state.userBrandPermissions.permissions;
