import React, { useEffect } from 'react';
import { VStack, Text, Heading, useDisclosure, useMediaQuery, Button, Image } from '@chakra-ui/react';
import { CustomModal } from 'Components';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { useFormik } from 'formik';
import { EmailVerificationModalContentOtp } from 'Pages/HomePage/Components/EmailVerificationModalContentOtp';
import { useSendEmailOtpMutation } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import errorIcon from 'Assets/svg/errorIcon.svg';
import * as yup from 'yup';
import { useLocale } from 'context/LocalizationContext';

interface ScanFailedCodeInUseProps {
	onVerified: () => void;
}

export const ScanFailedCodeInUse = ({ onVerified }: ScanFailedCodeInUseProps) => {
	const { locale } = useLocale();
	const { isOpen: isOpenPopup, onOpen: onOpenPopup, onClose: onClosePopup } = useDisclosure();
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

	const [
		sendEmailOtp,
		{ isSuccess: isSendOtpSuccess, isLoading: isSendOtpLoading, isError: isSendOtpError, error: sendOtpError },
	] = useSendEmailOtpMutation();

	useDefaultErrorHandler(isSendOtpError, sendOtpError);

	const {
		handleChange,
		handleSubmit,
		errors,
		values: formValues,
	} = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: yup.object().shape({
			email: yup.string().required('Email required').email('Please enter a valid email'),
		}),
		onSubmit: ({ email }) => {
			sendEmailOtp({ email: email, isForActiveUser: false, locale });
		},
	});

	useEffect(() => {
		if (isSendOtpSuccess) {
			onOpenPopup();
		}
	}, [isSendOtpSuccess]);

	return (
		<>
			<VStack px='5' py='8' spacing={4} textAlign='center'>
				<Image src={errorIcon} w={'60px'} h={'60px'} />

				<Heading size='sm' pt={4} w={'80%'}>
					This QR code has already been assigned and used.
				</Heading>
				<Text w={'80%'}>If you believe you are the owner of this code, please enter your email below.</Text>

				<CustomInput
					bgColor={'#FFF'}
					w={'100%'}
					h={'50px'}
					borderColor={'#FFF'}
					borderRadius={['10px', '5px']}
					placeholder='Email address'
					onChange={handleChange}
					name='email'
					error={errors.email}
					_focus={{
						outline: 'none',
					}}
				/>

				<Button
					variant={'primary'}
					w={'100%'}
					h={['50px']}
					mt={['30px', 0]}
					borderRadius={['10px', '5px']}
					onClick={() => handleSubmit()}
					isLoading={isSendOtpLoading}
					isDisabled={Object.keys(errors).length > 0}
				>
					Continue
				</Button>
			</VStack>

			<CustomModal
				isOpen={isOpenPopup}
				onClose={onClosePopup}
				modalContentStyles={{ borderRadius: 10, boxShadow: '0px 0px 30px rgba(88, 96, 104, 0.1)' }}
				size={isLargerThan768 ? 'md' : 'sm'}
				hideFooter
				isCentered
				content={
					<EmailVerificationModalContentOtp
						performAuthorization={true}
						email={formValues.email}
						onVerified={onVerified}
					/>
				}
			/>
		</>
	);
};
