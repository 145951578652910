import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';
import dayjs from 'dayjs';
import { TransactionStatus, UserActivityType } from 'Types/Enums';

const formatDateLong = (date: string) => {
	return dayjs(date).format('MMMM D, YYYY');
};
const formatDateShort = (date: string | Date | undefined) => {
	if (date === undefined) return '';
	return dayjs(date).format('DD/MM/YYYY');
};

const TransactionStatusComponent = ({ status, ...boxProps }: { status: TransactionStatus | undefined } & BoxProps) => {
	const color =
		status === TransactionStatus.Completed
			? 'kyc.green'
			: status === TransactionStatus.Pending
				? 'kyc.yellow'
				: status === TransactionStatus.Failed || status === TransactionStatus.Canceled
					? 'kyc.red'
					: 'loopGrey.700';

	const text =
		status === TransactionStatus.Completed
			? 'Completed'
			: status === TransactionStatus.Pending
				? 'Pending'
				: status === TransactionStatus.Failed
					? 'Failed'
					: status === TransactionStatus.Canceled
						? 'Canceled'
						: 'Unknown';

	return (
		<Box fontSize={['12px', '14px']} color={color} fontWeight='500' {...boxProps}>
			⦁ {text}
		</Box>
	);
};

const getStatusDescription = (activity?: UserActivityType): string => {
	if (activity === UserActivityType.SurveyCompleted) {
		return 'Survery Completed';
	} else if (activity === UserActivityType.Withdrawal) {
		return 'Withdrawal';
	} else if (activity === UserActivityType.CodeScan) {
		return 'Code Scan';
	} else if (activity === UserActivityType.RepeatCodeScan) {
		return 'Repeat Code Scan';
	} else {
		return 'Unknown';
	}
};

export { formatDateLong, formatDateShort, getStatusDescription, TransactionStatusComponent };
