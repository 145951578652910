import { Box, BoxProps, useRadio, UseRadioProps } from '@chakra-ui/react';
import React from 'react';
import { RadioButtonChecked } from 'Components/Icons';

type CustomRadioButtonProps = Omit<BoxProps, 'onChange'> & UseRadioProps & {
	children: React.ReactNode;
	onChange?: UseRadioProps['onChange'];
};

export const CustomRadioButton: React.FC<CustomRadioButtonProps> = (props) => {
	const { getInputProps, getCheckboxProps, state } = useRadio(props);

	const input = getInputProps();
	const checkboxProps = getCheckboxProps();

	const { isChecked, ...restProps } = props;

	return (
		<Box as="label">
			<input {...input} />
			<Box
				{...checkboxProps}
				cursor="pointer"
				borderWidth="1px"
				borderRadius="20px"
				borderColor={'loopGrey.600'}
				px={5}
				py={5}
				h={['auto', '90px']}
				display="flex"
				alignItems="center"
				_hover={{
					borderColor: 'darkCoral',
				}}
				{...restProps}
			>
				<Box display="flex"
					alignItems="center"
					justifyContent="center"
					w='22px'
					mr={5}>
					{!state.isChecked && (
						<Box					
							height="20px"
							width="20px"
							borderRadius="50%"
							borderColor={'loopGrey.600'}
							borderWidth={'2px'}
						>
						</Box>
					)}
					{state.isChecked && <RadioButtonChecked />}
				</Box>
				
				{props.children}
			</Box>
		</Box>
	);
};
