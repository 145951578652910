import React, { ReactNode, useContext, useEffect } from 'react';
import { Link, Text, Stack, Button, HStack, Spacer, chakra, Box } from '@chakra-ui/react';
import { OTPInput } from 'Components/FormInput/OTPInput';
import { EmailVerificationIcon } from 'Components/Icons/EmailVerificationIcon';
import { useFormik } from 'formik';
import {
	useResendEmailOtpMutation,
	useVerifyEmailWithOtpCodeAndAuthorizeMutation,
	useVerifyEmailWithOtpCodeMutation,
} from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useToast } from 'Hooks';
import { useRefreshUserProfile } from 'Utils/useRefreshUserProfile';
import { AuthContext } from 'context/AuthContext';
import { useLocale } from 'context/LocalizationContext';

interface EmailVerificationModalContentOtpProps {
	email: string;
	onVerified: () => void;
	performAuthorization?: boolean;
	children?: ReactNode;
}

export const EmailVerificationModalContentOtp = ({
	children,
	email,
	onVerified,
	performAuthorization = false,
}: EmailVerificationModalContentOtpProps) => {
	const { locale } = useLocale();
	const [resendEmailOtp, { isSuccess: isResendSuccess, isError: isResendError, error: resendError }] =
		useResendEmailOtpMutation();

	const [
		verifyEmail,
		{ isSuccess: isVerifySuccess, isLoading: isVerifyLoading, isError: isVerifyError, error: verifyError },
	] = useVerifyEmailWithOtpCodeMutation();

	const [
		verifyEmailAndAuthorize,
		{
			data: authResponse,
			isSuccess: isVerifyAndAuthorizeSuccess,
			isLoading: isVerifyAndAuthorizeLoading,
			isError: isVerifyAndAuthorizeError,
			error: verifyAndAuthorizeError,
		},
	] = useVerifyEmailWithOtpCodeAndAuthorizeMutation();

	useDefaultErrorHandler(isResendError, resendError);
	useDefaultErrorHandler(isVerifyError, verifyError, () => {
		setFieldValue('otp', '');
	});
	useDefaultErrorHandler(isVerifyAndAuthorizeError, verifyAndAuthorizeError, () => {
		setFieldValue('otp', '');
	});

	const { refreshUserProfile } = useRefreshUserProfile();

	const auth = useContext(AuthContext);

	const { alert } = useToast();

	useEffect(() => {
		if (isResendSuccess) {
			alert(
				'success',
				'We have sent you another verification code. Please check you messages or spam folder if it is not in your inbox',
			);
		}
	}, [isResendSuccess]);

	useEffect(() => {
		if (isVerifySuccess) {
			refreshUserProfile({ locale });
			onVerified();
		}
	}, [isVerifySuccess]);

	useEffect(() => {
		if (isVerifyAndAuthorizeSuccess && !!authResponse?.data) {
			auth.setAuthInfo(authResponse?.data);
			onVerified();
		}
	}, [isVerifyAndAuthorizeSuccess]);

	const { values, setFieldValue, handleSubmit } = useFormik({
		initialValues: { otp: '' },
		onSubmit: async (values) => {
			if (performAuthorization) {
				verifyEmailAndAuthorize({ email, code: values.otp, locale });
			} else {
				verifyEmail({ code: values.otp, locale });
			}
		},
	});

	return (
		<Box display={'flex'}  flexDirection={'column'} minH={['100vh', 'auto']} alignItems={'center'} justifyContent={'center'} >
			<Stack p={['20px', '40px']} spacing={['30px', '35px']} alignItems={'center'} justifyContent={'center'} >
				<EmailVerificationIcon boxSize={'80px'} />
				<Text fontSize={['20px', '30px']} fontWeight={'600'}>
					Verify code
				</Text>
				{children ? (
					children
				) : (
					<Text fontSize={['12px', '16px']} textAlign={'center'} pb={3}>
						Please enter the 6 digits code sent to <chakra.span textDecor={'underline'}>{email}</chakra.span>
					</Text>
				)}
				<OTPInput value={values.otp} onChange={(value) => setFieldValue('otp', value)} />
				<Spacer h={1} />
				<Button
					variant={'primary'}
					width={'100%'}
					h={['50px', '60px']}
					fontSize={['14px', '18px']}
					isLoading={isVerifyLoading || isVerifyAndAuthorizeLoading}
					onClick={() => handleSubmit()}
				>
					Continue
				</Button>
				<HStack pt={3}>
					<Text fontSize={['12px', '16px']} textAlign={'center'}>
						Didn&apos;t get a code?
					</Text>{' '}
					<Link
						fontWeight={'400'}
						color='darkCoral'
						cursor={'pointer'}
						onClick={() => {
							resendEmailOtp({ email: performAuthorization ? email : '', locale});
						}}
						fontSize={['12px', '16px']}
						textDecoration={'underline'}
						_hover={{ textDecoration: 'none' }}
					>
						Resend
					</Link>
				</HStack>
			</Stack>
		</Box>
	);
};
