/* eslint-disable no-restricted-globals */
import React from 'react';
import { ErrorBoundary as ErrorBoundaryPackage } from 'react-error-boundary';
import ErrorPage from './ErrorPage';

const errorHandler = (error: unknown) => {
	// eslint-disable-next-line no-console
	console.error(error);
};

interface IErrorBoundaryProps {
  children: React.ReactNode;
  onError?: (error: Error) => void;
}

export const ErrorBoundary = ({ children, onError }: IErrorBoundaryProps) => (
	<ErrorBoundaryPackage FallbackComponent={ErrorPage} onError={onError || errorHandler}>
		{children}
	</ErrorBoundaryPackage>
);

export default ErrorBoundary;
