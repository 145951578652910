import { Box, Heading } from '@chakra-ui/react';
import React from 'react';

interface IAccountLayout {
	title: string;
	children: React.ReactNode;
}

export const AccountLayout = ({ title, children }: IAccountLayout) => {
	return (
		<Box>
			{title !== 'My Loops' && (
				<Heading fontSize='lg' color={'#586068'} my={6}>
					{title}
				</Heading>
			)}
			<Box my={6}>{children}</Box>
		</Box>
	);
};
