import React from 'react';

import {
	Flex,
	Heading,
	VStack,
	Text,
	Button,
	HStack
} from '@chakra-ui/react';

import { IJumbotronContents } from 'Types/Interface/ILandingPage';
import { Icon } from '@chakra-ui/react';
import { GoArrowUpRight } from 'react-icons/go';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';


interface IJumbotronProps{
	contents: IJumbotronContents[]
}

export const Jumbotron = ({ contents }: IJumbotronProps) => {
	const navigate = useLocalizedNavigate();
	return (
		<Flex
			alignItems={['center', 'center']}
			flexDirection={{ base: 'column', md: 'row' }}
			justifyContent={['center']}
			h={['100vh',null, null, null, '100vh','100vh']}
			mt={{base:'-12', xl:''}}
			// m={'32 auto'}
			// px={{'2xl':'20'}}
		>
			{contents.map(content => {
				return(
					<VStack
						alignItems={['flex-start', 'center']}
						spacing={10}
						textAlign={['left', 'left', 'center']}
						key={content.heading}
					>
						<VStack alignItems={['flex-start', 'flex-start', 'center']} spacing={[8,10]} >
							<Heading fontSize={{ base: '40px', tablet: '2xl', xxl: '5xl' }}
								color="black" 
								w={['100%', '100%',null, null, '60%']}
								fontWeight={'extrabold'}
								// lineHeight={['8', '61px']}
								letterSpacing={'-0.03em'}
							>
								{content.heading}
							</Heading>
							<Text fontSize={{ base: '16px', tablet: '16px', xxl:'16px' }} 
								lineHeight={6} w={{base:'100%',xl:'50%'}} 
								color="black"
							>
								{content.text}
							</Text>
						</VStack>
						<HStack
							alignItems={['center', 'flex-start', 'center']} 
							justifyContent={['flex-start','space-between']}
							// visibility={['hidden', 'visible']}
							spacing={'15px'}
						>
							<Button
								w={['125px','160px']}
								h={['50px','60px']}
								variant={'primary'}
								onClick={() => navigate(AppRoutes.contact)}
							>
								Book a call 
							</Button>
							<Text onClick={() => navigate(AppRoutes.processOverview)} 
								display={'flex'} alignItems={'center'} fontSize={{ sm: 'xs', tablet: 'xs', xxl:'xs' }} 
								color="darkCoral" 
								fontWeight="600"
								cursor={'pointer'}
							>
								{content.subText}
								<Icon as={GoArrowUpRight} boxSize={'16px'} />
							</Text>
						</HStack>
					</VStack>
				);
			})}	
		</Flex>
	);
};
