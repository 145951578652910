import React from 'react';

import { BrandsJumbotronSection } from '../LandingPage/Components/BrandsJumbotronSection';
import { ExplorePageLayout } from 'Layouts/ExplorePageLayout';
import {ExplorePageJumbotronSectionContents} from 'Constants/JumbotronConstant';
import { SwipeableSlider } from './sections/SwipeableSlider';
import { ProductCarousel } from './sections/ProductCarousel';
import {  FeaturedbrandsCarousel} from './sections/FeaturedBrandsCarousel';
import { useGetAllProductsQuery } from 'services';
import { useLocale } from 'context/LocalizationContext';

export const Explore = () => {
	const { locale } = useLocale();
	const params = {pageNumber:1, pageSize:200, categoryId: '', locale};
	const {data, isLoading, isError} = useGetAllProductsQuery(params);

	return (
		<ExplorePageLayout 
			JumbotronSection={
				<BrandsJumbotronSection 
					contents={ExplorePageJumbotronSectionContents} 
					justify={'center'} textAlign={'center'}
				/> 
			}
			linkColorMode='black'
		>
			<SwipeableSlider shouldIgnoreParentPadding />
			<ProductCarousel products={data?.data} isLoading={isLoading} isError={isError} />
			< FeaturedbrandsCarousel/>
			{/* <FeaturedProducts brands={FeaturedProductsConstant} shouldIgnoreParentPadding={true} /> */}
			{/* <Deals /> */}
			{/* <ProductCarousel products={data?.data} isLoading={isLoading} /> */}
		</ExplorePageLayout>	
	);
};