import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, ModalContentProps } from '@chakra-ui/react';

interface IRewardModalProps {
	content: React.ReactNode;
	isOpen: boolean;
	onClose?: () => void;
	isCentered?: boolean;
	modalContentStyles?: ModalContentProps;
	showCloseButton?: boolean;
	size?: string;
}

export const CustomModal = ({
	content,
	isOpen,
	onClose,
	isCentered,
	modalContentStyles,
	showCloseButton = true,
	size = 'lg',
}: IRewardModalProps) => {
	return (
		<Modal
			size={size}
			isOpen={isOpen}
			onClose={() => {
				onClose && onClose();
			}}
			isCentered={isCentered}
		>
			<ModalOverlay />
			<ModalContent h={'560px'} w={'600px'} padding={0} borderRadius={1} {...modalContentStyles}>
				{showCloseButton && <ModalCloseButton bg='white' borderRadius='50%' zIndex='overlay' />}
				<ModalBody px={['10px', '30px']} overflow='auto' className='custom-modal'>
					{content}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
