/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
	Button,
	// Divider,
	Flex,
	Heading,
	HStack,
	// IconButton,
	Link,
	Stack,
	Text
} from '@chakra-ui/react';
import { ChevronRightIcon, Icon } from '@chakra-ui/icons';
import { BsHeart, BsHeartFill } from 'react-icons/bs';
// import { GrFacebookOption, GrInstagram, GrLinkedinOption } from 'react-icons/gr';
// import { HiLink } from 'react-icons/hi';
import { formatCurrency } from 'Utils';
import { ILandingPageLayoutChild } from 'Layouts/LandingPageLayout';
import { ProductImageViewer } from './ProductImageViewer';
import { IProductDetails } from 'Types/Interface/IProduct';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { useAppSelector } from 'Hooks';
import { AppRoutes } from 'Routes/AppRoutes';
import {
	useFavoriteProductMutation,
	useUnFavoriteProductMutation,
	useContinueSurveyMutation,
	useGetAvailableSurveysQrCodesForProductQuery,
} from 'services';
import { useDispatch } from 'react-redux';
import { setActiveSurvey, setCodeType, setSurveyCode, setShowScanCodeMessage, setUserBrandPermissions } from 'store/slices';
import { CodeType } from 'Types/Enums';
import { CustomModal } from 'Components';
import { SuccessScan } from 'Pages/Survey/components/SuccessScan';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { ScanFailedCodeInUse } from 'Pages/Survey/components/ScanFailedCodeInUse';
import { useRefreshUserProfile } from 'Utils/useRefreshUserProfile';
import { useLocale } from 'context/LocalizationContext';
import ReactMarkdown from 'react-markdown';

interface IDescriptionProps extends ILandingPageLayoutChild {
	data: IProductDetails;
	isMobile?: boolean;
	productId: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Description = ({ isMobile, data, productId }: IDescriptionProps) => {
	const {
		showScanCodeMessage,
		codeScanData: { isCodeAssignedToAnotherAccount },
	} = useAppSelector((state) => state.survey);

	const { refreshUserProfile } = useRefreshUserProfile();

	const [isOpen, setIsOpen] = useState(showScanCodeMessage);

	const { locale } = useLocale();

	const {
		data: qrCodesForAvailableSurveys,
		isError: isAvailableSurveysError,
		error: availableSurveysError,
	} = useGetAvailableSurveysQrCodesForProductQuery({ productId, locale });

	const [getSurvey, { data: surveyData, isSuccess, isLoading, isError, error }] = useContinueSurveyMutation();

	// const modalSize = useBreakpointValue({ base: 'full', md: 'sm' });
	const navigate = useLocalizedNavigate();
	const dispatch = useDispatch();

	const [favorite] = useFavoriteProductMutation();
	const [unFavorite] = useUnFavoriteProductMutation();
	const [isFavorited, setIsFavorited] = useState(data?.isFavoritedByCurrentUser);

	const price = data?.price ? data?.price : 0;
	const images = data?.images?.length ? data?.images : [];

	const startSurvey = () => {
		if (qrCodesForAvailableSurveys && qrCodesForAvailableSurveys.data.length > 0) {
			getSurvey({ qrCode: qrCodesForAvailableSurveys.data[0], locale });
		}
	};

	useEffect(() => {
		if (isSuccess && surveyData?.data && qrCodesForAvailableSurveys) {
			const qrCode = qrCodesForAvailableSurveys.data[0];
			dispatch(setActiveSurvey(surveyData.data));
			dispatch(setSurveyCode(qrCode));
			dispatch(setCodeType(CodeType.qrCode));			
			dispatch(setUserBrandPermissions(surveyData?.data.brandPermission));
			setTimeout(() => {
				navigate(AppRoutes.survey.replace(':webcode', qrCode));
			}, 500);
		}
	}, [isSuccess, surveyData, qrCodesForAvailableSurveys]);

	const onClose = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		setIsFavorited(data?.isFavoritedByCurrentUser);
	}, [data]);

	useEffect(() => {
		if (showScanCodeMessage) {
			setIsOpen(true);
			dispatch(setShowScanCodeMessage(false));
		}
	}, [showScanCodeMessage, dispatch]);

	const _handleFavoriteProduct = () => {
		setIsFavorited(!isFavorited);
		const params = { productId: data?.id, locale };
		if (data?.isFavoritedByCurrentUser) {
			unFavorite(params);
		} else {
			favorite(params);
		}
	};

	useDefaultErrorHandler(isError, error);
	useDefaultErrorHandler(isAvailableSurveysError, availableSurveysError);

	const _handleNavigateToBrand = (brandId: string) => {
		navigate(AppRoutes.brand.replace(':brandId', brandId));
	};

	const onLoginToAnotherAccount = () => {
		onClose();
		refreshUserProfile({ locale });
	};

	return (
		<>
			<CustomModal
				isOpen={isOpen}
				onClose={onClose}
				hideFooter
				isCentered
				content={
					isCodeAssignedToAnotherAccount ? (
						<ScanFailedCodeInUse onVerified={onLoginToAnotherAccount} />
					) : (
						<SuccessScan />
					)
				}
				modalContentStyles={{ borderRadius: isMobile ? 0 : 10 }}
				size={isMobile ? 'full' : 'sm'}
			/>
			<Flex gap={8} mt={5} {...(isMobile && { mt: 0 })} direction={['column', null, null, 'row']}>
				<ProductImageViewer images={images} isMobile={isMobile} />
				<Stack {...(isMobile && { px: 4 })} spacing={[4, 5]} w={['100%', null, null, '45%']}>
					<Heading color={'brand.black'} fontSize={['24px', null, '30px']}>
						{data?.name}
					</Heading>
					<HStack justify={'space-between'}>
						<Text color={'blackTextColor.100'} fontSize={['12px', '14px']}>
							By{' '}
							<Link onClick={() => _handleNavigateToBrand(data.brandId)} textDecor={'underline'}>
								{data?.brand?.name}
							</Link>
						</Text>
						<Icon
							as={isFavorited ? BsHeartFill : BsHeart}
							color={'darkCoral'}
							boxSize={4}
							display={['flex', 'none']}
							_hover={{ outline: 'none' }}
							onClick={_handleFavoriteProduct}
						/>
					</HStack>
					<Heading fontSize={['18px', null, '22px']}>{formatCurrency(price)}</Heading>
					<Stack spacing={'10px'} fontWeight={'400'} fontSize={['14px','16px']}><ReactMarkdown >
						{data?.description}
					</ReactMarkdown></Stack>
					<Flex gap={3} w='100%'>
						{qrCodesForAvailableSurveys && qrCodesForAvailableSurveys.data.length > 0 && (
							<Button
								w={['350px', '50%']}
								h={['50px']}
								p={3}
								px={4}
								fontSize={'sm'}
								bg='darkCoral'
								color={'white'}
								border={'1px solid darkCoral'}
								borderRadius={['50px', '5']}
								rightIcon={<ChevronRightIcon />}
								onClick={startSurvey}
								isLoading={isLoading}
								position={['fixed', 'relative']}
								top={['85%', 0]}
								left={['50%', 0]}
								transform={['translate(-50%, -50%)', 'none']}
								zIndex={[99999, 0]}
								_hover={
									isMobile ? {} : { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', transform: 'translateY(1px)' }
								}
							>
								Take survey
							</Button>
						)}
						<Button
							w={['350px', '50%']}
							fontSize={['xs', 'sm']}
							border={'1.5px solid #FF6F61'}
							color='darkCoral'
							h={['50px']}
							rightIcon={<Icon as={isFavorited ? BsHeartFill : BsHeart} color={'darkCoral'} />}
							onClick={_handleFavoriteProduct}
							display={['none', 'flex']}
							_hover={{
								boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
								transform: 'translateY(1px)',
							}}
						>
							{isFavorited ? 'Remove Favourite' : 'Add to Favourites'}
						</Button>
					</Flex>
					{/* <Divider /> */}
					{/* <Flex gap={3}>
						<Text color={'brand.black'} fontSize='14px' fontWeight='semibold'>
							SHARE:
						</Text>
						<IconButton aria-label='facebook share' icon={<Icon as={GrFacebookOption} h={[4, 5]} w={[4, 5]} />} />
						<IconButton aria-label='insta share' icon={<Icon as={GrInstagram} h={[4, 5]} w={[4, 5]} />} />
						<IconButton aria-label='linkedin share' icon={<Icon as={GrLinkedinOption} h={[4, 5]} w={[4, 5]} />} />
						<IconButton aria-label='link share' icon={<Icon as={HiLink} h={[4, 5]} w={[4, 5]} />} />
					</Flex> */}
				</Stack>
			</Flex>
		</>
	);
};
