import { Button, FormControl, FormLabel, Input, SimpleGrid, Stack } from '@chakra-ui/react';
import { LandingPageLayout } from 'Layouts/LandingPageLayout';
import { useForm } from '@formspree/react';
import React from 'react';
import CalendlyWidget from './Components/Calendly';
import { Jumbotron } from './Components/Jumbotron';
import { useToast } from 'Hooks';
import * as Yup from 'yup';
import { SubmissionData, FieldValues } from '@formspree/core';
import { useFormik } from 'formik';
import { CustomInput } from 'Components/FormInput/CustomInput';

const content = [
	{
		heading: 'Let’s Build Your Brand-Owned Community',
		text: 'Book a free 30-minute consultation to explore how Loop can transform your customer engagement strategy.',
		subText: 'Get in Touch',
	},
];

const validationSchema = Yup.object({
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	email: Yup.string().email('Invalid email').required('Email is required'),
	phoneNumber: Yup.string().matches(/^\d+$/, 'Phone number must be numeric'),
	preferredDate: Yup.date().nullable(),
	preferredTime: Yup.string().nullable(),
	companyName: Yup.string(),
	other: Yup.string(),
});

export const Contact = () => {
	const [state, handleSubmit] = useForm(process.env.REACT_APP_FORMSPREE_ID as string); 
	const { alert } = useToast();

	const { handleChange, handleSubmit:submitForm, errors, values } = useFormik({
		initialValues: {
			firstName: '',
			lastName:'',
			email: '',
			phoneNumber: '',
			preferredDate: '',
			preferredTime: '',
			companyName: '',
			other: '',
		},
		validationSchema,
		onSubmit: async (values: React.FormEvent<HTMLFormElement> | SubmissionData<FieldValues>, { resetForm }: any) => {
			await handleSubmit(values); 
			alert('success', 'Thanks for your submission!');
			resetForm();
		},});
	

	return (
		<LandingPageLayout JumbotronSection={<Jumbotron contents={content} />} isBusiness>
			<Stack mt="70px" spacing="40px" align={['flex-start', 'center']} justify="center" w="100%" h="100%">
				
				<SimpleGrid w={['full', '100%']} gap={['30px', '40px']} justifyContent="center" columns={[1, 2]}>
					<FormControl w={['100%',null, 'auto','auto', '450px']} isRequired>
						<FormLabel>First Name</FormLabel>
						<CustomInput
							onChange={handleChange}
							name='firstName'
							type='text'
							placeholder=''
							value={values.firstName}
							error={errors.firstName}
						/>
					</FormControl>

					<FormControl w={['100%', null, null,'auto', '450px']} isRequired>
						<FormLabel>Last Name</FormLabel>
						<CustomInput
							onChange={handleChange}
							name='lastName'
							type='text'
							placeholder=''
							value={values.lastName}
							error={errors.lastName}
						/>
					</FormControl>

					<FormControl w={['100%',null, null, 'auto', '450px']} isRequired>
						<FormLabel>Email</FormLabel>
						<CustomInput
							onChange={handleChange}
							name='email'
							type='email'
							placeholder=''
							value={values.email}
							error={errors.email}
						/>
					</FormControl>

					<FormControl w={['100%', null, null, 'auto', '450px']}>
						<FormLabel>Phone Number</FormLabel>
						<CustomInput
							onChange={handleChange}
							name='phoneNumber'
							type='text'
							placeholder=''
							value={values.phoneNumber}
							error={errors.phoneNumber}
						/>
					</FormControl>

					<FormControl w={['100%', null, null, 'auto', '450px']}>
						<FormLabel>Preferred Date</FormLabel>
						<Input type="date" name="preferredDate" value={values.preferredDate}  onChange={handleChange} />
					</FormControl>

					<FormControl w={['100%', null, null, 'auto', '450px']}>
						<FormLabel>Preferred Time</FormLabel>
						<Input type="time" name="preferredTime" value={values.preferredTime} onChange={handleChange} />
					</FormControl>

					<FormControl w={['100%',null, null, '350px', '450px']}>
						<FormLabel>Company Name</FormLabel>
						<Input type="text" name="companyName" value={values.companyName} onChange={handleChange} />
					</FormControl>

					<FormControl w={['100%',null, null, '350px', '450px']}>
						<FormLabel>Other</FormLabel>
						<Input type="text" name="other" value={values.other} onChange={handleChange} />
					</FormControl>
				</SimpleGrid>

				<Button
					w={['125px', '450px']}
					h={['50px', '60px']}
					variant="primary"
					type="submit"
					isLoading={state.submitting}
					justifySelf={['flex-start', 'center']}
					mt="40px"
					onClick={() => submitForm()}
				>
					Submit
				</Button>
				
			</Stack>

			<CalendlyWidget shouldIgnoreParentPadding />
		</LandingPageLayout>
	);
};