import { useEffect, useState } from 'react';
import { IGeolocation } from 'Types/Interface/IGeoLocation';

export const useGeolocation = (shouldFetch: boolean): IGeolocation | null => {
	const [coords, setCoords] = useState<IGeolocation | null>(null);

	useEffect(() => {
		if (!shouldFetch) return;

		const location = navigator.geolocation;

		if (location) {
			location.getCurrentPosition(
				(position) => {
					const latitude = position.coords.latitude;
					const longitude = position.coords.longitude;
					setCoords({ latitude, longitude });
				},
				(error) => {
					console.error('Error getting location:', error);
				}
			);
		} else {
			console.error('Geolocation is not supported by this browser.');
		}
	}, [shouldFetch]);

	return coords;
};
