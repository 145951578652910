import React from 'react';
import { preferenceDetails } from 'Constants';
import { DashboardLayoutWithDescription } from 'Pages/Dashboard/Components/LayoutWithDescription';
import { Content } from './Content';

export const Preferences = () => {

	return (
		<DashboardLayoutWithDescription
			content={<Content isSurvey={false}/>}
			description={preferenceDetails}/>
	);
};
