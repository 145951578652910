import React, { useContext } from 'react';
import { Box, CloseButton, Link } from '@chakra-ui/react';
import { AuthContext } from 'context/AuthContext';
import { useAppDispatch, useAppSelector } from 'Hooks';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';
import { setHideCompleteSignupSection } from 'store/slices';

export const CompleteSignupPrompt = () => {
	const navigate = useLocalizedNavigate();
	const dispatch = useAppDispatch();
	const auth = useContext(AuthContext);

	const { hideCompleteSignupSection } = useAppSelector((state) => state.authUser);

	const handleHideCompleteSignupSection = () => {
		dispatch(setHideCompleteSignupSection(true));
	};

	return hideCompleteSignupSection || auth.isFullyRegisteredUser() ? (
		<></>
	) : (
		<>
			<Box
				backgroundColor={'extraLightCoral'}
				textAlign={'center'}
				py={4}
				px={['5', null, 6, '20']}
				fontSize={['10px', '14px']}
			>
				Please complete signing up to enjoy benefits{' '}
				<Link
					fontWeight={'400'}
					color='darkCoral'
					cursor={'pointer'}
					onClick={() => {
						navigate(AppRoutes.register);
					}}
					pl={1}
					textDecoration={'underline'}
					_hover={{ textDecoration: 'none' }}
				>
					Sign up
				</Link>
				<CloseButton
					height={['13px', '20px']}
					fontSize={'9px'}
					float={'right'}
					onClick={handleHideCompleteSignupSection}
				></CloseButton>
			</Box>
		</>
	);
};
