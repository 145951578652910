import { ISurvey } from './../Types/Interface/ISurvey';
import { useAppSelector } from 'Hooks';
import { CampaignType } from 'Types/Enums';

export function isLastSurveySection() {
	const {
		activeSurvey: { survey },
		section,
	} = useAppSelector((state) => state.survey);

	return (
		section === 'brandSharingSettings' ||
		(section === 'questions' && survey.campaignType === CampaignType.feedbackOnly)
	);
}

export function getMaxRewardPoints(survey: ISurvey | undefined): number {
	if (survey === undefined) return 0;
	return (1 + getInitialScanPointPercentage(survey) / 100) * getPointsPerFeedback(survey) + survey.higherSharingPoints;
}

export function getPointsPerFeedback(survey: ISurvey | undefined): number {
	return survey?.rewards?.[0]?.pointsPerFeedback ?? 0;
}

export function getInitialScanPointPercentage(survey: ISurvey | undefined): number {
	return survey?.rewards?.[0]?.initialScanPointsPercentage ?? 0;
}
