import { createApi } from '@reduxjs/toolkit/query/react';
import { routes } from 'Api/apiRoutes';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IBrandHistory } from 'Types/Interface/IBrand';
import { IProduct, IProductDetails } from 'Types/Interface/IProduct';
import { IPaginatedResponse } from './types';

export const productApi = createApi({
	reducerPath: 'productApi',
	baseQuery,
	tagTypes: ['Product'],
	endpoints: (builder) => ({
		getProductDetails: builder.query<IProductDetails, { productId: string; locale: string }>({
			query: ({ productId, locale }) => ({
				url: `${routes.product}/${productId}`,
				headers: getLocalizationHeader(locale),
			}),
			providesTags: ['Product'],
		}),
		getAllProducts: builder.query<
			IPaginatedResponse<IProduct[]>,
			{ pageNumber?: number; pageSize?: number; categoryId?: string; locale: string }
		>({
			query: ({ pageNumber, pageSize, categoryId, locale }) => ({
				url: `/${routes.product}/getproducts?categoryId=${categoryId}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
				headers: getLocalizationHeader(locale),
			}),
		}),
		getProductByCategory: builder.query<IJsonResponse<IProduct[]>, { categoryId: string; locale: string }>({
			query: ({ categoryId, locale }) => ({
				url: `categories/${categoryId}/products`,
				headers: getLocalizationHeader(locale),
			}),
		}),
		getAvailableSurveysQrCodesForProduct: builder.query<IJsonResponse<string[]>, { productId: string; locale: string }>(
			{
				query: ({ productId, locale }) => ({
					url: `${routes.product}/available-surveys-qrcodes/${productId}`,
					headers: getLocalizationHeader(locale),
				}),
			},
		),
		favoriteProduct: builder.mutation<unknown, { productId: string | undefined; locale: string }>({
			query: ({ productId, locale }) => ({
				url: `${routes.product}/${productId}/favorite`,
				method: 'POST',
				headers: getLocalizationHeader(locale),
			}),
			invalidatesTags: ['Product'],
		}),
		unFavoriteProduct: builder.mutation<unknown, { productId: string; locale: string }>({
			query: ({ productId, locale }) => ({
				url: `${routes.product}/${productId}/unfavorite`,
				method: 'POST',
				headers: getLocalizationHeader(locale),
			}),
			invalidatesTags: ['Product'],
		}),
		getProductScanHistory: builder.query<IJsonResponse<IBrandHistory>, { productId: string; locale: string }>({
			query: ({ productId, locale }) => ({
				url: `survey/${productId}/productscanhistory`,
				headers: getLocalizationHeader(locale),
			}),
		}),
	}),
});

export const {
	useGetProductDetailsQuery,
	useGetAllProductsQuery,
	useGetProductByCategoryQuery,
	useFavoriteProductMutation,
	useUnFavoriteProductMutation,
	useGetProductScanHistoryQuery,
	useGetAvailableSurveysQrCodesForProductQuery,
} = productApi;