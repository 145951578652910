import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IBaseSanityDocument } from 'Types/Interface/Sanity/IBaseSanityDocument';
import StorageService from 'Utils/storageService';

const projectId = process.env.REACT_APP_SANITY_PROJECT_ID;
const dataset = process.env.REACT_APP_SANITY_DATASET;

export const sanityApi = createApi({
	reducerPath: 'sanityApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${projectId}.api.sanity.io/v2022-03-07/data/query/${dataset}`,
		prepareHeaders: (headers) => {
			headers.set('authorization', `Bearer ${process.env.REACT_APP_SANITY_TOKEN}`);
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getDocumentByTypeAndLocale: builder.query<IBaseSanityDocument, {type: string, locale: string, country: string}>({
			query: ({type, locale, country}) => {
				const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
				const defaultCountry = process.env.REACT_APP_DEFAULT_COUNTRY;

				const query = `*[_type == "${type}" && (language == "${country}-${locale}" || language == "${country}-${defaultLanguage}" || language == "${defaultCountry}-${defaultLanguage}")]`;
				return {
					url: '',
					params: {
						query: query,
					},
					method: 'GET',
				};
			},
			transformResponse: (response: { result: IBaseSanityDocument[] }) => {
				const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
				const defaultCountry = process.env.REACT_APP_DEFAULT_COUNTRY;
				const locale = StorageService.getLanguageOrDefault();
				const country = StorageService.getCountryUrlOrDefault();
				const localeCode = `${country}-${locale}`;
				const defaultLocaleCode = `${country}-${defaultLanguage}`;
				const globalDefaultLocaleCode = `${defaultCountry}-${defaultLanguage}`;

				return (
					response.result.find((doc) => doc.language === localeCode) ||
					response.result.find((doc) => doc.language === defaultLocaleCode) ||
					response.result.find((doc) => doc.language === globalDefaultLocaleCode) ||
					response.result[0]
				);
			},
		}),
	}),
});

export const { useGetDocumentByTypeAndLocaleQuery } = sanityApi;
