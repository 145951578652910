import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';
import { AppRoutes } from 'Routes/AppRoutes';
import { localizePath } from 'Utils';

type RoleRestrictedRouteType = {
	component: React.ReactNode;
	restrictedRole: string;
};

export const RoleRestrictedRoute = ({ component, restrictedRole }: RoleRestrictedRouteType) => {
	const { getAuthenticatedUserRole } = useContext(AuthContext);

	const role = getAuthenticatedUserRole();

	if (!role || role === restrictedRole) {
		return <Navigate to={localizePath(AppRoutes.landingpage)} />;
	}

	return <>{component}</>;
};
