// Error codes are random strings of digits of length 10
const ErrorCodes = {
	FetchBrand: '5612024864',
	FetchBrandLoopDetails: '4443877159',
	FetchNotifications: '2388105371',
	FetchProduct: '3735316348',
	FetchSurveyByCode: '0740678926',
	FetchSurveyById: '0219011942',
	FetchUserProfile: '3084637716',
	GoogleLogin: '5010902961',
	TiliaWidget: '1756799518',
};

export default ErrorCodes;
