import React, { useContext, useEffect, useState } from 'react';
import {
	Heading,
	Text,
	Button,
	Stack,
	useDisclosure,
	Box,
	useMediaQuery,
} from '@chakra-ui/react';
import { PhoneNumberInput } from 'Components/FormInput/PhoneNumberInput';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSendVerification } from 'Hooks';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AuthContext } from 'context/AuthContext';
import { AppRoutes } from 'Routes/AppRoutes';
import { DynamicProductPageLayout } from 'Layouts/DynamicProductPageLayout';
import { CustomModal, PhoneNumberVerification } from 'Components';
import { useLocale } from 'context/LocalizationContext';
import {
	useCreatePartialAccountMutation,
	useVerifyPhoneWithOtpCodeAndAuthorizeMutation,
} from 'services';
import { IPhoneNumberVerificationDto } from 'Types/Interface/IUser';
import { TermsAgreement } from 'Components/TermsAgreement';

export const RegisterPartialUser = () => {
	const { locale } = useLocale();
	const navigate = useLocalizedNavigate();
	const auth = useContext(AuthContext);
	const {
		isOpen: isPhoneModalOpen,
		onOpen: onPhoneModalOpen,
		onClose: onPhoneModalClose,
	} = useDisclosure();
	const [otpValue, setOtpValue] = useState('');
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

	const {
		mutate: sendPhoneVerificationCode,
		isSuccess: isSendPhoneCodeSuccess,
		isError: isSendPhoneCodeError,
		error: sendPhoneCodeError,
	} = useSendVerification();
	const [
		registerUserWithPhone,
		{ data, isLoading: isRegisterLoading, isError: isRegisterError, error },
	] = useCreatePartialAccountMutation();
	const [
		verifyPhoneAndAuthorize,
		{
			data: authResponse,
			isSuccess: isVerifyAndAuthorizeSuccess,
			isLoading: isVerifyAndAuthorizeLoading,
			isError: isVerifyAndAuthorizeError,
			error: verifyAndAuthorizeError,
		},
	] = useVerifyPhoneWithOtpCodeAndAuthorizeMutation();

	const handleOtpSubmitted = (data: IPhoneNumberVerificationDto) => {
		verifyPhoneAndAuthorize({ data, locale });
	};

	useEffect(() => {
		if (isVerifyAndAuthorizeSuccess && !!authResponse?.data) {
			auth.setAuthInfo(authResponse?.data);
			onPhoneModalClose();
			navigate(AppRoutes.home);
		}
	}, [isVerifyAndAuthorizeSuccess]);

	useDefaultErrorHandler(isRegisterError, error);
	useDefaultErrorHandler(isSendPhoneCodeError, sendPhoneCodeError, onPhoneModalClose);
	useDefaultErrorHandler(isVerifyAndAuthorizeError, verifyAndAuthorizeError, () => {
		setOtpValue('');
	});

	const {
		handleSubmit,
		setFieldValue,
		errors,
		values: formValues,
	} = useFormik({
		initialValues: {
			phoneNumber: '',
			countryCode: '',
		},
		validationSchema: yup.object().shape({
			phoneNumber: yup
				.string()
				.required('Phone number is required')
		}),
		onSubmit: ({ phoneNumber, countryCode }) => {
			registerUserWithPhone({ requestDto: { phoneNumber, countryCode }, locale }); // Send verification code on submit
		},
	});

	const handleSendPhoneCode = () => {
		if (formValues.phoneNumber) {
			const data = {
				phoneNumber: formValues.phoneNumber.toString().toInternationalPhoneNumber(),
				countryCode: formValues.countryCode,
			};
			sendPhoneVerificationCode(data);
		}
	};

	useEffect(() => {
		if (data?.data) {
			if (data.data.isUserAlreadyRegistered) {
				onPhoneModalOpen(); // Open verification modal if the user is already registered
			} else {
				auth.setAuthInfo(data?.data.tokenResponse);
				navigate(AppRoutes.home);
			}
		}
	}, [data]);

	useEffect(() => {
		if (isSendPhoneCodeSuccess) {
			onPhoneModalOpen(); // Open the modal when phone code is successfully sent
		}
	}, [isSendPhoneCodeSuccess]);

	return (
		<DynamicProductPageLayout
			JumbotronSection={
				<>
					<Stack spacing={6}>
						<Heading as='h1' fontSize={['20px', '30px']}>
							We Just Need Your Phone Number
						</Heading>
						<Text fontSize={'16px'}>
							Please enter your phone number so we can assign this QR code session and your cash rewards to it.
						</Text>

						<Box w={'300px'}>
							<PhoneNumberInput
								value={formValues.phoneNumber}
								onChange={(phoneNumber, countryCode) => {
									setFieldValue('phoneNumber', phoneNumber);
									setFieldValue('countryCode', countryCode);
								}}
								error={errors.phoneNumber}
							/>
						</Box>
						<TermsAgreement isAccepted={termsAccepted} onAccepted={setTermsAccepted} />
					</Stack>

					<Button
						variant={'primary'}
						w={['100%', '160px']}
						h={'50px'}
						mt={'60px'}
						borderRadius={['10px', '5px']}
						fontSize={['14px', '16px']}
						onClick={() => handleSubmit()}
						isLoading={isRegisterLoading}
						isDisabled={!termsAccepted || Object.keys(errors).length > 0}
					>
						Confirm
					</Button>
				</>
			}
		>
			<CustomModal
				isOpen={isPhoneModalOpen}
				onClose={onPhoneModalClose}
				modalContentStyles={{ borderRadius: 10, boxShadow: '0px 0px 30px rgba(88, 96, 104, 0.1)' }}
				size={isLargerThan768 ? 'md' : 'full'}
				hideFooter
				isCentered
				content={
					<PhoneNumberVerification
						phoneNumber={formValues.phoneNumber}
						countryCode={formValues.countryCode}
						otpValue={otpValue}
						setOtpValue={setOtpValue}
						onClose={onPhoneModalClose}
						onSubmit={handleOtpSubmitted}
						handleSendCode={handleSendPhoneCode}
						isSendCodeError={isSendPhoneCodeError}
						sendCodeError={sendPhoneCodeError}
						isLoading={isVerifyAndAuthorizeLoading}
					/>
				}
			/>
		</DynamicProductPageLayout>
	);
};
