import React, { useState } from 'react';
import {
	Flex,
	Heading,
	Text,
	UnorderedList,
	ListItem,
	useMediaQuery,
	Stack,
	VStack,
} from '@chakra-ui/react';

import { Carousels } from 'Constants/ImageSliderConstant';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { HowItWorksDesktop } from './HowItWorksDesktop';
import { HowItWorksMobile } from './HowItWorksMobile';

export const HowItWorksSection = () => {
	const [currentSlide, setcurrentSlide] = useState(0);
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	const active = currentSlide;

	const onRight = () => {
		if (currentSlide < Carousels.length - 1) {
			setcurrentSlide(currentSlide + 1);
		}
	};

	const onLeft = () => {
		if (currentSlide > 0) {
			setcurrentSlide(currentSlide - 1);
		}
	};

	const Slidernavigation = (index: number) => {
		setcurrentSlide(index);
	};

	const activeCarousel = {
		desktop: <HowItWorksDesktop currentSlide={currentSlide} />,
		mobile: <HowItWorksMobile />
	};

	return (
		<Flex
			direction={'column'}
			maxW={'100%'}
			pt={['40px','100px']}
			// {...(shouldIgnoreParentPadding && { pl: ['8', '20'] })}
		>
			<VStack spacing={['50px','40px']} w={['100%', null, null, '100%']} align={'flex-start'}>
				<Stack spacing={['20px','30px']} >
					<Heading
						fontSize={{ base: '20px', tablet: '2xl', xxl: '50px' }}
						fontWeight={'600'}
					>
						How It Works.
					</Heading>
					<Text fontSize={['16px', '18px']} fontWeight={500} color={'#2B2B2B;'}>
						Be Part of a Brand Community in 3 Simple Steps
					</Text>
				</Stack>
				<Flex justifyContent={'space-between'} w={'100%'}>
					<Flex direction={'column'} mt="8" mr={[0,10]}>
						<Flex
							cursor={'pointer'}
							ml={3}
							h={10}
							display={['none', 'none', 'block']}
						>
							<ArrowBackIcon
								onClick={onRight}
								boxSize={5}
								w={10}
								color="#586068"
							/>
							<ArrowForwardIcon
								onClick={onLeft}
								boxSize={5}
								w={10}
								color="#586068"
							/>
						</Flex>
						{Carousels.map((Carousel, index) => {
							return (
								<UnorderedList key={Carousel.id}>
									<ListItem
										mb={6}
										listStyleType={'none'}
										fontSize={'md'}
										fontWeight={active === index ? 'semibold' : 'normal'}
										color={active === index ? '#121B26' : '#96999B'}
										_hover={{ color: '#121B26', fontWeight: '600' }}
										cursor={'pointer'}
										onClick={() => Slidernavigation(index)}
										display={['none', 'none', 'block']}
									>
										{Carousel.label}
									</ListItem>
								</UnorderedList>
							);
						})}
					</Flex>
					<Flex
						justifyContent={'space-between'}
						alignItems={'center'}
						w={['100%', '80%']}
						overflow={['', '', 'hidden']}
					>
						{
							activeCarousel[isLargerThan768 ? 'desktop' : 'mobile']
						}
					</Flex>
				</Flex>
			</VStack>
		</Flex>
	);
};