import { ChevronDownIcon} from '@chakra-ui/icons';
import { Button, chakra, Heading, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';

export const HomeJumbotronSection = () => {
	const navigate = useLocalizedNavigate();
	return(
		<Stack 
			py={['70px','100px']} 
			spacing={10} 
			textAlign={['left','center']} 
			justifyContent={'center'} 
			alignItems={['start','center']}
			h={'100vh'}
		>
			<Heading fontSize={{ base: '40px', tablet: '2xl', xxl: '5xl' }} w={'100%'}>
			Join the  <chakra.span color={'darkCoral'}> Conversation </chakra.span> with  <br />  Your Favorite Brands
			</Heading>
			<Stack spacing={['20px']}>
				<Text  fontSize={['16px','16px']}>
					Loop connects you to your favorite brands, making you part of their community.<br />			
					Everything is private and between just you and them. 
				</Text>
				<Text  fontSize={['16px','16px']}>
					Share your thoughts, earn cash rewards, and help shape the products you already buy, <br /> all while staying in control of your privacy.
				</Text>
			</Stack>
			
			<Button boxShadow={'0px 20px 40px 0px rgba(18, 27, 38, 0.14)'} variant={'primary'} 
				w={['160px','200px']} h={['50px','60px']} borderRadius={['10px','20px']} onClick={() => navigate(AppRoutes.register)}
			>Get Started</Button>
			<ChevronDownIcon display={['none', 'block']} boxSize={'30px'}/>
		</Stack>
	);
};