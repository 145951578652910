import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import scan from '../../Assets/svg/scan.svg';


export const UserScan = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={scan} ref={ref} {...props} />;
});