import React, { useState, useCallback } from 'react';
import { DataTable, IPaginationConfig } from 'Components/DataTable';
import { Column } from 'react-table';
import { useGetUserProductActivitiesQuery } from 'services';
import { useMediaQuery, theme, Flex, Image, HStack, Text, VStack } from '@chakra-ui/react';
import { formatDateShort } from 'Utils/dataTableUtils';
import { IUserProductActivity } from 'Types/Interface/IUserProductActivity';
import { RoundedImage } from 'Components/RoundedImage';
import useNavigation from 'Utils/navigationUtils';
import { useLocale } from 'context/LocalizationContext';

export interface IProductActivitesTableProps {
	brandId: string;
}

export const ProductActivities = ({ brandId }: IProductActivitesTableProps) => {
	const { locale } = useLocale();
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
	const [pageIndex, setPageIndex] = useState(1);
	const params = { brandId, pageIndex, pageSize: 10, locale };
	const { data, isLoading, isFetching, isError } = useGetUserProductActivitiesQuery(params);
	const { navigateToProduct } = useNavigation();

	const newData = data?.data?.map((item) => ({
		...item,
		id: item.productId,
	}));

	const columns: Column<IUserProductActivity>[] = React.useMemo(
		() => [
			{
				Header: '',
				accessor: 'productImageUrl',
				Cell: (item) => (
					<Flex justifyContent={'center'} bg={'#F1F1F1'} borderRadius={'14px'}>
						<Image src={item.row.original.productImageUrl} borderRadius={'14px'}  w={'44px'}  objectFit={'cover'} maxH={['16px', '30px']} />
					</Flex>
				),
			},
			{
				Header: 'Product',
				accessor: 'productName',
			},
			{
				Header: 'First interaction',
				accessor: 'firstInteractionDate',
				Cell: (item) => <>{formatDateShort(item.row.original.firstInteractionDate)}</>,
			},
			{
				Header: 'Last interaction',
				accessor: 'lastInteractionDate',
				Cell: (item) => <>{formatDateShort(item.row.original.lastInteractionDate)}</>,
			},
			{
				Header: 'Total scans',
				accessor: 'scansCount',
			},
			{
				Header: 'Total surveys',
				accessor: 'surveysCount',
			},
			{
				Header: 'Total earned',
				accessor: 'totalEarned',
				Cell: (item) => <>{item.row.original.totalEarned} LNT</>,
			},
		],
		[],
	);

	const mobileColumns: Column<IUserProductActivity>[] = React.useMemo(
		() => [
			{
				accessor: 'productName',
				Cell: (item) => {
					const rowData = item.row.original;

					return (
						<HStack spacing={3} align={'flex-start'}  lineHeight={'18px'}>
							<RoundedImage src={item.row.original.productImageUrl} />
							<VStack alignItems={'flex-start'}  fontSize={'10px'} spacing={'0'} flex={1}>
								<Text 
									fontSize={'12px'} 
									fontWeight={500}
									whiteSpace={'normal'} 
									// wordWrap={'break-word'} 
									overflowWrap={'anywhere'} 
								>{rowData.productName}</Text>
								<Text >Total scans: {rowData.scansCount}</Text>
								<Text>Total surveys: {rowData.surveysCount}</Text>
							</VStack>
						</HStack>
					);
				},
			},
			{
				accessor: 'totalEarned',
				Cell: (item) => (
					<HStack spacing={3} alignItems="flex-start" lineHeight={'18px'}>
						<VStack
							alignItems={'flex-end'}
							spacing={'0'}
							flex={1}
							fontSize={'10px'}
						>
							<Text fontSize={'12px'} fontWeight={600}>
								{item.row.original.totalEarned} LNT
							</Text>
							<Text>Last interaction: {formatDateShort(item.row.original.lastInteractionDate)}</Text>
							<Text>First interaction: {formatDateShort(item.row.original.firstInteractionDate)}</Text>
						</VStack>
					</HStack>
				),
			},
		],
		[],
	);

	const paginate = useCallback(({ pageIndex }: IPaginationConfig) => {
		setPageIndex(pageIndex + 1);
	}, []);

	return (
		<DataTable
			data={newData || []}
			columns={isMobile ? mobileColumns : columns}
			pageCount={data?.totalNoOfPages}
			paginate={paginate}
			initialPageIndex={0}
			isLoading={isLoading || isFetching}
			isError={isError}
			pageSize={params.pageSize}
			isMobile={isMobile}
			showCheckboxes={false}
			showMenu={false}
			cellPadding={3}
			isFlexible={true}
			tableContainerStyles={{border: 'none'}}
			headerStyles={{color: 'rgba(32, 32, 32, 0.7)', fontSize: '14px'}}
			bodyStyles={{color: '#202020' }}
			onclickRow={(data: IUserProductActivity) => {
				navigateToProduct(data.productId);
			}}
		/>
	);
};
