import { updateUserProfileQuestions } from 'Api';
import { useMutation, useQueryClient } from 'react-query';
import { IUserDemographics } from 'Types/Interface/IUser';
import { reactQueryUniqueKeys } from './reactQueryKeys';

export const useAddUserProfileQuestion = () => {
	const queryClient = useQueryClient();
	return useMutation((data: IUserDemographics) => updateUserProfileQuestions(data), {
		onSettled: () => {
			queryClient.invalidateQueries(reactQueryUniqueKeys.profile);
		},
	});
};
