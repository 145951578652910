/* eslint-disable @typescript-eslint/no-empty-function */
import { reqClient } from 'Api/reqClient';
import { useAppDispatch } from 'Hooks';
import React, { createContext, useEffect, useState } from 'react';
import { AppRoutes } from 'Routes/AppRoutes';
import { setUserInfo } from 'store/slices';
import { IJwtToken, IUser } from 'Types/Interface/IUser';
import jwt_decode from 'jwt-decode';
import { UserRoleEnum } from 'Types/Enums';
import { IAuthenticateUserResponse } from 'Types/Interface/IAuthenticateUserResponse';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';

interface IContextValues {
	setAuthInfo: (data: IAuthenticateUserResponse) => void;
	logout: () => void;
	isAuthenticated: () => boolean;
	user: IUser | null;
	setAuthUserDetails: (data: IUser) => void;
	getAuthenticatedUserRole: () => string | null;
	isFullyRegisteredUser : () => boolean;
}

const initialValues = {
	setAuthInfo: () => {},
	setAuthUserDetails: () => {},
	logout: () => {},
	isAuthenticated: () => false,
	user: null,
	getAuthenticatedUserRole: () => {
		return '';
	},
	isFullyRegisteredUser : () => false,
};

interface IAuthProviderProps {
	children: React.ReactNode;
}

const AuthContext = createContext<IContextValues>(initialValues);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: IAuthProviderProps) => {
	const navigate = useLocalizedNavigate();
	const dispatch = useAppDispatch();

	const accessToken = localStorage.getItem('accessToken') ?? '';
	const userInfo = localStorage.getItem('userDetails') ?? null;
	const expiresIn = localStorage.getItem('expiresIn') ?? '';
	const [authState, setAuthState] = useState<IAuthenticateUserResponse | null>({
		accessToken,
		expiresIn: parseInt(expiresIn),
	});
	const [userDetails, setUserDetails] = useState<IUser | null>(userInfo ? JSON.parse(userInfo) : null);

	const setBearerToken = async (token?: string | null) => {
		if (token) {
			localStorage.setItem('accessToken', token);
		}

		const accessToken = token || localStorage.getItem('accessToken');

		if (accessToken) {
			reqClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
		}
	};

	useEffect(() => {
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken) {
			reqClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
		}
	}, []);

	const setAuthInfo = ({ accessToken, expiresIn }: IAuthenticateUserResponse) => {
		localStorage.setItem('accessToken', accessToken);
		localStorage.setItem('expiresIn', expiresIn.toString());

		setBearerToken(accessToken);

		setAuthState({
			accessToken,
			expiresIn,
		});
	};

	const setAuthUserDetails = (userDetails: IUser) => {
		localStorage.setItem('userDetails', JSON.stringify(userDetails));
		setUserDetails(userDetails);
		dispatch(setUserInfo(userDetails));
	};

	const logout = () => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('userInfo');
		localStorage.removeItem('expiresAt');
		setAuthState(null);
		navigate(AppRoutes.login);
	};

	const isAuthenticated = () => {
		if (!authState?.accessToken || !authState?.expiresIn) {
			return false;
		}
		return true;
	};

	const getAuthenticatedUserRole = () => {
		if (authState == null) {
			return null;
		}
		try {
			const token = authState.accessToken;
			const decodedToken = jwt_decode(token) as IJwtToken;
			return decodedToken.role;
		} catch (error) {
			return null;
		}
	};

	const isFullyRegisteredUser = () => {
		const role = getAuthenticatedUserRole();
		return !!role && role !== UserRoleEnum.PartialUser;
	};

	return (
		<Provider
			value={{
				user: userDetails,
				setAuthInfo,
				logout,
				isAuthenticated,
				isFullyRegisteredUser,
				getAuthenticatedUserRole,
				setAuthUserDetails,
			}}
		>
			{children}
		</Provider>
	);
};

export { AuthContext, AuthProvider };
