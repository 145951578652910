import React from 'react';
import { Image, Text, Flex, chakra, Box } from '@chakra-ui/react';
import { IProduct } from 'Types/Interface/IProduct';
import { formatCurrency } from 'Utils';
import { AppRoutes } from 'Routes/AppRoutes';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';

interface IProductItemsProps {
	product: IProduct;
}


export const ProductItems = ({ product }: IProductItemsProps) => {
	const navigate = useLocalizedNavigate();

	const handleNavigate = () => {
		navigate(AppRoutes.productPage.replace(':productId', product.id));
	};

	return (
		<Box
			onClick={handleNavigate}
			w={'100%'}
			h={'100%'}
		>
			<Flex
				cursor={'pointer'}
				h={['200px', '200px', null, '300px']}
				w={'100%'}
				position="relative"
				borderRadius="lg"
				overflow="hidden"
			>
				<Image
					src={product?.images[0].imageUrl}
					boxSize="100%"
					objectFit="cover"
					rounded={4}
					bgColor={'#f9f9f9'}
				/>
			</Flex><Flex
				alignItems={'flex-start'}
				py={4}
				direction={'column'}
			>
				<Text
					fontSize={{ base: 'sm', md: 'sm', xl: 'md' }}
					fontWeight={'500'}
					cursor={'pointer'}
					textAlign='left' w={'auto'}
					mb={1}
					color={'#000'}
				>
					{product.name}
				</Text>
				<Text
					fontSize={{ base: 'sm', tablet: 'sm', xxl: 'xs' }}
					fontWeight={'400'}
					cursor={'pointer'}
					textAlign='left' mb={1}
				>
					By <chakra.span textDecor={'underline'}>{product.brand.name}</chakra.span>
				</Text>
				<Text
					fontSize={{ base: 'sm', tablet: 'sm', xxl: 'sm' }}
					fontWeight={'bold'}
					cursor={'pointer'}
					textAlign='left'
				>
					{formatCurrency(product.price)}
				</Text>
			</Flex>
		</Box>
	);
};
