import { Flex } from '@chakra-ui/react';
import { Gallery } from 'Components/Gallery';
import { GalleryMobile } from 'Components/Gallery/GalleryMobile';
import { Beauty } from 'Constants';
import { SeeAllLink } from 'Pages/LandingPage/Components/SeeAllLink';
import { CategoryHeader } from 'Pages/LandingPage/HomeSection/sections/CategoryHeader';
import React from 'react';
import { AppRoutes } from 'Routes/AppRoutes';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';

export const FeaturedBrandsSlider = () => {
	const navigate = useLocalizedNavigate();
	return (
		<Flex
			direction={'column'}
			// px={{base:'0', xxl: 6 }}
			py={{ xxl: 6 }}
		>
			<CategoryHeader
				onClickLink={() => {
					navigate(AppRoutes.featuredBrands);
				}}
				title='Features brands'
			/>
			<Gallery 
				collection={Beauty} 
				visibility={true} 
				onClickImage={()=> navigate(AppRoutes.featuredBrands)} 
				display={'none'}
			/>
			<GalleryMobile 
				collection={Beauty} 
				visibility={true} 
				onClickImage={()=> navigate(AppRoutes.featuredBrands)} 
				display = {'none'}
			/>
			<SeeAllLink onClickLink={() => console.log('goto')}/>
		</Flex>
	);
};