export const AppRoutes = {
	login: '/login',
	resetPassword: '/reset-password',
	landingpage: '/',
	register: '/register',
	verify: '/register/verify',
	success: '/register/success',
	registerPartial: '/register-partial',
	introduction: '/register/introduction',
	home: '/home',
	explore: '/explore',
	brand: '/brand/:brandId',
	allBrands: '/all-brands',
	allProducts: '/all-products',
	notifications: '/notifications',
	EnterCode: '/enter-code',

	dashboard: '/dashboard',
	security: '/dashboard/security',
	privacy: '/dashboard/privacy',
	paymentAndPayout: '/dashboard/payment&Payout',
	referral: '/dashboard/credits&coupons',
	notification: '/dashboard/notification',
	preference: '/dashboard/preference',
	withdrawalHistory: '/dashboard/withdrawal-history',
	brandLoops: '/dashboard/brand-loops',
	brandLoop: '/dashboard/brand-loop/:brandId',
	activityDetails: '/dashboard/activity-details/:Id',

	comingSoon: '/coming-soon',
	business: '/business',
	LoopForBusiness: '/loop-for-business',
	processOverview: '/process',
	contact: '/contact',
	loopApp: '/loopApp',
	howItWorks: '/how-it-works',
	brands: '/brands',
	help: '/help',
	featuredBrands: '/featured-brands',
	homePage: '/main',
	accountDashboard: '/dashboard',

	productPage: '/product/:productId',
	dynamicLinkProduct: '/product-survey/:qrCode',
	survey: '/survey/:webcode',
	surveyQrCode: '/survey/:qrCode',

	scan: '/scan/:qrCode',
};