import { Heading, SimpleGrid, Stack, Text, Box} from '@chakra-ui/react';
import { InformationIcon, VaultIcon, DataManagementIcon } from 'Components/Icons';
import React from 'react';

const PrivacyContent = [
	{
		title: 'Your Information, Your Choice',
		subText: 'For every interaction, you decide what information to share with brands. Adjust your privacy settings at any time to ensure you’re comfortable with your level of connection. ',
		icon:<InformationIcon/> 
	},
	{
		title : 'Control Your Brand Relationships',
		subText: 'Your “Loop” with each brand is yours to manage. You can change your privacy preferences or level of engagement at any time, putting you in the driver’s seat of every relationship.',
		icon: <DataManagementIcon/>
	},
	{
		title : 'Bank-Level Security for Payments',
		subText: 'We’ve partnered with Tilia to ensure smooth, secure payments for your rewards. When you’re ready, withdraw your earnings to your bank or PayPal account with confidence.',
		icon: <VaultIcon/>
	}
];


export const PrivacySection = () => {
	return(
		<Stack spacing={['30px','80px']} >
			<Stack spacing={'30px'}>
				<Heading fontSize={['20px','50px']} fontWeight={600}>We Take Your Privacy Seriously</Heading>
				<Text w={['','70%']} fontSize={['16px', '18px']} fontWeight={500} color={'#2B2B2B;'}>At Loop, we prioritize your privacy, ensuring you can connect with brands in a safe and secure environment while staying in full control of your relationship.</Text>
			</Stack>
			<SimpleGrid columns={[1,3]} spacing={'30px'} pb={['50px','100px']} >
				{
					PrivacyContent.map((item) => {
						return(
							<Stack	spacing={['20px','30px']} key={item.title}>
								<Box boxSize={['30px','50px']}>{item.icon}</Box>
								<Text fontWeight={600} fontSize={['18px','20px']}>{item.title}</Text>
								<Text fontSize={['16px','18px']} w={'100%'}>
									{item.subText}
								</Text>
							</Stack>
						);
					})
				}
			</SimpleGrid>
			{/* <Stack 
				justify={'center'} 
				align={'center'} 
				pos={'relative'}

			>
				<Image display={['none', 'block']} src={backdrop} w={'100%'} h={'450px'} />
				<Image display={['block', 'none']} src={backdropMobile} w={'100%'} h={'427px'} />
				<Stack pos={'absolute'} align={'center'} textAlign={'center'} spacing={'20px'} px={4} >
					<Heading fontSize={['24px','40px']} color={'white'}>Have a business and want to <br /> sell with Loop?</Heading>
					<Text fontSize={['16px','18px']} color={'white'}>Build strong, safe and meaningful connections with your customers!</Text>
					<Stack direction={['column','row']} spacing={'20px'}>
						<Button border={'1px solid #fff'} w={'150px'} h={'60px'} borderRadius={'5px'} bg={'white'} color={'darkCoral'}>Contact us</Button>
					</Stack>
				</Stack>
			</Stack> */}
		</Stack>
	);
};