import * as React from 'react';
import { MainRoute } from './Routes/MainRoute';
import '@fontsource/montserrat';
import { Global } from '@emotion/react';
import { GlobalStyles } from 'Theme/GlobalStyles';

export const App = () => (
	<>
		<Global styles={GlobalStyles} />
		<MainRoute />
	</>
);
