import React from 'react';

import { JumbotronSection } from '../Components/JumbotronSection';
import { HowItWorksSection } from './sections/HowItWorksSection';
import {TestimonialSection} from './sections/TestimonialSection';
import { CallToAction } from './sections/CallToAction';
import { LoopAppJumbotronSectionContents } from 'Constants/JumbotronConstant';
import { LandingPageLayout } from 'Layouts/LandingPageLayout';


export const LoopAppPage = () => {
	return (
		<LandingPageLayout 
			JumbotronSection={
				<JumbotronSection contents={LoopAppJumbotronSectionContents}/> 
			}
		>
			<HowItWorksSection />
			<TestimonialSection/>
			<CallToAction />
		</LandingPageLayout>
	);
};