import { useFormik } from 'formik';
import { IUserRegistrationData } from 'Types/Interface/IUser';
import { useAddUserProfileQuestion, useAppSelector} from 'Hooks';
import { UserPreferenceFormName } from 'Types/Enums';
import { useDispatch } from 'react-redux';
import { setUserDemographics } from 'store/slices';


export const useUserBasicProfile = () => {
	const {userInfo} = useAppSelector(state => state.authUser);
	const dispatch = useDispatch();
	const { mutate: addUserProfileQuestion, isLoading, isSuccess, isError, error } = useAddUserProfileQuestion();
	const { setFieldValue, values, handleSubmit } = useFormik({
		initialValues: {
			[UserPreferenceFormName.Gender]: userInfo?.gender,
			[UserPreferenceFormName.Ethnicity]: userInfo?.userDemographics.ethnicity,
			[UserPreferenceFormName.Children]: userInfo?.userDemographics.children,
			[UserPreferenceFormName.Education]: userInfo?.userDemographics.education,
			[UserPreferenceFormName.Religion]: userInfo?.userDemographics.religion,
			[UserPreferenceFormName.Politics]: userInfo?.userDemographics.politics,
			[UserPreferenceFormName.Hometown]: userInfo?.userDemographics.homeTown,
			[UserPreferenceFormName.School_location]: userInfo?.userDemographics.schoolLocation,
			[UserPreferenceFormName.Sexuality]: userInfo?.userDemographics.sexuality,
			[UserPreferenceFormName.Workplace]: '',
			[UserPreferenceFormName.Industry]: userInfo?.userDemographics.industry,
			[UserPreferenceFormName.Alchohol]: +((userInfo?.userDemographics?.alchohol) ?? 0),
			[UserPreferenceFormName.Smoke]: +((userInfo?.userDemographics?.smoke) ?? 0),
			[UserPreferenceFormName.Marijuana]: +((userInfo?.userDemographics?.marijuana) ?? 0),
			[UserPreferenceFormName.Drugs]: +((userInfo?.userDemographics?.drugs) ?? 0),
			[UserPreferenceFormName.AvatarUrl]: userInfo?.avatarUrl,
			[UserPreferenceFormName.GenderName]: userInfo?.genderName,
			[UserPreferenceFormName.ReligionName]: userInfo?.userDemographics.religionName,
			[UserPreferenceFormName.DateOfBirth]: userInfo?.dateOfBirth
		} as { [key: string]: number | string }, // TODO: create type for this formstate
		enableReinitialize: true,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onSubmit: (values) => {
			const data = {
				genderName: values.genderName,
				gender: values.gender,
				children: values.children,
				ethnicity: values.ethnicity,
				education: values.education,
				religion: values.religion,
				politics: values.politics,
				homeTown: values.hometown,
				schoolLocation: values.school_location,
				smoke: values.smoke,
				alchohol: values.alchohol,
				marijuana: values.marijuana,
				drugs: values.drugs,
				industry: values.industry,
				sexuality: values.sexuality,
				dateOfBirth: values.dateOfBirth,
				avatarUrl: values.avatarUrl
			} as unknown as IUserRegistrationData;
			addUserProfileQuestion(data);
			dispatch(setUserDemographics(data));
		},
	});

	return { setFieldValue, values, handleSubmit, isLoading,isSuccess, isError, error };
};
