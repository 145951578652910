import React, { useEffect, useState } from 'react';
import {
	Box,
	Text,
	IconButton,
	Stack,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { ILocalizationCountry } from 'Types/Interface/ILocalizationCountry';
import Flag from 'react-world-flags';
import { Autocomplete, IAutocompleteItem } from './Autocomplete';
import { LoopV2Icon } from './Icons';

interface CountrySelectorProps {
	isOpen: boolean;
	onCountryClose: () => void;
	handleCountrySelect: (country: ILocalizationCountry) => void;
	countriesData: { data: ILocalizationCountry[] } | undefined;
	countriesLoading: boolean;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({
	isOpen,
	onCountryClose,
	handleCountrySelect,
	countriesData,
	countriesLoading,
}) => {
	const [autocompleteItems, setAutocompleteItems] = useState<IAutocompleteItem[]>([]);

	useEffect(() => {
		if (countriesData?.data) {
			const formattedCountries = countriesData.data.map((country) => ({
				label: country.name,
				value: country.id,
				customComponent: <Flag code={country.code} style={{ height: '16px', width: '20px' }} />,
			}));
			setAutocompleteItems(formattedCountries);
		}
	}, [countriesData]);

	const handleCountrySelection = (selectedItem: IAutocompleteItem) => {
		const selectedCountry = countriesData?.data.find((country) => country.id === selectedItem.value);
		if (selectedCountry) {
			handleCountrySelect(selectedCountry);
		}
	};

	if (!isOpen) return null;

	return (
		<Box
			position="fixed"
			top={0}
			left={0}
			width="100vw"
			height="100vh"
			bg="white"
			zIndex={9999}
			display="flex"
			flexDirection="column"
		>
			<IconButton
				aria-label="Close country selector"
				icon={<CloseIcon />}
				position="absolute"
				top={['22px', '80px']}
				right={['20px', '90px']}
				onClick={onCountryClose}
			/>

			<LoopV2Icon
				display={['block', 'none']}
				height={'24px'}
				position="absolute"
				top='20px'
				left='20px'
			/>

			<Stack mx={['20px', '17%']} mt={['100px', '120px']} direction={['column', null, null, 'row']}>
				<Text fontSize={'3xl'} fontWeight={'600'} mb={6} whiteSpace={'nowrap'}>
					Change your country
				</Text>

				<Box pl={[0, null, null, '80px']} w={'100%'}>
					<Autocomplete
						data={autocompleteItems}
						onSelect={handleCountrySelection}
						isLoading={countriesLoading}
						placeholder='Select your country'
						inputlinkColorMode='light'
						hideToggleButton
					/>
				</Box>
			</Stack>
		</Box>
	);
};

export default CountrySelector;
