import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGeolocation } from 'Types/Interface/IGeoLocation';
import { ITiliaPayload } from 'Types/Interface/ITilia';
import { IUser, IPaymentMethods, IUserSummary, IUserDemographics } from 'Types/Interface/IUser';

interface UserInfoSlice {
	userInfo: IUser | null;
	authToken: string;
	paymentMethods: IPaymentMethods[]; // todo: remove this
	tiliaPaymentMethods: ITiliaPayload | null;
	userSummary: IUserSummary | null;
	userGeolocation: {
		data: IGeolocation | null;
		timestamp: number | null;
	} | null;
	hideVerifyEmailSection: boolean;
	hideCompleteSignupSection: boolean;
}

const initialState: UserInfoSlice = {
	paymentMethods: [],
	tiliaPaymentMethods: null,
	userInfo: null,
	authToken: '',
	userSummary: null,
	userGeolocation: null,
	hideVerifyEmailSection: false,
	hideCompleteSignupSection: false,
};

export const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState,
	reducers: {
		setUserInfo: (state, action: PayloadAction<IUser>) => {
			state.userInfo = { ...state.userInfo, ...action.payload };
		},		
		setUserDemographics: (state, action: PayloadAction<IUserDemographics>) => {
			if (state.userInfo != null) {
				state.userInfo = { ...state.userInfo, userDemographics: {...state.userInfo.userDemographics, ...action.payload} };
			}
		},
		setUserSummary: (state, action: PayloadAction<IUserSummary>) => {
			state.userSummary = { ...state.userSummary, ...action.payload };
		},
		setAuthToken: (state, action: PayloadAction<string>) => {
			state.authToken = action.payload;
		},
		setPaymentMethod: (state, action: PayloadAction<IPaymentMethods>) => {
			state.paymentMethods?.push(action.payload);
		},
		setTiliaPaymentMethod: (state, action: PayloadAction<ITiliaPayload | null>) => {
			state.tiliaPaymentMethods = action.payload;
		},
		setDefaultPaymentMethod: (state, action: PayloadAction<IPaymentMethods>) => {
			state.paymentMethods.map((card) => {
				if (card.id === action.payload.id) {
					card.default = action.payload.default;
				}
			});
		},
		setUserCoords: (state, action: PayloadAction<IGeolocation>) => {
			state.userGeolocation = {data: action.payload, timestamp: Date.now()};
		},
		deletePaymentMethod: (state, action: PayloadAction<IPaymentMethods>) => {
			state.paymentMethods = state.paymentMethods.filter((card) => card.id !== action.payload.id);
		},
		setHideVerifyEmailSection: (state, action: PayloadAction<boolean>) => {
			state.hideVerifyEmailSection = action.payload;
		},
		setHideCompleteSignupSection: (state, action: PayloadAction<boolean>) => {
			state.hideCompleteSignupSection = action.payload;
		},
	},
});

export const {
	setUserInfo,
	setUserDemographics,
	setAuthToken,
	setPaymentMethod,
	setTiliaPaymentMethod,
	setDefaultPaymentMethod,
	setUserSummary,
	setUserCoords,
	deletePaymentMethod,
	setHideVerifyEmailSection,
	setHideCompleteSignupSection,
} = userInfoSlice.actions;

export default userInfoSlice.reducer;
