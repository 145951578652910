export const routes = {
	// Account routes
	accountLoginPhone: '/account/login',
	requestAccountVerificationCode: '/account/request-verification-code',
	validateAndRequestAccountVerificationCode: '/account/validate-request-verification-code',
	verifyCode: '/account/verify-code',
	accountLoginGoogle: '/account/google',
	accountLoginFacebook: '/account/facebook',
	createAccountWithPhone: '/account/create-account-by-phone',
	createPartialAccount: '/account/create-partial-account',
	accountPhoneComplete: '/account/phone/complete',
	getResetCode: '/account/get-reset-code',

	// User routes
	userProfile: '/user/profile',
	users: '/user/all',
	user: '/user',
	userRoles: '/user/roles',
	userProfileQuestion: '/user/question',
	userProfilePicture: '/user/avatar',

	// Company routes
	companyList: '/company/GetAllCompaniesAsync',
	company: '/company',
	addCompany: '/company/addcompany',
	editCompany: '/company/update/:companyId',
	searchCompany: '/company/GetAllCompaniesAsync',

	// Product routes
	productList: '/product/getproducts',
	product: 'product',
	productTags: '/product/tags',
	addProduct: '/product/addproduct',
	editProduct: '/product/editproduct',
	deleteProduct: '/product/deleteproduct',

	// Brand routes
	addBrand: '/brand/addbrand',
	brandList: '/brand/GetAllBrandsAsync',
	brand: '/brand/GetByBrandId',
	deleteBrand: '/brand/delete',
	editBrand: '/brand/editbrand',

	// Survey routes
	dynmaiclinkqrCode: 'survey/dynmaiclinkqrCode',

	// Reward routes
	rewardHistory: '/reward/history',

	// Search routes
	searchBrand: '/search/getBrandsAndProducts',

	// Feedback routes
	ongoingFeedBack: '/feedback/ongoing',

	// Category routes
	categories: '/categories',

	// Image routes
	imageUpload: '/image/upload',
};
