import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const AccordionTheme: ComponentStyleConfig = {
	// All parts of multipart components can be found in the @chakra-ui/anatomy package,
	// accordionAnatomy consists of following part: 'container', 'item', 'button', 'panel'
	/*
	 **Accordion anatomy**
	 * - Item: the accordion item contains the button and panel
	 * - Button: the button is the trigger for the panel
	 * - Panel: the panel is the content of the accordion item
	 * - Icon: the expanded/collapsed icon
	 */
	parts: ['container', 'item', 'button', 'panel', 'icon'],
	baseStyle: {},
	sizes: {},
	defaultProps: {},
	variants: {
		primary: {
			panel: {
				paddingStart: '0',
				paddingEnd: '0',
				fontSize: ['sm', 'md'],
			},
			container: {
				border: '0',
			},
			button: {
				paddingStart: '0',
				paddingEnd: '0',
				color: 'placeholderText',
				fontSize: ['sm', 'md'],
				textDecoration: 'underline',
				textUnderlineOffset: '4px',
				textDecorationStyle: 'dashed',

				_hover: {
					textDecorationStyle: 'solid',
					bg: 'white',
				},
			},
		},
		red: {
			panel: {
				paddingStart: '0',
				paddingEnd: '0',
				fontSize: ['sm'],
			},
			container: {
				border: '0',
			},
			button: {
				paddingStart: '0',
				paddingEnd: '0',
				color: 'darkCoral',
				fontSize: ['sm'],

				_hover: {
					textDecoration: 'underline',
					textDecorationStyle: 'solid',
					bg: 'white',
				},
			},
		},
	},
};
