import {Heading, Text, Image, VStack, Stack } from '@chakra-ui/react';
import React from 'react';
import donation from '../../Assets/svg/donation.svg';
import money2 from '../../Assets/svg/money2.svg';
import threeQuarter from '../../Assets/svg/three-quarters.svg';


export const CostOfReliance = () => {
	return(
		<VStack spacing={['30px','100px']} align={'flex-start'} py={['0px','100px']}>
			<Heading fontSize={['20px', '40px']} fontWeight={'extrabold'} w={['auto','620px']}> The Hidden Costs of Relying on Rented Channels </Heading>
			<Stack spacing={['30px','30px']} direction={['column', 'row']} justify={'space-between'} w={'full'} >
				<VStack w={['100%','350px']} align={'flex-start'} spacing={['16px','20px']}>
					<Image  boxSize={['30px']} src={money2} />
					<Heading  fontSize={'18px'}>High costs:</Heading>
					<Text fontSize={['16px','18px']}>
						Platforms force you to outbid competitors for audience access
					</Text>  
				</VStack>
				<VStack w={['100%','350px']} align={'flex-start'} spacing={'20px'}>
					<Image  boxSize={['30px']}  src={threeQuarter} />
					<Heading   fontSize={'18px'}>Incomplete data:</Heading> 
					<Text fontSize={['16px','18px']}>
					Limited to owned channels provided first-party data is collected and only limited profiles with a lack of rich, truly useable information
					</Text>  
				</VStack>
				<VStack w={['100%','350px']} align={'flex-start'} spacing={'20px'}>
					<Image boxSize={['30px']} src={donation} />
					<Heading  fontSize={'18px'}>Lack of ownership:</Heading> 
					<Text fontSize={['16px','18px']}>
						You’re continuously paying to reach your customers with no guarantee of result.
					</Text>  
				</VStack>
			</Stack>

			
			
		</VStack>
	);
};