import React, { useContext } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, useMediaQuery, MenuDivider, Avatar } from '@chakra-ui/react';
import { AppRoutes } from 'Routes/AppRoutes';
import { clearBrowserStorage, localizePath } from 'Utils';
import { useAppSelector } from 'Hooks';
import { AuthContext } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface IMenuDropdownProps {
	linkColorMode?: 'light' | 'black';
}

export const MenuDropdown = ({ linkColorMode }: IMenuDropdownProps) => {
	const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');
	const { userInfo } = useAppSelector((state) => state.authUser);
	const { isFullyRegisteredUser } = useContext(AuthContext);

	const links = isFullyRegisteredUser()
		? [
			{
				label: 'Account',
				path: localizePath(AppRoutes.dashboard),
			},
		]
		: [];

	const navigate = useNavigate();
	const handleNavigation = (path: string) => {
		navigate(path);
	};
	const handleLogout = () => {
		clearBrowserStorage();
		window.location.reload();
	};
	return (
		<Menu>
			<MenuButton
				borderWidth={1}
				borderColor={linkColorMode === 'light' ? 'white' : '#E5E5E5'}
				{...(!isLargerThan1000 && { borderColor: '#E5E5E5' })}
				borderRadius='50%'
				p={1}
				display='flex'
				alignItems='center'
				justifyContent='center'
			>
				<Avatar
					src={userInfo?.avatarUrl}
					name={`${userInfo?.firstName} ${userInfo?.lastName}`}
					objectFit='cover'
					size='xs'
				/>
			</MenuButton>
			<MenuList>
				{links.length > 0 && (
					<>
						{links.map(({ label, path }) => (
							<MenuItem onClick={() => handleNavigation(path)} key={label}>
								{label}
							</MenuItem>
						))}
						<MenuDivider />
					</>
				)}
				<MenuItem color='loop.500' onClick={handleLogout}>
					Sign out
				</MenuItem>
			</MenuList>
		</Menu>
	);
};
