import React, { useRef, useState } from 'react';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import { CarouselContainer } from '../Components/CarouselContainer';
import { useGetAllBrandsQuery } from 'services';
import { BrandCaoruselItems } from 'Components/Gallery/BrandCarouselItem';
import { useMediaQuery, Box } from '@chakra-ui/react';
import { MobileBrandsSlider} from 'Pages/HomePage/Components/MobileBrandsSlider';
import { SkeletonLoader } from '../Components/SkeletonLoader';
import { useLocale } from 'context/LocalizationContext';


export const FeaturedbrandsCarousel = () =>{
	const { locale } = useLocale();
	const [currentSlide, setcurrentSlide] = useState(0);
	const params = {pageNumber:1, pageSize:100, categoryId: '', locale};
	const {data, isLoading}  = useGetAllBrandsQuery(params);
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	const boxRef = useRef<HTMLImageElement>(null);
	const clientWidth = boxRef.current?.clientWidth || 0;

	const onRight = () => {
		if (data?.data.length !== undefined && currentSlide < data?.data.length - 1) {
			setcurrentSlide(currentSlide + 1);
		}
	};
	const onLeft = () => {
		if (currentSlide > 0) {
			setcurrentSlide(currentSlide - 1);
		}
	};
	const sliderVariant = {
		initial: { x: 0 },
		animate: () => ({
			x: `-${currentSlide * clientWidth}px`,
			transition: {
				type: 'spring',
				stiffness: 250,
				damping: 30,
			},
		}),
	};
	return (
		<>
			{	isLoading ? <SkeletonLoader /> :
				<><CarouselContainer title={'Featured'} onLeft={onLeft} onRight={onRight} itemCount={data?.data.length}>
					{isLargerThan768 && data?.data.map((brand, index) => {
						return (
							<MotionBox
								w={['30vw', null, '30vw', '22vw', null, '15vw']}
								custom={index}
								key={index}
								variants={sliderVariant}
								initial="initial"
								animate="animate"
								mr={[6, 5]}
								ref={boxRef}
							>
								<BrandCaoruselItems
									visibility={true}
									brands={brand} />
							</MotionBox>
						);
					})}
				</CarouselContainer>
				<Box mb={10}>
					{!isLargerThan768 && <MobileBrandsSlider brands={data?.data} isLoading={isLoading}/>}
				</Box></>
			}
		</>
	);
};