import { chakra, Divider, Heading, Image, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import structuredData from 'Assets/svg/deep-learning.svg';
import videoChat from 'Assets/svg/video-chat.svg';
import management from 'Assets/svg/management.svg';
import marketing from 'Assets/svg/marketing.svg';
import referrral from 'Assets/svg/referral-program.svg';
import affiliateMarketing from 'Assets/svg/affiliate-marketing.svg';
import chatBubbles from 'Assets/svg/chat-bubbles.svg';
import React from 'react';

const campaigns = [
	{
		id: 0,
		type: 'Structured Data',
		icon: structuredData
	},
	{
		id: 1,
		type: 'Video Interviews',
		icon: videoChat
	},
	{
		id:2,
		type: 'Source UGC / Content',
		icon: management
	},
	{
		id:3,
		type: 'Direct Marketing',
		icon: affiliateMarketing
	},
	{
		id:4,
		type: 'Referral Campaigns',
		icon: referrral
	},
	{
		id:5,
		type: 'Brand / Product News',
		icon: marketing
	},
	{
		id:6,
		type: 'Instant Messaging/ Chat bots',
		icon: chatBubbles
	},
];

export const Campaigns = () => {
	return(
		<Stack spacing={['30px','50px']} >
			<Heading  fontSize={['20px','40px']}>Use the connections with our <chakra.span color={'darkCoral'}>Engagement Campaigns</chakra.span></Heading>
			<Text w={['100%', null, null,'955px']} fontSize={'16px'}  color={'#525252'}>Once customers are part of your Loop, you can run diverse engagement campaigns to capture insights, build loyalty, and re-market to drive retention:</Text>
			<SimpleGrid gap={['2','40px']} columns={[2, null, null, 3, 4]}>
				{
					campaigns.map((campaign) => {
						return(
							<VStack align={'flex-start'} key={campaign.id} spacing={['30px','50px']} >
								<Image src={campaign.icon} boxSize={'30px'} />
								<Divider w={['100%','280px']} borderColor={'#264653'}/>
								<Text fontSize={['16px','18px']} fontWeight={600}>{campaign.type}</Text>
							</VStack>
						);
					})
				}
			</SimpleGrid>
		</Stack>
	);
};