import React, { useEffect} from 'react';
import { Button, Stack } from '@chakra-ui/react';
import { IQuestionOption } from 'Types/Interface/ISurvey';
import { FadeInContainer } from '../FadeInContainer';

interface ISingleSelectProps {
	questionOptions: IQuestionOption[];
	onChange: (id: string) => void;
	selectedValue: string;
}

export const SingleSelect = ({ questionOptions, onChange, selectedValue }: ISingleSelectProps) => {
	useEffect(() => {
		onChange(selectedValue);
	}, []);

	return (
		<FadeInContainer>
			<Stack spacing={4} >
				{questionOptions.map((option) => (
					<Button
						onClick={() => onChange(option.id)}
						borderRadius='20px'
						w={['full', '300px']}
						h={'60px'}
						variant={'secondary'}
						bg={'#F6F6F6'}
						borderColor={'#F6F6F6'}
						color={'loopGrey.500'}
						key={option.id}
						_focus={{
							outline: 'none',
							boxShadow: 'none',
						}}
						_focusVisible={{
							outline: 'none',
							boxShadow: 'none',
						}}
						_active={{
							outline: 'none',
							boxShadow: 'none',
						}}
						_hover={{
							outline: 'none',
							boxShadow: 'none',
						}}
						
						
						
						
						{...(selectedValue === option.id && { bg: 'loop.500', color: 'white' })}
					>
						{option.text}
					</Button>
				))}
			</Stack>
		</FadeInContainer>
	);
};
