import React from 'react';
import { 
	ShieldIcon
} from 'Components/Icons';

import { AppRoutes } from 'Routes/AppRoutes';

export interface IAccountSettingCard {
    icon: React.ReactNode;
    title: string;
		hasCustomPanelTitle?: boolean;
    description: string;
    iconBgColor: string;
	path?: string;
}

export const accountSettingCards: IAccountSettingCard[] = [
	{
		title: 'Login & Security',
		icon: <ShieldIcon boxSize={5} />,
		description: 'Update your password and secure your account',
		iconBgColor: '#DDFFF0',
		path: `${AppRoutes.security}`,
	},
	// {
	// 	title: 'Privacy & Sharing',
	// 	icon: <Icon as={AiOutlineEye} boxSize={5}/>,
	// 	description: 'Control what you share with brands and who sees it',
	// 	iconBgColor: '#DAF4FF',
	// 	path: `${AppRoutes.privacy}`

	// },
	// {
	// 	title: 'Payments & Payouts',
	// 	icon: <MoneyIcon boxSize={5}/>,
	// 	description: 'Review payments, payouts, coupons and credits',
	// 	iconBgColor: '#E9F9FF',
	// 	path: `${AppRoutes.paymentAndPayout}`

	// },
	// {
	// 	title: 'Notifications',
	// 	icon: <BellIcon boxSize={5}/>,
	// 	description: 'Choose notification preferences and how you want to be contacted',
	// 	iconBgColor: '#FFEED1',
	// 	path: `${AppRoutes.notification}`
	// },
	// {
	// 	title: 'Referral Credits & Coupons',
	// 	icon: <TicketIcon boxSize={5}/>,
	// 	description: 'You have $0 referral credits & coupons. Learn more ',
	// 	iconBgColor: '#FFD7D7',
	// 	path: `${AppRoutes.referral}`
	// },
	// {
	// 	title: 'Account Preferences',
	// 	icon: <Icon as={AiOutlineSetting} boxSize={5} />,
	// 	description: 'Customize your account set up based on your preferences',
	// 	iconBgColor: '#F6F6F6',
	// 	path: `${AppRoutes.preference}`,
	// }
];