import { Flex, VStack, Text, HStack, Image, Button, Stack, useMediaQuery, Heading } from '@chakra-ui/react';
import checkmark from 'Assets/svg/Checkmark.svg';
import React from 'react';
import phoneLoop from 'Assets/Images/phone-loop.png';
import phoneLoopMobile from 'Assets/Images/PhoneLoopMobile.png';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';

const benefits = [
	'Build a verified customer community from all channels.',
	'Collect actionable, unified insights linked to rich customer data to inform your strategies.',
	'Foster long-term customer loyalty, rewarding customers for every engagement.'
];

interface ICustomerProps{
	shouldIgnoreParentPadding: boolean;
}

export const Customer = ({shouldIgnoreParentPadding}:ICustomerProps) => {
	const [isLargerThan768] = useMediaQuery('(min-width: 984px)');
	const navigate = useLocalizedNavigate();
	
	return(
		<Flex  
			direction={['column-reverse', 'row']} 
			bg={['#FFFCFC','#FFFCFC']} 
			w={'100%'} 
			justifyContent={['center','space-between']}
			py={['40px','100px']}
			{...(shouldIgnoreParentPadding && { px:{ base: '30px', xl: '80px', '2xl': '192px' }})}
		>
			{ isLargerThan768 ? <Flex maxWidth={'50%'} alignItems={'flex-end'} bg={['','#F5F5F5']} justifyContent={'center'}  w={'550px'} h={'650px'}>
				<Image w={'610px'}  objectFit={'contain'} h={'548px'} src={phoneLoop} />
			</Flex> :
				<Flex alignItems={'flex-end'} justifyContent={'center'} mt={['30px','50px']}>
					<Image w={'388px'}  objectFit={'contain'}  src={phoneLoopMobile} />
				</Flex>
			}
			<VStack justifyContent={'center'} spacing={['30px','40px']}  align={['flex-start','flex-start']}>
				<Stack spacing={[null,-2]} >
					<Heading fontSize={['20px','40px']} fontWeight={'extrabold'}>Own Your Customer</Heading>
					<Heading fontSize={['20px','40px']} fontWeight={'extrabold'}>
						Relationships With Loop
					</Heading>
				</Stack>
				
				<VStack spacing={'30px'} justifyContent={'center'} align={'flex-start'}>
					{
						benefits.map((benefit, id) => {
							return(
								<HStack w={['100%', null, null, null, '430px', 'auto']} alignItems={'flex-start'} key={benefit + id}>
									<Image boxSize={'20px'} src={checkmark} />
									<Text color={'blackText.200'} fontSize={['16px','18px']}>{benefit}</Text>
								</HStack>
							);
						})
					}
				</VStack>
				<Button
					w={['auto','220px']}
					h={['40px','60px']}
					variant={'primary'}
					onClick={() => navigate(AppRoutes.processOverview)}
				>
					Learn how it works
					<ArrowForwardIcon ml={2} boxSize={5} />
				</Button>
			</VStack>
		</Flex>
	);
};