import React, { useState } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { formatLntPriceInLocalCurrency } from 'Utils';

interface PriceToggleProps extends BoxProps {
	value: number;
}

const PriceToggle: React.FC<PriceToggleProps> = ({ value, ...boxProps }) => {
	const [isConverted, setIsConverted] = useState(false);

	const handleClick = () => {
		setIsConverted(!isConverted);
	};

	return (
		<Box
			as='span'
			cursor='pointer'
			onClick={handleClick}
			textDecoration='underline'
			textDecorationStyle='dashed'
			textUnderlineOffset={['2px', null, '4px']}
			color={'darkCoral'}
			{...boxProps}
		>
			{isConverted ? formatLntPriceInLocalCurrency(value) : `${value} LNT`}
		</Box>
	);
};

export default PriceToggle;
