import { Text, Heading, Divider, VStack, Img, SimpleGrid, chakra, Stack, Skeleton, Box, theme, useMediaQuery, HStack, } from '@chakra-ui/react';
import { useLocale } from 'context/LocalizationContext';
import { DashboardSettingLayout } from 'Layouts/DashboardSettingLayout';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserActivityDetailsQuery } from 'services';
import { formatDateShort, getStatusDescription, TransactionStatusComponent } from 'Utils/dataTableUtils';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import withdrawMobile from 'Assets/Images/withdraw-mobile.png';
import creditCard from 'Assets/svg/credit-card.svg';
import { TransactionStatus, UserActivityType } from 'Types/Enums';
import { useAppSelector } from 'Hooks';

export const ContentSkeleton = () => {
	return(
		<Stack spacing={5} w='30%'>
			{new Array(1).fill('_').map((_, index) => (
				<Skeleton height='30px' key={index} />
			))}
		</Stack>
	);
};


export const ActivityDetails = () => {
	const location = useParams();
	const { locale } = useLocale();
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
	const { userInfo } = useAppSelector((state) => state.authUser);
	
	const activityId = location.Id || '';
	const { data, isLoading, isError, error } = useGetUserActivityDetailsQuery({activityId, locale});
	useDefaultErrorHandler(isError, error );

	const getUserContactPreference = (allowContact?: boolean): string => {
		switch (allowContact) {
			case true:
				return 'Allow';
			case false:
				return 'Block';
			default:
				return 'Unknown'; 
		}
	};

	/**
	 * Adjusts the transaction status to Pending for users with unverified emails.
	 * This reflects that transactions, which fail due to unverified emails, can proceed once verification is complete.
	 */
	const adjustStatus = (status: TransactionStatus | undefined): TransactionStatus | undefined => {
		if (status === TransactionStatus.Failed && !userInfo?.emailConfirmed) {
			return TransactionStatus.Pending;
		}
		return status;
	};
	
	return(
		<DashboardSettingLayout>
			<Heading display={['none', 'block']} fontSize={'30px'} color={'loopGrey.500'} fontWeight={'600'} mb={'20px'}>Activity details</Heading>
			<Divider display={['none', 'block']}/>
			<Stack direction={['column-reverse', 'column']} justify={'center'} align={'flex-start'} spacing={['10px','20px']} my={['20px','30px']}>
				{ data?.type !== UserActivityType.Withdrawal && isLoading ? (
					<Stack spacing={5} w={['400px', '1000px']}>
						{new Array(1).fill('_').map((_, index) => (
							<Skeleton h={'400px'} key={index} />
						))}
					</Stack>
				) : (
					<>
						{isMobile && data?.type === UserActivityType.Withdrawal ? (
							<Img
								src={withdrawMobile}
								w={['400px', '1000px']}
								h={['240px', '400px']}
								mb={'10px'}
								objectFit={'cover'}
							/>
						) : (
							data?.type !== UserActivityType.Withdrawal && (
								<Img
									src={data?.imageUrl}
									w={['400px', '1000px']}
									h={['240px', '400px']}
									mb={'10px'}
									objectFit={'contain'}
								/>
							)
						)}
					</>
				)}
				
				<VStack justify={'center'} align={'flex-start'}>
					<Text fontSize={['20px','25px']} fontWeight={[600,500]} color={['loopGrey.400','loopGrey.500']} >{data?.productName}</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text display={ data?.type === UserActivityType.Withdrawal ? 'none' : 'block'}  fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>  By {data?.brandName}</Text>}
				</VStack>
				
			</Stack>
			{ data?.type !== UserActivityType.Withdrawal && <SimpleGrid columns={[2, 3]} spacing={'30px'}>
				<VStack align={'start'} spacing={'15px'} justify={'center'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}  color={'loopGrey.500'} >Date:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{formatDateShort(data?.dateCreated)}</Text>}
					
				</VStack>
				<VStack align={'start'} justify={'center'}  spacing={'15px'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']} color={'loopGrey.500'}>Type:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{getStatusDescription(data?.type)}</Text>}
				</VStack>
				<VStack align={'start'} justify={'center'}  spacing={'15px'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}  color={'loopGrey.500'}>Data Sharing:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{data?.privacyOption}</Text>}
					
				</VStack>
				<VStack align={'start'} justify={'center'} spacing={'15px'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}   color={'loopGrey.500'}>Contact preference:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{getUserContactPreference(data?.allowContact)}</Text>}
				</VStack>
				<VStack align={'start'} justify={'center'}  spacing={'15px'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}  color={'loopGrey.500'}>Total earned:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{data?.points} LNT</Text>}
				</VStack>
				<VStack align={'start'} justify={'center'}  spacing={'15px'}>
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}  color={'loopGrey.500'}>Status:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <TransactionStatusComponent status={adjustStatus(data?.status)} />}
					
				</VStack>
				
			</SimpleGrid>}
			{ data?.type === UserActivityType.Withdrawal && <SimpleGrid columns={[2, 3]} spacing={'30px'}>
				<VStack align={'start'} spacing={'15px'} justify={'center'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}  color={'loopGrey.500'} >Date:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{formatDateShort(data?.dateCreated)}</Text>}
					
				</VStack>
				<VStack align={'start'} justify={'center'}  spacing={'15px'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']} color={'loopGrey.500'}>Type:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{getStatusDescription(data?.type)}</Text>}
				</VStack>
				<VStack align={'start'} justify={'center'}  spacing={'15px'}>
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}  color={'loopGrey.500'}>Status:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <TransactionStatusComponent status={adjustStatus(data?.status)} />}
					
				</VStack>
				<VStack align={'start'} justify={'center'}  spacing={'15px'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}  color={'loopGrey.500'}>Total amount:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{data?.points} LNT</Text>}
				</VStack>
				<VStack align={'start'} justify={'center'}  spacing={'15px'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}  color={'loopGrey.500'}>Withdrawn to:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <HStack>
						<Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{data?.payoutDetails?.provider}</Text>
						<Img src={creditCard} />
					</HStack>}
				</VStack>
				
				<VStack align={'start'} justify={'center'}  spacing={'15px'} >
					<Text fontSize={['14px','18px']} fontWeight={['300','400']}  color={'loopGrey.500'}>Account:</Text>
					{isLoading ? (
						<ContentSkeleton />
					) : <Text fontSize={['14px','18px']} fontWeight={['400','500']} color={'brand.black'}>{data?.payoutDetails?.account}</Text>}
				</VStack>
			</SimpleGrid>}
			{ data?.type !== UserActivityType.Withdrawal && <>
				<Divider my={'30px'} border={'3px solid'} borderColor={'#5860681A'} />
				<Text fontSize={['16px', '25px']} fontWeight={[600, 500]} color={'blackTextColor.200'} mb={'20px'}>Your History with this Item</Text>
				<SimpleGrid columns={[2, 3]} spacing={'30px'}>


					<VStack align={'start'} spacing={'15px'} justify={'center'}>
						<Text fontSize={['14px', '18px']} fontWeight={['300', '400']} color={'loopGrey.500'}>Number of Survey:</Text>
						{isLoading ? (
							<ContentSkeleton />
						) : <Text fontSize={['14px', '18px']} fontWeight={['400', '500']} color={'brand.black'}>{data?.productHistory?.surveyCount}</Text>}

					</VStack>
					<VStack align={'start'} justify={'center'} spacing={'15px'}>
						<Text fontSize={['14px', '18px']} fontWeight={['300', '400']} color={'loopGrey.500'}>Total Scanned:</Text>
						{isLoading ? (
							<ContentSkeleton />
						) : <Text fontSize={['14px', '18px']} fontWeight={['400', '500']} color={'brand.black'}>{data?.productHistory?.scanCount}</Text>}

					</VStack>
					<VStack align={'start'} justify={'center'} spacing={'15px'}>
						<Text fontSize={['14px', '18px']} fontWeight={['300', '400']} color={'loopGrey.500'}>Total Earned:</Text>
						{isLoading ? (
							<ContentSkeleton />
						) : <Text fontSize={['14px', '18px']} fontWeight={['400', '500']} color={'brand.black'}>{data?.productHistory?.points} LNT</Text>}
					</VStack>

				</SimpleGrid>
			</>}
			{ data?.type !== UserActivityType.Withdrawal && <>
				<Divider my={'30px'} border={'3px solid'} borderColor={'#5860681A'} />
				<Text fontSize={['16px', '25px']} fontWeight={[600, 500]} color={'blackTextColor.200'} mb={'20px'}>Your <chakra.span color={'darkCoral'}>Loop</chakra.span> with {data?.brandName}</Text>
				<SimpleGrid columns={[2, 3]} spacing={'30px'}>

					<VStack align={'start'} spacing={'15px'} justify={'center'}>
						<Text fontSize={['14px', '18px']} fontWeight={['300', '400']} color={'loopGrey.500'}>Number of Survey:</Text>
						{isLoading ? (
							<ContentSkeleton />
						) : <Text fontSize={['14px', '18px']} fontWeight={['400', '500']} color={'brand.black'}>{data?.brandHistory?.surveyCount}</Text>}
					</VStack>
					<VStack align={'start'} justify={'center'} spacing={'15px'}>
						<Text fontSize={['14px', '18px']} fontWeight={['300', '400']} color={'loopGrey.500'}>Total Scanned:</Text>
						{isLoading ? (
							<ContentSkeleton />
						) : <Text fontSize={['14px', '18px']} fontWeight={['400', '500']} color={'brand.black'}>{data?.brandHistory?.scanCount}</Text>}
					</VStack>
					<VStack align={'start'} justify={'center'} spacing={'15px'}>
						<Text fontSize={['14px', '18px']} fontWeight={['300', '400']} color={'loopGrey.500'}>Total Earned:</Text>
						{isLoading ? (
							<ContentSkeleton />
						) : <Text fontSize={['14px', '18px']} fontWeight={['400', '500']} color={'brand.black'}>{data?.brandHistory?.points} LNT</Text>}
					</VStack>

				</SimpleGrid>
			</>}
			{ data?.responses && data?.responses?.length > 0 && <Box>
				<Divider my={'30px'} border={'3px solid'} borderColor={'#5860681A'}/>
				<Text fontSize={['16px','25px']} fontWeight={[600,500]} color={'blackTextColor.200'} mb={'20px'} >Your answers</Text>
				{
					data?.responses?.map((item) => (
						<Box key={item.id}>
							<VStack  justify={'center'} align={'flex-start'} spacing={['20px','15px']} >
								<Text fontSize={['14px','16px']} color={'brand.black'} fontWeight={400}>{item.question?.title}</Text>
								{
									item.selectedOptionValues?.map((option) => (
										<Text key={option.id} fontSize={['12px','16px']} color={'loopGrey.500'} fontWeight={400}>{option.text}</Text>
									))
								}
								
							</VStack>
							<Divider my={['25px','20px']} border={'0.5px solid'} borderColor={'#264653'}/>
						</Box>
					))
				}
			</Box>}
		</DashboardSettingLayout>
	);
};
