import React, { useEffect } from 'react';
import { Text, Stack, Button, Box } from '@chakra-ui/react';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { EmailVerificationIcon } from 'Components/Icons/EmailVerificationIcon';
import { useFormik } from 'formik';
import { useSendEmailOtpMutation } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import * as Yup from 'yup';
import { useLocale } from 'context/LocalizationContext';
import { useAppSelector } from 'Hooks';

interface IEmailVerificationModalContentEmailProps {
	onChange: (value: string) => void;
}

export const EmailVerificationModalContentEmail = ({ onChange }: IEmailVerificationModalContentEmailProps) => {
	const { locale } = useLocale();

	const { userInfo } = useAppSelector((state) => state.authUser);
	const [sendEmailOtp, { isSuccess, isLoading, isError, error }] = useSendEmailOtpMutation();
	

	useDefaultErrorHandler(isError, error);

	const { values, handleChange, handleSubmit, errors } = useFormik({
		initialValues: { email: userInfo?.emailAddress || '' }, // Use userInfo.emailAddress if available
		validationSchema: Yup.object().shape({
			email: Yup.string().email('Please enter a valid email').required('Email is required'),
		}),
		onSubmit: async (values) => {
			sendEmailOtp({ email: values.email, isForActiveUser: true, locale });
		},
	});

	useEffect(() => {
		if (isSuccess) {
			onChange(values.email);
		}
	}, [isSuccess]);

	return (
		<Box  display={'flex'}  flexDirection={'column'} minH={['100vh', 'auto']} alignItems={'center'} justifyContent={'center'}>
			<Stack py={10} px={['20px', '30px']} spacing={5} alignItems={'center'} justify={'center'}>
				<EmailVerificationIcon boxSize={['80px', '60px']} />
				<Text fontSize={['20px', '30px']} fontWeight={'600'}>
					Verify code
				</Text>
				<Text fontSize={['12px', '16px']} textAlign={'center'} pb={5}>
					Enter your email address below to verify your account to earn cashbacks
				</Text>
				<CustomInput
					onChange={handleChange}
					name='email'
					type='email'
					placeholder='Your email address'
					error={errors.email}
					h={['50px', '60px']}
				/>
				<Button
					variant={'primary'}
					w={'100%'}
					h={['50px', '60px']}
					fontSize={['14px', '18px']}
					isLoading={isLoading}
					onClick={() => handleSubmit()}
				>
					Verify
				</Button>
			</Stack>
		</Box>
	);
};
