import React from 'react';

import {
	Flex,
	Heading,
	VStack,
	Text,
	Button,
	HStack
} from '@chakra-ui/react';

import { IJumbotronContents } from 'Types/Interface/ILandingPage';
import { Icon } from '@chakra-ui/react';
import { FaCirclePlay } from 'react-icons/fa6';


interface IJumbotronProps{
	contents: IJumbotronContents[]
}

export const Jumbotron = ({ contents }: IJumbotronProps) => {
	return (
		<Flex
			alignItems={['center', 'center']}
			flexDirection={{ base: 'column-reverse', md: 'row' }}
			justifyContent={['center']}
			h={'100vh'}
			mt={{base:'-12', xl:''}}
			// mt={{base:'28',md:'24', xxl:'32'}}
			// m={'32 auto'}
			// px={{'2xl':'20'}}
		>
			{contents.map(content => {
				return(
					<VStack
						alignItems={['flex-start', 'center']}
						spacing={10}
						textAlign={['left', 'left', 'center']}
						key={content.heading}
					>
						<VStack alignItems={['flex-start', 'flex-start', 'center']} spacing={4} >
							<Heading fontSize={{ base: '50px', tablet: '2xl', xxl: '5xl' }}
								color="black" 
								w={['100%', '615.45px', '100%']}
								fontWeight={'extrabold'}
								letterSpacing={'-0.03em'}
							>
								{content.heading}
							</Heading>
							<Text fontSize={{ base: '16px', tablet: '16px', xxl:'16px' }} 
								lineHeight={6} w={{base:'100%', sm:'xs',tablet:'604px',xl:'604px'}} 
								color="black"
							>
								{content.text}
							</Text>
						</VStack>
						<HStack
							alignItems={['center', 'flex-start', 'center']} 
							justifyContent={['flex-start','space-between']}
							spacing={'15px'}
						>
							<Button
								w={['157px','160px']}
								h={['50px','60px']}
								variant={'secondary'}
								gap={'5px'}
							>
								<Icon as={FaCirclePlay} boxSize={'16px'} />
								Watch Demo
							</Button>
						</HStack>
					</VStack>
				);
			})}	
		</Flex>
	);
};
