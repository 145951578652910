import React, { useEffect } from 'react';
import { Checkbox, Stack } from '@chakra-ui/react';
import { IQuestionOption } from 'Types/Interface/ISurvey';
import { FadeInContainer } from '../FadeInContainer';

interface IMultiSelectProps {
	questionOptions: IQuestionOption[];
	onChange: (id: string[]) => void;
	value: string[];
}

export const MultiSelect = ({ questionOptions, onChange, value }: IMultiSelectProps) => {
	useEffect(() => {
		onChange(value);
	}, []);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value: checkBoxValue, checked } = event.target;
		const newValue = checked ? [...value, checkBoxValue] : value.filter((id) => id !== checkBoxValue);
		onChange(newValue);
	};

	return (
		<FadeInContainer>
			<Stack spacing={8}>
				{questionOptions.map(({ id, text }) => (
					<Checkbox
						value={id}
						onChange={handleCheckboxChange}
						key={id}
						variant='primary'
						size='lg'
						isChecked={value.includes(id)}
					>
						{text}
					</Checkbox>
				))}
			</Stack>
		</FadeInContainer>
	);
};
