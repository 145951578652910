import { IAuthenticateUserResponse } from 'Types/Interface/IAuthenticateUserResponse';
import { ILoginDetails } from 'Types/Interface/IUser';
import { DEFAULT_ERROR_MESSAGE, ErrorType, getErrorMsg } from './api';
import { routes } from './apiRoutes';
import { reqClient } from './reqClient';


export const loginWithPhone = async (
	loginDetails: ILoginDetails
): Promise<IAuthenticateUserResponse> => {
	try {
		const response = await reqClient.post(
			routes.accountLoginPhone,
			loginDetails
		);
		if (response.data.status) {
			return response.data.data;
		} else {
			throw new Error(DEFAULT_ERROR_MESSAGE);
		}
	} catch (error) {
		const message = getErrorMsg(error as ErrorType);
		throw new Error(message);
	}
};


export async function loginWithGoogle(
	accessToken: string
): Promise<IAuthenticateUserResponse> {
	try {
		const params = {
			accessToken,
		};
		const response = await reqClient.post(
			routes.accountLoginGoogle,
			{},
			{ params }
		);
		return response.data.data;

	} catch (error) {  
		const message = getErrorMsg(error as ErrorType);
		throw new Error(message);
	}
}

export const loginWithFacebook = async (
	accessToken: string
): Promise<IAuthenticateUserResponse> => {
	try {
		const params = {
			accessToken,
		};
		const response = await reqClient.post(
			routes.accountLoginFacebook,
			{},
			{ params }
		);
		return response.data.data;

	} catch (error) {  
		const message = getErrorMsg(error as ErrorType);
		throw new Error(message);
	}
};
