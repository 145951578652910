import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';
import withdraw from '../../Assets/svg/withdraw.svg';


export const Withdraw = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={withdraw} ref={ref} {...props} />;
});