import ErrorCodes from 'Data/ErrorCodes';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useGetUserProfileMutation } from 'services';
import { useAppDispatch } from 'Hooks';
import { setUserInfo } from 'store/slices';
import { useEffect } from 'react';

export function useRefreshUserProfile() {
	const dispatch = useAppDispatch();

	const [refreshUserProfile, { data: userProfileResponse, isSuccess, isError, error }] = useGetUserProfileMutation();

	if (error) {
		throw new Error(ErrorCodes.FetchUserProfile);
	}

	useDefaultErrorHandler(isError, error);

	useEffect(() => {
		if (isSuccess && userProfileResponse?.data) {
			dispatch(setUserInfo(userProfileResponse.data));
		}
	}, [isSuccess, userProfileResponse?.data, dispatch]);

	return {
		refreshUserProfile,
		userProfileData: userProfileResponse?.data || null,
	};
}
