import React, { useEffect, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { Navbar } from 'Pages/LandingPage/Components';
import { FadeOnScroll } from 'Pages/LandingPage/Components/FadeOnScroll';
import { ScrollUpBtn } from 'Components/ScrollUpBtn';
import { Footer } from 'Pages/LandingPage/HomeSection/sections/Footer';
import patternedBackground from 'Assets/svg/patternedBackground.svg';
import { NavbarBusiness } from 'Pages/LoopForBusiness/Components/NavbarBusiness';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';
export interface ILandingPageLayoutChild {
  shouldIgnoreParentPadding?: boolean;
}

interface ILandingPageLayoutProps {
  JumbotronSection: React.ReactNode;
  children: React.ReactNode;
  disableGradientBackground?: boolean;
  linkColorMode?: 'light' | 'black';
  paddingTop?: string;
  isBusiness?: boolean;
}

export const LandingPageLayout = ({
	JumbotronSection,
	children,
	linkColorMode,
	isBusiness
}: ILandingPageLayoutProps) => {
	const navigate = useLocalizedNavigate();
	const [isVisible, setIsVisible] = useState(true);

	const handleSwitch = (toBusiness: boolean) => {
		navigate(toBusiness ? AppRoutes.LoopForBusiness : AppRoutes.home);
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 100) {
				setIsVisible(false);
			} else {
				setIsVisible(true);
			}
		};
	
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<Box overflowX="hidden">
			<Box
				position="fixed"
				top="0"
				left="50%"
				transform="translateX(-50%)"
				zIndex="1000"
				bg="white"
				p={2}
				borderRadius="md"
				boxShadow="md"
				opacity={isVisible ? 1 : 0}
				transition="opacity 0.3s ease-in-out"
				pointerEvents={isVisible ? 'auto' : 'none'}
				display={['none', null, null, 'block']}
			>
				<Flex  gap={8}>
					<Button  onClick={() => handleSwitch(false)} fontWeight={isBusiness ? 'normal' : 'bold'} fontSize={'14px'} >Loop</Button>
					<Button onClick={() => handleSwitch(true)} fontWeight={isBusiness ? 'bold' : 'normal'} fontSize={'14px'} >For Business</Button>
				</Flex>
			</Box>
			<Box
				py="8"
				px={['5', '20',]}
				bg= {'linear-gradient(180deg, rgba(255, 111, 97, 0.18) -97.87%, rgba(255, 111, 97, 0.00) 100%)'}
				bgImage={`url(${patternedBackground})`}
				bgPosition="center"
				bgRepeat="no-repeat"
				bgSize="cover"
				// minHeight={{ base: '70vh', tablet: '50vh', xxl: '400px' }}
			>
				{isBusiness ? <NavbarBusiness /> : <Navbar linkColorMode={linkColorMode} />}
				<Box maxW={'1440px'}  minH={['400px', null, null,null,'100vh', '50vh']} m={'0 auto'}>{JumbotronSection}</Box>
			</Box>

			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					if (child.props.shouldIgnoreParentPadding) {
						return (
							<Box  maxW={'2560px'} m={'0 auto'} mb={['50px', '100px']} >
								<FadeOnScroll>{child}</FadeOnScroll>
							</Box>
						);
					}

					return (
						<Box maxW={'2560px'} m={'0 auto'} mb={['50px', null, null, null, '100px', null]} px={{base: '30px', xl: '80px', '2xl': '192px' }}>
							<FadeOnScroll>{child}</FadeOnScroll>
						</Box>
					);
				}
				return child;
			})}

			<Footer isBusiness={isBusiness} />
			<ScrollUpBtn />
		</Box>
	);
};
