export const LinkTheme = {
	baseStyle: {},
	sizes: {},
	variants: {
		primary: {
			color: 'darkCoral',
			fontweight: '500',
			textDecoration: 'underline',
			textUnderlineOffset: '4px',
			_hover: {
				textDecoration: 'none',
			},
		},
	},
	// default values for `size` and `variant`
	defaultProps: {
		size: '',
		variant: '',
	},
};
