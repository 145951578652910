// import { signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { useContext, useEffect, useState } from 'react';
import { loginWithFacebook, loginWithGoogle } from 'Api/login.api';
import { AuthContext } from 'context/AuthContext';
import { IAuthenticateUserResponse } from 'Types/Interface/IAuthenticateUserResponse';
import { useToast } from './useToast';
import { AppRoutes } from 'Routes/AppRoutes';
import {
	useVerifyFacebookAccountMutation,
	useVerifyGmailAccountMutation,
} from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { useLocale } from 'context/LocalizationContext';

export const useSocialLogin = () => {
	const { locale } = useLocale();
	const auth = useContext(AuthContext);
	const [fbAuthData, setFbAuthData] = useState<IAuthenticateUserResponse>();
	const [googleAuthData, setGoogleAuthData] = useState<IAuthenticateUserResponse>();
	const { alert } = useToast();
	const navigate = useLocalizedNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [checkGmailUserEmailExist, { data: gmailData, isSuccess: isGmailSuccess, isError:isGmailError, error:gmailError }] = useVerifyGmailAccountMutation();
	const [checkFacebookUserExists, { data: fbData, isSuccess: isFaceBookSuccess, isError:isFacebookError, error:facebookError }] = useVerifyFacebookAccountMutation();

	useEffect(() => {
		if (gmailData?.data && !isLoading) {
			alert('success', 'Login was successful');
			navigate(AppRoutes.home);
		} else if (fbData?.data && !isLoading) {
			alert('success', 'Login was successful');
			navigate(AppRoutes.home);
		} else if(isFaceBookSuccess && !fbData?.data){
			navigate(AppRoutes.introduction);
		}
		else if(isGmailSuccess && !gmailData?.data){
			navigate(AppRoutes.introduction);
		}
	}, [gmailData, fbData, isGmailSuccess, isFaceBookSuccess, isLoading]);

	useDefaultErrorHandler(isGmailError, gmailError);
	useDefaultErrorHandler(isFacebookError, facebookError);

	useEffect(() => {
		if (googleAuthData?.accessToken) {
			auth.setAuthInfo(googleAuthData as IAuthenticateUserResponse);
		}
		if (fbAuthData?.accessToken) {
			auth.setAuthInfo(fbAuthData as IAuthenticateUserResponse);
		}
	},[fbAuthData, googleAuthData]);

	const handleFacebookLogin = async (accessToken: string) => {
		setIsLoading(true);
		try {
			checkFacebookUserExists({ accessToken, locale });

			const response = await loginWithFacebook(accessToken ?? '');
			setFbAuthData(response);
			setIsLoading(false);
			auth.setAuthInfo(response);
		} catch (error) {
			setIsLoading(false);
			console.error('error', error);
			alert('error', 'Something went wrong');
		}
	};

	const handleGoogleLogin = async (idToken: string, userEmail: string) => {
		setIsLoading(true);
		try {
			setIsLoading(true);
			await checkGmailUserEmailExist({ userEmail, locale});

			const loginWithGoogleResponse = await loginWithGoogle(idToken ?? '');
			setGoogleAuthData(loginWithGoogleResponse);
			setIsLoading(false);
			auth.setAuthInfo(loginWithGoogleResponse);
		} catch (error) {
			setIsLoading(false);
			alert('error', 'Something went wrong');
		}
	};

	return { handleFacebookLogin, handleGoogleLogin, isLoading };
};
