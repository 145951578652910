/* eslint-disable max-lines */
import React from 'react';
import {
	IUserPreferenceQuestion,
	userPereferenceQuestionType,
} from 'Types/Interface/IUserPreferenceQuestion';
import { UserIconSvg } from 'Components/Icons/UserIcon';
import { SexualityIconSvg } from 'Components/Icons/SexualityIcon';
import { GlobeIconSvg } from 'Components/Icons/GlobeIcon';
import { BabyStrollerIconSvg } from 'Components/Icons/BabyStrollerIcon';
import { HomeIconSvg } from 'Components/Icons/HomeIcon';
import { BriefCaseIconSvg } from 'Components/Icons/BriefCaseIcon';
import { GraduationCapIconSvg } from 'Components/Icons/GraduationCapIcon';
import { PoliticsIconSvg } from 'Components/Icons/PoliticsIcon';
import { DrinkIconSvg } from 'Components/Icons/DrinkIcon';
import { CigaretteIconSvg } from 'Components/Icons/CigaretteIcon';
import { LeafIconSvg } from 'Components/Icons/LeafIcon';
import { DrugIconSvg } from 'Components/Icons/DrugIcon';
import {
	Children,
	EducationEnum,
	Ethnicity,
	Gender,
	Industry,
	PoliticsEnum,
	Religion,
	Sexuality,
	HabitsEnum,
	UserPreferenceFormName,
} from 'Types/Enums/UserPreferenceEnums';
import { CalendarIcon } from '@chakra-ui/icons';

export const userPreferenceQuestions: IUserPreferenceQuestion[] = [
	{
		question: 'What is your gender?',
		name: UserPreferenceFormName.Gender,
		options: [
			{
				label: 'Man',
				value: Gender.Male,
			},
			{
				label: 'Woman',
				value: Gender.Female,
			},
			{
				label: 'Non-binary',
				value: Gender.NonBinary,
			},
			{
				label: 'Prefer not say',
				value: Gender.NoInput,
			},
		],
		icon: <UserIconSvg />,
	},
	{
		question: 'What is your sexuality?',
		name: UserPreferenceFormName.Sexuality,
		options: [
			{
				label: 'Prefer not to say',
				value: Sexuality.NoInput,
			},
			{
				label: 'Straight',
				value: Sexuality.Straight,
			},
			{
				label: 'Gay',
				value: Sexuality.Gay,
			},
			{
				label: 'Lesbian',
				value: Sexuality.Lesbian,
			},
		],
		icon: <SexualityIconSvg />,
	},
	{
		question: 'What is your ethnicity?',
		name: UserPreferenceFormName.Ethnicity,
		options: [
			{
				label: 'American Indian',
				value: Ethnicity.AmericanIndian,
			},
			{
				label: 'Black/African',
				value: Ethnicity.BlackAfrican,
			},
			{
				label: 'East Asian',
				value: Ethnicity.EastAsian,
			},
			{
				label: 'South Asian',
				value: Ethnicity.SouthAsian,
			},
			{
				label: 'Latino',
				value: Ethnicity.Latino,
			},
			{
				label: 'White',
				value: Ethnicity.White,
			},
			{
				label: 'Others',
				value: Ethnicity.Others,
			},
			{
				label: 'Prefer not to say',
				value: Ethnicity.NoInput,
			},
		],
		icon: <GlobeIconSvg/>,
	},
	{
		question: 'What about children?',
		name: UserPreferenceFormName.Children,

		options: [
			{
				label: 'Don’t have children',
				value: Children.No,
			},
			{
				label: 'Have children',
				value: Children.Yes,
			},
			{
				label: 'Prefer not to say',
				value: Children.NoInput,
			},
		],
		icon: <BabyStrollerIconSvg />,
	},
	{
		question: 'Where is your hometown?',
		name: UserPreferenceFormName.Hometown,
		questionType: userPereferenceQuestionType.freeText,
		inputFields: ['City, Country'],
		icon: <HomeIconSvg />,
	},
	{
		question: 'When is your date of birth?',
		name: UserPreferenceFormName.DateOfBirth,
		questionType: userPereferenceQuestionType.freeText,
		inputFields: [''],
		icon: <CalendarIcon color={'#264653'} boxSize={'20px'} />,
	},
	{
		question: 'What industry do you work in?',
		name: UserPreferenceFormName.Industry,
		options: [
			{
				label: 'Others',
				value: Industry.Others,
			},
			{
				label: 'Medicine',
				value: Industry.Medical,
			},
			{
				label: 'Tech',
				value: Industry.InformationTechnology,
			},
			{
				label: 'Corporate',
				value: Industry.Legal,
			},
		],
		icon: <BriefCaseIconSvg />,
	},
	{
		question: 'Where did you go school?',
		name: UserPreferenceFormName.School_location,
		questionType: userPereferenceQuestionType.freeText,
		inputFields: ['School'],
		icon: <GraduationCapIconSvg />,
	},
	{
		question: 'What is the highest level you attained?',
		name: UserPreferenceFormName.Education,

		options: [
			{
				label: 'High school',
				value: EducationEnum.Highschool,
			},
			{
				label: 'Undergrad',
				value: EducationEnum.Undergrad,
			},
			{
				label: 'Postgrad',
				value: EducationEnum.Postgrad,
			},
			{
				label: 'Prefer not to say',
				value: EducationEnum.NoInput,
			},
		],
		icon: <GraduationCapIconSvg />,
	},
	{
		question: 'What are your religious beliefs?',
		name: UserPreferenceFormName.Religion,

		options: [
			{
				label: 'Buddist',
				value: Religion.Buddist,
			},
			{
				label: 'Catholic',
				value: Religion.Catholic,
			},
			{
				label: 'Christian',
				value: Religion.Christian,
			},
			{
				label: 'Hindu',
				value: Religion.Hindu,
			},
			{
				label: 'Jewish',
				value: Religion.Jewish,
			},
			{
				label: 'Muslim',
				value: Religion.Muslim,
			},
			{
				label: 'Atheist',
				value: Religion.Atheist,
			},
			{
				label: 'Agnostic',
				value: Religion.Agnostic,
			},
			{
				label: 'Others',
				value: Religion.Others,
			},
			{
				label: 'Prefer not to say',
				value: Religion.NoInput,
			},
		],
		icon: <GraduationCapIconSvg />,
	},
	{
		question: 'What are your political beliefs?',
		name: UserPreferenceFormName.Politics,

		options: [
			{
				label: 'Liberal',
				value: PoliticsEnum.Liberal,
			},
			{
				label: 'Moderate',
				value: PoliticsEnum.Moderate,
			},
			{
				label: 'Conservative',
				value: PoliticsEnum.Conservative,
			},
			{
				label: 'Others',
				value: PoliticsEnum.Others,
			},
			{
				label: 'Prefer not to say',
				value: PoliticsEnum.NoInput
			}
		],
		icon: <PoliticsIconSvg />,
	},
	{
		question: 'Do you drink?',
		name: UserPreferenceFormName.Alchohol,

		options: [
			{
				label: 'Yes',
				value: HabitsEnum.Yes,
			},
			{
				label: 'No',
				value: HabitsEnum.No,
			},
			// {
			// 	label: 'Sometimes',
			// 	value: 'Sometimes',
			// },
			{
				label: 'Prefer not to say',
				value: HabitsEnum.NoInput,
			},
		],
		icon: <DrinkIconSvg />,
	},
	{
		question: 'Do you smoke?',
		name: UserPreferenceFormName.Smoke,

		options: [
			{
				label: 'Yes',
				value: HabitsEnum.Yes,
			},
			{
				label: 'No',
				value: HabitsEnum.No,
			},
			{
				label: 'Prefer not to say',
				value: HabitsEnum.NoInput,
			},
		],
		icon: <CigaretteIconSvg />,
	},
	{
		question: 'Do you smoke marijuana?',
		name: UserPreferenceFormName.Marijuana,

		options: [
			{
				label: 'Yes',
				value: HabitsEnum.Yes,
			},
			{
				label: 'No',
				value: HabitsEnum.No,
			},
			{
				label: 'Prefer not to say',
				value: HabitsEnum.NoInput,
			},
		],
		icon: <LeafIconSvg />,
	},
	{
		question: 'Do you use drugs?',
		name: UserPreferenceFormName.Drugs,

		options: [
			{
				label: 'Yes',
				value: HabitsEnum.Yes,
			},
			{
				label: 'No',
				value: HabitsEnum.No,
			},
			{
				label: 'Prefer not to say',
				value: HabitsEnum.NoInput,
			},
		],
		icon: <DrugIconSvg />,
	},
];
