import React, { useEffect } from 'react';
import { Box, Heading, Stack, Text } from '@chakra-ui/react';

import { useUserBasicProfile, useToast} from 'Hooks';
import { UserPreferenceFormName } from 'Types/Enums';
import { PreferenceItem } from './components/PreferenceItem';
import { useDefaultErrorHandler } from 'Utils/errorHandling';

interface IPreferenceSectionProps {
	title: string;
	items: { title: UserPreferenceFormName; value: string | number }[];
	handleChange: (name: string, val: string | number) => void;
	handleSubmit: () => void;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	isSurvey?: boolean;
}

interface IContentProps{
	isSurvey?:boolean;
}

const PreferenceSection= ({ title, items, handleChange, handleSubmit, isLoading, isSuccess, isError, isSurvey }: IPreferenceSectionProps) => (
	<Stack spacing={10} flex={1}>
		<Stack>
			<Heading size="sm">{title}</Heading>
			<Text>Receive messages from brands about products...</Text>
		</Stack>
		{items.map((item) => (
			<Box key={item.title}>
				<PreferenceItem
					key={item.title}
					title={item.title}
					onChange={handleChange}
					onSave={handleSubmit}
					value={item.value}
					isLoading={isLoading}
					canClose={isSuccess || isError}
					isSurvey={isSurvey}
				/> 
			</Box>
			
		))}
	</Stack>
);

export const Content = ({isSurvey}: IContentProps) => {
	const { alert } = useToast();
	const { setFieldValue, handleSubmit, values, isLoading, isSuccess, isError, error } = useUserBasicProfile();
	const handleChange = (name: string, val: string | number) => {
		setFieldValue(name, val);
	};
	useEffect(() => {
		if (isSuccess) {
			alert('success', 'Updated info successfully');
		}
	}, [isSuccess]);

	useDefaultErrorHandler(isError, error);

	const basicInfoItems = [
		{ title: UserPreferenceFormName.Sexuality, value: values.sexuality },
		{ title: UserPreferenceFormName.Ethnicity, value: values.ethnicity },
		{ title: UserPreferenceFormName.Children, value: values.children },
		{ title: UserPreferenceFormName.Hometown, value: values.hometown },
		{ title: UserPreferenceFormName.Industry, value: values.industry },
		{ title: UserPreferenceFormName.School_location, value: values.school_location },
		{ title: UserPreferenceFormName.Education, value: values.education },
		{ title: UserPreferenceFormName.Religion, value: values.religion },
		{ title: UserPreferenceFormName.Politics, value: values.politics },
	];

	const habitsItems = [
		{ title: UserPreferenceFormName.Alchohol, value: values.alchohol },
		{ title: UserPreferenceFormName.Smoke, value: values.smoke },
		{ title: UserPreferenceFormName.Marijuana, value: values.marijuana },
		{ title: UserPreferenceFormName.Drugs, value: values.drugs },
	];

	return (
		<Stack spacing={10} flex={1}>
			<PreferenceSection
				title="Basic information"
				items={basicInfoItems}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				isLoading={isLoading}
				isSuccess={isSuccess}
				isError={isError}
				isSurvey={isSurvey}
			/>
			<PreferenceSection
				title="Habits"
				items={habitsItems}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				isLoading={isLoading}
				isSuccess={isSuccess}
				isError={isError} 
				isSurvey={isSurvey}
			/>
		</Stack>
	);
};