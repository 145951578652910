import React, { useState, useRef, useEffect } from 'react';
import { Box, Icon, Flex, useDisclosure } from '@chakra-ui/react';
import { CustomModal } from 'Components/CustomModal';
import { BsCamera } from 'react-icons/bs';
import lottie from 'lottie-web';

import { QrReader } from 'react-qr-reader';
import cameraAnim from './camera-scan.json';
import loadingAnim from './loading.json';
import { useGetQrCode } from 'Hooks';
import { CodeType } from 'Types/Enums';
import { useCodeScan } from 'Hooks/useCodeScan';
import { useDefaultErrorHandler } from 'Utils/errorHandling';

export const QrCodeScanner = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const loadingRef = useRef<HTMLDivElement>(null);

	const [dynamicLink, setDynamicLink] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { mutate: getQrCode, isLoading, isSuccess, data: qrCode, isError, error } = useGetQrCode();
	const surveyQrCode = qrCode?.data.toString();

	useDefaultErrorHandler(isError, error, onClose);

	useEffect(() => {
		if (dynamicLink) {
			getQrCode(dynamicLink);
			setDynamicLink('');
		}
	}, [dynamicLink]);

	useEffect(() => {
		if (isSuccess) {
			onClose();
		}
	}, [isSuccess]);

	const { handleScan, isLoading: isLoadingSurvey } = useCodeScan({
		onScanComplete: () => {
			onClose();
		},
	});

	useEffect(() => {
		if (surveyQrCode) {
			handleScan(surveyQrCode, CodeType.qrCode);
		}
	}, [surveyQrCode]);

	useEffect(() => {
		if (containerRef.current) {
			lottie.loadAnimation({
				container: containerRef.current,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: cameraAnim,
			});
		}
	}, [containerRef, isOpen]);

	useEffect(() => {
		if (loadingRef.current) {
			lottie.loadAnimation({
				container: loadingRef.current,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: loadingAnim,
			});
		}
	}, [loadingRef, isLoadingSurvey, isLoading]);

	return (
		<>
			{isOpen && (
				<Box
					ref={containerRef}
					position='fixed'
					zIndex={1000000}
					left='50%'
					top='50%'
					transform='translate(-50%, -50%)'
				/>
			)}
			{isLoadingSurvey && (
				<Box
					ref={loadingRef}
					position='fixed'
					zIndex={10000000}
					left='50%'
					top='50%'
					transform='translate(-50%, -50%)'
				/>
			)}
			<Flex
				w={16}
				h={16}
				bg='loop.500'
				position='fixed'
				bottom={4}
				right={4}
				zIndex='banner'
				alignItems='center'
				justifyContent='center'
				borderRadius='full'
				cursor='pointer'
				shadow='lg'
				display={['flex', null, null, 'none']}
				onClick={onOpen}
			>
				<Icon as={BsCamera} w={8} h={8} color='white' />
			</Flex>

			<CustomModal
				isOpen={isOpen}
				onClose={onClose}
				hideFooter
				isCentered
				content={
					<Box h='100%' w='100%'>
						<QrReader
							onResult={(result, error) => {
								if (result) {
									setDynamicLink(result?.getText());
								}
							}}
							constraints={{
								width: 300,
								height: 300,
								facingMode: 'environment',
							}}
						/>
					</Box>
				}
			/>
		</>
	);
};
