import React, { useEffect, useState } from 'react';

import {
	Button,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useMediaQuery,
} from '@chakra-ui/react';
import { Gallery } from 'Components/Gallery';
import { AllBrands } from 'Constants/AllBrandsConstant';
import { AllBrandsCategories } from 'Constants/AllBrandsCategoryConstant';
import { BsFilter } from 'react-icons/bs';
import { SearchIcon } from '@chakra-ui/icons';
import { GalleryMobile } from 'Components/Gallery/GalleryMobile';
import { FoodAndBeverages } from 'Constants';
import { CategoryHeader } from 'Pages/LandingPage/HomeSection/sections/CategoryHeader';
import { SeeAllLink } from 'Pages/LandingPage/Components/SeeAllLink';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';

export const BrandsCategorySection = () => {
	const [filtered, setFiltered] = useState(AllBrands);
	const [activeCategory, setActiveCategory] = useState('');
	const [VisibleItems, setVisibleItems] = useState(20);
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
	const navigate = useLocalizedNavigate();

	const _handleShowMoreItems = () => {
		setVisibleItems((prevValue) => prevValue + 4);
	};

	const _handleSearch = (query: string) => {
		const SearchedBrands = AllBrands.filter((item) => 
			item.brandName.toLowerCase().includes(query.toLowerCase())
		);
		setFiltered(SearchedBrands);
	};

	const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
		_handleSearch(e.target.value);
	};

	useEffect(() => {
		if (activeCategory === 'All') {
			setActiveCategory('');
			return;
		}
		const filterBrands = AllBrands.filter((item) =>
			item.category?.includes(activeCategory)
		);
		setFiltered(filterBrands);
	}, [activeCategory]);
	
	return (
		<Flex direction={['column', 'column', 'column']}>
			<Flex justifyContent={'space-between'} alignItems={'center'}>
				<Menu>
					{isLargerThan768 && <MenuButton
						as={Button}
						rightIcon={<BsFilter   />}
						_focus={{ outline: 'none' }}
						border={'1px solid #C1C8CF'}
						borderRadius={'10px'}
						px={6}
						py={3}
						// display={['none','none', 'none', 'block']}
					>
            Filter
					</MenuButton>}
					{!isLargerThan768 && <MenuButton
						as={Button}
						_focus={{ outline: 'none' }}
						border={'1px solid #C1C8CF'}
						borderRadius={'5px'}
						px={2}
						py={2}
						bgColor={'#FF6F61'} color={'white'}
						// display={['block', 'block', 'block', 'none']}
					>
						<BsFilter  fontSize='1.7em' />
					</MenuButton>}
					<MenuList>
						{AllBrandsCategories.map(({ label }) => {
							return (
								<MenuItem key={label} onClick={() => setActiveCategory(label)}>
									{label}
								</MenuItem>
							);
						})}
					</MenuList>
				</Menu>
				{AllBrandsCategories.map(({ label }) => {
					return (
						<Button
							key={label}
							my={[8, 8, 12]}
							fontSize="sm"
							fontWeight="500"
							display={['none', 'none', 'none', 'block']}
							color={activeCategory === label ? '#121B26' : '#A0A8AF'}
							_focus={{
								color: '#121B26',
								borderBottom: '2px solid #FF6F61',
								borderRadius: '0',
								margin: '0',
							}}
							onClick={() => setActiveCategory(label)}
						>
							{label}
						</Button>
					);
				})}
				<InputGroup
					display={['block', 'block', 'block', 'none']}
				>
					<InputLeftElement 
						top={'24px'} left={'22px'}
						pointerEvents='none'>
						<SearchIcon color='gray.300' />
					</InputLeftElement>
					<Input placeholder='Search'
						borderColor='#C1C8CF'
						color={'#586068'}
						m={6} w={['90%', '95%']}
						onChange={onChange}
					/>
				</InputGroup>
			</Flex>
			{!isLargerThan768 &&
				<>
					<CategoryHeader
						onClickLink={() => {
							console.log('goto');
						} }
						title='Featured brands' 
					/>
					<GalleryMobile
						collection={FoodAndBeverages}
						visibility={false}
						onClickImage={() => console.log('goto')}
						display = {'none'} 
					/> 
					<SeeAllLink onClickLink={() => navigate(AppRoutes.brands)}/>
				</>
			}
			<Gallery 
				collection={filtered.slice(0, VisibleItems)} 
				visibility={false} 
				onClickImage={()=> console.log('goto')} 
				display = {'block'}
			/>
			<Button 
				variant={'secondary'}
				onClick={_handleShowMoreItems} 	
				w={{xxl:'52'}}
				h={{xxl:'10'}}
				alignSelf={'center'}
				my={16}
				_focus={{outline:'none'}}
			>
				Load More
			</Button>
		</Flex>
	);
};