import React from 'react';
import { Input, Textarea, theme, useMediaQuery } from '@chakra-ui/react';
import { FadeInContainer } from '../FadeInContainer';

interface IFreeTextProps {
	name: string;
	onChange: any
	value?: string;
}

export const FreeText = ({name, onChange, value}:IFreeTextProps) => {
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
	return (
		<FadeInContainer>
			{ isMobile ?
				<Textarea 
					w={['100%']}  
					bg={'#F6F6F6'} 
					color={'#525252'} 
					h={['250px']} 
					colorScheme={'gray'} 
					size={'sm'} 
					onChange={onChange} 
					value={value} 
					name={name} 
					placeholder='Type your answer'
					_focus={{
						outline: 'none',
						boxShadow: 'none',
					}}
				/>:
				<Input onChange={onChange} value={value} variant='flushed' name={name} placeholder='Type your answer' /> 
			}
		</FadeInContainer>
	);
};
