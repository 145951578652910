import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { BrandLoops, UserActivity, MyAccount, Rewards, Settings } from './sections';
import { AccountLayout, DashBoardLayout } from 'Layouts';
import { useDispatch } from 'react-redux';
import { setActiveDashboardTab } from 'store/slices/dashboardSlice';
import { useAppSelector } from 'Hooks';
import { Preferences } from './sections/AccountSettings/sections/Preferences';

export const Dashboard = () => {
	const tabs = [
		{
			title: 'My Account',
			content: <MyAccount />,
		},
		{
			title: 'Account Preferences',
			content: <Preferences />
		},
		{
			title: 'My Loops',
			content: <BrandLoops />
		},
		{
			title: 'Activities',
			panelTitle: 'Account Activities',
			content: <UserActivity />,
		},
		{
			title: 'Rewards',
			content: <Rewards />,
		},
		{
			title: 'Account Settings',
			content: <Settings />,
		}
	];

	const dispatch = useDispatch();
	const activeTab = useAppSelector((state) => state.dashboard.activeTab);
	const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);


	useEffect(() => {
		if (tabRefs.current[activeTab]) {
			tabRefs.current[activeTab]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		}
	}, [activeTab]);

	useEffect(() => {
		if(activeTab){
			dispatch(setActiveDashboardTab(activeTab));
		}
	}, []);

	const handleTabIndex = (index:number) => {
		dispatch(setActiveDashboardTab(index));
		localStorage.setItem('currentTab', `${index}`);
	};


	useLayoutEffect(() => {
		const localStorageTabIndex = localStorage.getItem('currentTab');
		if (localStorageTabIndex) {
			dispatch(setActiveDashboardTab(parseInt(localStorageTabIndex)));
		}
	}, [dispatch]);

	return (
		<DashBoardLayout>
			<Tabs mt={5} index={activeTab}>
				<TabList borderBottom='none' overflow='auto' pb={['1', null, 2]} className='no-scrollbar'>
					{tabs.map(({ title }, index) => (
						<Tab
							key={title}
							ref={(el) => (tabRefs.current[index] = el)}
							color='#586068'
							fontSize='sm'
							px={0}
							mr={[4, 10]}
							_selected={{ borderColor: ['loop.300', 'loop.300'], fontWeight: 600 }}
							_hover={{ borderColor: ['none', 'loop.300'] }}
							onClick={() => handleTabIndex(index)}
							whiteSpace='nowrap'
							_active={{ border: 'none' }}
						>
							{title}
						</Tab>
					))}
				</TabList>

				<TabPanels>
					{tabs.map(({ title, panelTitle, content }) => (
						<TabPanel key={title} px={0}>
							<AccountLayout title={panelTitle ?? title}>{content}</AccountLayout>
						</TabPanel>
					))}
				</TabPanels>
			</Tabs>
			
		</DashBoardLayout>
	);
};
