import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import axios from 'axios';

import { theme } from 'Theme/BaseTheme';

import {
	AuthProvider,
} from 'context/AuthContext';
import 'focus-visible/dist/focus-visible';
import { persistor, store } from './store';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'Components/ErrorBoundary';
import { GoogleOAuthProvider } from '@react-oauth/google';

const queryClient = new QueryClient();

axios.defaults.baseURL = process.env.REACT_APP_API_PROD;


ReactDOM.render(
	<React.StrictMode>
		<ColorModeScript />
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<BrowserRouter>
						<GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
							<AuthProvider>
								<ChakraProvider theme={theme}>
									<ErrorBoundary>
										<App />
									</ErrorBoundary>
								</ChakraProvider>
							</AuthProvider>
						</GoogleOAuthProvider>
					</BrowserRouter>
				</PersistGate>
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
