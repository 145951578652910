import { Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ReactStars from 'react-stars';
import { IQuestionOption } from 'Types/Interface/ISurvey';
import { FadeInContainer } from '../FadeInContainer';

export interface IstarRatingProps {
	onChange: (ratingValue: number) => void;
	value: number;
	options: Array<IQuestionOption>;
}

export const StarRating = ({ onChange, value, options }: IstarRatingProps) => {
	const [rating, setRating] = useState(value);

	const handleRating = (ratingValue: number) => {
		onChange(ratingValue);
		setRating(ratingValue);
	};

	useEffect(() => {
		setRating(value);
	}, [value]);

	return (
		<FadeInContainer>
			<Flex direction={'column'} justifyContent={'center'} alignItems={'center'} w='max-content'>
				<ReactStars
					count={options.length}
					value={rating}
					onChange={handleRating}
					size={40}
					color2={'#264653'}
					half={false}
					className={'star-rating-container'}
				/>
				<Flex  justifyContent='space-between' w='100%'>
					<Text color={'loopGrey.500'} fontWeight={500} fontSize={'12px'}>
						{options[0].text}
					</Text>
					{options.length > 1 && (
						<Text color={'loopGrey.500'} fontWeight={500} fontSize={'12px'}>
							{options[options.length - 1].text}
						</Text>
					)}
				</Flex>
			</Flex>
		</FadeInContainer>
	);
};
