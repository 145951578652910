import React from 'react';
import {
	Image,
	ImageProps,
	Flex,
} from '@chakra-ui/react';

export const RoundedImage = ({ w, h, ...imageProps }: ImageProps) => {
	return (
		<>
			<Flex
				justifyContent={'center'}
				w={w ?? '40px'}
				h={h ?? '40px'}
				borderRadius={'12px'}
				backgroundColor={'loopGrey.100'}
			>
				<Image src={imageProps.src} objectFit='contain' w={['30px','20px']}  borderRadius={'12px'} />
			</Flex>
		</>
	);
};
