import { Flex, VStack, Text, chakra, HStack, Image, Button, Stack, useMediaQuery, Heading } from '@chakra-ui/react';
import checkmark from 'Assets/svg/Checkmark.svg';
import React from 'react';
import PhoneMarkup from 'Assets/Images/PhoneMarkup.png';
import PhoneMarkupMobile from 'Assets/Images/PhoneMarkupMobile.png';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';

interface IGrowWithLoopProps{
	shouldIgnoreParentPadding: boolean;
}

const benefits = [
	'First-party data is only collected on a fraction of your sales - those that are direct. Loop changes that and helps you connect with customers in-moment across all channels - owned and unowned.',
	'It\'s also very limited - used in low-engagement channels like Email and SMS or to enable re-marketing on rented channels. Loop\'s on-platform engagement campaigns lead to 8x the conversion.',
	'Relationships are built over valuable engagement where both sides benefit... reward customers for every engagement and build your true community.'
];

export const GrowWithLoop = ({shouldIgnoreParentPadding}:IGrowWithLoopProps) => {
	const [isLargerThan768] = useMediaQuery('(min-width: 984px)');
	const navigate = useLocalizedNavigate();

	return(
		<Flex 
			direction={['column', null, null, 'row']} 
			bg={['#FFFCFC', null, null, '#FFFCFC']} 
			backgroundSize={'cover'}
			// pt={[null,'80px']} 
			py={['40px','100px']}
			w={'100%'} 
			h={'auto'}
			justifyContent={'space-between'}
			{...(shouldIgnoreParentPadding && { px: {base: '30px', xl: '80px', '2xl': '192px' }})}
		>
			<VStack spacing={['30px','40px']} w={['100%', null, null, null, '50%', null]} align={'flex-start'}>
				<Stack spacing={[1,2]} >
					<Heading  fontSize={['20px','40px']} fontWeight={'extrabold'}> Grow with </Heading>
					<Heading pb={'10px'} fontSize={['20px','40px']} fontWeight={'extrabold'}>
						First-Party <chakra.span textDecoration={'line-through'} fontWeight={400}>Data</chakra.span> <chakra.span color={'darkCoral'}>Relationships.</chakra.span>
					</Heading>
					<Text fontSize={['16px','18px']}>
						First-party data is considered marketing-gold, but it&apos;s still very limited. We&apos;ll help you build real relationships with your customers
					</Text>
				</Stack>
				
				<VStack spacing={['18px','30px']} justifyContent={'center'} align={'flex-start'}>
					{
						benefits.map((benefit, id) => {
							return(
								<HStack alignItems={'flex-start'} key={benefit + id}>
									<Image boxSize={'20px'} src={checkmark} />
									<Text color={'blackText.200'} fontSize={['16px','18px']}>{benefit}</Text>
								</HStack>
							);
						})
					}
				</VStack>
				<Text fontSize={['16px','20px']} color={'loopGrey.500'} fontWeight={'600'}>Build first-party relationships with Loop, unifying customers from all channels for deeper engagement.</Text>
				<Button
					w={['auto','220px']}
					h={['40px','60px']}
					fontWeight={600}
					variant={'secondary'}
					onClick={() => navigate(AppRoutes.processOverview)}
				>
					Learn how it works
					<ArrowForwardIcon ml={2} boxSize={5} />
				</Button>
			</VStack>
			{ isLargerThan768 ? <Flex maxWidth={'50%'} bg={'#F5F5F5'} alignItems={'flex-end'} justifySelf={'center'}  w={['388px','auto']} h={['450px','650px']}>
				<Image w={['auto','610px']}  objectFit={['contain','cover']} h={['auto','548px']} src={PhoneMarkup} />
			</Flex> :
				<Flex alignItems={'flex-end'} justifyContent={'center'} mt={'50px'}  >
					<Image w={'610px'}  objectFit={'contain'} h={'auto'} src={PhoneMarkupMobile} />
				</Flex>
			}
		</Flex>
	);
};