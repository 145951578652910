import React from 'react';
import { Image, Text, Flex, Skeleton } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
// import { useGetAllBrandsQuery } from 'services';
import { GalleryLayout } from 'Layouts/GalleryLayout';
import { IBrand } from 'Types/Interface/IBrand';
import { AppRoutes } from 'Routes/AppRoutes';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';

interface IGalleryItemProps {
  items?: IBrand[];
  visibility: boolean;
  isLoading?: boolean;
}

export const BrandGalleryItems = ({
	isLoading,
	items,
	visibility,
}: IGalleryItemProps) => {
	const navigate = useLocalizedNavigate();

	const handleNavigate = (brandId?: string) => {
		brandId && navigate(AppRoutes.brand.replace(':brandId', brandId));

	};

	return (
		<GalleryLayout display={'block'}>
			{isLoading
				? new Array(10)
					.fill('_')
					.map((_, index) => <Skeleton mb={6} height="250px" key={index} />)
				: items?.map((item) => {
					return (
						<Flex
							key={item.id}
							direction={'column'}
							justifyContent={'center'}
						>
							<Flex
								direction={'column'}
								justifyContent={'flex-end'}
								alignItems={'flex-start'}
								onClick={() => handleNavigate(item.id)}
								cursor={'pointer'}
								h={['200px', null, null, '250px']}
								w="full"
								position="relative"
							>
								<Image
									src={item.imageUrl}
									boxSize="100%"
									objectFit="cover"
									rounded={4}
									filter={'auto'}
									brightness={'80%'}
									background={'#f9f9f9'}
								/>
								<Image
									src={item.logoUrl}
									position="absolute"
									m={[2, 5]}
									w={['2.5rem', '4rem']}
									h={['2.5rem', '4rem']}
									bg={'rgba(255, 255, 255, 0.8)'}
									borderRadius={'50%'}
									p={2}
								/>
							</Flex>
							<Flex alignItems={'center'} py={4}>
								<Text
									fontSize={{ base: 'sm', tablet: 'sm', xxl: '.9rem' }}
									fontWeight={'semibold'}
									cursor={'pointer'}
									textAlign="left"
									onClick={() => handleNavigate(item.id)}
								>
									{item.name}
								</Text>
								{visibility === true && (
									<ChevronRightIcon w={4} h={3} color={'gray.700'} />
								)}
							</Flex>
						</Flex>
					);
				})}
		</GalleryLayout>
	);
};
