import { Heading, HStack, Image, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, Text, Stack } from '@chakra-ui/react';
import LoopHeaderMockUp from 'Assets/Images/LoopHeaderMockUp.png';
import checkmark from 'Assets/svg/Checkmark.svg';
import React from 'react';

const brands = [
	'Unified customer data across all sales channels.',
	'Deeper customer insights for better marketing decisions.',
	'Increased customer loyalty and retention.'
];
const customers = [
	'Build a verified customer community from all channels.',
	'Collect actionable, unified data to inform your strategies.',
	'Foster long-term customer loyalty without relying on third-party platforms.'
];

export const Benefits = () => {
	return(
		<Stack direction={['column', null, null, 'column', 'row']} gap={['30px','100px']} >
			<Image objectFit={'cover'} w={['388px', 'auto', null, null, '620px']} h={['400px', 'auto', null, null, '610px']} borderRadius={['3px','5px']} src={LoopHeaderMockUp} />
			<VStack spacing={'30px'} align={'flex-start'} justify={'center'}>
				<Heading fontSize={['20px','40px']}>Key Benefits</Heading>
				<Tabs variant='soft-rounded' colorScheme={'blackAlpha'}>
					<TabList bg={'#ECEFF2'} w={['200px','236px']} h={'46px'} borderRadius={'20px'} alignItems={'center'} mb={['30px','40px']}  >
						<Tab  w={['74','96px']} h={['31px','36px']} fontSize={['12px','14px']} ml={'5px'}>Brands</Tab>
						<Tab  w={['74','96px']} h={['31px','36px']} fontSize={['12px','14px']}>Customers</Tab>
					</TabList>
					<TabPanels >
						<TabPanel p={2}>
							{
								brands.map((benefit, id) => {
									return(
										<HStack spacing={'4px'} w={['100%', null, null, null,'414px']} alignItems={'baseline'} key={benefit + id} mb={'30px'}>
											<Image pos={'relative'} top={'5px'} boxSize={'20px'} src={checkmark} />
											<Text color={'blackText.200'} fontSize={['16px','18px']}>{benefit}</Text>
										</HStack>
									);
								})
							}
						</TabPanel>
						<TabPanel p={2}>
							{
								customers.map((benefit, id) => {
									return(
										<HStack spacing={'4px'} w={['100%','414px']} alignItems={'flex-start'} key={benefit + id} mb={'30px'}>
											<Image pos={'relative'} top={'5px'} boxSize={'20px'} src={checkmark} />
											<Text color={'blackText.200'} fontSize={['16px','18px']}>{benefit}</Text>
										</HStack>
									);
								})
							}
						</TabPanel>
					</TabPanels>
				</Tabs>
			</VStack>
		</Stack>
	);
};