/* eslint-disable max-lines */
/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import {
	Flex,
	HStack,
	IconButton,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Box,
	TableContainer,
	Checkbox,
	MenuButton,
	MenuItem,
	MenuList,
	Menu,
	Icon,
} from '@chakra-ui/react';
import { Column, usePagination, useRowSelect, useTable } from 'react-table';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Loader } from './Loader';
import { BsThreeDots } from 'react-icons/bs';
import ErrorComponent from 'Components/ErrorComponent';


export interface IPaginationConfig {
	pageIndex: number;
	pageSize: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface IDataTableProps<T extends Object> {
	data: T[];
	columns: Column<T>[];
	onclickRow?: (data: T) => void;
	pageCount?: number;
	paginate?: (data: IPaginationConfig) => void;
	initialPageIndex?: number;
	pageSize?: number;
	isLoading?: boolean;
	isError?: boolean;
	showCheckboxes?: boolean;
	showMenu?: boolean;
	isMobile?: boolean;
	tableTopCaption?: React.ReactNode;
	tableBottomCaption?: React.ReactNode;
	cellPadding?: number;
	isFlexible?: boolean;
	bodyStyles?:React.CSSProperties;
	headerStyles?: React.CSSProperties;
	tableContainerStyles?: React.CSSProperties;
}

interface IIndeterminateCheckboxProps {
	indeterminate?: boolean;
	name?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCombinedRefs = (...refs: any[]): React.MutableRefObject<any> => {
	const targetRef = React.useRef();

	React.useEffect(() => {
		refs.forEach((ref) => {
			if (!ref) return;

			if (typeof ref === 'function') {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
};

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IIndeterminateCheckboxProps>(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef(null);
		const combinedRef = useCombinedRefs(ref, defaultRef);

		React.useEffect(() => {
			combinedRef.current.indeterminate = indeterminate;
		}, [combinedRef, indeterminate]);

		return (
			<>
				<Checkbox
					bgColor={'#ECEFF2'}
					border={'none'}
					borderRadius={'15px'}
					size='md'
					onClick={(e: React.MouseEvent<HTMLInputElement>) => {
						e.stopPropagation();
					}}
					ref={combinedRef}
					{...rest}
				/>
			</>
		);
	},
);

// eslint-disable-next-line @typescript-eslint/ban-types
export const DataTable = <T extends Object>({
	data,
	columns,
	onclickRow,
	pageCount: controlledPageCount,
	paginate,
	initialPageIndex,
	isLoading,
	isError,
	tableTopCaption,
	tableBottomCaption,
	pageSize: controlledPageSize,
	showCheckboxes = true,
	showMenu = true,
	isMobile = false,
	cellPadding = 5,
	isFlexible = false,
	headerStyles = {},
	bodyStyles = {},
	tableContainerStyles = {},
}: IDataTableProps<T>) => {
	const modifiedColumns = React.useMemo(() => {
		const cols = [...columns];

		if (showCheckboxes) {
			const idcolumn: Column<T> = {
				id: 'selection',
				// eslint-disable-next-line react/prop-types
				Header: ({ getToggleAllRowsSelectedProps }) => (
					<div>
						<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				Cell: ({ row }: { row: any }) => (
					<div>
						<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
					</div>
				),
			};

			cols.unshift(idcolumn);
		}
		return cols;
	}, [columns]);

	const tableInstance = useTable(
		{
			columns: modifiedColumns,
			data,
			initialState: { pageIndex: initialPageIndex || 0, pageSize: controlledPageSize || 20 },
			manualPagination: true,
			pageCount: controlledPageCount,
		},
		usePagination,
		useRowSelect,
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		gotoPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		state: { pageIndex, pageSize },
	} = tableInstance;

	useEffect(() => {
		if (paginate) {
			paginate({ pageIndex, pageSize });
		}
	}, [paginate, pageIndex, pageSize]);

	if (isError) {
		return <ErrorComponent height={['250px', null, '350px']}/>;
	}

	return (
		<Box overflowX='auto' pb='2' className='no-scrollbar'>
			{isMobile && (
				<TableContainer style={tableContainerStyles}>
					<Table {...getTableProps()} width='100%' size={'lg'}>
						{tableTopCaption}
						{tableBottomCaption}

						{isLoading ? (
							<Tbody>
								<Tr>
									<Td>
										<Loader />
									</Td>
								</Tr>
							</Tbody>
						) : (
							<Tbody {...getTableBodyProps()}>
								{rows.map((row) => {
									prepareRow(row);
									return (
										<Tr {...row.getRowProps()} cursor='pointer' onClick={() => onclickRow && onclickRow(row.original)}>
											{row.cells.map((cell, index) => {
												const isFirstCell = index === 0;
												const isLastCell = index === row.cells.length - 1;
												return (
													<Td
														{...cell.getCellProps()}
														whiteSpace={'nowrap'}
														color='#4B4D4F'
														fontSize='md'
														py={cellPadding}
														pl={isFirstCell ? '0' : cellPadding}
														pr={isLastCell ? '0' : cellPadding}
													>
														{cell.render('Cell')}
													</Td>
												);
											})}
										</Tr>
									);
								})}
							</Tbody>
						)}
					</Table>
				</TableContainer>
			)}
			{!isMobile && (
				<TableContainer border={'1px'} borderColor={'#ECEFF2'} borderRadius={'2px'} style={tableContainerStyles}>
					<Table {...getTableProps()} overflow='auto' width='100%' size={'lg'}>
						{tableTopCaption}
						{tableBottomCaption}

						<Thead>
							{
								// Loop over the header rows
								headerGroups.map((headerGroup) => (
									// Apply the header row props
									<Tr {...headerGroup.getHeaderGroupProps()}>
										{
											// Loop over the headers in each row
											headerGroup.headers.map((column) => (
												// Apply the header cell props
												<Th
													{...column.getHeaderProps()}
													whiteSpace={isFlexible ? 'normal' : 'nowrap'}
													color={'#939393'}
													bg={'loopGrey.200'}
													fontWeight={'400'}
													p={cellPadding}
													style={headerStyles as React.CSSProperties}
												>
													{
														// Render the header
														column.render('Header')
													}
												</Th>
											))
										}
										{showMenu && <Th></Th>}
									</Tr>
								))
							}
						</Thead>
						{isLoading ? (
							<Tbody>
								<Tr>
									{modifiedColumns.map((_, index) => (
										<Td key={index} whiteSpace='nowrap'>
											<Loader />
										</Td>
									))}
									{showMenu && (
										<Td>
											<Loader />
										</Td>
									)}
								</Tr>
							</Tbody>
						) : (
							<Tbody {...getTableBodyProps()}>
								{
									// Loop over the table rows
									rows.map((row) => {
										// Prepare the row for display
										prepareRow(row);
										return (
											// Apply the row props
											<Tr
												{...row.getRowProps()}
												cursor='pointer'
												onClick={() => onclickRow && onclickRow(row.original)}
											>
												{
													// Loop over the rows cells
													row.cells.map((cell) => {
														// Apply the cell props
														return (
															<Td
																{...cell.getCellProps()}
																whiteSpace={isFlexible ? 'normal' : 'nowrap'}
																color={'#4B4D4F'}
																fontSize='md'
																p={cellPadding}
																style={bodyStyles as React.CSSProperties}
															>
																{
																	// Render the cell contents
																	cell.render('Cell')
																}
															</Td>
														);
													})
												}
												{showMenu && (
													<Td isNumeric={true}>
														<Menu>
															<MenuButton>
																<Icon as={BsThreeDots} w={5} h={5} color='#BBBBBB' />
															</MenuButton>
															<MenuList minW={'100px'}>
																{/* MenuItems are not rendered unless Menu is open */}
																<MenuItem onClick={() => onclickRow && onclickRow(row.original)}>View</MenuItem>
																<MenuItem>Export</MenuItem>
															</MenuList>
														</Menu>
													</Td>
												)}
											</Tr>
										);
									})
								}
							</Tbody>
						)}
					</Table>
				</TableContainer>
			)}
			<Flex display={!pageOptions.length ? 'none' : 'flex'} justifyContent='center' mt={5}>
				<HStack spacing={4}>
					<IconButton
						aria-label='previous page'
						icon={<ArrowBackIcon w={4} h={4} />}
						onClick={() => gotoPage(pageIndex - 1)}
						disabled={!canPreviousPage}
					/>
					<HStack>
						<strong>{pageIndex + 1}</strong>
						<Text>out of</Text>
						<strong>{pageOptions.length}</strong>
					</HStack>
					<IconButton
						aria-label='next page'
						icon={<ArrowForwardIcon w={4} h={4} />}
						onClick={() => gotoPage(pageIndex + 1)}
						disabled={!canNextPage}
					/>
				</HStack>
			</Flex>
		</Box>
	);
};
