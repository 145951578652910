/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import StorageService from './storageService';

export const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API,
	prepareHeaders: (headers) => {
		const authToken = StorageService.getAccessToken();
		if (authToken) {
			headers.set('authorization', `Bearer ${authToken}`);
		}
		headers.set('content-type', 'application/json; charset=utf-8');

		const locale = StorageService.getLanguageOrDefault();

		if (locale) {
			headers.set('Accept-Language', locale);
		}

		const country = StorageService.getCountryUrlOrDefault();

		if (country) {
			headers.set('X-UI-Country', country);
		}

		return headers;
	},
});

export const getLocalizationHeader = (locale: string) => {
	return { 'Accept-Language': locale };
};
