import { useLocale } from 'context/LocalizationContext';
import { useDebounce } from 'Hooks';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { useSearchBrandAndProductsQuery } from 'services';
import { IBrand } from 'Types/Interface/IBrand';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { Autocomplete, IAutocompleteItem } from './Autocomplete';


export const SearchBrandsAndProducts = () => {
	const { locale } = useLocale();
	const navigate = useLocalizedNavigate();
	const location = useLocation();
	const [searchString, setSearchString] = useState('');
	const debounceQuery = useDebounce<string>(searchString, 1000);
	const { data, isSuccess, isLoading, isFetching, isError, error } = useSearchBrandAndProductsQuery({searchString: debounceQuery, locale}, {skip:!debounceQuery});
	useDefaultErrorHandler(isError, error);
	const [searchResult, setSearchResult] = useState<IAutocompleteItem[]>([]);

	useEffect(() => {
		if (isSuccess && data) {
			let results: IAutocompleteItem[] = [];
			if (location.pathname === '/all-brands') {
				results = data?.data?.brands?.map((item) => ({
					...item,
					value: item.id,
					label: item.name,
				})) as IAutocompleteItem[];
			} else if (location.pathname === '/all-products') {
				results = data?.data?.products?.map((item) => ({
					...item,
					value: item.id,
					label: item.name,
					imageUrl: item.images[0]?.imageUrl,
				})) as IAutocompleteItem[];
			} else {
				const brands = data?.data?.brands?.map((item) => ({
					...item,
					value: item.id,
					label: item.name,
				})) as IAutocompleteItem[];
				const products = data?.data?.products?.map((item) => ({
					...item,
					value: item.id,
					label: item.name,
					imageUrl: item.images[0]?.imageUrl,
				})) as IAutocompleteItem[];
				results = [...brands, ...products];
			}
			setSearchResult(results);
		}
	}, [data, isSuccess, location.pathname]);


	const handleSelect = (item: IAutocompleteItem) => {
		const selectedItem = item as unknown as IBrand;
		const isBrand = selectedItem?.products;
		if (isBrand) {
			navigate(AppRoutes.brand.replace(':brandId', item.value));
		} else {
			navigate(AppRoutes.productPage.replace(':productId', item.value));
		}
	};

	return (
		<Autocomplete
			onChange={setSearchString}
			inputlinkColorMode="light"
			hideToggleButton
			data={searchResult}
			isLoading={isLoading || isFetching}
			onSelect={handleSelect}
		/>
	);
};
