import React from 'react';
import { BrandPageLayout } from 'Layouts/BrandPageLayout';
import { BrandPageJumbotronSection } from 'Pages/BrandPage/sections/BrandPageJumbotron';
import { PurposeOnLoop } from './sections/PurposeOnLoop';
import { BrandDetails } from './sections/BrandDetails';
import { ProductCarousel } from 'Pages/ExplorePage/sections/ProductCarousel';
import { BrandSummary } from './sections/BrandSummary';
import { useGetBrandByIdQuery } from 'services';
import { useParams } from 'react-router-dom';
import { Flex, Spinner } from '@chakra-ui/react';
import ErrorCodes from 'Data/ErrorCodes';
import { useLocale } from 'context/LocalizationContext';

export const BrandPage = () => {
	const { locale } = useLocale();
	const location = useParams();
	const brandId = location?.brandId || '';
	const { data, isLoading, isError } = useGetBrandByIdQuery({brandId, locale});

	if (isError) {
		throw new Error(ErrorCodes.FetchBrand);
	}

	return (
		<>
			{isLoading ? (
				<Flex
					justifyContent={'center'}
					alignItems={'center'}
					padding='6'
					boxShadow='lg'
					bg='white'
					h={'100vh'}
					w={'full'}
				>
					<Spinner size='xl' color='darkCoral' />
				</Flex>
			) : (
				<BrandPageLayout JumbotronSection={<BrandPageJumbotronSection data={data} />} data={data}>
					<PurposeOnLoop data={data} />
					<BrandDetails data={data} shouldIgnoreParentPadding={true} />
					{!!data?.data.products?.length && (
						<ProductCarousel title={'Products on Loop'} products={data?.data?.products} />
					)}
					<BrandSummary
						// Brands={Brands}
						shouldIgnoreParentPadding={true}
						data={data}
					/>
					{/* {!!data?.data.products?.length && (
						<ProductCarousel title={`Products you've from ${data?.data?.name}`} products={data?.data?.products} />
					)} */}
				</BrandPageLayout>
			)}
		</>
	);
};
