import React, { useContext, useEffect, useMemo } from 'react';
import {
	Button,
	Flex,
	Heading,
	Text,
	VStack,
	Alert,
	AlertIcon,
	chakra,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { OTPInput } from 'Components/FormInput/OTPInput';
import { EmailIcon } from 'Components/Icons';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';
import {
	INewUserRequestbody,
} from 'Types/Interface/IUser';
import {
	useSendVerification,
	useToast,
} from 'Hooks';
import { AuthContext } from 'context/AuthContext';
import { IAuthenticateUserResponse } from 'Types/Interface/IAuthenticateUserResponse';
import { useCreateAccountWithPhoneNumberMutation } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useLocale } from 'context/LocalizationContext';

const validationSchema = Yup.object().shape({
	otp: Yup.string().min(6).max(6).required('OTP is required'),
});

export const Verification = () => {
	const { locale } = useLocale();
	const auth = useContext(AuthContext);

	const newUserDetails = JSON.parse(
		sessionStorage.getItem('registration_details') ?? JSON.stringify({})
	) as INewUserRequestbody;

	const { alert } = useToast();

	const {
		mutate: sendVerificationCode,
		isLoading: isSendingCode,
		isSuccess: isSendCodeSuccess,
		isError: isSendCodeError,
		error: sendCodeError
	} = useSendVerification();

	const [
		registerWithPhone,{
			data,
			isSuccess: isRegisterSuccess,
			isLoading: isRegisterLoading,
			isError: isRegisterError,
			error
		}
	] = useCreateAccountWithPhoneNumberMutation();
	const number = useMemo(() => newUserDetails.phoneNumber, [newUserDetails]);
	const navigate = useLocalizedNavigate();

	const { values, handleSubmit, errors, setFieldValue } = useFormik({
		initialValues: {
			otp: '',
		},
		validationSchema,
		onSubmit: (values) => {
			const registerUserRequestBody ={
				...newUserDetails,
				token: values.otp
			};
			registerWithPhone({ body: registerUserRequestBody, locale});
		},
	});

	const _handleSendCode = () => {
		if (newUserDetails.countryCode) {
			const data = {
				phoneNumber: newUserDetails.phoneNumber.toInternationalPhoneNumber(), //TODO : convert toInternationalPhoneNumber to a normal function
				countryCode: newUserDetails.countryCode,
			};
			sendVerificationCode(data);
		}
	};

	useDefaultErrorHandler(isSendCodeError, sendCodeError);

	useEffect(() => {
		if (isSendCodeSuccess) {
			alert('success', 'OTP was sent');
		}
	}, [isSendCodeSuccess]);

	useDefaultErrorHandler(isRegisterError, error);

	useEffect(() => {
		if (data || isRegisterSuccess) {
			auth.setAuthInfo(data?.data as IAuthenticateUserResponse);
			navigate(AppRoutes.success);
		}
	}, [data, isRegisterSuccess]);

	return (
		<Flex overflow={'hidden'} alignItems='center' justifyContent='center' py={20} p='10'>
			<form onSubmit={handleSubmit}>
				<VStack align={['flex-start', 'center']} spacing={8}>
					<EmailIcon boxSize={'50px'} />
					<Heading  fontWeight={600} fontSize={'22px'}>Check your sms</Heading>
					<Text color={'#121B26'} fontSize={'16px'} fontWeight={400} textAlign='left'>
            Please enter the 6-digits verification code sent to +{number}
					</Text>
					{errors.otp && (
						<Alert status='error'>
							<AlertIcon />
							{errors.otp}
						</Alert>
					)}
					<OTPInput
						value={values.otp}
						onChange={(value) => setFieldValue('otp', value)}
					/>
					<Button
						variant='ghost'
						// p='2'
						onClick={_handleSendCode}
						isLoading={isSendingCode}
						fontSize={'16px'}
					>
            Click here to <chakra.span color={'#FF6F61'}ml={1} textDecor={'underline'}> send new code</chakra.span>
					</Button>
					<Flex alignItems='center' >
						<Text fontSize={'16px'} color={'#525252'} fontWeight={500}>{number}{' '}</Text>
						<Button
							fontSize={'16px'}
							color='darkCoral'
							textDecor={'underline'}
							ml='2'
							onClick={() => navigate(AppRoutes.register)}
						>
              Edit
						</Button>
					</Flex>
					<Button
						type='submit'
						variant='primary'
						w={['335px', '100%']}
						h={['48px', 'auto']}
						isLoading={isRegisterLoading}
					>
            Submit
					</Button>
				</VStack>
			</form>
		</Flex>
	);
};
