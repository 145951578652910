/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Flex, Box, Heading, Text, VStack, Button, Link } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { Link as RLink } from 'react-router-dom';

import { AuthLayout } from 'Layouts/AuthLayout';
import { CustomInput } from 'Components/FormInput/CustomInput';
import { AppRoutes } from 'Routes/AppRoutes';
import { OTPInput } from 'Components/FormInput/OTPInput';
import { IResetPasswordDto } from 'Types/Interface/IUser';
import * as yup from 'yup';
import { useGetResetCodeMutation, useResetPasswordMutation } from 'services';
import { useToast } from 'Hooks';
import { PhoneNumberInput } from 'Components/FormInput/PhoneNumberInput';
import { PASSWORD_REGEX } from 'Pages/Dashboard/sections/AccountSettings/sections/LoginAndSecurity/components';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { useLocale } from 'context/LocalizationContext';
import { localizePath } from 'Utils';

enum Step {
	SEND_OTP = 1,
	VERIFY_OTP = 2,
	ENTER_NEW_PASSWORD = 3,
}

const validationSchema = yup.object().shape({
	newPassword: yup
		.string()
		.min(6, 'Password too short should be atleast 6 characters long')
		.matches(
			PASSWORD_REGEX,
			'Minimum six characters, at least one upper case English letter, one lower case English letter, one number and one special character',
		)
		.required('Password required'),
	confirmPassword: yup
		.string()
		.required('Password required')
		.when('newPassword', {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			is: (val: string | any[]) => (val && val.length > 0 ? true : false),
			then: yup.string().oneOf([yup.ref('newPassword')], 'password does not match'),
		}),
});

export const ResetPassword = () => {
	const { locale } = useLocale();
	const [currentStep, setCurrentStep] = useState<Step>(Step.SEND_OTP);
	const [resetPassword, { isLoading, isSuccess, isError, error }] = useResetPasswordMutation();

	const [
		getResetCode,
		{ isLoading: isLoadingResetCode, isSuccess: isResetCodeSuccess, isError: isResetCodeError, error: resetCodeError },
	] = useGetResetCodeMutation();
	const { alert } = useToast();
	const navigate = useLocalizedNavigate();

	const { handleChange, values, handleSubmit, setFieldValue, errors } = useFormik({
		initialValues: {
			phoneNumber: '',
			country: '',
			countryCode: '',
			otp: '',
			newPassword: '',
			confirmPassword: '',
		},
		validationSchema,
		onSubmit: (values) => {
			const data: IResetPasswordDto = {
				phoneNumber: values.phoneNumber,
				password: values.newPassword,
				code: values.otp,
				countryCode: values.countryCode,
			};
			resetPassword({ body: data, locale });
		},
	});

	const _handleSendCode = () => {
		const data = {
			phoneNumber: values.phoneNumber.toInternationalPhoneNumber(), //TODO : convert toInternationalPhoneNumber to a normal function
			countryCode: values.countryCode.toInternationalPhoneNumber(),
		};
		getResetCode({ body: data, locale });
	};

	useEffect(() => {
		if (isSuccess) {
			alert('success', 'password reset successfully');
			navigate(AppRoutes.login);
		}
	}, [isSuccess]);

	const _handleNextStep = (step: Step) => {
		setCurrentStep(step);
	};

	useEffect(() => {
		if (isResetCodeSuccess) {
			alert('success', `Token was sent to ${values.phoneNumber}`);
			_handleNextStep(Step.VERIFY_OTP);
		}
	}, [isResetCodeSuccess]);

	useDefaultErrorHandler(isError, error);
	useDefaultErrorHandler(isResetCodeError, resetCodeError);

	const _renderSendOtp = () => {
		return (
			<>
				<PhoneNumberInput
					value={values.phoneNumber}
					onChange={(phoneNumber, countryPhoneCode) => {
						setFieldValue('phoneNumber', phoneNumber);
						setFieldValue('countryCode', countryPhoneCode);
					}}
					error={errors.phoneNumber}
				/>
				<Button variant='primary' onClick={_handleSendCode} w='full' isLoading={isLoadingResetCode}>
					Continue
				</Button>
				<Link as={RLink} to={localizePath(AppRoutes.login)} ml='1' fontSize='sm' fontWeight='bold' color='darkCoral'>
					Return to sign in
				</Link>
			</>
		);
	};

	const _renderVerifyOtp = () => {
		return (
			<>
				<OTPInput value={values.otp} onChange={(value) => setFieldValue('otp', value)} />
				<Button variant='primary' w='full' onClick={() => _handleNextStep(Step.ENTER_NEW_PASSWORD)}>
					Submit
				</Button>
			</>
		);
	};

	const _renderEnterNewPassword = () => {
		return (
			<>
				<CustomInput
					onChange={handleChange}
					name='newPassword'
					type='password'
					placeholder='New password'
					error={errors.newPassword}
				/>
				<CustomInput
					onChange={handleChange}
					name='confirmPassword'
					type='password'
					placeholder='Confirm password'
					error={errors.newPassword}
				/>
				<Button variant='primary' w='full' onClick={() => handleSubmit()} isLoading={isLoading}>
					Change password
				</Button>
			</>
		);
	};

	const currentStepHeadings = {
		[Step.SEND_OTP]: {
			mainHeading: 'Reset your password',
			subHeading:
				"Enter the phone number associated with your account and we'll send you a code to reset your password.",
			render: _renderSendOtp,
		},
		[Step.VERIFY_OTP]: {
			mainHeading: 'Verify code',
			subHeading: `Please enter the 6-digits verification code sent to ${values.phoneNumber}`,
			render: _renderVerifyOtp,
		},
		[Step.ENTER_NEW_PASSWORD]: {
			mainHeading: 'Reset your password',
			subHeading: 'Please type in your new password',
			render: _renderEnterNewPassword,
		},
	};

	return (
		<AuthLayout>
			<Flex
				alignItems={['flex-start', 'center']}
				h='full'
				justifyContent={['flex-start', 'center']}
				bg='white'
				flex={1}
				p={['0', '10']}
			>
				<Box
					shadow={['none', 'lg']}
					px={['5', '10']}
					py='10'
					borderRadius='md'
					boxShadow={['none', '0px 0px 90px rgba(0, 0, 0, 0.1)']}
					w={['100%', '450px']}
				>
					<Heading alignSelf='flex-start' fontSize='xl' mb='4'>
						{currentStepHeadings[currentStep].mainHeading}
					</Heading>
					<Text mb='10'>{currentStepHeadings[currentStep].subHeading}</Text>
					<VStack spacing={8} mb='7'>
						{currentStepHeadings[currentStep].render()}
					</VStack>
				</Box>
			</Flex>
		</AuthLayout>
	);
};
