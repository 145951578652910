import { createApi } from '@reduxjs/toolkit/query/react';
import { IHistory, IUserActivityDetails } from 'Types/Interface/IHistory';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';
import { IPaginatedResponse } from './types';

export const historyApi = createApi({
	reducerPath: 'historyApi',
	baseQuery,
	endpoints: (builder) => ({
		getRewardHistory: builder.query<
			IPaginatedResponse<IHistory[]>,
			{ pageIndex: number; pageSize: number; locale: string }
		>({
			query: ({ pageIndex, pageSize, locale }) => ({
				url: `reward/history?pageNumber=${pageIndex}&pageSize=${pageSize}`,
				headers: getLocalizationHeader(locale),
			}),
		}),

		getUserActivityDetails: builder.query<IUserActivityDetails, {activityId: string; locale: string}>({
			query: ({activityId, locale}) => ({
				url: `/useractivity/details?id=${activityId}`,
				headers: getLocalizationHeader(locale),
			})
		})
	}),
});

export const { useGetRewardHistoryQuery, useGetUserActivityDetailsQuery } = historyApi;
