import React from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Text,
	VStack,
	BoxProps,
	useMediaQuery,
} from '@chakra-ui/react';
import { ChevronCircleDown } from 'Components/Icons/ChevronCircleDown';
import { ChevronCircleUp } from 'Components/Icons/ChevronCircleUp';
import { useAppSelector } from 'Hooks';
import { useDispatch } from 'react-redux';
import { useTheme } from '@chakra-ui/react';
import { setActiveDashboardTab } from 'store/slices';

const BalanceDetailsAccordion: React.FC = () => {
	const theme = useTheme();
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

	const dispatch = useDispatch();

	const { userSummary } = useAppSelector((state) => state.authUser);

	const pointsData = [
		{
			label: 'Total Points',
			value: `${((userSummary?.availableRewardPoints ?? 0) + (userSummary?.pendingRewardPoints ?? 0)).toLocaleString()} LNT`,
			borderLeftColor: theme.colors.darkCoral,
		},
		{
			label: 'Total Cleared',
			value: `${(userSummary?.availableRewardPoints ?? 0).toLocaleString()} LNT`,
			borderLeftColor: theme.colors.maxYellow,
		},
		{
			label: 'Pending Clearance',
			value: `${(userSummary?.pendingRewardPoints ?? 0).toLocaleString()} LNT`,
			borderLeftColor: theme.colors.green,
		},
	];

	const pointsLabelStyles: BoxProps = {
		py: '6px',
		pl: '14px',
		borderLeftWidth: '2px',
		borderBottom: 'none',
		color: 'loopGrey.500',
		fontWeight: '500',
		textTransform: 'uppercase',
		fontSize: '12px',
	};

	const pointsValueStyles: BoxProps = {
		pl: '0',
		pb: '20px',
		borderBottom: 'none',
		fontSize: '24px',
		fontWeight: '600',
		color: 'blackTextColor.100',
	};

	const navigateToActivities = () => {
		dispatch(setActiveDashboardTab(1));
	};

	return (
		<Accordion variant={'red'} allowMultiple pt={[4, null, 8]}>
			<AccordionItem>
				{({ isExpanded }) => (
					<>
						<AccordionButton>
							<Box textAlign='left' mr={'2'}>
								View {isExpanded ? 'less' : 'more'} details
							</Box>
							{isExpanded ? <ChevronCircleUp /> : <ChevronCircleDown />}
						</AccordionButton>
						<AccordionPanel mt={8}>
							{!isMobile && (
								<Table
									variant='simple'
									sx={{
										width: '100%',
										tableLayout: 'fixed',
										borderCollapse: 'collapse',
										th: pointsLabelStyles,
										td: pointsValueStyles,
									}}
								>
									<Thead>
										<Tr>
											{pointsData.map((item, index) => (
												<Th key={index} borderLeftColor={item.borderLeftColor}>
													{item.label}
												</Th>
											))}
										</Tr>
									</Thead>
									<Tbody>
										<Tr>
											{pointsData.map((item, index) => (
												<Td key={index}>{item.value}</Td>
											))}
										</Tr>
									</Tbody>
								</Table>
							)}

							{isMobile && (
								<VStack alignItems='left' spacing={2}>
									{pointsData.map((item, index) => (
										<React.Fragment key={index}>
											<Box borderLeftColor={item.borderLeftColor} {...pointsLabelStyles}>
												{item.label}
											</Box>
											<Text {...pointsValueStyles}>{item.value}</Text>
										</React.Fragment>
									))}
								</VStack>
							)}

							<Button
								color='loop.500'
								fontWeight={'normal'}
								_hover={{
									textDecoration: 'underline',
								}}
								onClick={navigateToActivities}
							>
								View all activities&nbsp;&nbsp;→
							</Button>
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		</Accordion>
	);
};

export default BalanceDetailsAccordion;
