import { Flex, Text, Box, IconButton, Icon, Divider, HStack, useDisclosure, useMediaQuery, theme } from '@chakra-ui/react';
import React, { useState } from 'react';
import { GrFacebookOption } from 'react-icons/gr';
import { AiFillInstagram } from 'react-icons/ai';
import { FaTiktok, FaTwitter } from 'react-icons/fa';
import { TermsModal } from 'Components/TermsAgreement';
import LocalizationSelector from 'Components/LocalizationSelector';

interface IFooterProps{
	isBusiness?: boolean
}

export const Footer = ({isBusiness}:IFooterProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [modalType, setModalType] = useState<'privacy' | 'terms'>('terms');
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

	return (
		<Box px={{ base: '8', lg: '14', '2xl': '80px' }}  pb={7}>
			<Divider
				borderColor={'#C3C5C7'}
				variant={'solid'}
				size={'1px'}
			/>
			<Flex
				direction={['column', null, null, 'row']}
				pt={[6]}
				mb={[7, 8, null, 5]}
				alignItems={'center'}
				justifyContent={'space-between'}>
				<Text mb={['20px', null]} textAlign={'center'} fontSize={['12px', '16px']}>All rights reserved - Feed the Loop &copy; {new Date().getFullYear()}</Text>
				<HStack mb={['20px', null]} spacing={['20px', '50px']} >
					<Text cursor={'pointer'} onClick={() => {
						setModalType('privacy');
						onOpen();
					}} 
					fontSize={['12px', '16px']}>
						Privacy
					</Text>
					<Text cursor={'pointer'} onClick={() => {
						setModalType('terms');
						onOpen();
					}}
					fontSize={['12px', '16px']}
					>
						Terms of use
					</Text>
				</HStack>
				<HStack spacing={'30px'} >
					<IconButton aria-label='facebook share' icon={<Icon as={GrFacebookOption} h={['14px', '20px']} w={['14px', '20px']} />} />
					<IconButton aria-label='insta share' icon={<Icon as={AiFillInstagram} h={['14px', '20px']} w={['14px', '20px']} />} />
					<IconButton aria-label='tiktok share' icon={<Icon as={FaTiktok} h={['14px', '20px']} w={['14px', '20px']} />} />
					<IconButton aria-label='twitter share' icon={<Icon as={FaTwitter} h={['14px', '20px']} w={['14px', '20px']} />} />
				</HStack>
			</Flex>
			<HStack
				justifyContent={{ base: 'center', lg: 'flex-start' }}
				width={'100%'}
			>
				<LocalizationSelector fontSize={isMobile ? '12px' : '16px'} direction='row' />
			</HStack>
			<TermsModal onAccept={onClose} isOpen={isOpen} onClose={onClose} showButtons={false} isBusiness={isBusiness} type={modalType} />
		</Box>
	);
};