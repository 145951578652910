import { useCallback } from 'react';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';

const useNavigation = () => {
	const navigate = useLocalizedNavigate();

	const navigateToBrandLoopDetails = useCallback(
		(brandId: string) => {
			navigate(AppRoutes.brandLoop.replace(':brandId', brandId));
		},
		[navigate],
	);

	const navigateToActivityDetails = useCallback(
		(Id: string) => {
			navigate(AppRoutes.activityDetails.replace(':Id', Id));
		},
		[navigate],
	);

	const navigateToProduct = useCallback(
		(productId: string) => {
			navigate(AppRoutes.productPage.replace(':productId', productId));
		},
		[navigate],
	);

	const navigateToWithdrawalHistoryTable = useCallback(
		() => navigate(AppRoutes.withdrawalHistory, { state: { title: 'Withdrawal history' } }),
		[navigate]	
	);

	return { navigateToBrandLoopDetails, navigateToProduct, navigateToWithdrawalHistoryTable, navigateToActivityDetails   };
};

export default useNavigation;
