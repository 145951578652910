import { Flex, Stack, Image, VStack, Heading, Text, Button, useMediaQuery, chakra } from '@chakra-ui/react';
import React from 'react';
import EarnPointToSpend from 'Assets/Images/EarnPointToSpend.png';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';

export const EarnToSpend = () => {
	const navigate  = useLocalizedNavigate();
	const [isLargerThan768] = useMediaQuery('(min-width: 900px)');
	return(
		<Flex direction={['column', null, null,  'row']}  justifyContent={'space-between'} >
			<Stack align={'start'} spacing={['30px','40px']} mt={['null','30px']}>
				<VStack align={'start'} spacing={['30px','20px']}>
					<Heading  fontSize={['20px','40px']} w={['full','500px']}>Build Relationships, Earn Rewards, <chakra.span color={'darkCoral'}>Shape the Future</chakra.span></Heading>
					{/* <Text fontSize={['18px', '20px']} fontWeight={600} color={'#2B2B2B;'}>Join a Community of Like-Minded Fans</Text> */}
					<Text fontSize={['16px','18px']} w={['auto', null, null, '480px', '80%']} >
						Loop lets you connect directly with the brands you already buy from, giving you a voice in their community. 
						Share your experiences, hear from the brand, and be part of something bigger—all while staying in control of what you share.
					</Text>
					
					{ !isLargerThan768 && <Image src={EarnPointToSpend} boxSize={'100%'}  objectFit={'contain'} />}
				</VStack>
				<VStack align={'start'} spacing={'20px'}>
					<Heading fontSize={['18px','20px']}>Earn Cash for Every Interaction.</Heading>
					<Text  fontSize={['16px','18px']} w={['auto', null, null, '480px', '80%']} >
						Get rewarded for every step of your journey with the brand. 
						From scanning a connector to sharing your thoughts, you’ll earn cash rewards that reflect your important thoughts, 
						helping brands you already buy from create better products for you and others.
					</Text>
				</VStack>
				<VStack align={'start'} spacing={'20px'}>
					<Heading fontSize={['18px','20px']}>Spend Your Rewards Anywhere</Heading>
					<Text fontSize={['16px','18px']} w={['auto', null, null, '480px', '80%']}>
						Your earnings on Loop are real cash with a direct value in USD.
						Withdraw them to your bank or PayPal account and spend them on anything, anywhere—no restrictions.
					</Text>
				</VStack>
				<Button variant={'secondary'} onClick={() => navigate(AppRoutes.register)} w={['150px','170px']} h={['50px','60px']}>Sign up now!</Button>
			</Stack>
			{isLargerThan768 && <Image 
				src={EarnPointToSpend} 
				boxSize={{ base: '100%', md: '100%', lg: '40%' }}
				mt={{md:'30px'}}
				objectFit="cover"
			/>}
		</Flex>
	);
};