import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import StorageService, { defaultUSACountry } from 'Utils/storageService';
import { ILocalizationCountry } from 'Types/Interface/ILocalizationCountry';
import { fetchCountryByUrl } from 'Utils';

interface LocalizationContextValues {
	country: ILocalizationCountry | null;
	locale: string;
	setLocale: (country: ILocalizationCountry, locale: string) => void;
}

const LocaleContext = createContext<LocalizationContextValues | undefined>(undefined);

export const LocalizationProvider: React.FC = ({ children }) => {
	const { country: urlCountry, locale: urlLocale } = useParams<{ country: string; locale: string }>();

	const [country, setCountry] = useState<ILocalizationCountry>(() => {
		return StorageService.getCountry() || defaultUSACountry;
	});
	const [locale, setLocale] = useState<string>(() => {
		return StorageService.getLanguage() || urlLocale || process.env.REACT_APP_DEFAULT_LANGUAGE || defaultUSACountry.defaultLanguage.code;
	});

	useEffect(() => {
		if (urlLocale && (urlLocale !== locale || !StorageService.getLanguage())) {
			setLocale(urlLocale);
			StorageService.setLanguage(urlLocale);
		}

		const initializeCountry = async () => {
			if (urlCountry && (country.url !== urlCountry || !StorageService.getCountry())) {
				const fetchedCountry = (await fetchCountryByUrl(urlCountry)) || defaultUSACountry;
				setCountry(fetchedCountry);
				StorageService.setCountry(fetchedCountry);
			}
		};

		initializeCountry();

	}, [urlCountry, urlLocale]);

	const updateLocale = (newCountry: ILocalizationCountry, newLocale: string) => {
		setCountry(newCountry);
		setLocale(newLocale);
		StorageService.setCountry(newCountry);
		StorageService.setLanguage(newLocale);
	};

	return (
		<LocaleContext.Provider value={{ country, locale, setLocale: updateLocale }}>
			{children}
		</LocaleContext.Provider>
	);
};

export const useLocale = () => {
	const values = useContext(LocaleContext);
	if (!values) {
		throw new Error('useLocale must be used within a LocaleProvider');
	}
	return values;
};
