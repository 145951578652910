import { Input } from '@chakra-ui/react';
import { useAppSelector } from 'Hooks';
import React from 'react';
import { IEditProfileDetail } from './FullNameEdit';

export const DateOfBirthEdit = ({ onChange, inputName }: IEditProfileDetail) => {
	const { userInfo } = useAppSelector((state) => state.authUser);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		onChange(inputName, value);
	};
	return (
		<Input
			placeholder="Date of Birth"
			type={'date'}
			defaultValue={userInfo?.dateOfBirth}
			name={inputName}
			onChange={handleChange}
		/>
	);
};
