import { configureStore } from '@reduxjs/toolkit';
import userInfoSlice from './slices/authUserSlice';
import surveySlice from './slices/surveySlice';
import dashboardSlice from './slices/dashboardSlice';
import userBrandPermissionsSlice from './slices/userBrandPermissionsSlice';

import {
	historyApi,
	productApi,
	userAPI,
	accountApi,
	surveyApi,
	localizationApi,
	brandApi,
	categoryApi,
	notificationsApi,
	tiliaApi,
	sanityApi,
} from 'services';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	blacklist: [
		historyApi.reducerPath,
		productApi.reducerPath,
		userAPI.reducerPath,
		accountApi.reducerPath,
		brandApi.reducerPath,
		surveyApi.reducerPath,
		categoryApi.reducerPath,
		notificationsApi.reducerPath,
		tiliaApi.reducerPath,
		sanityApi.reducerPath,
		localizationApi.reducerPath,
	],
};

const rootReducer = combineReducers({
	authUser: userInfoSlice,
	survey: surveySlice,
	dashboard: dashboardSlice,
	userBrandPermissions: userBrandPermissionsSlice,
	[historyApi.reducerPath]: historyApi.reducer,
	[productApi.reducerPath]: productApi.reducer,
	[userAPI.reducerPath]: userAPI.reducer,
	[brandApi.reducerPath]: brandApi.reducer,
	[accountApi.reducerPath]: accountApi.reducer,
	[surveyApi.reducerPath]: surveyApi.reducer,
	[categoryApi.reducerPath]: categoryApi.reducer,
	[notificationsApi.reducerPath]: notificationsApi.reducer,
	[tiliaApi.reducerPath]: tiliaApi.reducer,
	[sanityApi.reducerPath]: sanityApi.reducer,
	[localizationApi.reducerPath]: localizationApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(
			...[
				historyApi.middleware,
				productApi.middleware,
				userAPI.middleware,
				accountApi.middleware,
				surveyApi.middleware,
				brandApi.middleware,
				categoryApi.middleware,
				notificationsApi.middleware,
				tiliaApi.middleware,
				sanityApi.middleware,
				localizationApi.middleware,
			],
		),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
