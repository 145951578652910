import React, { useEffect, useRef } from 'react';
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react';

interface ICalendlyWidgetProps {
	shouldIgnoreParentPadding: boolean;
}
const CalendlyWidget = ({shouldIgnoreParentPadding}:ICalendlyWidgetProps) => {
	const calendlyRef = useRef(null);

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://assets.calendly.com/assets/external/widget.js';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<Stack gap={'30px'} w={'100vw'} h={'100%'} bg={'#264653'} align={['flex-start','center']} justify={['flex-start','center']} {...(shouldIgnoreParentPadding && { py: '50px', px: ['20px', null, null, '20']})} >
			<Flex direction={'column'} py={'30px'} justify={'center'} alignItems={['flex-start', 'center']}>
				<Text color={'white'} fontSize={['18px','24px']}  >Skip the line</Text>
				<Heading w={['319px', '100%']} fontWeight={600} color={'white'} fontSize={['24px','40px']} textAlign={['start','center']}>Schedule a call directly with us</Heading>
			</Flex>
			
			<Box
				ref={calendlyRef}
				className="calendly-inline-widget"
				data-url="https://calendly.com/brands-feedtheloop/30min?hide_gdpr_banner=1&text_color=264653&primary_color=264653"
				style={{ minWidth: '100%', height: '900px', borderRadius: '10px' }}
			/>
		</Stack>
	);
};

export default CalendlyWidget;