import React, { useState } from 'react';
import { MotionBox } from 'Pages/LandingPage/BusinessSection/BusinessSection';
import { CarouselContainer } from 'Pages/ExplorePage/Components/CarouselContainer';
import { OngoingSurvey } from './OngoingSurvey';
import { useGetOngoingSurveysQuery } from 'services/survey';
import { Box, Skeleton, useMediaQuery, Text, Stack } from '@chakra-ui/react';
import { SwipeableGalleryContainer } from 'Pages/LandingPage/Components/SwipeableGalleryContainer';
import ErrorComponent from 'Components/ErrorComponent';
import { useLocale } from 'context/LocalizationContext';

export const OngoingSurveyContainer = () => {
	const { locale } = useLocale();
	const [currentSlide, setcurrentSlide] = useState(0);
	const { data, isLoading, isError } = useGetOngoingSurveysQuery({locale});
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

	const onRight = () => {
		if (data?.data.length) {
			setcurrentSlide(currentSlide + 1);
		}
	};
	const onLeft = () => {
		setcurrentSlide(Math.max(currentSlide - 1, 0));
	};
	const sliderVariant = {
		initial: { x: 0 },
		animate: () => ({
			x: `-${currentSlide * 20}vw`,
			transition: {
				type: 'spring',
				stiffness: 250,
				damping: 30,
			},
		}),
	};

	if (isError) {
		return <ErrorComponent height={['250px', null, '300px']} />;
	}

	return (
		<Box py={6}>
			{isLoading && (
				<Stack direction="row" spacing={8}>
					{new Array(4)
						.fill('a')
						.map((_, index) =>
							isLargerThan768 ? (
								<Skeleton
									key={index}
									h={['250px', '250px', null, '300px']}
									w={['180px', '180px', null, 'full']}
								/>
							) : (
								<Skeleton
									key={index}
									h={['250px', '250px', null, '300px']}
									minW={['180px', '180px', null, 'full']}
								/>
							),
						)}
				</Stack>
			)}
			{isLargerThan768 && data?.data?.length ? (
				<CarouselContainer
					title="Continue survey"
					onLeft={onLeft}
					onRight={onRight}
					itemCount={data.data.length}
				>
					{data?.data.map((survey, index) => {
						return (
							<MotionBox
								w={['70vw', '20vw', '22vw', '22vw']}
								custom={index}
								key={index}
								variants={sliderVariant}
								initial="initial"
								animate="animate"
								mr={[0, 8, null, 5]}
							>
								<OngoingSurvey survey={survey} />
							</MotionBox>
						);
					})}
				</CarouselContainer>
			) : null}
			{!isLargerThan768 && !!data?.data?.length && (
				<>
					<Text
						fontSize={{ base: 'sm', tablet: 'md', xxl: '1.3rem' }}
						fontWeight={'600'}
						lineHeight={'32px'}
						py={4}
					>
            Continue survey
					</Text>
					<SwipeableGalleryContainer>
						{data?.data.map((survey, index) => {
							return (
								<MotionBox
									w={['48vw', '40vw', '20vw', '22vw', '22vw']}
									custom={index}
									key={index}
									variants={sliderVariant}
									initial="initial"
									animate="animate"
									mr={[6, 5]}
								>
									<OngoingSurvey survey={survey} />
								</MotionBox>
							);
						})}
					</SwipeableGalleryContainer>
				</>
			)}
		</Box>
	);
};
