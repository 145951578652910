import { LandingPageLayout } from 'Layouts/LandingPageLayout';
import { CTA } from 'Pages/LoopForBusiness/CTA';
import React from 'react';
import { IJumbotronContents } from 'Types/Interface/ILandingPage';
import { Benefits } from './Benefits';
import { Campaigns } from './Campaigns';
import { Jumbotron } from './Components/Jumbotron';
import { Connections } from './Connections';

const content:IJumbotronContents[] = [{
	heading : 'Connect then Engage',
	text: 'Follow four simple steps to connect, engage, and build lasting customer relationships..',
	subText: ''
}];

export const ProcessOverview = () => {
	return(
		<LandingPageLayout
			JumbotronSection={<Jumbotron contents={content}/>}
			paddingTop='0px'
			isBusiness
		>
			<Connections />
			<Campaigns />
			<Benefits/>
			<CTA shouldIgnoreParentPadding isBenefits/>
		</LandingPageLayout>
	);
};