import React from 'react';
import {
	Box,
	Flex,
	Heading,
	IconButton,
	Text,
	forwardRef,
	Spacer,
	HStack,
	VStack,
	Input,
	useMediaQuery,
} from '@chakra-ui/react';
import { BsDot } from 'react-icons/bs';
import { IUserPreferenceQuestion } from 'Types/Interface/IUserPreferenceQuestion';
import { Icon } from '@chakra-ui/icons';
import {DatePicker} from './DatePicker';


type StepItemLayoutPropsType = {
  data: IUserPreferenceQuestion;
  stepsLeft: number;
  onChange: (value: string | number) => void;
  value: string | number ;
  hideIcon?: boolean;
  activeColor?: string;
  isSurvey?: boolean;
};

export const StepItem = forwardRef<StepItemLayoutPropsType, 'div'>(
	(props, ref) => {
		const { data, stepsLeft, onChange, value, hideIcon, activeColor, isSurvey } = props;

		const _handleSelect = (value: string | number) => {
			onChange(value);
		};

		const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			onChange(value);
		};

		const handleDateChange = ( isoDate: string	) => {
			const value = isoDate;
			onChange(value);
		};

		const selectedColor = activeColor || 'blackTextColor.100';
		const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

		return (
			<Box ref={ref} w="100%">
				<Flex>
					{!hideIcon && (
						<Flex
							minW={[8, 10]}
							minH={[8, 10]}
							borderRadius="50%"
							borderWidth={1}
							borderColor="#264653"
							alignItems="center"
							justifyContent="center"
							mr={1}
						>
							{data.icon}
						</Flex>
					)}
					<HStack spacing={1} flexWrap="wrap">
						{[...new Array(stepsLeft)].map((_, index) => (
							<Icon
								key={index}
								color="#BCC2C8"
								w={[4, 6]}
								h={[4, 6]}
								as={BsDot}
							/>
						))}
					</HStack>
				</Flex>
				<Spacer h={8} />
				<Heading mb={['30px']} color="blackTextColor.200" fontWeight={'medium'} size="md">
					{data?.question}
				</Heading>
				<Spacer h={[5, 10]} />
				{isSurvey && !isLargerThan768 ? <VStack justify={'flex-start'} align={'flex-start'} spacing={'15px'}>
					{data?.options?.map((option) => (
						<Text 
							key={option.value} 
							onClick={() => _handleSelect(option.value)} 
							fontWeight={400} 
							fontSize={['sm', 'md']}
							color={value === option.value ? selectedColor : '#000000'}>
							{option.label}
						</Text>
					))}
				</VStack>
				: <Flex flexWrap="wrap">
					{data?.options?.map((option) => (
						<IconButton
							aria-label={option.label}
							key={option.value}
							icon={<Text>{option.label}</Text>}
							bg={value === option.value ? activeColor : '#E1E7EA'}
							color={value === option.value ? 'white' : 'blackTextColor.100'}
							p="2"
							px="3"
							fontWeight="light"
							borderRadius={100}
							mr={2}
							mb={2}
							onClick={() => _handleSelect(option.value)}
							_focus={{
								bg: selectedColor,
								color: 'white',
							}}
						/>
					))}
				</Flex>}
				{data?.question === 'When is your date of birth?' ? <Box >
					<DatePicker  onChange={handleDateChange} />
				</Box> : <VStack mb={[8, 16]}>
					{data?.inputFields?.map((field) => (
						<Input
							onChange={_handleChange}
							// value={value}
							variant="flushed"
							key={field}
							placeholder={field}
							autoFocus
						/>
					))}
				</VStack> 
				}
				
			</Box>
		);
	},
);
