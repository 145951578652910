import React from 'react';
import { InputName } from '../AccountDetailList';
import { FullNameEdit, GenderEdit, PhoneEdit, EmailEdit } from './Components';
import { DateOfBirthEdit } from './Components/DateOfBirthEdit';

export const renderEditForm = (
	inputName: InputName,
	onChange: (inputName: InputName, value: string | number, ...args: string[]) => void,
) => {
	switch (inputName) {
		case 'fullName':
			return <FullNameEdit inputName={inputName} onChange={onChange} />;
		case 'dateOfBirth':
			return <DateOfBirthEdit inputName={inputName} onChange={onChange} />;
		case 'gender':
			return <GenderEdit inputName={inputName} onChange={onChange} />;
		case 'phoneNumber':
			return <PhoneEdit inputName={inputName} onChange={onChange} />;
		case 'emailAddress':
			return <EmailEdit inputName={inputName} onChange={onChange} />;
		default:
			break;
	}
};
