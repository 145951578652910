import React, { useEffect } from 'react';
import {
	Box,
	Heading,
	HStack,
	Stack,
	Text,
	useRadioGroup,
} from '@chakra-ui/react';
import { CustomRadioButton } from '../../Pages/Survey/components/CustomRadioButton';
import { useAppSelector } from 'Hooks';
import { useDispatch } from 'react-redux';
import { setUserBrandPermissions } from 'store/slices';
import { IPrivacyLevelRewards } from 'Types/Interface';

export interface IPrivacySelectionProps {
	privacyLevelRewards: IPrivacyLevelRewards | undefined;
	onPrivacyLevelChange: (extraPoints: number | undefined, adjustedPoints: number | undefined) => void;
}

export const PrivacySelection = ({ privacyLevelRewards, onPrivacyLevelChange }: IPrivacySelectionProps) => {
	const dispatch = useDispatch();
	const permissions = useAppSelector((state) => state.userBrandPermissions.permissions);


	useEffect(() => {
		if (privacyLevelRewards && permissions?.privacyLevelId) {
			const initialPrivacyLevel = privacyLevelRewards.privacyLevels.find(
				({ id }) => id === permissions.privacyLevelId
			);
			if (initialPrivacyLevel?.extraPoints) {
				onPrivacyLevelChange(initialPrivacyLevel.extraPoints, initialPrivacyLevel.adjustedExtraPoints);
			} else {
				onPrivacyLevelChange(undefined, undefined);
			}
		}
	}, [privacyLevelRewards, permissions?.privacyLevelId, onPrivacyLevelChange]);

	const handleRadioChange = (value: string) => {
		if (permissions) {
			dispatch(setUserBrandPermissions({ ...permissions, privacyLevelId: value }));
		}

		const selectedPrivacyLevel = privacyLevelRewards?.privacyLevels.find(({ id }) => id === value);
		if (selectedPrivacyLevel?.extraPoints) {
			onPrivacyLevelChange(selectedPrivacyLevel.extraPoints, selectedPrivacyLevel.adjustedExtraPoints);
		} else {
			onPrivacyLevelChange(undefined, undefined);
		}
	};

	const { getRootProps, getRadioProps } = useRadioGroup({
		name: 'privacy',
		defaultValue: permissions?.privacyLevelId,
		onChange: handleRadioChange,
	});

	const group = getRootProps();

	return	(
		<Stack spacing={[4]}>
			{privacyLevelRewards && (
				<Stack spacing={4} {...group}>
					{privacyLevelRewards.privacyLevels.map(({ id, title, description, extraPoints, adjustedExtraPoints }) => {
						const radio = getRadioProps({ value: id });
						return (
							<CustomRadioButton key={id} {...radio} bg="white">
								<HStack justifyContent="space-between" alignItems="center" w="100%">
									<Box flex={1}>
										<Heading fontSize={['sm', 'lg']} fontWeight={500} mb={2}>
											{title}
										</Heading>
										<Text fontSize={['xs', 'sm']}>{description}</Text>
									</Box>
									{extraPoints && (
										<Box ml="auto">
											{extraPoints !== adjustedExtraPoints && (
												<Box
													as="span"
													fontSize={['sm', 'md']}
													color="loopGrey.300"
													textDecoration="line-through"
													mr={2}
												>
													+ {extraPoints} LNT
												</Box>
											)}
											<Box as="span" fontSize={['sm', 'md']} color="darkCoral" fontWeight="bold">
												+ {adjustedExtraPoints} LNT
											</Box>
										</Box>
									)}
								</HStack>
							</CustomRadioButton>
						);
					})}
				</Stack>
			)}
		</Stack>);
};
