import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'Hooks';
import { useGetSurveyByCodeQuery } from 'services';
import { Spinner, Flex, Box } from '@chakra-ui/react';
import { DynamicProductPageLayout } from 'Layouts/DynamicProductPageLayout';
import { DynamicProductPageContents } from './sections/DynamicProductPageContents';
import { Faq } from './sections/Faq';
import ErrorCodes from 'Data/ErrorCodes';
import { ISurvey } from 'Types/Interface/ISurvey';
import { useLocale } from 'context/LocalizationContext';

export const DynamicProductPage = () => {
	const { locale } = useLocale();
	const { qrCodeFromDynamicLink, webCodeFromEnterCode } = useAppSelector((state) => state.survey);
	const {
		data: surveyByCode,
		isLoading: isSurveyByCodeLoading,
		isSuccess: isSurveyByCodeSuccess,
		isError: isSurveyByCodeError,
	} = useGetSurveyByCodeQuery({code: webCodeFromEnterCode || qrCodeFromDynamicLink, locale});
	const [survey, setSurvey] = useState<ISurvey>();

	useEffect(() => {
		if (surveyByCode && isSurveyByCodeSuccess) {
			setSurvey(surveyByCode?.data);
		}
	}, [surveyByCode]);


	if (isSurveyByCodeError) {
		throw new Error(ErrorCodes.FetchSurveyById);
	}

	return (
		<Box>
			{isSurveyByCodeLoading ? (
				<Flex
					justifyContent={'center'}
					alignItems={'center'}
					// padding="6"
					boxShadow='lg'
					bg='white'
					h={'100vh'}
					w={'full'}
				>
					<Spinner size='xl' color='darkCoral' />
				</Flex>
			) : (
				<DynamicProductPageLayout JumbotronSection={<DynamicProductPageContents survey={survey} />}>
					<Faq />
				</DynamicProductPageLayout>
			)}
		</Box>
	);
};
