import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { SurveyContainer, SurveyDetails, SurveyQuestion } from './components';
import { CampaignType } from 'Types/Enums';
import { setSection, setUserCoords } from 'store/slices';
import { useGeolocation } from 'Hooks/useGeolocation';

export const NewSurvey = () => {
	const {
		activeSurvey: { currentQuestion: currentQuestionIndex, survey, responses },
		isStartSurvey,
		section,
	} = useAppSelector((state) => state.survey);

	const dispatch = useAppDispatch();	
	const coords = useGeolocation(true);

	const isNewSurvey = !responses || responses.length === 0;
	const currentQuestion = survey?.questions[currentQuestionIndex];

	useEffect(() => {
		if (survey.campaignType === CampaignType.brandLoopOnly && section === 'questions') {
			dispatch(setSection('brandSharingSettings'));
		}
	}, [section, survey.campaignType]);

	useEffect(() => {
		if (coords) {
			dispatch(setUserCoords(coords));
		}
	}, [coords, dispatch]);

	return (
		<SurveyContainer>
			{isNewSurvey && !isStartSurvey && survey.campaignType !== CampaignType.brandLoopOnly ? (
				<SurveyDetails />
			) : (
				<SurveyQuestion question={currentQuestion} />
			)}
		</SurveyContainer>
	);
};
