import React, { useState, useCallback } from 'react';
import { DataTable, IPaginationConfig } from 'Components/DataTable';
import { Column } from 'react-table';
import { useGetRewardHistoryQuery } from 'services';
import { IHistory } from 'Types/Interface/IHistory';
// import { CustomModal } from 'Components';
import { Box, useMediaQuery, VStack, Text, theme, Flex, Tabs, Input, Tab, TabIndicator, TabList, TabPanel, TabPanels, Icon } from '@chakra-ui/react';
// import { UserActivityModalContent } from './UserActivityModalContent';
import { TransactionStatus, UserActivityType } from 'Types/Enums';
import { formatDateLong, formatDateShort, getStatusDescription, TransactionStatusComponent } from 'Utils/dataTableUtils';
import { useLocale } from 'context/LocalizationContext';
import ErrorComponent from 'Components/ErrorComponent';
import { Withdraw } from 'Components/Icons/Withdraw';
import { UserScan } from 'Components/Icons/UserScan';
import { CustomerFeedbackIcon } from 'Components/Icons/CustomerFeedbackIcon';
import useNavigation from 'Utils/navigationUtils';
import { useAppSelector } from 'Hooks';


export const UserActivity = () => {
	const { locale } = useLocale();
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const [pageIndex, setPageIndex] = useState(1);
	const params = { pageIndex, pageSize: 10, locale };
	const { data, isLoading, isFetching, isError } = useGetRewardHistoryQuery(params);
	const [searchQuery, setSearchQuery] = useState('');
	const { navigateToActivityDetails } = useNavigation();
	const { userInfo } = useAppSelector((state) => state.authUser);

	// const searchFiltered = data?.data?.filter((item) =>
	// 	item?.productName?.toLowerCase().includes(searchQuery?.toLowerCase())
	// );

	const Withdrawal = data?.data?.filter((item) => [UserActivityType.Withdrawal].includes(item.type));
	
	const feedbacks = data?.data?.filter((item) => [UserActivityType.SurveyCompleted].includes(item.type));
	const scan = data?.data?.filter((item) => [UserActivityType.CodeScan, UserActivityType.RepeatCodeScan].includes(item.type));

	const newData = data?.data?.map((item) => ({
		...item,
		id: item.id,
	}));


	const activityIcons: Record<UserActivityType, React.ElementType> = {
		[UserActivityType.SurveyCompleted]: CustomerFeedbackIcon,
		[UserActivityType.Withdrawal]: Withdraw,
		[UserActivityType.CodeScan]: UserScan,
		[UserActivityType.RepeatCodeScan]: UserScan,
	};

	const columns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'dateCreated',
				Cell: (item) => <>{formatDateLong(item.row.original.dateCreated)}</>,
			},
			{
				Header: 'Brand',
				accessor: 'brandName',
			},
			{
				Header: 'Product',
				accessor: 'productName',
			},
			{
				Header: 'Type',
				accessor: 'type',
				Cell: (item) => <>{getStatusDescription(item.row.original.type)}</>,
			},
			{
				Header: 'Amount',
				accessor: 'points',
				Cell: (item) => <Box textAlign={'left'}>{item.row.original.points} LNT</Box>,
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: (item) => <TransactionStatusComponent status={adjustStatus(item.row.original.status)} />,
			},
		],
		[],
	);

	const mobileColumns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				accessor: 'dateCreated',
				Cell: (item) => {
					const rowData = item.row.original;
					const isWithdrawal = rowData.type === UserActivityType.Withdrawal;
					const activityType: UserActivityType = rowData.type;
					const ActivityIcon = activityIcons[activityType];

					return (
						<Flex alignItems={'center'}>
							<Flex justifyContent={'center'} align={'center'} mr={4} borderRadius={'7px'} w={'40px'} h={'40px'} bg={'loopGrey.50'}><Icon as={ActivityIcon} w={'20px'} height={'20px'}  /></Flex>
							<VStack align={'flex-start'} spacing={'3px'}>
								<Text fontSize='14px' w={'180px'} isTruncated>{isWithdrawal ? 'Withdrawal' : rowData.productName}</Text>
								<Box fontSize='12px' color='loopGrey.800'>
									By {rowData.brandName}
								</Box>
								<Box fontSize='12px' color='loopGrey.800'>
									{formatDateShort(item.row.original.dateCreated)}
								</Box>
							</VStack>
						</Flex>
					);
				},
			},
			{
				accessor: 'points',
				Cell: (item) => (
					<VStack alignItems='flex-end'>
						<Box fontWeight='400' fontSize={'14px'}>
							{item.row.original.points} LNT
						</Box>
						<TransactionStatusComponent status={adjustStatus(item.row.original.status)} />
					</VStack>
				),
			},
		],
		[],
	);

	/**
	 * Adjusts the transaction status to Pending for users with unverified emails.
	 * This reflects that transactions, which fail due to unverified emails, can proceed once verification is complete.
	 */
	const adjustStatus = (status: TransactionStatus | undefined): TransactionStatus | undefined => {
		if (status === TransactionStatus.Failed && !userInfo?.emailConfirmed) {
			return TransactionStatus.Pending;
		}
		return status;
	};

	const handleRowClick = (data: IHistory) => {
		navigateToActivityDetails(data.id || '');
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.target.value);
		setPageIndex(1); 
	};

	const paginate = useCallback(({ pageIndex }: IPaginationConfig) => {
		setPageIndex(pageIndex + 1);
	}, []);

	const renderDataTable = useCallback(
		(data) => (
			<DataTable
				data={data || []}
				columns={isMobile ? mobileColumns : columns}
				onclickRow={handleRowClick}
				pageCount={data?.totalNoOfPages}
				paginate={paginate}
				initialPageIndex={0}
				isLoading={isLoading || isFetching}
				isError={isError}
				pageSize={params.pageSize}
				isMobile={isMobile}
				showCheckboxes={false}
				showMenu={false}
			/>
		),
		[columns, mobileColumns, isLoading, isFetching, params.pageSize, handleRowClick]
	);

	return (
		<>
			<Tabs variant='unstyled'>
				<Flex direction={['column', 'row']} w={'100'} justifyContent={'space-between'} alignItems={['flex-start','center']}>	
					<TabList >
						<Tab fontSize={['12px', '18px']}>All</Tab>
						<Tab fontSize={['12px', '18px']}>Feedbacks</Tab>
						<Tab fontSize={['12px', '18px']}>Scan</Tab>
						<Tab fontSize={['12px', '18px']}>Withdrawal</Tab>
					</TabList>
					{!isMobile && <Input
						placeholder="Search activities..."
						value={searchQuery}
						onChange={handleSearch}
						w={'330px'}
						size="md"
						mb={'20px'}
					/>}
				</Flex>
				<TabIndicator mt={['-2.5px','-13px']}  height='2px'  bg={'darkCoral'} borderRadius='1px' />
				{isMobile  &&<Input
					placeholder="Search activities..."
					value={searchQuery}
					onChange={handleSearch}
					w={'100%'}
					mt={'15px'}
					borderRadius={'0.5px'}
					size="sm"
				/>}
				<TabPanels >
					<TabPanel p={0}>{isError ? <ErrorComponent height={['250px', null, '400px']} /> : renderDataTable(newData)}</TabPanel>
					<TabPanel p={0}>{isError ? <ErrorComponent height={['250px', null, '400px']} /> : renderDataTable(feedbacks)}</TabPanel>
					<TabPanel p={0}>{isError ? <ErrorComponent height={['250px', null, '400px']} /> : renderDataTable(scan)}</TabPanel>
					<TabPanel p={0}>{isError ? <ErrorComponent height={['250px', null, '400px']} /> : renderDataTable(Withdrawal)}</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
};
