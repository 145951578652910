import React from 'react';
import { Flex, Heading, Image, Stack, Box, Tag, Button, TagLeftIcon, TagLabel, Text, Spinner } from '@chakra-ui/react';
import loopIcon from 'Assets/svg/loopIcon2.svg';

import { useAppDispatch, useAppSelector } from 'Hooks';
import { BsPencil } from 'react-icons/bs';
import { setIsStartSurvey } from 'store/slices';
import { useGetAvailableSurveysQrCodesForProductQuery } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { useLocale } from 'context/LocalizationContext';
import { getPointsPerFeedback } from 'Utils/surveyUtils';
import PriceToggle from 'Components/PriceToggle';

export const SurveyDetails = () => {
	const {
		activeSurvey: { survey },
	} = useAppSelector((state) => state.survey);

	const dispatch = useAppDispatch();
	const { locale } = useLocale();

	const { product, questions, title } = survey;

	const {
		data: qrCodesForAvailableSurveys,
		isError: isAvailableSurveysError,
		error: availableSurveysError,
		isLoading: isAvailableSurveysLoading
	} = useGetAvailableSurveysQrCodesForProductQuery({ productId: product.id, locale });

	useDefaultErrorHandler(isAvailableSurveysError, availableSurveysError);

	const handleStartSurvey = () => {
		dispatch(setIsStartSurvey(true));
	};

	return (
		<Stack h={'100%'} alignItems='center' justifyContent={'center'}>
			<Image src={product?.brand?.logoUrl} objectFit='contain' boxSize={['60px', '80px']} mb={'30px'}/>
			<Heading 
				color={'#2B2B2B'} 
				fontSize={['20px', '40px']} 
				w={['285px','634px']} 
				h={['50px', '114px']} 
				textAlign={'center'} 
				fontWeight={600}
			>
				{title}
			</Heading>
			<Box h={['20px', '30px']} />
			<Text fontSize={['16px','20px']} fontWeight={500} color={'loopGrey.500'} pb={['20px','30px']}>Complete and Earn</Text>
			<Flex alignItems='center'>
				<Image src={loopIcon} boxSize={'35px'} mr={'12px'} />
				<Heading color={'darkCoral'} fontSize={['20px', '30px']}>
					<PriceToggle value={getPointsPerFeedback(survey)} />
				</Heading>
			</Flex>
			<Box h={['40', '70px']} />
			<Flex direction={['column-reverse', 'column']} alignItems={'center'}>
				{ isAvailableSurveysLoading ? <Flex justifyContent={'center'} align={'center'}><Spinner display={'flex'} color='darkCoral' /></Flex> : qrCodesForAvailableSurveys && qrCodesForAvailableSurveys.data.length > 0 && (
					<Button
						variant='primary'
						borderRadius={'5px'}
						px={20}
						w={['315px']}
						h={['50px']}
						onClick={handleStartSurvey}
						mb={['100px']}
					>
						Start Survey
					</Button>
				)}
				{/* <Box h='8' /> */}
				{qrCodesForAvailableSurveys && qrCodesForAvailableSurveys.data.length > 0 && questions?.length > 0 && (
					<Tag mb={['40px']} borderRadius={'40px'} w={'143px'} h={'36px'} alignItems={'center'} p={4}>
						<TagLeftIcon boxSize='12px' as={BsPencil} />
						<TagLabel>{questions?.length} Questions</TagLabel>
					</Tag>
				)}
			</Flex>
		</Stack>
	);
};
