import { useEffect } from 'react';
import { useToast } from 'Hooks';
import { useAppDispatch } from 'Hooks';
import { useScanCodeMutation } from 'services';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { AppRoutes } from 'Routes/AppRoutes';
import { setCodeScanData } from 'store/slices';
import { CodeType } from 'Types/Enums';
import { getErrorMessage } from 'Utils/errorHandling';
import { useLocale } from 'context/LocalizationContext';
import { useGeolocation } from './useGeolocation';

interface ICodeScanProps {
	onScanComplete: () => void;
	onError?: (errorMessage: string) => void;
}

export const useCodeScan = ({ onScanComplete, onError }: ICodeScanProps) => {
	const dispatch = useAppDispatch();
	const navigate = useLocalizedNavigate();
	const {locale} = useLocale();
	const { alert } = useToast();

	const geolocation = useGeolocation(true);

	const [scanCode, { data: codeScanResponse, isSuccess, isLoading, isError, error }] = useScanCodeMutation();

	const handleScan = (code: string, codeType: CodeType) => {
		scanCode({code, codeType, geolocation, locale});
	};

	useEffect(() => {
		if (isSuccess) {
			if (!isError) {
				const successMessage = codeScanResponse?.message;
				alert('success', successMessage || 'Code has been scanned successfully');
			}
			onScanComplete();
		}
	}, [isSuccess, isLoading]);

	useEffect(() => {
		if (isError) {
			const errorMessage = getErrorMessage(error);
			
			if (onError) {
				// don't show alert in this case
				onError(errorMessage);
			} else {
				alert('error', errorMessage);
			}
		}
	}, [isError, error]);

	useEffect(() => {
		if (codeScanResponse) {
			dispatch(setCodeScanData(codeScanResponse?.data));
			const productId = codeScanResponse?.data?.productId;
			setTimeout(() => {
				navigate(AppRoutes.productPage.replace(':productId', productId));
			}, 500);
		}
	}, [codeScanResponse]);

	return { handleScan, isLoading };
};
