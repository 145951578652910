import React from 'react';
import {Heading, Skeleton, Stack, Text, VStack } from '@chakra-ui/react';

export interface ISummaryItemProps {
  title: string;
  value: number | string;
  icon?: React.ReactNode;
  isLoading?: boolean;
  fontSize?: string;
}

export const SummaryItem = ({
	title,
	value,
	icon,
	isLoading,
	fontSize
}: ISummaryItemProps) => {
	return (
		<Stack direction='row'>
			<VStack justify={'center'} alignItems={['center', 'center', 'flex-start']} spacing={[2, 4]}>
				{icon}
				<Text fontSize={['8px', '13px']} whiteSpace='nowrap' color={'loopGrey.500'} textTransform={'uppercase'}>
					{title}
				</Text>
				{isLoading ? (
					<Skeleton width={['80px', '150px']} height={['30px', '40px']} />
				) : (
					<Heading
						fontSize={{ base: fontSize, lg: '30px' }}
						fontWeight={'bold'}
						color={'blackTextColor.200'}
						whiteSpace='nowrap'
					>
						{value}
					</Heading>
				)}
			</VStack>
		</Stack>
	);
};
