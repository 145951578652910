import { ILocalizationCountry } from 'Types/Interface/ILocalizationCountry';
import { StorageKeys } from './storageKeys';

export const defaultUSACountry: ILocalizationCountry = {
	id: 'USA',
	name: 'United States',
	code: 'USA',
	codeIsoAlpha2: 'US',
	currency: 'USD',
	exchangeRateToUsd: 1,
	url: 'us',
	phoneCode: '+1',
	defaultLanguage: { id: '', code: 'en', name: 'English' },
	languages: [{ id: '', code: 'en', name: 'English' }],
};

class StorageService {
	// Country
	static getCountry(): ILocalizationCountry | null {
		try {
			const countryData = localStorage.getItem(StorageKeys.Country);
			return countryData ? (JSON.parse(countryData) as ILocalizationCountry) : null;
		} catch (error) {
			console.error('Failed to parse country data from localStorage:', error);
			return null;
		}
	}

	static getCountryOrDefault = (): ILocalizationCountry => StorageService.getCountry() ?? defaultUSACountry;

	static setCountry(country: ILocalizationCountry): void {
		localStorage.setItem(StorageKeys.Country, JSON.stringify(country));
	}

	// Country url
	static getCountryUrl(): string | null {
		return StorageService.getCountry()?.url ?? null;
	}

	static getCountryUrlOrDefault = () =>
		StorageService.getCountryUrl() ?? process.env.REACT_APP_DEFAULT_COUNTRY ?? defaultUSACountry.url;

	// Language
	static getLanguage(): string | null {
		return localStorage.getItem(StorageKeys.Language);
	}

	static getLanguageOrDefault = () =>
		StorageService.getLanguage() ?? process.env.REACT_APP_DEFAULT_LANGUAGE ?? defaultUSACountry.defaultLanguage.code;

	static setLanguage(code: string): void {
		localStorage.setItem(StorageKeys.Language, code);
	}

	// Access token
	static getAccessToken(): string | null {
		return localStorage.getItem(StorageKeys.AccessToken);
	}

	static setAccessToken(code: string): void {
		localStorage.setItem(StorageKeys.AccessToken, code);
	}
}

export default StorageService;
