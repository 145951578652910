/* eslint-disable max-lines */
import React, { useEffect, useState} from 'react';
import { Box, Button, Flex, Heading, HStack, IconButton, Image, Stack, Text } from '@chakra-ui/react';
import { IAddFeedbackDto, IQuestion } from 'Types/Interface/ISurvey';
import EnterIcon from 'Assets/svg/enter-key.svg';
import { CampaignType, SurveyQuestionType } from 'Types/Enums';
import { FreeText, MultiSelect, SingleSelect, StarRating } from './QuestionComponents';
import { FormikErrors, useFormik } from 'formik';
import { useAppSelector, useAppDispatch, usePressEnter } from 'Hooks';
import { SurveyCompleted } from './SurveyCompleted';
import {
	navigateSurvey,
	setIsSurveyComplete,
	setIsCurrentAnswerValid,
	setSection,
	setQrCodeFromDynamicLink,
	setWebCodeFromEnterCode,
	setActiveSurvey,
	setFeedbackPoints,
} from 'store/slices';
import { useUpdateFeedbackMutation } from 'services';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { SurveyBrandLoop } from './SurveyBrandLoop';
import { isLastSurveySection } from 'Utils/surveyUtils';
import { useLocale } from 'context/LocalizationContext';

interface ISurveyQuestionProps {
	question: IQuestion;
	ratingValue?: number;
}

interface IFormValues {
	freeText: string;
	ratingValue: number;
	singleSelect: string;
	multiSelect: string[];
}

export const SurveyQuestion = ({ question }: ISurveyQuestionProps) => {
	const { locale } = useLocale();
	const dispatch = useAppDispatch();

	const {
		activeSurvey,
		activeSurvey: { currentQuestion, survey, responses, surveyQRWebCodeId },
		section,
		surveyCode,
		codeType,
	} = useAppSelector((state) => state.survey);
	const { userGeolocation } = useAppSelector((state) => state.authUser);
	const userBrandPermissions = useAppSelector((state) => state.userBrandPermissions.permissions);

	const isLastSection = isLastSurveySection();
	const isLastQuestion = currentQuestion + 1 >= survey.questions.length;
	const isSurveyComplete = isLastQuestion && isLastSection;

	const [addFeedback, { isLoading, isError, error, isSuccess: isSuccessAddingFeedback, data }] =
		useUpdateFeedbackMutation();

	const [isAllSurveyDataFilled, setIsAllSurveyDataFilled] = useState(false);

	const { handleChange, setFieldValue, values, errors, isValid, isSubmitting, handleSubmit, resetForm } =
		useFormik<IFormValues>({
			initialValues: {
				freeText: '',
				singleSelect: '',
				ratingValue: 0,
				multiSelect: [],
			},
			validateOnMount: true,
			validate: (values) => {
				const errors: FormikErrors<IFormValues> = {};
				const requiredMessage = '* This answer is required';

				if (question && section === 'questions') {
					if (
						question.questionType === SurveyQuestionType.FreeText &&
						(!values.freeText || values.freeText.length === 0)
					) {
						errors.freeText = requiredMessage;
					}

					if (question.questionType === SurveyQuestionType.Rating && !values.ratingValue) {
						errors.ratingValue = requiredMessage;
					}

					if (
						question.questionType === SurveyQuestionType.SingleSelection &&
						(!values.singleSelect || values.singleSelect.length === 0)
					) {
						errors.singleSelect = requiredMessage;
					}

					if (
						question.questionType === SurveyQuestionType.MultipleSelection &&
						(!values.multiSelect || values.multiSelect.length === 0)
					) {
						errors.multiSelect = requiredMessage;
					}
				}

				dispatch(setIsCurrentAnswerValid(Object.keys(errors).length === 0));
				return errors;
			},
			onSubmit: (values, { setSubmitting }) => {
				const feedback: IAddFeedbackDto = {
					isSurveyComplete: isSurveyComplete,
					code: surveyCode,
					currentQuestion: currentQuestion < survey.questions.length - 1 ? currentQuestion + 1 : currentQuestion,
					location: userGeolocation?.data ?? null,
					codeType: codeType,
					surveyQRWebCodeId: surveyQRWebCodeId,
					brandPermission: userBrandPermissions,
					responses: [],
				};

				if (survey.campaignType !== CampaignType.brandLoopOnly) {
					const surveyResponse = {
						[SurveyQuestionType.FreeText]: {
							questionId: question.id,
							stringValue: values.freeText,
						},
						[SurveyQuestionType.Rating]: {
							questionId: question.id,
							ratingValue: values.ratingValue,
						},
						[SurveyQuestionType.SingleSelection]: {
							questionId: question.id,
							selectedOptionValues: [values.singleSelect],
						},
						[SurveyQuestionType.MultipleSelection]: {
							questionId: question.id,
							selectedOptionValues: values.multiSelect,
						},
					};

					feedback.responses = [surveyResponse[question.questionType]];
				}

				if (survey.campaignType === CampaignType.feedbackOnly) {
					feedback.brandPermission = undefined;
				}
				
				// Add feedback if user has answered a question or completed a survey
				if (section === 'questions') {
					// still answering questions
					addFeedback({ feedback, locale }).then(() => {
						const updatedResponses = [...responses];
						updatedResponses[currentQuestion] = {
							...updatedResponses[currentQuestion],
							stringValue: values.freeText,
							ratingValue: values.ratingValue,
							selectedOptionValues:
								question.questionType === SurveyQuestionType.SingleSelection
									? [{ id: values.singleSelect }]
									: values.multiSelect.map((value) => ({ id: value })),
						};
						dispatch(setActiveSurvey({ ...activeSurvey, responses: updatedResponses }));

						if (isLastQuestion && !isLastSection) {
							dispatch(setSection('brandSharingSettings'));
						}
					});
				} else if (isSurveyComplete) {
					addFeedback({ feedback, locale });
				}

				if (isSurveyComplete) {
					setIsAllSurveyDataFilled(true);
					dispatch(setQrCodeFromDynamicLink(''));
					dispatch(setWebCodeFromEnterCode(''));
				}

				setSubmitting(false);
			},
		});

	usePressEnter(handleSubmit);

	const errorComponent = !question ? undefined : (
		<Box color={'kyc.red'} textAlign={'right'} className='error'>
			<>
				{question.questionType === SurveyQuestionType.FreeText && <>{errors.freeText}</>}
				{question.questionType === SurveyQuestionType.SingleSelection && <>{errors.singleSelect}</>}
				{question.questionType === SurveyQuestionType.MultipleSelection && <>{errors.multiSelect}</>}
				{question.questionType === SurveyQuestionType.Rating && <>{errors.ratingValue}</>}
			</>
		</Box>
	);

	const questionComponent = !question
		? undefined
		: {
			[SurveyQuestionType.FreeText]: <FreeText onChange={handleChange} name={'freeText'} value={values.freeText} />,
			[SurveyQuestionType.Rating]: (
				<StarRating
					onChange={(ratingValue) => setFieldValue('ratingValue', ratingValue)}
					value={values.ratingValue}
					options={question.questionOptions}
				/>
			),
			[SurveyQuestionType.SingleSelection]: (
				<SingleSelect
					questionOptions={question.questionOptions}
					onChange={(id) => setFieldValue('singleSelect', id)}
					selectedValue={values.singleSelect}
				/>
			),
			[SurveyQuestionType.MultipleSelection]: (
				<MultiSelect
					questionOptions={question.questionOptions}
					onChange={(ids) => setFieldValue('multiSelect', ids)}
					value={values.multiSelect}
				/>
			),
		};

	useEffect(() => {
		if (isSuccessAddingFeedback) {
			resetForm();
			const index = Math.min(currentQuestion + 1, survey.questions.length - 1);
			dispatch(navigateSurvey({ index, dir: 'NEXT' }));
		}
		if (isAllSurveyDataFilled && isSuccessAddingFeedback) {
			dispatch(setFeedbackPoints(data ?? 0));
			dispatch(setIsSurveyComplete(true));
		}
	}, [isSuccessAddingFeedback, isAllSurveyDataFilled]);

	useDefaultErrorHandler(isError, error);

	useEffect(() => {
		if (!question) return;

		const currentResponse = responses[currentQuestion];

		if (question.questionType === SurveyQuestionType.FreeText) {
			setFieldValue('freeText', currentResponse?.stringValue || '');
		} else if (question.questionType === SurveyQuestionType.SingleSelection) {
			setFieldValue('singleSelect', currentResponse?.selectedOptionValues?.[0]?.id || '');
		} else if (question.questionType === SurveyQuestionType.MultipleSelection) {
			const result = currentResponse?.selectedOptionValues?.map((value) => value?.id) || [];
			setFieldValue('multiSelect', result);
		} else if (question.questionType === SurveyQuestionType.Rating) {
			setFieldValue('ratingValue', currentResponse?.ratingValue || 0);
		}
	}, [currentQuestion, activeSurvey, responses]);

	const isCompleteSurvey = isAllSurveyDataFilled && isSuccessAddingFeedback;
	return (
		<Box w={['90%', '90%', '650px', '650px']}>
			{isCompleteSurvey ? (
				<SurveyCompleted />
			) : (
				<Box mb={['10']}>
					<Stack spacing={8}>
						{section === 'brandSharingSettings' ? (
							<SurveyBrandLoop 
								brand={activeSurvey.survey.product.brand} 
								onSubmit={()=>{handleSubmit();}} 
								isLoading={isLoading} />
						) : (
							questionComponent && (
								<>
									<Text mt={['70px', '60px']} color={'blackTextColor.100'} w={'auto'} fontSize={['20px', '30px']} fontWeight={[400, 500]}>
										{question.title}
									</Text>
									<Box>{questionComponent[question.questionType]}</Box>
									{errorComponent}
								</>
							)
						)}

						{section !== 'brandSharingSettings' && (
							<HStack spacing={4} display={['none', 'flex']}>
								<Button 
									isLoading={isLoading} 
									disabled={!isValid || isSubmitting} 
									onClick={() => handleSubmit()} 
									variant='primary'>
									{isSurveyComplete ? 'Finish' : 'Next'}
								</Button>
								<HStack>
									<Text>or press enter </Text>
									<Image src={EnterIcon} />
								</HStack>
							</HStack>
						)}

						{section !== 'brandSharingSettings' && (
							<Flex alignSelf={'flex-end'} display={['flex', 'none']}>
								<IconButton
									aria-label={'next step'}
									fontSize={'35px'}
									icon={<ChevronRightIcon color={'white'} />}
									isRound
									bg={'loop.500'}
									size={'lg'}
									isLoading={isLoading}
									onClick={() => handleSubmit()}
								></IconButton>
							</Flex>
						)}
					</Stack>
				</Box>
			)}
		</Box>
	);
};
