import { createApi } from '@reduxjs/toolkit/query/react';
import { IBrand, IBrandHistory } from 'Types/Interface/IBrand';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { IPaginatedResponse, ISearchBrandAndProductResult } from './types';
import { IPrivacyLevelRewards } from 'Types/Interface';

export const brandApi = createApi({
	reducerPath: 'brandApi',
	baseQuery,
	endpoints: (builder) => ({
		getBrandById: builder.query<IJsonResponse<IBrand>, { brandId: string; locale: string }>({
			query: ({ brandId, locale }) => ({
				url: `brand/GetByBrandId/?brandId=${brandId}`,
				headers: getLocalizationHeader(locale),
			}),
		}),
		getAllBrands: builder.query<
			IPaginatedResponse<IBrand[]>,
			{ pageNumber?: number; pageSize?: number; categoryId?: string; locale: string }
		>({
			query: ({ pageNumber, pageSize, categoryId, locale }) => ({
				url: `brand/GetAllBrandsAsync?categoryId=${categoryId}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
				headers: getLocalizationHeader(locale),
			}),
		}),
		getBrandByCategory: builder.query<IPaginatedResponse<IBrand[]>, { categoryId: string; locale: string }>({
			query: ({ categoryId, locale }) => ({
				url: `categories/${categoryId}/brands`,
				headers: getLocalizationHeader(locale),
			}),
		}),
		searchBrandAndProducts: builder.query<
			IJsonResponse<ISearchBrandAndProductResult>,
			{ searchString: string; locale: string }
		>({
			query: ({ searchString, locale }) => ({
				url: '/search/getBrandsAndProducts',
				params: { searchString },
				headers: getLocalizationHeader(locale),
			}),
		}),
		getBrandScanHistory: builder.query<IJsonResponse<IBrandHistory>, { brandId: string; locale: string }>({
			query: ({ brandId, locale }) => ({
				url: `survey/${brandId}/brandscanhistory`,
				headers: getLocalizationHeader(locale),
			}),
		}),
		getPrivacyLevels: builder.query<IPrivacyLevelRewards, { surveyId: string | undefined; locale: string }>({
			query: ({ surveyId, locale }) => ({
				url: `privacy/rewards?surveyId=${surveyId || ''}`,
				headers: getLocalizationHeader(locale),
			}),
		}),
	}),
});

export const {
	useGetBrandByIdQuery,
	useGetAllBrandsQuery,
	useGetBrandByCategoryQuery,
	useSearchBrandAndProductsQuery,
	useGetBrandScanHistoryQuery,
	useGetPrivacyLevelsQuery,
} = brandApi;
