import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

type PhoneNumberInputPropType = {
	value: string;
	onChange: (phoneNumber: string, countryPhoneCode: string) => void;
	error?: string;
};

// copied from 'react-phone-input-2', because they don't export this interface
interface CountryData {
	name: string;
	dialCode: string;
	countryCode: string;
	format: string;
}

export const PhoneNumberInput = ({ value, onChange, error }: PhoneNumberInputPropType) => {
	return (
		<FormControl isInvalid={!!error}>
			<PhoneInput
				enableSearch
				countryCodeEditable={false}
				country={'us'}
				value={value}
				onChange={(phoneNumber, country) => {
					onChange(phoneNumber, (country as CountryData)?.dialCode);
				}}
				containerClass='phone-input-container'
				inputClass='phone-input-field'
				buttonClass='phone-input-button'
				searchClass='phone-input-search'
			/>
			{!!error && <FormErrorMessage fontSize='xs'>{error}</FormErrorMessage>}
		</FormControl>
	);
};
