import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, Heading, SimpleGrid, Text, VStack, chakra, Divider,Stack } from '@chakra-ui/react';
import React from 'react';
import { AppRoutes } from 'Routes/AppRoutes';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';


interface IServicesProps{
	shouldIgnoreParentPadding: boolean;
}

export const Services = ({shouldIgnoreParentPadding}:IServicesProps) => {
	const navigate = useLocalizedNavigate();
	
	return(
		<>
			<VStack spacing={['30px','10']} py={['0px','100px']} bg={['none','darkCoral']} h={['730px','auto']} justifyContent={'center'} {...(shouldIgnoreParentPadding && { px:{ base: '30px', xl: '80px','2xl': '192px' }})}>
				<SimpleGrid columns={[1,3]}>
					<Heading fontSize={['20px','40px']} fontWeight={600} color={['darkCoral','#ffffff']} mb={['30px', null]}> <chakra.span color={'brand.black'}>Why</chakra.span> Loop?</Heading>
					<Text fontSize={['18px','24px']} fontWeight={600} color={['brand.black','#ffffff']} mb={['12px', null]}>Rented Media</Text>
					<Text fontSize={['16px','20px']} fontWeight={[400,500]} color={['brand.black','#ffffff']}>
						When you operate campaigns on rented media,
						you are bidding against other brands for attention and all your money is going to the platform.
					</Text>
				</SimpleGrid>
				<Divider alignSelf={'end'} border={'0.5px'} borderColor={'blackTextColor.100'} w={['100%','67%']} />
				<SimpleGrid columns={[1,3]}>
					<Text></Text>
					<Text fontSize={['18px','24px']} fontWeight={600} color={['brand.black','#ffffff']} mb={['12px', null]}>Loop Engagement</Text>
					<Text fontSize={['16px','20px']} fontWeight={[400,500]} color={['brand.black','#ffffff']}>
					Any engagement campaign in loop is exclusively between you and your customers. You decide on the engagement and what a successful engagement means to you.
					</Text>
				</SimpleGrid>
				<SimpleGrid columns={[1,3]}>
					<Text></Text>
					<Text></Text>
					<VStack spacing={['30px', null]} align={'flex-start'}>
						<Heading fontSize={'90px'} fontWeight={700} color={['darkCoral','blackTextColor.100']}>
							70%
						</Heading>
						<Text  fontSize={['16px','20px']} fontWeight={[400,500]} color={['brand.black','#ffffff']}>of any spend goes back to your customers as a reward for their engagement. Everyone wins.</Text>
						<Button
							w={['auto','220px']}
							h={['50px','60px']}
							bg={'blackTextColor.100'}
							borderColor={'blackTextColor.100'}
							variant={'dark'}
							onClick={() => navigate(AppRoutes.processOverview)}
						>
							How it Works
							<ArrowForwardIcon ml={2} boxSize={[5,5]} />
						</Button>
					</VStack>

				</SimpleGrid>
			</VStack>
			<VStack alignItems={['center','flex-start']} px={{ base: '30px', xl: '80px', '2xl': '192px' }} mt={['30px','']}>
				<Stack align={'flex-start'} spacing={'25px'} py={['40px', '100px']} w={['100%','50%']}>
					<Heading alignSelf={'flex-start'} fontSize={['20px','40px']} fontWeight={900} >What Makes Loop Different?</Heading>
					
					<Text fontSize={['16px','18px']}>
						We can relate. As brand owners ourselves, we&apos;ve faced the frustration of zero visibility on many customers and the pain of bidding on rented media just to re-target the ones we know. 
					</Text>
					<Text fontSize={['16px','18px']}>
						And we&apos;ve dealt with the low engagement rates on one-way email and SMS campaigns with no incentives - making it difficult to learn from and grow with customers. 
					</Text>
					<Text fontSize={['16px','18px']}>
						But we&apos;ve also seen how real customer relationships and building a highly engaged community changes everything, becoming your most powerful marketing asset.
					</Text>
					
				</Stack>
				
				
				
			</VStack>
			
		</>
	);
};