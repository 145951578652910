import * as React from 'react';
import {
	chakra,
	ImageProps,
	forwardRef,
} from '@chakra-ui/react';

import radiobuttonChecked from '../../Assets/svg/radiobutton-checked.svg';

export const RadioButtonChecked = forwardRef<ImageProps, 'img'>((props, ref) => {
	return <chakra.img src={radiobuttonChecked} ref={ref} {...props} />;
});