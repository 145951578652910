import {
	UserPreferenceFormName,
	Gender,
	Ethnicity,
	Children,
	Industry,
	EducationEnum,
	Religion,
	Sexuality,
	PoliticsEnum,
	HabitsEnum,
} from 'Types/Enums';
import { IGeolocation } from 'Types/Interface/IGeoLocation';
import { GEOLOCATION_EXPIRATION_TIME_MS } from './constants';
import StorageService from './storageService';

export const formatCurrency = (price: number) => {
	const country = StorageService.getCountryOrDefault();
	return new Intl.NumberFormat(country.codeIsoAlpha2, {
		style: 'currency',
		currency: country.currency,
		maximumFractionDigits: 2,
	}).format(price);
};

export const formatLntPriceInLocalCurrency = (price: number) => {
	const country = StorageService.getCountryOrDefault();
	return formatCurrency(convertLNTToUsd(price) * country.exchangeRateToUsd);
};

const RATE = 0.01;
export const convertLNTToUsd = (lnt: number) => lnt * RATE;
export const convertUSDToLNT = (usd: number) => Math.ceil(usd / RATE);

export const clearBrowserStorage = () => {
	localStorage.clear();
};

export const localizePath = (path: string): string => {
	const locale = StorageService.getLanguageOrDefault();
	const country = StorageService.getCountryUrlOrDefault();
	return `/${country}/${locale}${path}`;
};

export const isGeolocationExpired = (userGeolocation: { data: IGeolocation | null; timestamp: number | null } | null): boolean => {
	return (
		!userGeolocation ||
		!userGeolocation.timestamp ||
		Date.now() - userGeolocation.timestamp > GEOLOCATION_EXPIRATION_TIME_MS
	);
};

const genderValue: Record<string, string> = {
	[Gender.Male]: 'Male',
	[Gender.Female]: 'Female',
	[Gender.NoInput]: 'Prefer not to say',
	[Gender.NonBinary]: 'Non-binary',
	[Gender.Others]: 'Others',
	[Gender.NA]: 'NA',
};
const ethnicityValue: Record<string, string> = {
	[Ethnicity.AmericanIndian]: 'American Indian',
	[Ethnicity.BlackAfrican]: 'BlackAfrican',
	[Ethnicity.EastAsian]: 'East Asian',
	[Ethnicity.Latino]: 'Latino',
	[Ethnicity.White]: 'White',
	[Ethnicity.SouthAsian]: 'SouthAsian',
	[Ethnicity.NoInput]: 'Prefer not to say',
	[Ethnicity.Others]: 'Others',
	[Ethnicity.NA]: 'NA',
};
const childrenValue: Record<string, string> = {
	[Children.No]: 'No',
	[Children.Yes]: 'Yes',
	[Children.NoInput]: 'Prefer not to say',
	[Children.NA]: 'NA',
};
const habitsValue: Record<string, string> = {
	[HabitsEnum.No]: 'No',
	[HabitsEnum.Yes]: 'Yes',
	[HabitsEnum.NoInput]: 'Prefer not to say',
	[HabitsEnum.NA]: 'NA',
};
const industryValue: Record<string, string> = {
	[Industry.InformationTechnology]: 'Information Technology',
	[Industry.Legal]: 'Legal',
	[Industry.Medical]: 'Medicine',
	[Industry.Others]: 'Others',
	[Industry.NA]: 'NA',
};
const educationValue: Record<string, string> = {
	[EducationEnum.Highschool]: 'Highschool',
	[EducationEnum.Postgrad]: 'Postgrad',
	[EducationEnum.Undergrad]: 'Undergrad',
	[EducationEnum.NoInput]: 'Prefer not to say',
	[EducationEnum.NA]: 'NA',
};
const religionValue: Record<string, string> = {
	[Religion.Agnostic]: 'Agnostic',
	[Religion.Atheist]: 'Atheist',
	[Religion.Buddist]: 'Buddist',
	[Religion.Catholic]: 'Catholic',
	[Religion.Christian]: 'Christian',
	[Religion.Hindu]: 'Hindu',
	[Religion.Jewish]: 'Jewish',
	[Religion.Muslim]: 'Muslim',
	[Religion.NoInput]: 'Prefer not to say',
	[Religion.Others]: 'Others',
	[Religion.NA]: 'NA',
};
// const yesNoValue: Record<string, string> = {
// 	[TrueFalse.False]: 'No',
// 	[TrueFalse.True]: 'Yes',
// };
const sexualityValue: Record<string, string> = {
	[Sexuality.Gay]: 'Gay',
	[Sexuality.BiSexual]: 'BiSexual',
	[Sexuality.Straight]: 'Straight',
	[Sexuality.Lesbian]: 'Lesbian',
	[Sexuality.NoInput]: 'Prefer not to say',
	[Sexuality.NA]: 'NA',
};

const politicsValue: Record<string, string> = {
	[PoliticsEnum.Liberal]: 'Liberal',
	[PoliticsEnum.Moderate]: 'Moderate',
	[PoliticsEnum.Conservative]: 'Conservative',
	[PoliticsEnum.NoInput]: 'Prefer not to say',
	[PoliticsEnum.Others]: 'Others',
	[PoliticsEnum.NA]: 'NA',
};

export const getValueName = (title: UserPreferenceFormName, value?: string | number) => {
	switch (title) {
		case UserPreferenceFormName.Gender:
			return genderValue[value as string];
		case UserPreferenceFormName.Ethnicity:
			return ethnicityValue[value as string];
		case UserPreferenceFormName.Children:
			return childrenValue[value as string];
		case UserPreferenceFormName.Hometown:
			return value;
		case UserPreferenceFormName.Age:
			return value;
		case UserPreferenceFormName.Industry:
			return industryValue[value as string];
		case UserPreferenceFormName.Education:
			return educationValue[value as string];
		case UserPreferenceFormName.Sexuality:
			return sexualityValue[value as string];
		case UserPreferenceFormName.School_location:
			return value;
		case UserPreferenceFormName.ReligionName:
			return value;
		case UserPreferenceFormName.Religion:
			return religionValue[value as string];
		case UserPreferenceFormName.Politics:
			return politicsValue[value as string];
		case UserPreferenceFormName.Alchohol:
			return habitsValue[value as string];
		case UserPreferenceFormName.Drugs:
			return habitsValue[value as string];
		case UserPreferenceFormName.Marijuana:
			return habitsValue[value as string];
		case UserPreferenceFormName.Smoke:
			return habitsValue[value as string];
		case UserPreferenceFormName.PoliticsName:
			return value;
		default:
			return null;
	}
};

export * from '../Api/country.api';
export * from './useLocalizationRedirect';
export * from './storageService';
