import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodeType } from 'Types/Enums';
import { IActiveSurvey, ICodeScanResponse } from 'Types/Interface/ISurvey';

export type QuestionNavigationDirection = 'PREV' | 'NEXT';
export type SurveySection = 'questions' | 'brandSharingSettings';
interface IInitialState {
	activeSurvey: IActiveSurvey;
	isStartSurvey: boolean;
	isSurveyComplete: boolean;
	surveyCode: string;
	navigationDirection: QuestionNavigationDirection | undefined;
	codeType: CodeType;
	qrCodeFromDynamicLink: string;
	webCodeFromEnterCode: string;
	isCurrentAnswerValid: boolean;
	codeScanData: ICodeScanResponse;
	showScanCodeMessage: boolean;
	section: SurveySection;
}

const initialState: IInitialState = {
	isStartSurvey: false,
	isSurveyComplete: false,
	activeSurvey: {} as IActiveSurvey,
	codeScanData: {} as ICodeScanResponse,
	navigationDirection: undefined,
	codeType: CodeType.Webcode,
	surveyCode: '',
	qrCodeFromDynamicLink: '',
	webCodeFromEnterCode: '',
	isCurrentAnswerValid: false,
	showScanCodeMessage: false,
	section: 'questions',
};

export const surveySlice = createSlice({
	name: 'survey',
	initialState,
	reducers: {
		setActiveSurvey: (state, action: PayloadAction<IActiveSurvey>) => {
			state.activeSurvey = action.payload;
		},
		setCodeScanData: (state, action: PayloadAction<ICodeScanResponse>) => {
			state.codeScanData = action.payload;
			state.showScanCodeMessage =
				!!state.codeScanData?.latestEarnedPoints || state.codeScanData?.isCodeAssignedToAnotherAccount;
		},
		setQrCodeFromDynamicLink: (state, action: PayloadAction<string>) => {
			state.qrCodeFromDynamicLink = action.payload;
		},
		setWebCodeFromEnterCode: (state, action: PayloadAction<string>) => {
			state.webCodeFromEnterCode = action.payload;
		},
		setIsStartSurvey: (state, action: PayloadAction<boolean>) => {
			state.isStartSurvey = action.payload;
		},
		setSurveyCode: (state, action: PayloadAction<string>) => {
			state.surveyCode = action.payload;
		},
		setCodeType: (state, action: PayloadAction<CodeType>) => {
			state.codeType = action.payload;
		},
		setIsSurveyComplete: (state, action: PayloadAction<boolean>) => {
			state.isSurveyComplete = action.payload;
		},
		setSection: (state, action: PayloadAction<SurveySection>) => {
			state.section = action.payload;
		},
		setIsCurrentAnswerValid: (state, action: PayloadAction<boolean>) => {
			state.isCurrentAnswerValid = action.payload;
		},
		setFeedbackPoints: (state, action: PayloadAction<number>) => {
			if (!state.codeScanData) {
				state.codeScanData = {
					productId: '',
					latestEarnedPoints: 0,
					pointsForCompletingSurvey: 0,
					isCodeAssignedToAnotherAccount: false,
				};
			}
			state.codeScanData.latestEarnedPoints = action.payload;
		},
		navigateSurvey: (state, action: PayloadAction<{ index: number; dir: QuestionNavigationDirection }>) => {
			const { index, dir } = action.payload;
			state.activeSurvey.currentQuestion = index;
			state.navigationDirection = dir;
		},
		resetActiveSurvey: (state) => {
			Object.assign(state, initialState);
		},
		setShowScanCodeMessage: (state, action: PayloadAction<boolean>) => {
			state.showScanCodeMessage = action.payload;
		},
	},
});

export const {
	setActiveSurvey,
	setCodeScanData,
	setIsStartSurvey,
	setIsSurveyComplete,
	setIsCurrentAnswerValid,
	setSection,
	navigateSurvey,
	resetActiveSurvey,
	setCodeType,
	setSurveyCode,
	setQrCodeFromDynamicLink,
	setWebCodeFromEnterCode,
	setFeedbackPoints,
	setShowScanCodeMessage,
} = surveySlice.actions;

export default surveySlice.reducer;
