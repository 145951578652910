import { createApi } from '@reduxjs/toolkit/query/react';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { ICategory } from 'Types/Interface/IProduct';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';

export const categoryApi = createApi({
	reducerPath: 'categoryApi',
	baseQuery,
	endpoints: (builder) => ({
		getCategories: builder.query<IJsonResponse<ICategory[]>, { locale: string }>({
			query: ({ locale }) => ({
				url: '/categories',
				headers: getLocalizationHeader(locale),
			}),
		}),
	}),
});

export const { useGetCategoriesQuery } = categoryApi;
