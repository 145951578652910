import React from 'react';
import {
	Box,
	Text,
	Button,
	IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { ILocalizationCountry } from 'Types/Interface/ILocalizationCountry';
import { ILocalizationLanguage } from 'Types/Interface/ILocalizationLanguage';
import { LoopV2Icon } from './Icons';

interface LanguageSelectorProps {
	isOpen: boolean;
	selectedCountry: ILocalizationCountry | null;
	selectedLanguage: ILocalizationLanguage | null;
	onLanguageClose: () => void;
	handleLanguageSelect: (language: ILocalizationLanguage) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
	isOpen,
	selectedCountry,
	selectedLanguage,
	onLanguageClose,
	handleLanguageSelect,
}) => {
	if (!isOpen) return null;

	return (
		<Box
			position="fixed"
			m={0}
			top={0}
			left={0}
			width="100vw"
			height="100vh"
			bg="white"
			zIndex={9999}
			display="flex"
			flexDirection="column"
		>
			<IconButton
				aria-label="Close language selector"
				icon={<CloseIcon />}
				position="absolute"
				top={['22px', '80px']}
				right={['20px', '90px']}
				onClick={onLanguageClose}
			/>

			<LoopV2Icon
				display={['block', 'none']}
				height={'24px'}
				position="absolute"
				top='20px'
				left='20px'
			/>

			<Box mx={['5px', '17%']} mt={['100px', '120px']}>
				<Text fontSize={['2xl', '3xl']} fontWeight={'600'} mb={6} px={4}>
					Select your preferred language
				</Text>

				<Box w="100%" maxW={['100%', '65%']}>
					{selectedCountry ? (
						<Box display={['block', 'grid']} gridTemplateColumns="repeat(3, 1fr)" gap={6}>
							{selectedCountry.languages.map((language) => {
								const isSelectedLanguage = language === selectedLanguage;
								return (
									<Button
										key={language.id}
										bg={isSelectedLanguage ? 'loopGrey.400' : 'none'}
										color={isSelectedLanguage ? 'white' : 'loopGrey.400'}
										fontSize={'lg'}
										fontWeight={[400, 600]}
										p="3"
										px="4"
										borderColor={isSelectedLanguage ? 'loopGrey.400' : 'white'}
										borderWidth={1}
										borderRadius={3}
										width="100%"
										textAlign="left"
										justifyContent="flex-start"
										mb={2}
										_hover={!isSelectedLanguage ? { borderColor: 'loopGrey.400' } : {}}
										onClick={() => handleLanguageSelect(language)}
									>
										{language.name}
									</Button>
								);
							})}
						</Box>
					) : (
						<Text>Select a country first</Text>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default LanguageSelector;
