import { useNavigate } from 'react-router-dom';
import StorageService from './storageService';

const useLocalizedNavigate = () => {
	const navigate = useNavigate();
	const locale = StorageService.getLanguageOrDefault();
	const country = StorageService.getCountryUrlOrDefault();

	const navigateWithLocale = (to: string, options?: { replace?: boolean; state?: any }) => {
		navigate(`/${country}/${locale}${to}`, options);
	};

	return navigateWithLocale;
};

export default useLocalizedNavigate;
