import React from 'react';
import { DataTable } from 'Components/DataTable';
import { Column } from 'react-table';
import { useGetPayoutRequestsQuery } from 'services';
import { Box, useMediaQuery, VStack, theme, Image, Flex, Text, TableCaption } from '@chakra-ui/react';
import WithdrawalIcon from 'Assets/svg/WithdrawalIcon.svg';
import { IHistory } from 'Types/Interface/IHistory';
import { formatDateShort, TransactionStatusComponent } from 'Utils/dataTableUtils';
import useNavigation from 'Utils/navigationUtils';
import ErrorComponent from 'Components/ErrorComponent';
import { useLocale } from 'context/LocalizationContext';

export const WithdrawalHistory = () => {
	const { locale } = useLocale();
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const params = { pageNumber: 1, pageSize: 5, locale };
	const { data, isLoading, isFetching, isError } = useGetPayoutRequestsQuery(params);
	const newData = data?.data?.map((item) => ({
		...item,
		id: item.id,
	}));

	const transactionCount = newData?.length ?? 0;
	const { navigateToWithdrawalHistoryTable } = useNavigation();

	const columns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'dateCreated',
				Cell: (item) => (
					<Box color={'brand.black'} fontWeight={400} fontSize={'14px'}>
						{formatDateShort(item.row.original.dateCreated)}
					</Box>
				),
			},
			{
				Header: 'Amount',
				accessor: 'points',
				Cell: (item) => (
					<Box color={'brand.black'} fontWeight={400} fontSize={'14px'} textAlign={'left'}>
						{item.row.original.points} LNT
					</Box>
				),
			},
			{
				id: 'status',
				Header: 'Status',
				accessor: 'status',
				Cell: (item) => <TransactionStatusComponent status={item.row.original.status} />,
			},
		],
		[],
	);

	const mobileColumns: Column<IHistory>[] = React.useMemo(
		() => [
			{
				accessor: 'dateCreated',
				Cell: (item) => (
					<Flex alignItems={'center'}>
						<Image w={'40px'} height={'40px'} src={WithdrawalIcon} mr={'10px'} />
						<VStack align={'flex-start'} spacing={'5px'}>
							<Text fontSize='14px'>Payment</Text>
							<Box fontSize='12px' color='loopGrey.800'>
								{formatDateShort(item.row.original.dateCreated)}
							</Box>
						</VStack>
					</Flex>
				),
			},
			{
				accessor: 'points',
				Cell: (item) => (
					<VStack alignItems={'flex-end'} spacing={'5px'}>
						<Box fontSize={['14px']} fontWeight='500'>
							{item.row.original.points} LNT
						</Box>
						<Box>
							<TransactionStatusComponent status={item.row.original.status} />
						</Box>
					</VStack>
				),
			},
		],
		[],
	);

	const headerStyles: React.CSSProperties = {
		backgroundColor: 'white',
		textTransform: 'none',
	};

	const tableContainerStyles: React.CSSProperties = {
		borderRadius: '10px',
		borderWidth: '1px',
		paddingLeft: isMobile ? '20px' : 0,
		paddingRight: isMobile ? '20px' : 0,
	};

	if (isError) {
		return <ErrorComponent mx={[0, null, 6]} h={[null, null, '280px']}/>;
	}

	return (
		<DataTable
			data={newData || []}
			columns={isMobile ? mobileColumns : columns}
			isLoading={isLoading || isFetching}
			isMobile={isMobile}
			showCheckboxes={false}
			showMenu={false}
			headerStyles={headerStyles}
			tableContainerStyles={tableContainerStyles}
			tableTopCaption={
				<TableCaption placement='top' fontWeight={'500'} fontSize={'xl'} textAlign={'left'} color={'loopGrey.500'}>
					Withdrawal history
				</TableCaption>
			}
			tableBottomCaption={
				<TableCaption
					fontSize={'sm'}
					textAlign={'left'}
					placement='bottom'
					mb={5}
					fontWeight={'500'}
					color={'loop.500'}
					cursor={'pointer'}
					onClick={navigateToWithdrawalHistoryTable}
				>
					{transactionCount > 0 ? (
						<>
							View more
							<Box as='span' ml={2}>
								→
							</Box>
						</>
					) : (
						'No transaction yet'
					)}
				</TableCaption>
			}
		/>
	);
};
