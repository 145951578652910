import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, CSSObject, Flex, HStack, Icon, IconButton, Stack, Text, useMediaQuery } from '@chakra-ui/react';
import { LoopV2Icon } from 'Components/Icons';
import { NavbarLink } from './elements/NavbarLink';
import { MenuDropdown, NotificationWidget } from './elements';
import { CustomDrawer } from 'Components/CustomDrawer';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { AppRoutes } from 'Routes/AppRoutes';
import { clearBrowserStorage, localizePath } from 'Utils';
import { SearchBrandsAndProducts } from 'Components/SearchBrandsAndProducts';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { useNavbarLocalization } from 'Utils/localizationHooks';
import LocalizationSelector from 'Components/LocalizationSelector';
import { AuthContext } from 'context/AuthContext';

interface INavbarProps {
	linkColorMode?: 'light' | 'black';
	inputlinkColorMode?: 'light' | 'black';
	sx?: CSSObject;
}

export const Navbar = ({ linkColorMode, sx }: INavbarProps) => {
	const navigate = useLocalizedNavigate();
	const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');
	const [isIos, setIsIos] = useState(false);
	const [links, setLinks] = useState<{ label: string; path: string }[]>([]);
	
	const { isFullyRegisteredUser } = useContext(AuthContext);
	const { localization: navbarLocalization, isLoading: isLoadingLocalization } = useNavbarLocalization();

	useEffect(() => {
		if (!isLoadingLocalization && navbarLocalization) {
			setLinks([
				{
					label: navbarLocalization.home,
					path: '/home',
				},
				{
					label: navbarLocalization.explore,
					path: '/explore',
				},
			]);
		}
	}, [navbarLocalization, isLoadingLocalization]);

	useEffect(() => {
		const userAgent = window.navigator.userAgent.toLowerCase();
		setIsIos(userAgent.includes('iphone') || userAgent.includes('ipad'));
	}, []);

	const renderLeftBarSection = () => {
		return (
			<Stack direction='row' alignItems='center' spacing={'25px'}>
				{links.map(({ label, path }) => (
					<NavbarLink linkColorMode={linkColorMode} key={label} label={label} path={localizePath(path)} />
				))}
			</Stack>
		);
	};

	const renderRightSection = () => {
		return (
			<HStack spacing={4} alignItems='center' justifyContent='space-around'>
				<SearchBrandsAndProducts />
				<LocalizationSelector fontSize='14px' showCountrySelector={false} colorMode={linkColorMode} />
				<NotificationWidget linkColorMode={linkColorMode} />
				<MenuDropdown linkColorMode={linkColorMode} />
			</HStack>
		);
	};

	const handleClickAppLogo = () => {
		navigate(AppRoutes.home);
	};

	const handleLogout = () => {
		clearBrowserStorage();
		window.location.reload();
	};

	return (
		<Flex justifyContent='space-between' sx={sx}>
			<Flex flex={1} alignItems='center'>
				<LoopV2Icon mr={20} height={'30px'} cursor='pointer' onClick={handleClickAppLogo} />
				{isLargerThan1000 && renderLeftBarSection()}
			</Flex>
			{isLargerThan1000 && renderRightSection()}

			{!isLargerThan1000 && (
				<CustomDrawer
					title={<LoopV2Icon height='25px' cursor='pointer' />}
					buttonContent={
						<IconButton
							icon={<Icon as={HiOutlineMenuAlt3} w={6} h={6} />}
							aria-label='menu'
							color={linkColorMode === 'light' ? 'white' : 'darkCoral'}
						/>
					}
					isFull
				>
					<Flex flexDirection='column' justifyContent='space-between' height='100%' pb='20'>
						<Box>
							<Stack spacing={8} mt={10}>
								{links.map(({ label, path }) => (
									<NavbarLink
										linkColorMode={linkColorMode}
										key={label}
										label={label}
										path={localizePath(path)}
										sx={{
											fontSize: '18px',
											fontWeight: 500,
										}}
									/>
								))}
								<Button variant='primary' borderRadius='full' width='max-content'>
									Download app
								</Button>
							</Stack>
							<Box mt={'100px'}>
								<LocalizationSelector fontSize='14px' direction='column' />
							</Box>
						</Box>
						<HStack paddingBottom={isIos ? '30px' : '0'} justify={'space-between'}>
							{isFullyRegisteredUser() && (<NavbarLink
								linkColorMode={linkColorMode}
								label={'My account'}
								path={AppRoutes.dashboard}
								sx={{
									fontSize: '18px',
									fontWeight: 500,
								}}
							/>)}
							<Text fontSize={'lg'} fontWeight={500} onClick={handleLogout}>
								Sign out
							</Text>
						</HStack>
					</Flex>
				</CustomDrawer>
			)}
		</Flex>
	);
};
