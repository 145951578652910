import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DashboardState {
	activeTab: number;
}

const initialState: DashboardState = {
	activeTab: 0,
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setActiveDashboardTab(state, action: PayloadAction<number>) {
			state.activeTab = action.payload;
		},
	},
});

export const { setActiveDashboardTab } = dashboardSlice.actions;
export default dashboardSlice.reducer;
