/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
	Button,
	Flex,
	IconButton,
	useMediaQuery,
	Image,
	Stack,
	Divider,
	Box,
} from '@chakra-ui/react';
import { LoopV2Icon } from 'Components/Icons';
import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'Routes/AppRoutes';
import { HamburgerIcon, Icon } from '@chakra-ui/icons';
import { CustomDrawer } from 'Components/CustomDrawer';
// import userSvg from 'Assets/svg/userSvg.svg';
import UserIcon from 'Assets/svg/UserIcon.svg';
import { NavbarLink } from 'Pages/Dashboard/Components/Navbar/elements';
import { getUserAgent, UserAgent } from 'Utils/userAgent';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import LocalizationSelector from 'Components/LocalizationSelector';

interface INavbarProps {
	linkColorMode?: 'light' | 'black'
}

export const Navbar = ({ linkColorMode = 'light' }: INavbarProps) => {
	const navigate = useLocalizedNavigate();
	const location = useLocation();

	const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');
	const [isIos, setIsIos] = useState(false);

	useEffect(() => {
		const userAgent = getUserAgent();
		setIsIos(userAgent === UserAgent.Ipad || userAgent === UserAgent.Iphone);
	}, []);

	const _renderRightBarSection = () => {
		return (
			<Flex alignItems='center' direction={['column', 'column', 'column', 'row']}>
				<Button
					variant='ghost'
					color='darkCoral'
					p='2'
					py='2.5'
					fontWeight='bold'
					mr='3'
					mb={[8, 8, 8, 0]}
					fontSize='14px'
					_hover={{ bgColor: 'none' }}
					onClick={() => navigate(AppRoutes.login)}
				>
					<Image src={UserIcon} />
					Login
				</Button>
				<Button variant='primary' shadow='2xl' p='2' py='2.5' fontWeight='bold' fontSize='12px'>
					Download app
				</Button>
			</Flex>
		);
	};

	return (
		<Flex justifyContent='space-between'>
			<Flex flex={1} justifyContent={'space-between'} alignItems='center'>
				<Flex>
					<LoopV2Icon height={'40px'} onClick={() => navigate(AppRoutes.landingpage)} mr={'60px'} />
					<Button
						variant='ghost'
						color={location.pathname === AppRoutes.landingpage ? 'darkCoral' : '#2B2B2B'}
						_active={{ color: 'darkCoral' }}
						fontWeight={'medium'}
						display={['none', 'flex']}
						mb={[8, 8, 8, 0]}
						mr={'30px'}
						fontSize='14px'
						_hover={{ bgColor: 'none' }}
						onClick={() => navigate(AppRoutes.home)}
					>
						Home
					</Button>
					<Button
						display={['none', 'flex']}
						variant='ghost'
						color={location.pathname === AppRoutes.EnterCode ? 'darkCoral' : '#2B2B2B'}
						_active={{ color: 'darkCoral' }}
						fontWeight={'medium'}
						mb={[8, 8, 8, 0]}
						fontSize='14px'
						_hover={{ bgColor: 'none' }}
						onClick={() => navigate(AppRoutes.EnterCode)}
					>
						Enter Webcode
					</Button>
				</Flex>
				{isLargerThan1000 && _renderRightBarSection()}
			</Flex>

			{!isLargerThan1000 && (
				<CustomDrawer
					title={<LoopV2Icon height='25px' cursor='pointer' />}
					buttonContent={
						<IconButton
							icon={<Icon as={HamburgerIcon} color={'darkCoral'} w={6} h={6} />}
							aria-label='menu'
							color={linkColorMode === 'light' ? 'white' : 'darkCoral'}
						/>
					}
					isFull
				>
					<Stack alignItems={'flex-start'} spacing={8} mt={20}>
						<Button
							variant='ghost'
							color='#2B2B2B'
							fontWeight={500}
							fontSize='18px'
							_hover={{ bgColor: 'none' }}
							onClick={() => navigate(AppRoutes.EnterCode)}
						>
							Enter Webcode
						</Button>
						<Divider border={'1.5px solid rgba(88, 96, 104, 0.50)'} />
						<NavbarLink
							linkColorMode={linkColorMode}
							label={'Register'}
							path={AppRoutes.register}
							sx={{
								fontSize: '18px',
								fontWeight: 500,
								color: '#2B2B2B',
							}}
						/>
						<Divider border={'1.5px solid rgba(88, 96, 104, 0.50)'} />
						<Button
							variant='ghost'
							color='#2B2B2B'
							fontWeight={500}
							fontSize='20px'
							onClick={() => navigate(AppRoutes.login)}
						>
							Login
						</Button>
						<Divider border={'1.5px solid rgba(88, 96, 104, 0.50)'} />
						<Button
							variant='ghost'
							color='darkCoral'
							fontWeight={500}
							fontSize='20px'
							onClick={() => navigate(AppRoutes.LoopForBusiness)}
						>
							Loop for Business
						</Button>
					</Stack>
					<Box mt={'100px'}>
						<LocalizationSelector fontSize='14px' direction='column' />
					</Box>
					<Button
						variant='primary'
						shadow='2xl'
						borderRadius={'10px'}
						w={'100%'}
						h={'50px'}
						fontWeight={500}
						fontSize='16px'
						mt={'150px'}
					>
						Download app
					</Button>
				</CustomDrawer>
			)}
		</Flex>
	);
};