import { Flex, Heading, VStack, Image, Text, Stack } from '@chakra-ui/react';
import { LandingPageLayout } from 'Layouts/LandingPageLayout';
import React from 'react';
import { IJumbotronContents } from 'Types/Interface/ILandingPage';
import { Jumbotron } from './Components/Jumbotron';
import { CostOfReliance } from './CostOfReliance';
import { CTA } from './CTA';
import { Customer } from './Customer';
import { GrowWithLoop } from './GrowWithLoop';
import { Services } from './Services';
import socialMedia from '../../Assets/svg/social-media-icon.svg';
import speedometer from '../../Assets/svg/speedometer.svg';
import userEngagement from '../../Assets/svg/user-engagement.svg';

const content:IJumbotronContents[] = [{
	heading : 'Turn Customer Engagement into Your Most Valuable Asset',
	text: 'Build a community of verified customers from all sales channels, gain actionable insights, and drive loyalty—on your terms.',
	subText: 'Learn How It Works '
}];

export const LoopForBusiness = () => {
	return(
		<LandingPageLayout
			JumbotronSection={<Jumbotron contents={content}/>}
			paddingTop='0px'
			isBusiness
		>
			<GrowWithLoop shouldIgnoreParentPadding/>
			<CostOfReliance />
			<Stack
				direction={['column', 'row']}
				spacing={['20px', '40px']}
				align={'center'}
				justify={'center'}
				w={'100%'}
				// pt={['20px', '40px']}
				// px={['20px', '40px']}
			>
				<VStack align={['center']} justify={'center'} spacing={['20px','10px']}>
					<Heading fontSize={['30px', '24px']} textAlign={'center'}>
						What if there was an alternative to the constant reliance on Rented Media?
					</Heading>
					<Text fontSize={['16px', '18px']} textAlign={'center'}>
						What if you didn&apos;t have to constantly bid against others to reach your customers?
					</Text>
				</VStack>
			</Stack>
			<Customer shouldIgnoreParentPadding />
			<VStack spacing={['20px', '50px']}  pb={['0px','100px']} justify={'flex-start'} alignItems={'flex-start'}>
				<VStack spacing={'10px'}  justify={'center'} alignItems={'flex-start'} >
					<Heading fontSize={['20px', '40px']} >
						Placeholder Title
					</Heading>
					<Text  fontSize={['16px','18px']}>Placeholder Sub-text</Text>
				</VStack>
			

				<Flex direction={['column', 'row']} justifyContent={'space-between'} w={['','full']}>
					<VStack mb={['28px', null]} borderRadius={'3px'} bg={'#ECEFF2'} align={'flex-start'}  w={['auto','400px']} h={['200px','300px']} p={['20px','30px']}>
						<Image src={socialMedia} mb={['20px','60px']} boxSize={'40px'} />
						<Heading fontWeight={600} fontSize={'18px'} pb={'10px'}>Bridging Campaigns</Heading>
						<Text  fontSize={['16px','18px']}>Use QR codes and NFC chips on product packaging to connect with customers.</Text>
						
					</VStack>
						
						
					<VStack mb={['28px', null]} borderRadius={'3px'} align={'flex-start'} bg={'#ECEFF2'}  w={['auto','400px']} h={['200px','300px']} p={['20px','30px']}>
						<Image src={userEngagement} mb={['20px','60px']}  boxSize={'40px'} />
						<Heading fontWeight={600} fontSize={'18px'} pb={'10px'}>Engagement Tools</Heading>
						<Text  fontSize={['16px','18px']}>Run loyalty programs, surveys, and UGC campaigns.</Text>
						
					</VStack>
						
					<VStack borderRadius={'3px'} align={'flex-start'}  w={['auto','400px']} bg={'#ECEFF2'} h={['200px','300px']} p={['20px','30px']}>
						<Image src={speedometer} mb={['20px','60px']}  boxSize={'40px'} />
						<Heading fontWeight={600} fontSize={'18px'} pb={'10px'}>Performance-Based Pricing</Heading>
						<Text  fontSize={['16px','18px']} >Only pay for results, ensuring maximum ROI.</Text>
						
					</VStack>
				</Flex>
			</VStack>
			
			<Services shouldIgnoreParentPadding/>
			<CTA shouldIgnoreParentPadding />
		</LandingPageLayout>
	);
};