import React, { useState, useCallback } from 'react';
import { DataTable, IPaginationConfig } from 'Components/DataTable';
import { Column } from 'react-table';
import { useGetBrandLoopsQuery } from 'services';
import { useMediaQuery, theme, Flex, Image, Button, Heading, Text, VStack, HStack, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { formatDateShort } from 'Utils/dataTableUtils';
import { IBrandLoop } from 'Types/Interface/IBrandLoop';
import WithdrawalIcon from 'Assets/svg/WithdrawalIcon.svg';
import { RoundedImage } from 'Components/RoundedImage';
import useNavigation from 'Utils/navigationUtils';
import ErrorComponent from 'Components/ErrorComponent';
import { useLocale } from 'context/LocalizationContext';
import { SearchIcon } from '@chakra-ui/icons';

export const BrandLoops = () => {
	const { locale } = useLocale();
	const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
	const [pageIndex, setPageIndex] = useState(1);
	const params = { pageIndex, pageSize: 10, locale };
	const { data, isLoading, isFetching, isError } = useGetBrandLoopsQuery(params);
	const [searchQuery, setSearchQuery] = useState('');


	const searchFiltered = data?.data?.filter((item) =>
		item.brandName.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const newData = searchFiltered?.map((item) => ({
		...item,
		id: item.brandId,
	}));
	
	// const loopedData = searchFiltered?.filter((item) => item.isLooped);
	// const unLoopedData = searchFiltered?.filter((item) => !item.isLooped);
	
	const { navigateToBrandLoopDetails } = useNavigation();

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.target.value);
		setPageIndex(1); 
	};

	const paginate = useCallback(({ pageIndex }: IPaginationConfig) => {
		setPageIndex(pageIndex + 1);
	}, []);

	const columns: Column<IBrandLoop>[] = React.useMemo(
		() => [
			{
				Header: '',
				accessor: 'brandImageUrl',
				Cell: (item) => (
					<Flex justifyContent={'center'} bg={'#F1F1F1'} borderRadius={'14px'} w={'44px'} p={'0.5'} objectFit={'cover'} maxH={['16px', '30px']}>
						<Image src={item.row.original.brandImageUrl} borderRadius={'14px'}/>
					</Flex>
				),
			},
			{
				Header: 'Brand',
				accessor: 'brandName',
			},
			{
				Header: 'Joined',
				accessor: 'joinedDate',
				Cell: (item) => <>{formatDateShort(item.row.original.joinedDate)}</>,
			},
			{
				Header: 'Events',
				accessor: 'userActivitiesCount',
			},
			{
				Header: 'Last Activity',
				accessor: 'lastActivityDate',
				Cell: (item) => <>{formatDateShort(item.row.original.lastActivityDate)}</>,
			},
			{
				Header: 'Action',
				accessor: 'brandId',
				Cell: (item) => (
					<Button
						variant={'greyBorder'}
						onClick={() => {
							navigateToBrandLoopDetails(item.row.original.brandId);
						}}
					>
						Manage
					</Button>
				),
			},
		],
		[],
	);

	const mobileColumns: Column<IBrandLoop>[] = React.useMemo(
		() => [
			{
				accessor: 'brandName',
				Cell: (item) => {
					const rowData = item.row.original;

					return (
						<HStack spacing={3} lineHeight={'18px'}>
							<RoundedImage src={item.row.original.brandImageUrl} w={'30px'} h={'30px'} />
							<VStack alignItems={'flex-start'}  spacing={'0'}>
								<Flex fontSize={'12px'} alignItems={'center'}>
									{rowData.brandName} <Image w={'10px'} h={'10px'} ml={1} src={WithdrawalIcon} />
								</Flex>
								
								<Text fontSize={'10px'}>Events: {rowData.userActivitiesCount}</Text>
							</VStack>
						</HStack>
					);
				},
			},
			{
				accessor: 'isLooped',
				Cell: (item) => (
					<VStack alignItems='flex-end' fontSize={'10px'} spacing={'0'} lineHeight={'18px'}>
						<Text>Joined {formatDateShort(item.row.original.joinedDate)}</Text>
						<Text>Last activity: {formatDateShort(item.row.original.lastActivityDate)}</Text>
					</VStack>
				),
			},
		],
		[],
	);

	const renderDataTable = useCallback(
		(data) => (
			<DataTable
				data={data || []}
				columns={isMobile ? mobileColumns : columns}
				pageCount={data?.totalNoOfPages}
				paginate={paginate }
				initialPageIndex={0}
				isLoading={isLoading || isFetching}
				pageSize={params.pageSize}
				isMobile={isMobile}
				showCheckboxes={false}
				showMenu={false}
				onclickRow={(data: IBrandLoop) => navigateToBrandLoopDetails(data.brandId)}
				tableContainerStyles={{border: 'none'}}
				headerStyles={{color: 'rgba(32, 32, 32, 0.7)', fontSize: '14px'}}
				bodyStyles={{color: '#202020' }}
			/>
		),
		[columns, mobileColumns, isLoading, isFetching, params.pageSize, isMobile, navigateToBrandLoopDetails]
	);

	return (
		<>
			<Heading fontSize={['20px', null, '30px']} color={'loopGrey.500'} mb={6}>
				My{' '}
				<Text as='span' fontWeight={600} color='darkCoral'>
					Loops
				</Text>
			</Heading>
			<Text fontSize={['12px', null, null, '14px']} color={'loopGrey.500'} fontWeight={400} lineHeight={['6']} mb={8}>
				Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore
				magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo
				con sequat.{' '}
			</Text>

			<Flex direction={['column', 'column']} w={'full'} justifyContent={'space-between'} >
				<InputGroup mb={'10px'}>
					<InputLeftElement pointerEvents='none'>
						<SearchIcon color='loopGrey.500' boxSize={'14px'} />
					</InputLeftElement>
					<Input
						placeholder="Search Loops..."
						_placeholder={{color: '#586068', fontSize: '12px'}}
						value={searchQuery}
						onChange={handleSearch}
						w={['full','332px']}
						height={'40px'}
						size="sm"
						borderRadius={'3px'}
						border={'1px'}
					/>
				</InputGroup>
					
				{isError ? <ErrorComponent height={['250px', null, '400px']} /> : renderDataTable(newData)}
			</Flex>
			
			{/* <Tabs variant='unstyled'>
				<TabList >
					<Tab fontSize={['12px', '18px']}>All</Tab>
					<Tab fontSize={['12px', '18px']}>Looped</Tab>
					<Tab fontSize={['12px', '18px']}>Unlooped</Tab>
				</TabList>
				<TabIndicator mt='-1.5px' height='2px' bg={'darkCoral'} borderRadius='1px' />
				<TabPanels>
					<TabPanel>{isError ? <ErrorComponent height={['250px', null, '400px']} /> : renderDataTable(newData)}</TabPanel>
					<TabPanel>{isError ? <ErrorComponent height={['250px', null, '400px']} /> : renderDataTable(loopedData)}</TabPanel>
					<TabPanel>{isError ? <ErrorComponent height={['250px', null, '400px']} /> : renderDataTable(unLoopedData)}</TabPanel>
				</TabPanels>
			</Tabs> */}
		</>
	);
};
