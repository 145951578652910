import React, { useEffect, useRef, useState } from 'react';
import { Box, VStack, Text, Flex } from '@chakra-ui/react';

interface IDatePickerProps {
  onChange?: (isoDate: string) => void;
}

export const DatePicker = ({ onChange }: IDatePickerProps) => {
	const currentYear = new Date().getFullYear();
	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [selectedMonth, setSelectedMonth] = useState(2);
	const [selectedDay, setSelectedDay] = useState(2);
	const [age, setAge] = useState(0);
	const [isoDate, setIsoDate] = useState('');

	const monthNames = [...Array(12)].map((_, i) =>
		new Date(0, i).toLocaleString('en-US', { month: 'long' })
	);

	const years = [...Array(currentYear - 1900 + 1)].map((_, i) => 1900 + i);
	const days = [...Array(31)].map((_, i) => i + 1);

	useEffect(() => {
		const calculateAgeAndFormatDate = () => {
			const birthDate = new Date(selectedYear, selectedMonth - 1, selectedDay);
			const today = new Date();

			let age = today.getFullYear() - birthDate.getFullYear();
			const monthDiff = today.getMonth() - birthDate.getMonth();
			const dayDiff = today.getDate() - birthDate.getDate();

			if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
				age -= 1;
			}
			setAge(age);

			const formattedDate = `${selectedYear}-${String(selectedMonth).padStart(2, '0')}-${String(selectedDay).padStart(2, '0')}T00:00:00`;
			setIsoDate(formattedDate);

			if (onChange) {
				onChange(formattedDate);
			}
		};

		calculateAgeAndFormatDate();
	}, [selectedYear, selectedMonth, selectedDay]);

	const handleDateChange = (type: 'year' | 'month' | 'day', value: number) => {
		if (type === 'year') setSelectedYear(value);
		if (type === 'month') setSelectedMonth(value);
		if (type === 'day') setSelectedDay(value);
	};

	const renderOptions = (
		values: (number | string)[],
		selectedValue: number | string,
		onChange: (value: number) => void
	) => {
		const containerRef = useRef<HTMLDivElement | null>(null);

		useEffect(() => {
			if (containerRef.current) {
				const items = containerRef.current.children;
				const selectedItem = [...items].find(item => item.textContent === String(selectedValue));

				if (selectedItem) {
					const container = containerRef.current;
					const containerHeight = containerRef.current.clientHeight;
					const itemHeight = selectedItem.clientHeight;
					const maxScroll = container.scrollHeight - containerHeight;
					let scrollTo = (selectedItem as HTMLElement).offsetTop - containerHeight / 2 + itemHeight / 2;

					if (scrollTo < 0) {
						scrollTo = 0;
					}
			

					if (scrollTo > maxScroll) {
						scrollTo = maxScroll;
					}
					containerRef.current.scrollTo({ top: scrollTo, behavior: 'smooth' });
				}
			}
		}, [selectedValue]);

		return (
			<Box
				ref={containerRef}
				overflowY="auto"
				maxH="150px"
				w={['100px', '120px']}
				textAlign="center"
				position="relative"
				scrollBehavior="smooth"
				sx={{
					'::-webkit-scrollbar': { display: 'none' },
					'-ms-overflow-style': 'none',
					'scrollbar-width': 'none',
				}}
			>
				{values.map((value, index) => (
					<Box
						key={value}
						py="20px"
						fontSize="16px"
						fontWeight="bold"
						borderBottom={'1px solid'}
						borderColor={'gray.500'}
						color={value === selectedValue ? 'darkCoral' : 'rgba(88, 96, 104, 0.5)'}
						cursor="pointer"
						onClick={() => onChange(typeof value === 'string' ? index + 1 : (value as number))}
						borderTop={index === 0 ? '1px solid' : 'none'}
						borderTopColor={index === 0 ? 'gray.500' : 'transparent'}
					>
						{value}
					</Box>
				))}
				<Box py="50px"></Box>
			</Box>
		);
	};

	return (
		<VStack spacing={4}>
			<Flex justify="center" align="center" mb={4}>
				{renderOptions(monthNames, monthNames[selectedMonth - 1], (value) =>
					handleDateChange('month', value)
				)}
				{renderOptions(days, selectedDay, (value) => handleDateChange('day', value))}
				{renderOptions(years, selectedYear, (value) => handleDateChange('year', value))}
			</Flex>
			{selectedYear && age > 0 && <Text fontSize="20px" color={'gray.500'} fontWeight="bold">Age: {age}</Text>}
		</VStack>
	);
};