import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Stack, Button, Text, Flex, Spacer } from '@chakra-ui/react';
import { IAccountSettingCard } from 'Constants/AccountSettingCardsConstant';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';

interface ISettingCardProps {
  data: IAccountSettingCard;
}

export const SettingCard = ({ data }: ISettingCardProps) => {
	const { icon, title, description, iconBgColor, path, hasCustomPanelTitle } = data;
	const navigate = useLocalizedNavigate();

	const handleNavigation = () => {
		path && navigate(path, { state: { title: hasCustomPanelTitle ? undefined : title } });
	};
	return (
		<Stack
			spacing={4}
			bg="white"
			boxShadow="0px 0px 30px rgba(88, 96, 104, 0.15)"
			p={5}
			borderRadius="5px"
			onClick={handleNavigation}
			cursor='pointer'
			borderWidth='1px'
			borderColor="transparent"
			_hover={{
				borderColor: 'loop.400',
			}}
		>
			<Flex
				h="35px"
				w="35px"
				borderRadius="50%"
				bg={iconBgColor}
				alignItems="center"
				justifyContent="center"
			>
				{icon}
			</Flex>
			<Spacer h={5} />
			<Button
				fontSize="sm"
				rightIcon={<ChevronRightIcon />}
				w="max-content"
				
			>
				{title}
			</Button>
			<Text>{description}</Text>
		</Stack>
	);
};
