import React from 'react';
import { Checkbox, Text, Link, useDisclosure } from '@chakra-ui/react';
import { TermsModal } from '.';

interface TermsAgreementProps {
	isAccepted: boolean;
	onAccepted: (accepted: boolean) => void;
}

export const TermsAgreement: React.FC<TermsAgreementProps> = ({ isAccepted, onAccepted }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	// Prevent the checkbox from toggling when clicking the link.
	const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();
		onOpen();
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onAccepted(e.target.checked);
	};

	const handleAccept = (isAccepted: boolean) => {
		onAccepted(isAccepted);
	};

	return (
		<>
			<Checkbox py="10px" isChecked={isAccepted} onChange={handleCheckboxChange}>
				<Text fontSize="xs">
					I agree to all{' '}
					<Link
						color="darkCoral"
						textDecoration="underline"
						cursor="pointer"
						onClick={handleLinkClick}
					>
						terms and conditions
					</Link>
				</Text>
			</Checkbox>
			<TermsModal isOpen={isOpen} onClose={onClose} onAccept={handleAccept} isBusiness={false} type={'terms'} />
		</>
	);
};
