import { Stack, Text, chakra, Flex, Image, Button, HStack } from '@chakra-ui/react';
import React from 'react';
import LoopSvg from 'Assets/svg/LoopSvg.svg';
import CollabIcon from 'Assets/svg/CollabIcon.svg';
import { AppRoutes } from 'Routes/AppRoutes';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import PriceToggle from 'Components/PriceToggle';
import { ClockIcon } from 'Components/Icons/ClockIcon';
import { PresentIcon } from 'Components/Icons/PresentIcon';
import { ISurvey } from 'Types/Interface/ISurvey';
import { getMaxRewardPoints } from 'Utils/surveyUtils';

interface IDynamicProductPageProps {
	survey?: ISurvey;
}

export const DynamicProductPageContents = ({ survey }: IDynamicProductPageProps) => {
	const navigate = useLocalizedNavigate();

	const handleLogin = () => {
		navigate(AppRoutes.login);
	};

	const handleContinueWithoutLogin = () => {
		navigate(AppRoutes.registerPartial);
	};

	const data = survey?.product;
	const reward = getMaxRewardPoints(survey);

	return (
		<Stack spacing={7}>
			<Text fontSize={['18px', '24px']} fontWeight={400} pt={['20px', '50px']}>
				{data?.brand.name} has teamed up with <chakra.span color={'darkCoral'}>Loop</chakra.span> to get your feedback
				on{' '}
				<chakra.span fontWeight={600} textDecoration={'underline'}>
					{data?.name}
				</chakra.span>
			</Text>
			<Flex justifyContent={'space-evenly'} alignItems={'center'} bg={['none', '#F9F9F9']} h={'150px'}>
				<Image src={LoopSvg} boxSize={'50px'} objectFit={'contain'} />
				<Image src={CollabIcon} boxSize={'21px'} />
				<Image src={data?.brand.logoUrl} boxSize={'90px'} objectFit={'contain'} />
			</Flex>
			<HStack justifyContent={['space-between', null, 'flex-start']} fontSize={['xs', null, 'md']}>
				{!!survey?.estimatedTime && (
					<Flex pr={[0, null, 24]} alignItems={'center'}>
						<ClockIcon mr={[1, null, 2]} mb={['2px', null, '4px']} h={['10px', null, '15px']} />
						{survey?.estimatedTime} survey
					</Flex>
				)}
				{reward > 0 && (
					<Flex alignItems={'center'}>
						<PresentIcon mr={[1, null, 2]} mb={['2px', null, '4px']} h={['10px', null, '15px']} />
						Earn <PriceToggle value={reward} pl={1} pr={[5, null, 0]} />
					</Flex>
				)}
			</HStack>
			<Stack justify={'flex-start'} spacing={'30px'}>
				<Text fontSize={'16px'} color={'#2B2B2B'}>
					Your response will only be shared with <chakra.span textDecor={'underline'}>{data?.brand.name}</chakra.span>.
					You can choose what level of information you want to share.
				</Text>
				<Text fontSize={'16px'} color={'#2B2B2B'}>
					Thank you for scanning, please Login or Register to attach this QR code to your account.
				</Text>
			</Stack>
			<Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '20px', md: '30px' }} alignItems={'center'}>
				<Button variant='primary' w={'335px'} h={'50px'} borderRadius={['10px', '5px']} px={20} onClick={handleLogin}>
					Login / Sign up
				</Button>
				<Button
					variant='grey'
					w={'335px'}
					h={'50px'}
					borderRadius={['10px', '5px']}
					px={20}
					fontWeight={'400'}
					onClick={handleContinueWithoutLogin}
				>
					Continue without signing in
				</Button>
			</Stack>
		</Stack>
	);
};
