import { useLocale } from './../context/LocalizationContext';
import { useGetDocumentByTypeAndLocaleQuery } from 'services';
import { IBrandTermsOfServiceLocalizationDocument, IHomePageLocalizationDocument, INavbarLocalizationDocument, IPrivacyLocalizationDocument, ITermsOfServiceUserLocalizationDocument } from 'Types/Interface/Sanity';
import StorageService from './storageService';

const useLocalization = <T>(type: string) => {
	const { locale } = useLocale();
	const country = StorageService.getCountryUrlOrDefault();
	const { data, isLoading } = useGetDocumentByTypeAndLocaleQuery<{ data: T, isLoading: boolean }>({
		type,
		locale,
		country
	});

	return { localization: data ?? null, isLoading };
};

const useHomePageLocalization = () => {
	return useLocalization<IHomePageLocalizationDocument>('homePage');
};

const useTermsOfServiceUserLocalization = () => {
	return useLocalization<ITermsOfServiceUserLocalizationDocument>('termsOfServiceUser');
};

const useTermsOfServiceBrandsLocalization = () => {
	return useLocalization<IBrandTermsOfServiceLocalizationDocument>('termsOfServiceBrands');
};

const usePrivacyLocalization = () => {
	return useLocalization<IPrivacyLocalizationDocument>('privacy');
};

const useNavbarLocalization = () => {
	return useLocalization<INavbarLocalizationDocument>('navigationBar');
};

export { useHomePageLocalization, 
	useNavbarLocalization, 
	useTermsOfServiceUserLocalization, 
	useTermsOfServiceBrandsLocalization,
	usePrivacyLocalization
};
