import React, { useEffect } from 'react';
import { Box, Divider, HStack, Stack, Switch, Text } from '@chakra-ui/react';
import { useAppSelector, useAppDispatch } from 'Hooks';
import { clearUserBrandPermissions, setUserBrandPermissions } from 'store/slices/userBrandPermissionsSlice';
import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';

interface BrandPermissionsProps {
	brandName: string;
	isDisabled?: boolean;	
	onUpdate?: (updatedPermissions: IUserBrandPermission) => void;
}

export const BrandPermissions: React.FC<BrandPermissionsProps> = ({ brandName, onUpdate, isDisabled = false }) => {
	const dispatch = useAppDispatch();
	const { userInfo } = useAppSelector((state) => state.authUser);
	const permissions = useAppSelector((state) => state.userBrandPermissions.permissions);


	const handlePermissionChange = (fieldName: string, value?: boolean) => {
		if (permissions) {
			const updatedPermissions = {
				...permissions,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				[fieldName]: value ?? !(permissions as any)[fieldName],
			};

			dispatch(setUserBrandPermissions(updatedPermissions));
			onUpdate && onUpdate(updatedPermissions);
		}
	};

	useEffect(() => {
		dispatch(clearUserBrandPermissions());
	}, []);

	return (
		<Stack spacing={'5'} fontSize={['sm', null, 'md']}>
			<Text color='loopGrey.500' opacity='70%'>Information preferences</Text>

			<Text>What information are you ok sharing with {brandName}?</Text>

			<HStack justifyContent={'space-between'}>
				<Text>Share name</Text>
				<Switch
					variant='primary'
					isChecked={permissions?.shareName}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('shareName', event.target.checked)}
				/>
			</HStack>

			<HStack justifyContent={'space-between'}>
				<Text>Share email address</Text>
				<Switch
					variant='primary'
					isChecked={permissions?.shareEmail}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('shareEmail', event.target.checked)}
				/>
			</HStack>

			<HStack justifyContent={'space-between'}>
				<Text>Share phone number</Text>
				<Switch
					variant='primary'
					isChecked={permissions?.sharePhone}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('sharePhone', event.target.checked)}
				/>
			</HStack>
			
			<Text color='loopGrey.500' opacity='70%'>Contact preferences</Text>

			<Box fontSize={['14px', null, 'md']}>
				<Text as='span' textColor={'loopGrey.400'} opacity={'25%'}>
					Email:
				</Text>{' '}
				<Text as='span' textColor={'loopGrey.500'} opacity={'70%'}>
					{userInfo?.emailAddress}
				</Text>
			</Box>

			<HStack justifyContent={'space-between'}>
				<Text fontSize={['14px', null, 'md']}>Sign up to mailing list</Text>
				<Switch
					variant='primary'
					isChecked={permissions?.allowContactViaEmail}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowContactViaEmail', event.target.checked)}
				/>
			</HStack>
			<Divider my={8} border={'0.5px solid'} />

			<Box>
				<Text as='span' textColor={'loopGrey.400'} opacity={'25%'}>
					Tel:
				</Text>{' '}
				<Text as='span' textColor={'loopGrey.500'} opacity={'70%'}>
					{userInfo?.phoneNumber}
				</Text>
			</Box>

			<HStack justifyContent={'space-between'}>
				<Text>Receive SMS messages</Text>
				<Switch
					variant='primary'
					isChecked={permissions?.allowContactViaSms}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowContactViaSms', event.target.checked)}
				/>
			</HStack>

			<HStack justifyContent={'space-between'}>
				<Text>Receive WhatsApp messages</Text>
				<Switch
					variant='primary'
					isChecked={permissions?.allowContactViaWhatsapp}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowContactViaWhatsapp', event.target.checked)}
				/>
			</HStack>
			<Divider my={8} border={'0.5px solid'} />

			{/* <Text textColor={'loopGrey.500'} opacity={'70%'}>
				More settings
			</Text> */}

			<HStack justifyContent={'space-between'}>
				<Text>
					Allow {brandName} to use the above information for ad-targeting through platforms such as Facebook, Instagram,
					and TikTok
				</Text>
				<Switch
					variant='primary'
					isChecked={permissions?.allowAdTargeting}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowAdTargeting', event.target.checked)}
				/>
			</HStack>
			<Divider my={8} border={'0.5px solid'} />
			<Text color='loopGrey.500' opacity='70%'>Settings on Loop</Text>

			<HStack justifyContent={'space-between'}>
				<Text>Allow {brandName} to contact you on Loop for more paid engagement and special offers</Text>
				<Switch
					variant='primary'
					isChecked={permissions?.allowContactViaLoop}
					isDisabled={isDisabled}
					onChange={(event) => handlePermissionChange('allowContactViaLoop', event.target.checked)}
				/>
			</HStack>
			<Divider border={'2px solid'} />
		</Stack>
	);
};
