import React, { useState, useEffect } from 'react';
import {
	Button,
	useDisclosure,
	HStack,
	Stack,
	Box,
} from '@chakra-ui/react';
import { useGetCountriesQuery } from 'services/localization';
import { ILocalizationCountry } from 'Types/Interface/ILocalizationCountry';
import { ILocalizationLanguage } from 'Types/Interface/ILocalizationLanguage';
import { useLocale } from 'context/LocalizationContext';
import { useNavigate, useParams } from 'react-router-dom';
import { BsGlobe } from 'react-icons/bs';
import Flag from 'react-world-flags';
import LanguageSelector from './LanguageSelector';
import CountrySelector from './CountrySelector';
import StorageService from 'Utils/storageService';

interface ILocalizationSelectorProps {
	fontSize: string;
	direction?: 'row' | 'column';
	showCountrySelector?: boolean;
	colorMode?: 'light' | 'black';
}

const LocalizationSelector = ({ fontSize, direction = 'row', showCountrySelector = true, colorMode = 'black' }: ILocalizationSelectorProps) => {
	const { locale } = useLocale();
	const navigate = useNavigate();
	const { country: urlCountry, locale: urlLanguage } = useParams<{ country: string; locale: string }>();

	// States to manage selected country and language
	const [selectedCountry, setSelectedCountry] = useState<ILocalizationCountry | null>(null);
	const [selectedLanguage, setSelectedLanguage] = useState<ILocalizationLanguage | null>(null);

	// Fetch countries, including their available languages
	const { data: countriesData, isLoading: countriesLoading } = useGetCountriesQuery({ locale });

	// Popover controls for country and language selectors
	const { isOpen: isCountryOpen, onOpen: onCountryOpen, onClose: onCountryClose } = useDisclosure();
	const { isOpen: isLanguageOpen, onOpen: onLanguageOpen, onClose: onLanguageClose } = useDisclosure();

	// Set initial country and language from URL or localStorage (if URL doesn't provide it)
	useEffect(() => {
		if (countriesData?.data) {
			// Try to find the country from the URL first
			const initialCountry = countriesData.data.find((country: ILocalizationCountry) => country.url === urlCountry);

			if (initialCountry) {
				setSelectedCountry(initialCountry);
				const initialLanguage =
					initialCountry.languages.find((lang) => lang.code === urlLanguage) || initialCountry.defaultLanguage;
				setSelectedLanguage(initialLanguage);
			} else {
				// Fallback to localStorage or default behavior if no URL match
				const savedCountryUrl = StorageService.getCountryUrl();
				const savedLanguageCode = StorageService.getLanguage();

				if (savedCountryUrl && savedLanguageCode) {
					const savedCountry = countriesData.data.find((country) => country.url === savedCountryUrl);
					const savedLanguage =
						savedCountry?.languages.find((lang) => lang.code === savedLanguageCode) || savedCountry?.defaultLanguage;
					if (savedCountry && savedLanguage) {
						setSelectedCountry(savedCountry);
						setSelectedLanguage(savedLanguage);
					}
				}
			}
		}
	}, [countriesData, urlCountry, urlLanguage]);

	// Handle country selection
	const handleCountrySelect = (country: ILocalizationCountry) => {
		setSelectedCountry(country);
		setSelectedLanguage(country.defaultLanguage); // Set default language when country is selected
		onCountryClose(); // Close the country selector popup after selection

		// Use a Promise to ensure localStorage updates before navigating
		new Promise<void>((resolve) => {
			StorageService.setCountry(country);
			StorageService.setLanguage(country.defaultLanguage.code);
			resolve();
		}).then(() => {
			window.location.href = getLocalizedCurrentPath(country, country.defaultLanguage); // Force a reload by updating the URL
		});
	};

	// Handle language selection
	const handleLanguageSelect = (language: ILocalizationLanguage) => {
		setSelectedLanguage(language);
		onLanguageClose(); // Close the language selector popup after selection

		if (selectedCountry) {
			StorageService.setLanguage(language.code);
			navigate(getLocalizedCurrentPath(selectedCountry, language)); // Change the URL without reloading the page
		}
	};

	const getCurrentPath = () => window.location.pathname.split('/').slice(3).join('/');

	const getLocalizedCurrentPath = (country: ILocalizationCountry, language: ILocalizationLanguage) => {
		return `/${country.url.toLowerCase()}/${language.code.toLowerCase()}/${getCurrentPath()}`;
	};

	const flagSize = `${parseInt(fontSize) - 2}px`;
	const globeSize = `${parseInt(fontSize) + 2}px`;

	return (<>
		<CountrySelector
			isOpen={isCountryOpen}
			onCountryClose={onCountryClose}
			handleCountrySelect={handleCountrySelect}
			countriesData={countriesData}
			countriesLoading={countriesLoading}
		/>
		<LanguageSelector
			isOpen={isLanguageOpen}
			selectedCountry={selectedCountry}
			selectedLanguage={selectedLanguage}
			onLanguageClose={onLanguageClose}
			handleLanguageSelect={handleLanguageSelect}
		/>

		<Stack flexDirection={direction} spacing={direction == 'column' ? 6 : 0} color={colorMode == 'black' ? 'black' : 'white'}>
			{/* Country Selection Button */}
			{showCountrySelector && (<HStack spacing={1} mr={direction == 'row' ? 7 : 0}>
				<Box width="24px" display="flex" justifyContent="right">
					{selectedCountry?.code ? (
						<Flag code={selectedCountry.code} style={{ height: flagSize }} />
					) : (
						<BsGlobe style={{ width: fontSize }} />
					)}</Box>
				<Button fontSize={fontSize} fontWeight="400" onClick={onCountryOpen}>
					{selectedCountry?.name || 'Select Country'}
				</Button>
			</HStack>)}

			{/* Language Selection Button */}
			<HStack spacing={1}>
				<Box width="24px" display="flex" justifyContent="right">
					<BsGlobe style={{ height: globeSize, width: globeSize }} />
				</Box>
				<Button fontSize={fontSize} fontWeight="400" onClick={onLanguageOpen}>
					{selectedLanguage?.name || 'Select Language'}
				</Button>
			</HStack>
		</Stack>
	</>
	);
};

export default LocalizationSelector;
