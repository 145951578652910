import { Button, Divider, Flex, Stack } from '@chakra-ui/react';
import ErrorComponent from 'Components/ErrorComponent';
import React, { useEffect } from 'react';
import { useGetAllScanHistoryQuery, useGetUserSummaryQuery } from 'services';
import { SummaryItem } from './SummaryItem';
import { ILocalizationProps, ISummaryLocalization } from 'Types/ComponentLocalizations';
import { useLocale } from 'context/LocalizationContext';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { setActiveDashboardTab, setUserSummary } from 'store/slices';

export const SummaryMobile = ({ localization }: ILocalizationProps<ISummaryLocalization>) => {
	const { locale } = useLocale();
	const { data, isLoading, isError } = useGetAllScanHistoryQuery({locale});
	const dispatch = useAppDispatch();
	const { userSummary } = useAppSelector((state) => state.authUser);
	const { data: userSummaryData, isSuccess:isSummarySuccess, isLoading:isUserSummaryLoading } = useGetUserSummaryQuery({locale});
	const navigate = useNavigate();

	useEffect(() => {
		if (isSummarySuccess && userSummaryData?.data) {
			dispatch(setUserSummary(userSummaryData.data));
		}
	}, [isSummarySuccess]);

	const navigateToActivities = (index: number) => {
		dispatch(setActiveDashboardTab(index));
		navigate(AppRoutes.dashboard);
	};

	if (isError) {
		return <ErrorComponent height={'200px'} />;
	}

	return (
		<Stack borderWidth={1} borderRadius={'10px'} spacing={8} justify={'center'} h={'auto'}>
			{/* <Select 
				placeholder='Last 7 days ' 
				w={'100px'}  
				size={'xs'}
				variant={'outline'}
				bg={'rgba(217, 217, 217, 0.06)'}
				border={'1px solid'}
				// borderColor={'rgba(255, 111, 97, 0.5)'}
				borderRadius={'5px'}
				alignSelf={'flex-end'}
				my={4} mx={3}
			>
				<option value='option1'>Option 1</option>
				<option value='option2'>Option 2</option>
				<option value='option3'>Option 3</option>
			</Select> */}
			<Flex direction={'column'} justifyContent={'center'} alignItems={'center'} py={6}>
				<SummaryItem
					title={'Total Points'}
					value={`${((userSummary?.availableRewardPoints ?? 0) + (userSummary?.pendingRewardPoints ?? 0)).toLocaleString()} LNT`}
					isLoading={isUserSummaryLoading}
					fontSize={'25px'}
				/>
				<Button
					color='loop.500'
					mt={'20px'}
					fontWeight={'normal'}
					_hover={{
						textDecoration: 'underline',
					}}
					onClick={() => navigateToActivities(4)}
				>
					See breakdown&nbsp;&nbsp;→
				</Button>
			</Flex>
			<Flex
				padding={4}
				py={6}
				borderBottomRadius={'10px'}
				borderColor={'#FFEEEC'}
				background={'#FFEEEC'}
				justifyContent={'space-between'}
				alignItems={'center'}
				wrap={'wrap'}
			>
				<SummaryItem
					title={localization?.totalScanned}
					value={data?.data.totalScanned || 0}
					isLoading={isLoading}
					fontSize={'14px'}
				/>
				<Divider h={'45px'} orientation='vertical' borderColor={'#FF6F61'} />
				<SummaryItem
					title={localization?.surveysAnswered}
					value={data?.data.surveyCount || 0}
					isLoading={isLoading}
					fontSize={'14px'}
				/>
				<Divider h={'45px'} orientation='vertical' borderColor={'#FF6F61'} />
				<SummaryItem
					title={localization?.brandsLooped}
					value={data?.data.totalBrand || 0}
					isLoading={isLoading}
					fontSize={'14px'}
				/>
			</Flex>
		</Stack>
	);
};