import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, Icon } from '@chakra-ui/icons';
import { IconButton, Flex, Link } from '@chakra-ui/react';
import { AuthLayout } from 'Layouts/AuthLayout';
import { userPreferenceQuestions } from 'Data/UserPreferenceQuestions';
import { MainIntro, PictureUploadStep, StepItem } from './Steps';
import { CubesIcon } from 'Components/Icons';
import { useUserBasicProfile } from 'Hooks';
import { GrSend } from 'react-icons/gr';

const startHeading = {
	mainHeader:
    'Adding basic info helps the brands you love really understand your needs.',
	subHeader: 'So you can see the changes that you like to',
};

export const IntroductionPage = () => {
	const [currentStep, setCurrentStep] = useState(0);
	const [isStartCard, setIsStartCard] = useState(true);
	const [isPictureSection, setIsPictureSection] = useState(false);

	const { setFieldValue, values, handleSubmit } = useUserBasicProfile();

	const icon =
    currentStep === userPreferenceQuestions.length - 1 ? (<Icon as={GrSend} />
    ) : (<ChevronRightIcon color='#FF6F61' boxSize={6} />
    );

	const _navigateRight = () => {
		if (isStartCard) {
			setIsStartCard(false);
			return;
		} else if (currentStep === userPreferenceQuestions.length - 1) {
			handleSubmit();
			setIsPictureSection(true);
		} else {
			setCurrentStep((prev) =>
				Math.min(prev + 1, userPreferenceQuestions.length - 1)
			);
		}
	};

	const _navigateLeft = () => {
		setCurrentStep((prev) => Math.max(prev - 1, 0));
		setIsPictureSection(false);
	};

	const SaveOnSkip = () => {
		handleSubmit();
		setIsPictureSection(true);
	};

	return isPictureSection ? (
		<PictureUploadStep />
	) : (
		<AuthLayout>
			<Flex
				alignItems={[null,'center']}
				h='100vh'
				justifyContent='center'
				bg='white'
				flex={1}
				p={['5', '10']}
			>
				<Flex
					shadow='lg'
					p={['0','50']}
					px={['20px','10']}
					pb='10'
					borderRadius='md'
					boxShadow={['none','0px 0px 90px rgba(0, 0, 0, 0.1)']}
					w={['100%', '500px']}
					h={['auto', '600px']}
					flexDirection='column'
				>
					
					<Flex
						flexDirection='column'
						h='100%'
						justifyContent='space-evenly'
						w='100%'
					>
						{!isStartCard && currentStep > 0 && (
							<IconButton
								onClick={_navigateLeft}
								borderRadius='50%'
								w='max-content'
								bg='#E6E7E8'
								aria-label='previous'
								icon={
									<Icon
										w={5}
										h={5}
										borderRadius='50%'
										bg='#E6E7E8'
										color='blackTextColor.100'
										as={ChevronLeftIcon}
									/>
								}
								mb={[-12,5]}
							/>
						)}
						{isStartCard ? (
							<MainIntro heading={startHeading} icon={<CubesIcon />} />
						) : (
							<StepItem
								stepsLeft={userPreferenceQuestions.length - 2 - currentStep + 1}
								data={userPreferenceQuestions[currentStep]}
								value={values[userPreferenceQuestions[currentStep].name]}
								onChange={(val) =>
									setFieldValue(userPreferenceQuestions[currentStep].name, val)
								}
								activeColor="blackTextColor.100"
								isSurvey={false}
							/>
						)}

						<Flex justifyContent='space-between' w='100%' alignItems='center' >
							{isStartCard ? (<Link
								color='#FF6F61'
								fontSize={13}
								onClick={SaveOnSkip}
							>
								I&apos;ll do it later
							</Link>) : (<Link
								color='#FF6F61'
								fontSize={13}
								onClick={_navigateRight}
							>
								Skip
							</Link>)}

							<IconButton
								aria-label='next'
								w={10}
								h={10}
								mb={1}
								bgColor='#FFEEEC'
								borderRadius='full'
								alignContent='center'
								justifyContent='center'
								icon={icon}
								onClick={_navigateRight}
							/>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</AuthLayout>
	);
};
