import { IPayoutAmount } from './../Types/Interface/ITilia';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import { ITiliaKycStatus } from 'Types/Interface/ITilia';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';
import { IPaginatedResponse } from './types';
import { IHistory } from 'Types/Interface/IHistory';

export const tiliaApi = createApi({
	reducerPath: 'tiliaApi',
	baseQuery,
	tagTypes: ['payoutRequests'],
	endpoints: (builder) => ({
		getPayoutAmount: builder.query<
			IJsonResponse<IPayoutAmount>,
			{ amount: number; paymentMethodId?: string; locale: string }
		>({
			query: ({ amount, paymentMethodId, locale }) => ({
				url: `/tilia/getPayoutAmount?amount=${amount}&paymentMethodId=${paymentMethodId || ''}`,
				headers: getLocalizationHeader(locale),
			}),
		}),

		logPayoutRequest: builder.mutation<unknown, { payoutId: string; locale: string }>({
			query: ({ payoutId, locale }) => ({
				url: `/tilia/logPayoutRequest?payoutId=${payoutId}`,
				method: 'POST',
				headers: getLocalizationHeader(locale),
			}),
			invalidatesTags: ['payoutRequests'],
		}),

		getKycStatus: builder.query<IJsonResponse<ITiliaKycStatus>, { locale: string }>({
			query: ({ locale }) => ({
				url: '/tilia/getKyc',
				headers: getLocalizationHeader(locale),
			}),
		}),

		getPayoutRequests: builder.query<
			IPaginatedResponse<IHistory[]>,
			{ pageNumber: number; pageSize: number; locale: string }
		>({
			query: ({ pageNumber, pageSize, locale }) => ({
				url: `/reward/withdrawals?pageNumber=${pageNumber}&pageSize=${pageSize}`,
				headers: getLocalizationHeader(locale),
			}),
			providesTags: ['payoutRequests'],
		}),
	}),
});

export const {
	useLogPayoutRequestMutation,
	useGetKycStatusQuery,
	useGetPayoutAmountQuery,
	useGetPayoutRequestsQuery,
} = tiliaApi;
