import { routes } from './../Api/apiRoutes';
import { IRegisterPartialUserResponse } from './../Types/Interface/IRegisterPartialUserResponse';
import { IAuthenticateUserResponse } from 'Types/Interface/IAuthenticateUserResponse';
import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';
import { IBrandLoop, IBrandLoopDetails } from './../Types/Interface/IBrandLoop';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IJsonResponse } from 'Types/Interface/IApiRequestResponseBody';
import {
	IChangePasswordDto,
	IPhoneNumberVerificationDto as IVerifyPhoneNumber,
	INewUserRequestbody,
	IPhoneVerificationRequestDetails,
	IResetPasswordDto,
} from 'Types/Interface/IUser';
import { baseQuery, getLocalizationHeader } from '../Utils/queryUtils';
import { IPaginatedResponse } from './types';
import { IUserProductActivity } from 'Types/Interface/IUserProductActivity';
import { TagType } from 'Types/Enums';
import { IPartialUserCreationRequestDto } from 'Types/Interface/IPartialUserCreationRequestDto';

type VerifySocial = {
	data: boolean;
	status: boolean;
};

export const accountApi = createApi({
	reducerPath: 'accountAPI',
	baseQuery,
	tagTypes: [TagType.BrandLoops],
	endpoints: (builder) => ({
		updatePassword: builder.mutation<unknown, { body: IChangePasswordDto; locale: string }>({
			query: ({ body, locale }) => ({
				url: 'account/change-password',
				method: 'PUT',
				body,
				headers: getLocalizationHeader(locale),
			}),
		}),
		resetPassword: builder.mutation<unknown, { body: IResetPasswordDto; locale: string }>({
			query: ({ body, locale }) => ({
				url: 'account/reset-password',
				method: 'POST',
				body,
				headers: getLocalizationHeader(locale),
			}),
		}),
		updatePhoneNumber: builder.mutation<unknown, { body: IVerifyPhoneNumber; locale: string }>({
			query: ({ body, locale }) => ({
				url: '/account/change-phonenumber',
				method: 'POST',
				body,
				headers: getLocalizationHeader(locale),
			}),
		}),
		createAccountWithPhoneNumber: builder.mutation<
			IJsonResponse<IAuthenticateUserResponse>,
			{ body: INewUserRequestbody; locale: string }
		>({
			query: ({ body, locale }) => ({
				url: '/account/create-account-by-phone',
				method: 'POST',
				body,
				headers: getLocalizationHeader(locale),
			}),
		}),
		createPartialAccount: builder.mutation<
			IJsonResponse<IRegisterPartialUserResponse>,
			{ requestDto: IPartialUserCreationRequestDto, locale: string }
		>({
			query: ({ requestDto, locale }) => ({
				url: `${routes.createPartialAccount}`,
				method: 'POST',
				body: requestDto,
				headers: getLocalizationHeader(locale),
			}),
		}),
		verifyGmailAccount: builder.mutation<VerifySocial, { userEmail: string; locale: string }>({
			query: ({ userEmail, locale }) => ({
				url: `/account/verify-existing-gmailaccount?email=${userEmail}`,
				method: 'POST',
				headers: getLocalizationHeader(locale),
			}),
		}),
		verifyFacebookAccount: builder.mutation<VerifySocial, { accessToken: string; locale: string }>({
			query: ({ accessToken, locale }) => ({
				url: `/account/verify-existing-facebookaccount?accessToken=${accessToken}`,
				method: 'POST',
				headers: getLocalizationHeader(locale),
			}),
		}),
		getResetCode: builder.mutation<unknown, { body: IPhoneVerificationRequestDetails; locale: string }>({
			query: ({ body, locale }) => ({
				url: '/account/request-reset-code',
				method: 'POST',
				body,
				headers: getLocalizationHeader(locale),
			}),
		}),
		getBrandLoops: builder.query<
			IPaginatedResponse<IBrandLoop[]>,
			{ pageIndex: number; pageSize: number; locale: string }
		>({
			query: ({ pageIndex, pageSize, locale }) => ({
				url: `userbrandpermission/GetBrandLoops?pageNumber=${pageIndex}&pageSize=${pageSize}`,
				headers: getLocalizationHeader(locale),
			}),
			providesTags: [TagType.BrandLoops],
		}),
		getBrandLoopDetails: builder.query<IJsonResponse<IBrandLoopDetails>, { brandId: string; locale: string }>({
			query: ({ brandId, locale }) => ({
				url: `userbrandpermission/GetBrandLoopDetails/${brandId}`,
				headers: getLocalizationHeader(locale),
			}),
		}),
		getUserProductActivities: builder.query<
			IPaginatedResponse<IUserProductActivity[]>,
			{ brandId: string; pageIndex: number; pageSize: number; locale: string }
		>({
			query: ({ brandId, pageIndex, pageSize, locale }) => ({
				url: `userbrandpermission/GetUserProductActivities?brandId=${brandId}&pageNumber=${pageIndex}&pageSize=${pageSize}`,
				headers: getLocalizationHeader(locale),
			}),
		}),
		updateUserBrandPermission: builder.mutation<unknown, { body: IUserBrandPermission; locale: string }>({
			query: ({ body, locale }) => ({
				url: '/userbrandpermission/AddOrUpdate',
				method: 'POST',
				body,
				headers: getLocalizationHeader(locale),
			}),
			invalidatesTags: [TagType.BrandLoops],
		}),
		verifyEmailWithOtpCodeAndAuthorize: builder.mutation<
			IJsonResponse<IAuthenticateUserResponse>,
			{ email: string; code: string; locale: string }
		>({
			query: ({ email, code, locale }) => ({
				url: `account/login-partial-user-by-email?email=${email}&verificationCode=${code}`,
				method: 'POST',
				headers: getLocalizationHeader(locale),
			}),
		}),
		verifyPhoneWithOtpCodeAndAuthorize: builder.mutation<
			IJsonResponse<IAuthenticateUserResponse>,
			{ data: IVerifyPhoneNumber, locale: string }
		>({
			query: ({ data, locale }) => ({
				url: 'account/login-partial-user-by-phone',
				body: data,
				method: 'POST',
				headers: getLocalizationHeader(locale),
			}),
		}),
	}),
});

export const {
	useUpdatePasswordMutation,
	useCreateAccountWithPhoneNumberMutation,
	useVerifyGmailAccountMutation,
	useVerifyFacebookAccountMutation,
	useGetResetCodeMutation,
	useResetPasswordMutation,
	useUpdatePhoneNumberMutation,
	useGetBrandLoopsQuery,
	useGetBrandLoopDetailsQuery,
	useUpdateUserBrandPermissionMutation,
	useGetUserProductActivitiesQuery,
	useVerifyEmailWithOtpCodeAndAuthorizeMutation,
	useVerifyPhoneWithOtpCodeAndAuthorizeMutation,
	useCreatePartialAccountMutation
} = accountApi;
