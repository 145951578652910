import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, Heading, VStack, Text, Stack } from '@chakra-ui/react';
import React from 'react';
import { AppRoutes } from 'Routes/AppRoutes';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';

interface ICTAProps{
	shouldIgnoreParentPadding: boolean;
	isBenefits?:boolean;
}

export const CTA = ({shouldIgnoreParentPadding, isBenefits}:ICTAProps) => {
	const navigate = useLocalizedNavigate();
	return(
		<VStack 
			align={['flex-start','center']} 
			justifyContent={['center']} 
			bg={['darkCoral',isBenefits ? 'darkCoral' :'#FFEEEC']} 
			w={'full'} 
			minH={['800px', '400px']} 
			{...(shouldIgnoreParentPadding && { px:{ base: '30px', xl: '80px', '2xl': '192px'}})}
		>
			<Stack  direction={['column', null, null, 'row']} w={'full'} justifyContent={['space-between',null, null, null, null,'space-between']} alignItems={['flex-start','center']}>
				<Stack>
					<Text fontSize={'18px'} color={['#ECEFF2',isBenefits ? '#ECEFF2':'#FD9F96']}>Create a community for your customers and start meaningful conversations today! </Text>
					<Heading mb={['40px', null]} fontSize={'40px'} fontWeight={600} textColor={['white', isBenefits ? 'white' : 'darkCoral']} w={['auto','700px']}> Have a Business and Want to Build Your Loop? </Heading>
				</Stack>
				<Button onClick={() => navigate(AppRoutes.contact)} bg={'white'} border={'none'} color={'darkCoral'} variant={'primary'} w={'auto'} h={['50px','60px']}>
					Try Loop with Zero Risk
					<ArrowForwardIcon ml={2} boxSize={['5',5]} />
				</Button>
			</Stack>
		</VStack>
	);
};