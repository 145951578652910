/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
	Button,
	Flex,
	IconButton,
	useMediaQuery,
	Stack,
	Divider,
	Box,
} from '@chakra-ui/react';
import { LoopV2Icon } from 'Components/Icons';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';

import { HamburgerIcon, Icon } from '@chakra-ui/icons';
import { CustomDrawer } from 'Components/CustomDrawer';
import { NavbarLink } from 'Pages/Dashboard/Components/Navbar/elements';
import { getUserAgent, UserAgent } from 'Utils/userAgent';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import LocalizationSelector from 'Components/LocalizationSelector';
import { localizePath } from 'Utils';

interface INavbarProps {
	linkColorMode?: 'light' | 'black'
}

export const NavbarBusiness = ({ linkColorMode = 'light' }: INavbarProps) => {
	const navigate = useLocalizedNavigate();
	const location = useLocation();

	const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');
	const [isIos, setIsIos] = useState(false);

	useEffect(() => {
		const userAgent = getUserAgent();
		setIsIos(userAgent === UserAgent.Ipad || userAgent === UserAgent.Iphone);
	}, []);

	// const _renderRightBarSection = () => {
	// 	return (
	// 		<Flex alignItems='center' direction={['column', 'column', 'column', 'row']}>
	// 			<Button
	// 				variant='ghost'
	// 				color='darkCoral'
	// 				p='2'
	// 				py='2.5'
	// 				fontWeight='bold'
	// 				mr='3'
	// 				mb={[8, 8, 8, 0]}
	// 				fontSize='12px'
	// 				_hover={{ bgColor: 'none' }}
	// 				onClick={() => navigate(AppRoutes.login)}
	// 			>
	// 				<Image src={UserIcon} />
	// 				Login
	// 			</Button>
	// 			<Button variant='primary' shadow='2xl' p='2' py='2.5' fontWeight='bold' fontSize='12px'>
	// 				Download app
	// 			</Button>
	// 		</Flex>
	// 	);
	// };

	return (
		<Flex justifyContent='space-between'>
			<Flex flex={1} justifyContent={'space-between'} alignItems='center'>
				<Flex>
					<LoopV2Icon height={'40px'} onClick={() => navigate(AppRoutes.LoopForBusiness)} mr={'60px'} />
					<Button
						variant='ghost'
						color={location.pathname === localizePath( AppRoutes.LoopForBusiness) ? 'darkCoral' : '#2B2B2B'}
						_active={{ color: 'darkCoral' }}
						fontWeight={'medium'}
						display={['none', 'flex']}
						mb={[8, 8, 8, 0]}
						mr={'30px'}
						fontSize='14px'
						_hover={{ bgColor: 'none' }}
						onClick={() => navigate(AppRoutes.LoopForBusiness)}
					>
						Home
					</Button>
					<Button
						display={['none', 'flex']}
						variant='ghost'
						color={location.pathname === localizePath(AppRoutes.processOverview )? 'darkCoral' : '#2B2B2B'}
						_active={{ color: 'darkCoral' }}
						fontWeight={'medium'}
						mb={[8, 8, 8, 0]}
						mr={'30px'}
						fontSize='14px'
						_hover={{ bgColor: 'none' }}
						onClick={() => navigate(AppRoutes.processOverview)}
					>
						How it works
					</Button>
					{/* <Divider display={['none', 'flex']} mx={'20px'} orientation='horizontal' border={'0.5px solid '} borderColor={'rgba(88, 96, 104, 0.5)'} h={'16px'} position={'relative'} top={'5px'} /> */}
					<Button
						display={['none', 'flex']}
						variant='ghost'
						color={location.pathname === localizePath(AppRoutes.contact) ? 'darkCoral' : '#2B2B2B'}
						_active={{ color: 'darkCoral' }}
						fontWeight={'medium'}
						mb={[8, 8, 8, 0]}
						fontSize='14px'
						_hover={{ bgColor: 'none' }}
						onClick={() => navigate(AppRoutes.contact)}
					>
						Contact
					</Button>
				</Flex>
				{/* {isLargerThan1000 && _renderRightBarSection()} */}
			</Flex>

			{!isLargerThan1000 && (
				<CustomDrawer
					title={<LoopV2Icon height='25px' cursor='pointer' />}
					buttonContent={
						<IconButton
							icon={<Icon as={HamburgerIcon} color={'darkCoral'} w={6} h={6} />}
							aria-label='menu'
							color={linkColorMode === 'light' ? 'white' : 'darkCoral'}
						/>
					}
					isFull
				>
					<Stack alignItems={'flex-start'} spacing={8} mt={20}>
						<Button
							variant='ghost'
							color='#2B2B2B'
							fontWeight={500}
							fontSize='18px'
							_hover={{ bgColor: 'none' }}
							onClick={() => navigate(AppRoutes.LoopForBusiness)}
						>
							Home
						</Button>
						<Divider border={'1.5px solid rgba(88, 96, 104, 0.50)'} />
						<NavbarLink
							linkColorMode={linkColorMode}
							label={'How it works'}
							path={AppRoutes.processOverview}
							sx={{
								fontSize: '18px',
								fontWeight: 500,
								color: '#2B2B2B',
							}}
						/>
						<Divider border={'1.5px solid rgba(88, 96, 104, 0.50)'} />
						<Button
							variant='ghost'
							color='#2B2B2B'
							fontWeight={500}
							fontSize='20px'
							onClick={() => navigate(AppRoutes.contact)}
						>
							Contact
						</Button>
						<Divider border={'1.5px solid rgba(88, 96, 104, 0.50)'} />
						<Button
							variant='ghost'
							color='darkCoral'
							fontWeight={500}
							fontSize='20px'
							onClick={() => navigate(AppRoutes.home)}
						>
							Loop
						</Button>
					</Stack>
					<Box mt={'100px'}>
						<LocalizationSelector fontSize='14px' direction='column' />
					</Box>
					<Button
						variant='primary'
						shadow='2xl'
						borderRadius={'10px'}
						w={'100%'}
						h={'50px'}
						fontWeight={500}
						fontSize='16px'
						mt={'150px'}
					>
						Download app
					</Button>
				</CustomDrawer>
			)}
		</Flex>
	);
};