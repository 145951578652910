import React, { useEffect, useState } from 'react';
import {
	Box,
	Divider,
	Grid,
	GridItem,
	Heading,
	Link as ChakraLink,
	Skeleton,
	Stack,
	Text,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';
import { DashboardSettingLayout } from 'Layouts';
import { useGetBrandLoopDetailsQuery, useUpdateUserBrandPermissionMutation } from 'services';
import { useDefaultErrorHandler } from 'Utils/errorHandling';
import { AppRoutes } from 'Routes/AppRoutes';
import { formatDateShort } from 'Utils/dataTableUtils';
import { BrandPermissions } from 'Components/BrandPermissions';
import { IUserBrandPermission } from 'Types/Interface/IUserBrandPermission';
import { useAppDispatch } from 'Hooks';
import { ProductActivities } from './ProductActivities';
import ErrorCodes from 'Data/ErrorCodes';
import { useLocale } from 'context/LocalizationContext';
import { setUserBrandPermissions } from 'store/slices';
import PriceToggle from 'Components/PriceToggle';

interface ICellProps {
	label: string;
	data: number | string | JSX.Element;
}

export const BrandLoopDetails = () => {
	const { brandId = '' } = useParams();
	const { locale } = useLocale();
	const dispatch = useAppDispatch();

	const { data: brandLoopDetailsData, isError, isLoading: isDataLoading } = useGetBrandLoopDetailsQuery({
		brandId,
		locale,
	});

	const [updateUserBrandPermission, { error: updateError, isError: isUpdateError }] =
		useUpdateUserBrandPermissionMutation();

	useDefaultErrorHandler(isUpdateError, updateError);

	const [cells, setCells] = useState<ICellProps[]>([]);

	useEffect(() => {
		if (brandLoopDetailsData?.data?.userBrandPermission) {
			dispatch(setUserBrandPermissions(brandLoopDetailsData.data.userBrandPermission));
		}
	}, [brandLoopDetailsData, dispatch]);

	useEffect(() => {
		if (brandLoopDetailsData) {
			const brandLoopDetails = brandLoopDetailsData.data;
			const updatedCells = [
				{ label: 'Total scanned', data: brandLoopDetails.scansCount },
				{ label: 'Surveys completed', data: brandLoopDetails.surveysCount },
				{ label: 'Total earned', data: <PriceToggle value={brandLoopDetails.totalEarned} /> },
				{ label: 'Last activity', data: formatDateShort(brandLoopDetails.lastActivityDate) },
				{
					label: 'Highest engagement',
					data: (
						<ChakraLink
							as={ReactRouterLink}
							to={AppRoutes.productPage.replace(':productId', brandLoopDetails.highestEngagementProduct?.id ?? '')}
							textDecoration={'underline'}
							_hover={{ textDecoration: 'none' }}
						>
							{brandLoopDetails.highestEngagementProduct?.name}
						</ChakraLink>
					),
				} as ICellProps,
			];

			setCells(updatedCells);
		}
	}, [brandLoopDetailsData]);

	const handlePermissionChange = (permissions: IUserBrandPermission) => {
		updateUserBrandPermission({ body: permissions, locale });
	};

	if (isError) {
		throw new Error(ErrorCodes.FetchBrandLoopDetails);
	}

	return (
		<DashboardSettingLayout>
			<Heading fontSize={['20px', null, '30px']} fontWeight={600} color={'loopGrey.500'} mb={6}>
				Your{' '}
				<Text as='span' color='darkCoral'>
					Loop
				</Text>{' '}
				with {brandLoopDetailsData?.data?.brandName}
			</Heading>
			<Text fontSize={['12px', null, '14px']} fontWeight={400} lineHeight={['24px']} mb={[8, null, null, 10]} color={'loopGrey.500'}>
				Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore
				magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo
				con sequat.
			</Text>

			{isDataLoading && (
				<Box w='100%'>
					<Stack spacing={5} w='100%'>
						{new Array(2).fill('_').map((_, index) => (
							<Skeleton height='80px' key={index} />
						))}
					</Stack>
				</Box>
			)}
			<Grid templateColumns={['repeat(2, 1fr)','repeat(3, 1fr)']} gap={['8','10']} w={'100%'} >
				{cells.map((cell) => (
					<GridItem key={cell.label} w={'100%'}  >
						<Stack>
							<Text fontSize={['14px', null, 'lg']} color={'loopGrey.500'}>{cell.label}</Text>
							<Text fontSize={['14px', null, '2xl']} color={'brand.black'} fontWeight={[600, 500]}>
								{cell.data}
							</Text>
						</Stack>
					</GridItem>
				))}
			</Grid>
			{/* <Divider my={8} border={'2px solid'} />
			<Heading fontSize={['16px', null, '2xl']} mb={5} color={'blackTextColor.200'}>
				Loop status
			</Heading>
			<Text fontSize={['xs', null, 'sm']} color={'loopGrey.500'} lineHeight={['6']} mb={8}>
				Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore
				magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea commodo
				con sequat.
			</Text>{' '}
			<HStack spacing={3}>
				{brandLoopDetailsData?.data.userBrandPermission?.loopedDate && (
					<Box color={'loopGrey.500'} opacity={'30%'} fontStyle={'italic'} fontSize={['md', null, 'lg']} pl={4}>
						{formatDateShort(brandLoopDetailsData?.data.userBrandPermission?.loopedDate)}
					</Box>
				)}
			</HStack> */}
			<Divider my={8} border={'2px solid'} />
			{/* todo: add PrivacySelection */}
			{brandLoopDetailsData?.data && (
				<>
					<Heading fontSize={['16px', null, '25px']} mb={5} color={'blackTextColor.200'}>
						Information and Contact preferences
					</Heading>
					<BrandPermissions
						brandName={brandLoopDetailsData.data.brandName}
						onUpdate={handlePermissionChange}
					/>
				</>
			)}
			<Heading fontSize={['16px', null, '25px']} mt={7} mb={[0, null, 7]} color={'blackTextColor.200'} >
				Your activity with {brandLoopDetailsData?.data.brandName}
			</Heading>
			<ProductActivities brandId={brandId} />
		</DashboardSettingLayout>
	);
};
