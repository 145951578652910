import React, { useEffect } from 'react';
import { SummaryItem } from './SummaryItem';
import { useGetAllScanHistoryQuery, useGetUserSummaryQuery } from 'services';
import { PieChartIcon, BarcodeScanIcon, CommentIcon, ClickIcon } from 'Components/Icons';
import { Button, Divider, Flex, Stack, VStack } from '@chakra-ui/react';
import ErrorComponent from 'Components/ErrorComponent';
import { ILocalizationProps, ISummaryLocalization } from 'Types/ComponentLocalizations';
import { useLocale } from 'context/LocalizationContext';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes/AppRoutes';
import { setActiveDashboardTab, setUserSummary } from 'store/slices';

export const Summary = ({ localization }: ILocalizationProps<ISummaryLocalization>) => {
	const { locale } = useLocale();
	const { data, isLoading, refetch, isError } = useGetAllScanHistoryQuery({locale});
	const dispatch = useAppDispatch();
	const { userSummary } = useAppSelector((state) => state.authUser);
	const { data: userSummaryData, isSuccess:isSummarySuccess, isLoading:isUserSummaryLoading } = useGetUserSummaryQuery({locale});

	useEffect(() => {
		if (isSummarySuccess && userSummaryData?.data) {
			dispatch(setUserSummary(userSummaryData.data));
		}
	}, [isSummarySuccess]);

	const navigate = useNavigate();

	useEffect(() => {
		refetch();
	}, []);

	if (isError) {
		return <ErrorComponent height={'250px'} />;
	}
	const navigateToActivities = (index: number) => {
		dispatch(setActiveDashboardTab(index));
		navigate(AppRoutes.dashboard);
	};

	return (
		<Stack borderWidth={1} padding={10} py={16} borderRadius={'10px'} borderColor={'#FFEEEC'} background={'#FFEEEC'}>
			{/* <Select 
				placeholder='Last 7 days ' 
				w={'150px'}  
				alignSelf={'flex-end'}
				size={'sm'}
				variant={'outline'}
				bg={'#fff'}
				border={'1px solid'}
				borderColor={'rgba(255, 111, 97, 0.5)'}
				borderRadius={'3px'}
				mt={'-40px'} mb={'30px'}

			>
				<option value='option1'>Option 1</option>
				<option value='option2'>Option 2</option>
				<option value='option3'>Option 3</option>
			</Select> */}
			<Flex justifyContent={'space-between'} h={'117px'} maxH={'auto'}>
				<VStack spacing={'15px'} align={'flex-start'}>
					<SummaryItem
						title={'Total Points'}
						value={`${((userSummary?.availableRewardPoints ?? 0) + (userSummary?.pendingRewardPoints ?? 0)).toLocaleString()} LNT`}
						icon={<PieChartIcon height={'25px'} w={'25px'} />}
						isLoading={isUserSummaryLoading}
					/>
					<Button
						color='loop.500'
						fontWeight={'normal'}
						_hover={{
							textDecoration: 'underline',
						}}
						onClick={() => navigateToActivities(4)}
					>
						See breakdown&nbsp;&nbsp;→
					</Button>
				</VStack>
				
				<Divider orientation='vertical' borderColor={'#FF6F61'} />
				<SummaryItem
					title={localization?.totalScanned}
					value={data?.data.totalScanned || 0}
					icon={<BarcodeScanIcon height={6} w={6} />}
					isLoading={isLoading}
				/>
				<Divider orientation='vertical' borderColor={'#FF6F61'} />
				<SummaryItem
					title={localization?.surveysAnswered}
					value={data?.data.surveyCount || 0}
					icon={<CommentIcon height={6} w={6} />}
					isLoading={isLoading}
				/>
				<Divider orientation='vertical' borderColor={'#FF6F61'} />
				<SummaryItem
					title={localization?.brandsLooped}
					value={data?.data.totalBrand || 0}
					icon={<ClickIcon height={6} w={6} />}
					isLoading={isLoading}
				/>
			</Flex>
		</Stack>
	);
};
