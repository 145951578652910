import React from 'react';

import { Flex, useMediaQuery } from '@chakra-ui/react';
import { ProductNavigation } from '../Components/ProductNavigation';
import { BrandGallery } from 'Components/Gallery/BrandGallery';
import { useGetAllBrandsQuery } from 'services';
import { AppRoutes } from 'Routes/AppRoutes';
import useLocalizedNavigate from 'Utils/useLocalizedNavigate';
import { MobileBrandsSlider } from '../Components/MobileBrandsSlider';
import ErrorComponent from 'Components/ErrorComponent';
import { useLocale } from 'context/LocalizationContext';

interface DiscoverProductsProps {
	title: string | undefined;
	seeAllLinkText: string | undefined;
}

export const DiscoverBrands = (props: DiscoverProductsProps) => {
	const { locale } = useLocale();
	const params = { pageNumber: 1, pageSize: 4, categoryId: '', locale };
	const { data, isLoading, isError } = useGetAllBrandsQuery(params);
	const navigate = useLocalizedNavigate();
	const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

	return (
		<Flex direction={'column'}>
			<ProductNavigation
				onClickLink={() => {
					navigate(AppRoutes.allBrands);
				}}
				title={props.title}
				linkText={props.seeAllLinkText}
			/>
			{isError && <ErrorComponent />}
			{!isError && isLargerThan768 && <BrandGallery visibility={true} brands={data?.data} isLoading={isLoading} />}
			{!isError && !isLargerThan768 && <MobileBrandsSlider brands={data?.data} isLoading={isLoading} />}
		</Flex>
	);
};
