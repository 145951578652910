import { VStack, Text, Heading, chakra } from '@chakra-ui/react';
import { RewardIcon } from 'Components/Icons';
import { AuthContext } from 'context/AuthContext';
import { useAppSelector } from 'Hooks';
import React, { useContext } from 'react';

export const SuccessScan = () => {
	const { codeScanData } = useAppSelector((state) => state.survey);
	const { latestEarnedPoints, pointsForCompletingSurvey } = codeScanData;
	const auth = useContext(AuthContext);

	return (
		<VStack px='5' py='8' minH={['70vh','auto']} spacing={2} justify={'center'} align={'center'} textAlign='center'>
			<RewardIcon />

			<Heading size='sm' pt={4}>
				You have earned{' '}
				<chakra.span fontWeight='bold' color='loop.500'>
					{latestEarnedPoints}&nbsp;LNT
				</chakra.span>{' '}
				just for scanning.
				{(pointsForCompletingSurvey ?? 0) > 0 && (
					<>
						{' '}
						Complete the survey to earn another{' '}
						<chakra.span color='loop.500'>{pointsForCompletingSurvey}&nbsp;LNT</chakra.span>.
					</>
				)}
			</Heading>
			{!auth.isFullyRegisteredUser() && (
				<Text>These points will be kept safe for you in an account associated with your email.</Text>
			)}
		</VStack>
	);
};
